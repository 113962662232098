import { FeelingType, LanguageType, StatusType } from '@enums';

export const getList = `
query MyQuery {
  listPositiveMessages {
    nextToken
    items {
      createdAt
      feelingType
      id
      locale
      message
      status
      updatedAt
    }
  }
}
`;

export const listPositiveMessageQuery = (filter?: {
  status?: StatusType;
  locale?: LanguageType;
  feelingType?: FeelingType;
}) => {
  const filterQuery = `
  (
  filter: 
  {
    ${
      filter?.status
        ? `
        status: 
            {
                eq:${filter.status}
            },
        
        `
        : ''
    }
    ${
      filter?.locale
        ? `
          locale: 
              {
                  eq:${filter.locale}
              },
          
          `
        : ''
    }
    ${
        filter?.feelingType
          ? `
            feelingType: 
                {
                    eq:${filter.feelingType}
                },
            
            `
          : ''
      }
  }
  )
  `;

  return `
query MyQuery {
  listPositiveMessages 
  ${filter ? filterQuery : ''}
  {
    nextToken
    items {
      createdAt
      feelingType
      id
      locale
      message
      status
      updatedAt
    }
  }
}
`;
};

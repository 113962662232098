@if (inputType === 'textarea') {
<textarea
  placeholder="Enter Message"
  class="input text-area"
  [formControl]="control"></textarea>
}@else {
<div class="input-wrapper" [class.has-icon]="icon !== undefined">
  <ng-content select="[prefix]"></ng-content>
  <fa-icon *ngIf="icon" [icon]="icon" class="icon"></fa-icon>
  <input
    #inputElement
    class="input"
    [class.split-left]="split === 'left'"
    [class.split-right]="split === 'right'"
    [type]="inputType"
    [placeholder]="placeholder ? placeholder : ' '"
    [formControl]="control"
    [autocomplete]="autocomplete"
    [class.danger]="danger"
    [ngClass]="size === 'md' ? 'md' : 'sm'"
    [min]="min"
    [max]="max"
    (paste)="onPaste()"
    [style.padding-right.rem]="paddingRight"
    (keydown.enter)="preventEnter ? $event.preventDefault() : null" />

  @if (badge()) { @if (control.status === inputStatus.Valid) {
  <fa-icon class="success-icon" icon="circle-check"></fa-icon>
  }@else if (control.status === inputStatus.PENDING) {
  <fa-icon class="loading-icon" icon="spinner"></fa-icon>
  }@else if(control.status === inputStatus.Invalid && control.touched){
  <fa-icon class="error-icon" icon="circle-exclamation"></fa-icon>
  } }

  <ng-content select="[suffix]"></ng-content>
</div>
}

<app-input-error-message
  [showMessage]="control.getError('required') && control.touched"
  [message]="'error-message.required' | translate" />

<app-input-error-message
  [showMessage]="control.getError('maxlength') && control.touched"
  [message]="
    control.getError('maxlength')
      ? ('text.max-allowed-is' | translate)+' ' +
        control.getError('maxlength').requiredLength +
        ' ' +
        ('text.characters' | translate)
      : ''
  " />

<app-input-error-message
  [showMessage]="control.getError('email') && control.touched"
  [message]="'error-message.email' | translate" />

<app-input-error-message
  [showMessage]="control.getError('type') == validatorType.ERROR"
  [message]="control.getError('message') | translate" />

<div
  class="chip"
  [ngClass]="{
    yellow: color === 'yellow',
    green: color === 'green',
    gray: color === 'gray',
    orange: color === 'orange',
    blue: color === 'blue',
    red: color === 'red',
    white: color === 'white',
  }"
>
  <ng-content></ng-content>
  @if(removable){
  <button class="remove-btn" (click)="removeChip()">
    <fa-icon icon="xmark"></fa-icon>
  </button>
  }
</div>

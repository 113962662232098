import { Injectable } from '@angular/core';
import { concatMap, map, Observable, of } from 'rxjs';
import { ProgressService, BaseService } from '@services';
import { LanguageType, ModelType, StatusType, TagType } from '@enums';
import { MediaInterface } from '@interfaces';
import {
  InsightAttributes,
  InsightInterface,
  InsightCreateInterface,
  InsightUpdateInterface,
} from '@app/feature/insight/models';
import { listInsightQuery } from './insight.query';

@Injectable({
  providedIn: 'root',
})
export class InsightService extends BaseService<InsightInterface> {
  private readonly modelType = ModelType.INSIGHT;
  constructor(private progressService: ProgressService) {
    super();
  }

  public getListByQuery(filter?: {
    locale?: LanguageType;
    tag?: TagType[];
    status?: StatusType;
  }): Observable<InsightInterface[]>{
    return this.getAllDataByQuery(listInsightQuery(filter)).pipe(
      map((res:any) => {
        return res.data.listInsights.items;
      })
    )
  }

  public getAllInsights(): Observable<InsightInterface[]> {
    return this.getAllData(this.modelType).pipe(
      map((res) => {
        return res.data;
      })
    );
  }

  public getInsightById(id: string): Observable<InsightInterface> {
    return this.getDataById(id, this.modelType).pipe(map((res) => res.data));
  }

  public createInsight(data: InsightCreateInterface): Observable<any> {
    this.progressService.setProgress(
      27,
      `Uploading insight thumbnail...`
    );
    return this.uploadInsightThumbnail(data[InsightAttributes.THUMBNAIL]).pipe(
      concatMap((res) => {
        data[InsightAttributes.THUMBNAIL].url = res.path;

        delete data[InsightAttributes.THUMBNAIL].value;
        this.progressService.setProgress(
          42,
          `Uploading insight video...`
        );
        return this.uploadInsightVideo(data[InsightAttributes.VIDEO]);
      }),
      concatMap((res) => {
        data[InsightAttributes.VIDEO].url = res.path;
        delete data[InsightAttributes.VIDEO].value;
        this.progressService.setProgress(88, 'Uploading insight data...');
        return this.addData(data, this.modelType);
      }),
      concatMap((res) => {
        this.progressService.setProgress(100, 'Create insight success!');
        return of(res);
      })
    );
  }

  public updateInsight(data: InsightUpdateInterface): Observable<any> {
    this.progressService.setProgress(27, 'Updating insight data...');
    return this.uploadInsightThumbnail(data[InsightAttributes.THUMBNAIL]).pipe(
      concatMap((res) => {
        data[InsightAttributes.THUMBNAIL].url = res.path;

        delete data[InsightAttributes.THUMBNAIL].value;
        this.progressService.setProgress(
          42,
          `updating insight thumbnail & video...`
        );
        return this.uploadInsightVideo(data[InsightAttributes.VIDEO]);
      }),
      concatMap((res) => {
        data[InsightAttributes.VIDEO].url = res.path;
        delete data[InsightAttributes.VIDEO].value;
        this.progressService.setProgress(88, 'Updating insight data...');
        return this.updateData(data, this.modelType);
      }),
      concatMap((res) => {
        this.progressService.setProgress(100, 'Update insight success!');
        return of(res);
      })
    );
  }

  public deleteInsight(data: InsightInterface): Observable<any> {
    if (data.thumbnail.url !== undefined) {
      this.progressService.setProgress(44, 'removing thumbnail...');
      return this.removeFile(data.thumbnail.url).pipe(
        concatMap(() => {
          if (data.video.url !== undefined) {
            this.progressService.setProgress(67, 'removing video...');
            return this.removeFile(data.video.url);
          }
          
          return of([]);
        }),
        concatMap(() => {
          this.progressService.setProgress(67, 'delete insight data...');
          return this.deleteData(data.id, this.modelType);
        })
      );
    }

    return of([]);
  }

  public uploadInsightThumbnail(thumbnailData: MediaInterface): Observable<any> {
    const basePath = 'insight/thumbnails/';
    return this.uploadFile(basePath, thumbnailData);
  }

  public uploadInsightVideo(videoData: MediaInterface): Observable<any> {
    const basePath = 'insight/videos/';
    return this.uploadFile(basePath, videoData);
  }
}

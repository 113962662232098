import { Component } from '@angular/core';
import { PlacementType } from '@enums';
import { FilterService } from '@services';

@Component({
  selector: 'app-hack-placement-dropdown',
  standalone: true,
  imports: [],
  templateUrl: './hack-placement-dropdown.component.html',
  styleUrl: './hack-placement-dropdown.component.scss'
})
export class HackPlacementDropdownComponent {
  public readonly hackPlacementFilter = this.filterService.getHackPlacementFilter();

  constructor(private filterService: FilterService) {}

  public checkboxChange(hackPlacement: PlacementType, event: Event): void {
    const inputElement = event.target as HTMLInputElement;
    this.filterService.setHackPlacementFilter(hackPlacement, inputElement.checked);
  }
}

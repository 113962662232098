import { Injectable } from '@angular/core';
import { concatMap, map, Observable, of } from 'rxjs';
import { BaseService, ProgressService } from '@services';
import { LanguageType, ModelType, PlacementType, StatusType, TagType } from '@enums';
import { MediaInterface } from '@interfaces';
import {
  HackAttributes,
  HackInterface,
  HackCreateInterface,
  HackUpdateInterface,
} from '@app/feature/hack/models';
import { listHackQuery, listRelatedContentQuery } from './hack.query';

@Injectable({
  providedIn: 'root',
})
export class HackService extends BaseService<HackInterface> {
  readonly modelType = ModelType.HACK;
  constructor(private progressService: ProgressService) {
    super();
  }

  public getListByQuery(filter?: {
    locale?: LanguageType;
    hackPlacement?: PlacementType;
    tag?: TagType[];
    status?: StatusType;
  }): Observable<HackInterface[]> {
    return this.getAllDataByQuery(listHackQuery(filter)).pipe(
      map((res: any) => {
        return res.data.listHacks.items;
      })
    );
  }

  public getRelatedContentByQuery(filter?: {
    locale?: LanguageType;
    tag?: TagType[];
  }): Observable<any> {
    return this.getAllDataByQuery(listRelatedContentQuery(filter)).pipe(
      map((res:any) => {
        return res.data.getRelatedContentByTags;
      })
    )
  }

  public getAllHacks(): Observable<HackInterface[]> {
    return this.getAllData(this.modelType).pipe(
      map((res) => {
        return res.data;
      })
    );
  }

  

  public getHackById(id: string): Observable<HackInterface> {
    return this.getDataById(id, this.modelType).pipe(map((res) => res.data));
  }

  public createHack(data: HackCreateInterface): Observable<any> {
    this.progressService.setProgress(27, `Uploading hack thumbnail...`);
    return this.uploadHackThumbnail(data[HackAttributes.THUMBNAIL]).pipe(
      concatMap((res) => {
        data[HackAttributes.THUMBNAIL].url = res.path;

        delete data[HackAttributes.THUMBNAIL].value;
        this.progressService.setProgress(42, `Uploading hack video...`);
        return this.uploadHackVideo(data[HackAttributes.VIDEO]);
      }),
      concatMap((res) => {
        data[HackAttributes.VIDEO].url = res.path;
        delete data[HackAttributes.VIDEO].value;
        this.progressService.setProgress(88, 'Uploading hack data...');
        return this.addData(data, this.modelType);
      }),
      concatMap((res) => {
        this.progressService.setProgress(100, 'create hack success!');
        return of(res);
      })
    );
  }

  public updateHack(data: HackUpdateInterface): Observable<any> {
    this.progressService.setProgress(27, `Updating hack data...`);
    return this.uploadHackThumbnail(data[HackAttributes.THUMBNAIL]).pipe(
      concatMap((res) => {
        data[HackAttributes.THUMBNAIL].url = res.path;

        delete data[HackAttributes.THUMBNAIL].value;
        this.progressService.setProgress(
          42,
          `Updating hack thumbnail & video...`
        );
        return this.uploadHackVideo(data[HackAttributes.VIDEO]);
      }),
      concatMap((res) => {
        data[HackAttributes.VIDEO].url = res.path;
        delete data[HackAttributes.VIDEO].value;
        this.progressService.setProgress(88, 'Updating hack data...');
        return this.updateData(data, this.modelType);
      }),
      concatMap((res) => {
        this.progressService.setProgress(100, 'Update hack success!');
        return of(res);
      })
    );
  }

  public deleteHack(data: HackInterface): Observable<any> {
    if (data.thumbnail.url !== undefined) {
      this.progressService.setProgress(44, 'removing thumbnail...');
      return this.removeFile(data.thumbnail.url).pipe(
        concatMap(() => {
          if (data.video.url !== undefined) {
            this.progressService.setProgress(67, 'removing video...');
            return this.removeFile(data.video.url);
          }
          
          return of([]);
        }),
        concatMap(() => {
          this.progressService.setProgress(67, 'delete hack data...');
          return this.deleteData(data.id, this.modelType);
        })
      );
    }

    return of([]);
  }

  public uploadHackThumbnail(thumbnailData: MediaInterface) {
    const basePath = 'hack/thumbnails/';
    return this.uploadFile(basePath, thumbnailData);
  }

  public uploadHackVideo(videoData: MediaInterface) {
    const basePath = 'hack/videos/';
    return this.uploadFile(basePath, videoData);
  }
}

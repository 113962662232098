import { Injectable, model } from '@angular/core';
import { generateClient } from 'aws-amplify/api';
import { from, map, Observable, of } from 'rxjs';
import { ModelType } from '@enums';
import { IResponse, MediaInterface } from '@app/core/interfaces';
import { uploadData } from '@aws-amplify/storage';
import { remove } from 'aws-amplify/storage';
import { query } from '@angular/animations';

@Injectable({
  providedIn: 'root'
})
export class BaseService<T> {
  private amplifyClient = generateClient<any>({ authMode: 'userPool' });

  constructor() {}

  protected getAllData(modelName: ModelType): Observable<IResponse<T[]>> {
    if (this.amplifyClient.models[modelName]) {
      return from(
        this.amplifyClient.models[modelName]['list'] as unknown as Observable<
          IResponse<T[]>
        >
      );
    }

    return from(Promise.reject());
  }

  protected getAllDataByQuery(query: string): Observable<IResponse<any[]>> {
    return from(
      this.amplifyClient.graphql({
        query: query
      })
    );
  }

  protected getAllDataChallenge(
    query: string,
    modelName: ModelType
  ): Observable<any> {
    if (this.amplifyClient.models[modelName]) {
      return from(
        this.amplifyClient.graphql({
          query: query
        })
      );
    }

    return from(Promise.reject());
  }

  protected getListData(limit: number, nextToken: '', modelName: ModelType) {
    this.amplifyClient.models[modelName]['list']({
      limit: limit,
      nextToken: nextToken
    });
  }

  protected getDataById(
    id: string,
    modelName: ModelType
  ): Observable<IResponse<T>> {
    if (this.amplifyClient.models[modelName]) {
      return from(
        this.amplifyClient.models[modelName]['get']({
          id: id
        }) as unknown as Observable<IResponse<T>>
      );
    }

    return from(Promise.reject());
  }

  protected addData(data: any, modelName: ModelType): Observable<any> {
    if (this.amplifyClient.models[modelName]) {
      return from(this.amplifyClient.models[modelName]['create'](data));
    }
    return from(Promise.reject());
  }

  protected updateData(data: any, modelName: ModelType): Observable<any> {
    if (this.amplifyClient.models[modelName]) {
      return from(this.amplifyClient.models[modelName]['update'](data));
    }
    return from(Promise.reject());
  }

  protected deleteData(id: string, modelName: ModelType): Observable<any> {
    if (this.amplifyClient.models[modelName]) {
      return from(
        this.amplifyClient.models[modelName]['delete']({
          id: id
        })
      );
    }

    return from(Promise.reject());
  }

  protected uploadFile(
    basePath: string,
    file: MediaInterface
  ): Observable<any> {
    return file.value
      ? from(
          uploadData({
            path: basePath + file.title,
            data: file.value
          }).result
        )
      : of({
          path: file.url
        });
  }

  protected removeFile(path: string): Observable<any> {
    return from(
      remove({
        path: path
      })
    );
  }
}

<app-dialog [disableClose]="true">
  <div class="media-view-dialog-wrap">
    <div class="media-view-dialog-content">
      @if (data.mediaType === 'image') {
      <img [src]="data.media" alt="image" />
      }@else {
      <video controls autoplay>
        <source [src]="data.media" type="video/mp4" />
        Your browser does not support the video tag.
      </video>
      }
    </div>
  </div>
  <div class="button-wrap">
    <button
      appButton="secondary"
      class="outline"
      [stretch]="true"
      (click)="close()"
    >
      <app-label>{{ "button.close" | translate }}</app-label>
    </button>
  </div>
</app-dialog>

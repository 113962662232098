import { ContentDataValueType } from '@enums';
import { ChallengeAttributes } from './enums/challenge-attributes.enum';

interface tableHeaderInterface {
  attribute: ChallengeAttributes | 'action';
  headerTitle: string;
}

interface tableUniqueTableValue {
  columnName: ChallengeAttributes;
  type: ContentDataValueType;
}

export const ChallengeHeaders: tableHeaderInterface[] = [
  {
    attribute: ChallengeAttributes.TITLE,
    headerTitle: 'Challenge Title',
  },
  {
    attribute: ChallengeAttributes.THUMBNAIL,
    headerTitle: 'Thumbnail',
  },
  {
    attribute: ChallengeAttributes.CREATED_AT,
    headerTitle: 'Date Created',
  },
  {
    attribute: ChallengeAttributes.STATUS,
    headerTitle: 'Status',
  },
  {
    attribute: 'action',
    headerTitle: 'Action',
  },
];

export const ChallengeColumns = [
  ChallengeAttributes.TITLE,
  ChallengeAttributes.THUMBNAIL,
  ChallengeAttributes.CREATED_AT,
  ChallengeAttributes.STATUS,
  'action',
];

export const ChallengeUniqueTableValue: tableUniqueTableValue[] = [
  {
    columnName: ChallengeAttributes.THUMBNAIL,
    type: ContentDataValueType.IMAGE,
  },
  {
    columnName: ChallengeAttributes.STATUS,
    type: ContentDataValueType.CHIP,
  },
];

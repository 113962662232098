import { FormControl, FormGroup, Validators } from '@angular/forms';
import { InsightInterface } from './interfaces/insight.interface';
import { InsightAttributes } from './enums/insight-attributes.enum';
import { LanguageType, TagType } from '@enums';
import { MediaInterface, QuizInterface } from '@interfaces';

const field = InsightAttributes;

export type InsightCreateInterface = Pick<
  InsightInterface,
  | InsightAttributes.TITLE
  | InsightAttributes.LOCALE
  | InsightAttributes.THUMBNAIL
  | InsightAttributes.VIDEO
  | InsightAttributes.TAGS
  | InsightAttributes.QUIZ
  | InsightAttributes.PUBLISH_ON
  | InsightAttributes.STATUS
  | InsightAttributes.SEARCH_KEY
  | InsightAttributes.TYPE
  | InsightAttributes.PUBLISHED_AT
>;

export type InsightUpdateInterface = Pick<
  InsightInterface,
  | InsightAttributes.ID
  | InsightAttributes.TITLE
  | InsightAttributes.LOCALE
  | InsightAttributes.THUMBNAIL
  | InsightAttributes.VIDEO
  | InsightAttributes.TAGS
  | InsightAttributes.QUIZ
  | InsightAttributes.PUBLISH_ON
  | InsightAttributes.STATUS
  | InsightAttributes.SEARCH_KEY
  | InsightAttributes.TYPE
  | InsightAttributes.PUBLISHED_AT
>;

export const InsightFormField = new FormGroup({
  [field.TITLE]: new FormControl<string | null>(null, [
    Validators.required,
  ]),
  [field.LOCALE]: new FormControl<LanguageType>(
    LanguageType.DEFAULT,
    [Validators.required]
  ),
  [field.THUMBNAIL]: new FormControl<MediaInterface | ''>('', [
    // Validators.required,
  ]),
  [field.VIDEO]: new FormControl<MediaInterface | ''>('', [
    // Validators.required,
  ]),
  [field.TAGS]: new FormControl<TagType[]>(
    [],
    [Validators.required]
  ),
  [field.QUIZ]: new FormControl<QuizInterface | null>(null),
  [field.PUBLISH_ON]: new FormControl<string[]>(
    [],
    [Validators.required]
  ),
  [field.TYPE]: new FormControl<'Insights'>('Insights'),
  [field.PUBLISHED_AT]: new FormControl<string|null>(null),
});

export const InsightLocaleFormField = new FormGroup({
  [field.LOCALE]: new FormControl<LanguageType>(
    LanguageType.DEFAULT,
    [Validators.required]
  ),
});

// Update Purpose
export const InsightCurrentData: {
  [field.THUMBNAIL]: MediaInterface | undefined;
  [field.VIDEO]: MediaInterface | undefined;
  [field.QUIZ]: QuizInterface | undefined;
} = {
  [field.THUMBNAIL]: undefined,
  [field.VIDEO]: undefined,
  [field.QUIZ]: undefined,
};

import { Injectable } from '@angular/core';
import { InputType, StatusType } from '@enums';

@Injectable({
  providedIn: 'root'
})
export class TypeService {
  readonly inputType = InputType;
  readonly status = StatusType;
}

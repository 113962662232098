@for (tag of tagFilter; track $index) {
<div class="checkbox-wrap">
  <input
    type="checkbox"
    class="checkbox-input"
    (change)="checkboxChange(tag.value, $event)"
    [checked]="tag.isEnabled"
  />
  <span class="checkbox-title">
    {{ tag.title }}
  </span>
</div>
}

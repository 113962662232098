import { LanguageType } from '@enums';

export const localeOptions = [
  {
    name: 'English',
    value: LanguageType.EN,
  },
  {
    name: 'Dutch',
    value: LanguageType.NL,
  },
];

export const localeContentOptions = [
  {
    name: 'English Content',
    value: LanguageType.EN,
  },
  {
    name: 'Dutch Content',
    value: LanguageType.NL,
  },
]

import { LanguageType, StatusType, TagType } from '@enums';

export const listInsightQuery = (filter?: {
  locale?: LanguageType;
  tag?: TagType[];
  status?: StatusType;
}) => {

  let tagQuery = '';

  filter?.tag?.map((tag) => {
    tagQuery += `{ tags: { contains: "${tag}" } },`;
  });

  const filterQuery = `
  (
  filter: 
  {
    ${
      filter?.tag
        ? `
        or: [
          ${tagQuery}
        ],
        `: ''
    }
    ${
      filter?.status
        ? `
        status: 
            {
                eq:${filter.status}
            },
        
        `
        : ''
    }
    ${
      filter?.locale
        ? `
          locale: 
              {
                  eq:${filter.locale}
              },
          
          `
        : ''
    }
  }
  )
  `;
  return `
    query MyQuery {
  listInsights 
  ${filter ? filterQuery : ''}
  {
    items {
      createdAt
      id
      locale
      publishOn
      type
      publishedAt
      quiz {
        options {
          id
          isCorrect
          option
        }
        question
      }
      searchKey
      status
      tags
      thumbnail {
        duration
        size
        title
        url
      }
      title
      updatedAt
      video {
        duration
        size
        title
        url
      }
    }
  }
}
`;
};

export enum MilestoneAttributes {
  ID = 'id',
  START_DATE = 'startDate',
  END_DATE = 'endDate',
  STICKER = 'sticker',
  STICKERS = 'stickers',
  STICKER_PACKS = 'stickerPacks',
  MISSIONS = 'missions',
  STATUS = 'status',
  CREATED_AT = 'createdAt',
  UPDATED_AT = 'updatedAt',
  PACK_NAME = 'packName',
  TRAY = 'tray',
  SHORTCUT = 'shortcut'
}

import { Injectable } from '@angular/core';
import { BaseService } from '../../base/base.service';
import {
  PositiveMessageCreateInterface,
  PositiveMessageInterface,
  PositiveMessageUpdateInterface,
} from '@app/feature/positive-message/models';
import { FeelingType, LanguageType, ModelType, StatusType } from '@app/core/enums';
import { ProgressService } from '../../progress/progress.service';
import { concatMap, map, Observable, of } from 'rxjs';
import { listPositiveMessageQuery } from './positive-message.query';

@Injectable({
  providedIn: 'root',
})
export class PositiveMessageService extends BaseService<PositiveMessageInterface> {
  readonly modelType = ModelType.POSITIVE_MESSAGE;

  constructor(private progressService: ProgressService) {
    super();
  }

  public getAllPositiveMessages(): Observable<PositiveMessageInterface[]> {
    return this.getAllData(this.modelType).pipe(
      map((res) => {
        return res.data;
      })
    );
  }

  public getListByQuery(filter?: {
    status?: StatusType;
    locale?: LanguageType;
    feelingType?: FeelingType;
  }): Observable<PositiveMessageInterface[]> {
    return this.getAllDataByQuery(listPositiveMessageQuery(filter)).pipe(
      map((res: any) => {
        return res.data.listPositiveMessages.items;
      })
    );
  }

  public getAllPositiveMessages2(): Observable<PositiveMessageInterface[]> {
    return this.getAllDataByQuery(getPositiveMessagesQuery).pipe(
      map((res: any) => {
        return res.data.listPositiveMessages.items;
      })
    );
  }

  public getPositiveMessageById(
    id: string
  ): Observable<PositiveMessageInterface> {
    return this.getDataById(id, this.modelType).pipe(map((res) => res.data));
  }

  public createPositiveMessage(
    data: PositiveMessageCreateInterface
  ): Observable<any> {
    this.progressService.setProgress(27, `Uploading positive message data...`);

    return this.addData(data, this.modelType).pipe(
      concatMap((res) => {
        this.progressService.setProgress(
          100,
          'Create positive-message success!'
        );
        return of(res);
      })
    );
  }

  public updatePositiveMessage(
    data: PositiveMessageUpdateInterface
  ): Observable<any> {
    this.progressService.setProgress(27, 'Updating positive message data');
    return this.updateData(data, this.modelType).pipe(
      concatMap((res) => {
        this.progressService.setProgress(
          100,
          'Update positive message success!'
        );
        return of(res);
      })
    );
  }

  public deletePositiveMessage(id: string): Observable<any> {
    console.log('id', id);
    return this.deleteData(id, this.modelType);
  }
}

const getPositiveMessagesQuery = `
query MyQuery {
  listPositiveMessages {
    nextToken
    items {
      createdAt
      feelingType
      id
      locale
      message
      status
      updatedAt
    }
  }
}
`;

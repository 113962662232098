import { LanguageType, PlacementType, StatusType, TagType } from '@enums';

export const listRelatedContentQuery = (filter?: {
  locale?: LanguageType;
  tag?: TagType[];
}) => {

  // TODO!: FIX THIS
  let tagQuery = '';
  
  filter?.tag?.map((tag) => {
    tagQuery += `"${tag}",`;
  });
  const filterQuery = `
  (
  tags: [${tagQuery}]
  )
  `;

  return `
  query MyQuery {
  getRelatedContentByTags
  ${filter ? filterQuery : '(tags: "")'}
  {
    hacks {
      id
      title
      tags
      thumbnail{
        url
      }
    }
    insights {
      id
      title
      tags
      thumbnail{
        url
      }
    }
  }
}`;
};

export const listHackQuery = (filter?: {
  locale?: LanguageType;
  status?: StatusType;
  tag?: TagType[];
  hackPlacement?: PlacementType;
}) => {
  let tagQuery = '';

  filter?.tag?.map((tag) => {
    tagQuery += `{ tags: { contains: "${tag}" } },`;
  });

  const filterQuery = `
  (
  filter: 
  {
    ${
      filter?.tag
        ? `
        or: [
          ${tagQuery}
        ],
        `
        : ''
    }
    ${
      filter?.status
        ? `
        status: 
            {
                eq:${filter.status}
            },
        
        `
        : ''
    }
    ${
      filter?.locale
        ? `
          locale: 
              {
                  eq:${filter.locale}
              },
          
          `
        : ''
    }
    ${
      filter?.hackPlacement
        ? `
          hackPlacement: 
              {
                  eq:${filter.hackPlacement}
              },
          
          `
        : ''
    }
  }
  )
  `;
  return `
    query MyQuery {
  listHacks 
  ${filter ? filterQuery : ''}
  {
    items {
      title
      thumbnail {
        duration
        size
        title
        url
      }
      updatedAt
      tags
      status
      searchKey
      publishOn
      locale
      id
      type
      publishedAt
      hackPlacement
      createdAt
      quiz {
        question
        options {
          id
          isCorrect
          option
        }
      }
      reflectionPoll {
        options {
          count
          id
          option
        }
        prompt
        totalParticipants
      }
      relatedContents {
        hacks
        insights
      }
      video {
        duration
        size
        title
        url
      }
    }
  }
}
`;
};


import { Component, EventEmitter, Input, Output } from '@angular/core';
import { LabelComponent } from '@components';
import { TranslateModule } from '@ngx-translate/core';
import { ButtonDirective } from '@directives';

@Component({
  selector: 'app-table-no-data-value',
  standalone: true,
  imports: [LabelComponent, TranslateModule, ButtonDirective],
  templateUrl: './table-no-data-value.component.html',
  styleUrl: './table-no-data-value.component.scss',
})
export class TableNoDataValueComponent {
  @Input()
  public dataCount!: number;

  @Input({ required: true })
  public modelType!: 'hack' | 'positive message' | 'insight' | 'challenge' | 'milestone';

  @Input({required:true})
  public buttonUrl!: string;

  @Output()
  public buttonClick = new EventEmitter<void>();

  protected noDataIllustration =
    'assets/images/illustrations/no-data-illustration.png';

  protected noDataAlt = 'No Data Matching';

  public createData() {
    this.buttonClick.emit();
  }
}

import { Injectable, signal } from '@angular/core';

import * as filterData from './filter-data';
import { FeelingType, LanguageType, StatusType, TagType, PlacementType } from '@enums';

@Injectable({
  providedIn: 'root',
})
export class FilterService {
  public currentLocaleFilter = signal<any>(filterData.localeFilter);
  public currentStatusFilter = signal<any>(filterData.statusFilter);
  public currentFeelingTypeFilter = signal<any>(filterData.feelingTypeFilter);
  public currentTagFilter = signal<any>(filterData.tagFilter);
  public currentHackPlacementFilter = signal<any>(filterData.hackPlacementFilter)

  constructor() {}

  public getLocaleFilter() {
    return this.currentLocaleFilter();
  }

  public setLocaleFilter(locale: LanguageType, isEnabled: boolean) {
    let updatestLocaleFilter = this.currentLocaleFilter().map((l: any) => {
      if (l.value === locale) {
        l.isEnabled = isEnabled;
      } else {
        l.isEnabled = false;
      }

      return l;
    });

    this.currentLocaleFilter.set(updatestLocaleFilter);
  }

  public resetLocaleFilter() {
    let defaultLocaleFilter = this.currentLocaleFilter().map((l: any) => {
      l.isEnabled = false;
      return l;
    });
    this.currentLocaleFilter.set(defaultLocaleFilter);
  }

  public getStatusFilter() {
    return this.currentStatusFilter();
  }

  public setStatusFilter(status: StatusType, isEnabled: boolean) {
    let updatestStatusFilter = this.currentStatusFilter().map((s: any) => {
      if (s.value === status) {
        s.isEnabled = isEnabled;
      }
      // TODO!: REMOVE THE ELSE STATEMENT WHEN THE BACKEND READY
      else {
        s.isEnabled = false;
      }

      return s;
    });

    this.currentStatusFilter.set(updatestStatusFilter);
  }

  public resetStatusFilter() {
    let defaultStatusFilter = this.currentStatusFilter().map((s: any) => {
      s.isEnabled = false;
      return s;
    });
    this.currentStatusFilter.set(defaultStatusFilter);
  }

  public getFeelingTypeFilter() {
    return this.currentFeelingTypeFilter();
  }

  public setFeelingTypeFilter(feelingType: FeelingType, isEnabled: boolean) {
    let updatestFeelingTypeFilter = this.currentFeelingTypeFilter().map(
      (ft: any) => {
        if (ft.value === feelingType) {
          ft.isEnabled = isEnabled;
        }
        // TODO!: REMOVE THE ELSE STATEMENT WHEN THE BACKEND READY
        else {
          ft.isEnabled = false;
        }

        return ft;
      }
    );

    this.currentFeelingTypeFilter.set(updatestFeelingTypeFilter);
  }

  public resetFeelingTypeFilter() {
    let defaultFeelingTypeFilter = this.currentFeelingTypeFilter().map(
      (ft: any) => {
        ft.isEnabled = false;
        return ft;
      }
    );
    this.currentFeelingTypeFilter.set(defaultFeelingTypeFilter);
  }

  public getTagFilter() {
    return this.currentTagFilter();
  }

  public setTagFilter(tag: TagType, isEnabled: boolean) {
    let updatestTagFilter = this.currentTagFilter().map(
      (t: any) => {
        if (t.value === tag) {
          t.isEnabled = isEnabled;
        }
        return t;
      }
    );

    this.currentTagFilter.set(updatestTagFilter);
  }

  public resetTagFilter() {
    let defaultTagFilter = this.currentTagFilter().map(
      (t: any) => {
        t.isEnabled = false;
        return t;
      }
    );
    this.currentTagFilter.set(defaultTagFilter);
  }

  public getHackPlacementFilter() {
    return this.currentHackPlacementFilter();
  }

  public setHackPlacementFilter(hackPlacement: PlacementType, isEnabled: boolean) {
    let updatestHackPlacementFilter = this.currentHackPlacementFilter().map(
      (hp: any) => {
        if (hp.value === hackPlacement) {
          hp.isEnabled = isEnabled;
        }
        // TODO!: REMOVE THE ELSE STATEMENT WHEN THE BACKEND READY
        else {
          hp.isEnabled = false;
        }

        return hp;
      }
    );

    this.currentHackPlacementFilter.set(updatestHackPlacementFilter);
  }

  public resetHackPlacementFilter() {
    let defaultHackPlacementFilter = this.currentHackPlacementFilter().map(
      (hp: any) => {
        hp.isEnabled = false;
        return hp;
      }
    );
    this.currentHackPlacementFilter.set(defaultHackPlacementFilter);
  }
}

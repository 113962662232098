import { TagType } from "@enums";

export const tagOptions = [
  {
    name: 'Stimulus Processing / Prikkelverwerking',
    value: TagType.STIMULUS_PROCESSING,
  },
  {
    name: 'Sleep / Slaap',
    value: TagType.SLEEP,
  },
  {
    name: 'Self Esteem / Eigenwaarde',
    value: TagType.SELF_ESTEEM,
  },
  {
    name: 'Addiction / Verslaving',
    value: TagType.ADDICTION,
  },
  {
    name: 'Resilience / Weerbaarheid',
    value: TagType.RESILIENCE,
  },
  {
    name: 'Focus',
    value: TagType.FOCUS,
  },
  {
    name: 'Emotion / Emotie',
    value: TagType.EMOTION,
  },
  {
    name: 'Lifestyles / Levensstijl',
    value: TagType.LIFESTYLES,
  },
];

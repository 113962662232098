export enum ChallengeAttributes {
  ID = 'id',
  TITLE = 'title',
  THUMBNAIL = 'thumbnail',
  TAGS = 'tags',
  SEARCH_KEY = 'searchKey',
  LOCALE = 'locale',
  STATUS = 'status',
  HACKS = 'hacks',
  INSIGHTS = 'insights',
  BOOKMARKED_BY = 'bookmarkedBy',
  TYPE = 'type',
  CREATED_AT = 'createdAt',
  UPDATED_AT = 'updatedAt',
  PUBLISHED_AT = 'publishedAt'
}

@for (status of statusFilter; track $index) {
<div class="checkbox-wrap">
  <input
    type="checkbox"
    class="checkbox-input"
    (change)="checkboxChange(status.value, $event)"
    [checked]="status.isEnabled"
  />
  <app-chip [removable]="false" [color]="status.color">
    <span class="checkbox-title">
      {{ status.title }}
    </span>
  </app-chip>
</div>
}

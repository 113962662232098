@if (icon && !loading) {
  <fa-icon [icon]="icon" [class]="iconPosition"></fa-icon>
} @else if (loading) {
  <fa-icon  icon="spinner"></fa-icon>
}

@if (!iconOnly && !loading) {
  <span [class.underline]="underline">
    <ng-content></ng-content>
  </span>
}

import { FormControl, FormGroup } from '@angular/forms';
import { HackAttributes } from './enums/hack-attributes.enum';
import { HackInterface } from './interfaces/hack.interface';
import { MediaInterface, ReflectionPollInterface } from '@interfaces';
import { LanguageType, PlacementType, TagType } from '@enums';
import { HackRelatedContents } from './interfaces/hack.interface';
import { Validators } from '@app/shared/validators/validator.class';

const field = HackAttributes;

export type HackCreateInterface = Pick<
  HackInterface,
  | HackAttributes.TITLE
  | HackAttributes.HACK_PLACEMENT
  | HackAttributes.LOCALE
  | HackAttributes.THUMBNAIL
  | HackAttributes.VIDEO
  | HackAttributes.TAGS
  | HackAttributes.REFLECTION_POLL
  | HackAttributes.PUBLISH_ON
  | HackAttributes.STATUS
  | HackAttributes.SEARCH_KEY
  | HackAttributes.TYPE
  | HackAttributes.RELATED_CONTENTS
  | HackAttributes.PUBLISHED_AT
>;

export type HackUpdateInterface = Pick<
  HackInterface,
  | HackAttributes.ID
  | HackAttributes.TITLE
  | HackAttributes.HACK_PLACEMENT
  | HackAttributes.LOCALE
  | HackAttributes.THUMBNAIL
  | HackAttributes.VIDEO
  | HackAttributes.TAGS
  | HackAttributes.REFLECTION_POLL
  | HackAttributes.PUBLISH_ON
  | HackAttributes.STATUS
  | HackAttributes.SEARCH_KEY
  | HackAttributes.TYPE
  | HackAttributes.RELATED_CONTENTS
  | HackAttributes.PUBLISHED_AT
>;

export const HackFormField = new FormGroup({
  [field.TITLE]: new FormControl<string | null>(null, [
    Validators.required,
  ]),
  [field.HACK_PLACEMENT]: new FormControl<PlacementType | null>(null, [
    Validators.required,
  ]),
  [field.LOCALE]: new FormControl<LanguageType>(LanguageType.DEFAULT, [
    Validators.required,
  ]),
  [field.THUMBNAIL]: new FormControl<MediaInterface | ''>('', [
    // Validators.required,
  ]),
  [field.VIDEO]: new FormControl<MediaInterface | ''>('', [
    // Validators.required,
  ]),
  [field.TAGS]: new FormControl<TagType[]>([], [Validators.required]),
  [field.REFLECTION_POLL]:
    new FormControl<ReflectionPollInterface | null>(null),
  [field.RELATED_CONTENTS]: new FormControl<HackRelatedContents>({
    hacks: [],
    insights: [],
  }),
  [field.PUBLISH_ON]: new FormControl<string[]>(
    [],
    [Validators.required]
  ),
  [field.TYPE]: new FormControl<'Hacks'>('Hacks'),
  [field.PUBLISHED_AT]: new FormControl<string|null>(null),
});

export const HackLocaleFormField = new FormGroup({
  [field.LOCALE]: new FormControl<LanguageType>(LanguageType.DEFAULT, [
    Validators.required,
  ]),
});

// Update Purpose
export const HackCurrentData: {
  [field.THUMBNAIL]: MediaInterface | undefined;
  [field.VIDEO]: MediaInterface | undefined;
  [field.REFLECTION_POLL]: ReflectionPollInterface | undefined;
} = {
  [field.THUMBNAIL]: undefined,
  [field.VIDEO]: undefined,
  [field.REFLECTION_POLL]: undefined,
};

import { Component, Input } from '@angular/core';
import { Perform } from '@classes';
import { MediaService } from '@services';

@Component({
  selector: 'app-video',
  standalone: true,
  imports: [],
  templateUrl: './video.component.html',
  styleUrl: './video.component.scss'
})
export class VideoComponent {
  @Input({required: true})
  public path!: string;

  @Input({required: true})
  public videoType!: string;

  public mediaPerform =  new Perform<string>();

  constructor(private mediaService: MediaService){}

  ngOnInit(){
    this.loadData();
  }

  private loadData(){
    this.mediaPerform.load(this.mediaService.getMediaUrl(this.path));
  }
}

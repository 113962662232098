import { LanguageType, StatusType, TagType } from '@enums';

export const listChallengeQuery = (filter?: {
  locale?: LanguageType;
  tag?: TagType[];
  status?: StatusType;
}) => {
  let tagQuery = '';

  filter?.tag?.map((tag) => {
    tagQuery += `{ tags: { contains: "${tag}" } },`;
  });

  const filterQuery = `
    (
  filter: 
  {
    ${
      filter?.tag
        ? `
        or: [
          ${tagQuery}
        ],
        `
        : ''
    }
    ${
      filter?.status
        ? `
        status: 
            {
                eq:${filter.status}
            },
        
        `
        : ''
    }
    ${
      filter?.locale
        ? `
          locale: 
              {
                  eq:${filter.locale}
              },
          
          `
        : ''
    }
  }
  )
  `;

  return `
  query MyQuery {
  listChallenges 
  ${filter ? filterQuery : ''}
  {
        items {
          createdAt
          type
          publishedAt
          id
          locale
          searchKey
          status
          tags
          title
          updatedAt
          thumbnail {
            duration
            size
            title
            url
          }
          insights {
            items {
              challengeId
              createdAt
              id
              insightId
              updatedAt
              insight {
                id
                tags
                status
                searchKey
                publishOn
                locale
                createdAt
                reflectionPoll {
                  prompt
                  totalParticipants
                  options {
                    count
                    id
                    option
                  }
                }
                bookmarkedBy {
                  items {
                    id
                    createdAt
                    insightId
                    updatedAt
                    userId
                  }
                }
                video {
                  duration
                  size
                  title
                  url
                }
                title
                updatedAt
                thumbnail {
                  duration
                  size
                  title
                  url
                }
                likedBy {
                  items {
                    createdAt
                    id
                    insightId
                    updatedAt
                    userId
                  }
                }
              }
            }
          }
          hacks {
            nextToken
            items {
              challengeId
              createdAt
              hackId
              hack {
                video {
                  url
                  title
                  size
                  duration
                }
                updatedAt
                title
                thumbnail {
                  duration
                  title
                  size
                  url
                }
                tags
                status
                searchKey
                publishOn
                quiz {
                  options {
                    id
                    isCorrect
                    option
                  }
                  question
                }
                locale
                id
                hackPlacement
                createdAt
              }
              id
              updatedAt
            }
          }
        }
      }
  }
  `;
};

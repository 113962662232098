@for (feelingType of feelingTypeFilter; track $index) {
<div class="checkbox-wrap">
  <input
    type="checkbox"
    class="checkbox-input"
    (change)="checkboxChange(feelingType.value, $event)"
    [checked]="feelingType.isEnabled"
  />
  <app-chip [removable]="false" [color]="feelingType.color">
    <span class="checkbox-title">
      {{ feelingType.title }}
    </span>
  </app-chip>
</div>
}

import { StatusType } from '@enums';

export const listMilestoneQuery = (filter?: { status?: StatusType }) => {
    const filterQuery = `
  (
  filter: 
  {
    ${
      filter?.status
        ? `
        status: 
            {
                eq:${filter.status}
            },
        
        `
        : ''
    }
  }
  )
  `;
  return `
    query MyQuery {
  listMilestones 
  ${filter ? filterQuery : ''}
  {
    items {
      createdAt
      endDate
      id
      startDate
      status
      updatedAt
      missions {
        isEnabled
        missionId
        name
        total
      }
      stickerPacks {
        packName
        tray {
          duration
          size
          title
          url
        }
        stickers {
          shortcut
          sticker {
            duration
            size
            title
            url
          }
        }
      }
    }
  }
}`;
};

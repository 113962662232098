import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MilestoneAttributes } from './enums/milestone-attributes.enum';
import {
  MilestoneInterface,
  MissionInterface,
  StickerPackInterface,
} from './interfaces/milestone.interface';
import { MediaInterface } from '@interfaces';
import dayjs from 'dayjs';
export type MilestoneCreateInterface = Pick<
  MilestoneInterface,
  | MilestoneAttributes.START_DATE
  | MilestoneAttributes.END_DATE
  | MilestoneAttributes.STICKER_PACKS
  | MilestoneAttributes.MISSIONS
  | MilestoneAttributes.STATUS
>;

export type MilestoneUpdateInterface = Pick<
  MilestoneInterface,
  | MilestoneAttributes.ID
  | MilestoneAttributes.START_DATE
  | MilestoneAttributes.END_DATE
  | MilestoneAttributes.STICKER_PACKS
  | MilestoneAttributes.MISSIONS
  | MilestoneAttributes.STATUS
>;

export const MilestoneFormField = new FormGroup({
  milestoneMonth: new FormControl<number|null>(null, [Validators.required]),
  milestoneYear: new FormControl<number|null>(dayjs().year(), [Validators.required]),
  [MilestoneAttributes.STICKER_PACKS]: new FormControl<StickerPackInterface | null>(null,
    [Validators.required]
  ),
  [MilestoneAttributes.MISSIONS]: new FormControl<MissionInterface[]>(
    [],
    [Validators.required]
  ),
});

// Update Purpose
export const MilestoneCurrentData: {
  [MilestoneAttributes.STICKER_PACKS]: StickerPackInterface | null;
  [MilestoneAttributes.MISSIONS]: MissionInterface[] | undefined;
} = {
  [MilestoneAttributes.STICKER_PACKS]: null,
  [MilestoneAttributes.MISSIONS]: undefined,
};

import { Component } from '@angular/core';
import { FilterService } from '@services';
import { ChipComponent } from '@components';
import { StatusType } from '@enums';
@Component({
  selector: 'app-status-dropdown',
  standalone: true,
  imports: [ChipComponent],
  templateUrl: './status-dropdown.component.html',
  styleUrl: './status-dropdown.component.scss',
})
export class StatusDropdownComponent {
  public readonly statusFilter = this.filterService.getStatusFilter();
  constructor(private filterService: FilterService) {}

  public checkboxChange(status: StatusType, event: Event): void {
    const inputElement = event.target as HTMLInputElement;
    this.filterService.setStatusFilter(status, inputElement.checked);
  }
}

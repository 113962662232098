@if (dataCount === 0) {
<div class="content-image">
  <img [src]="noDataIllustration" [alt]="noDataAlt" />
</div>
<div class="content-text">
  <h4>No {{ modelType }} data available!</h4>
  <p>
    Click the add {{ modelType }} button to create {{ modelType }} content or
    find another {{ modelType }}.
  </p>
</div>
<div class="content-button">
  <a [href]="buttonUrl">
    <button appButton="primary" size="sm">
      <app-label icon="plus" [gap]="0.5">{{ modelType }}</app-label>
    </button>
  </a>
</div>
}@else {
<div class="content-image">
  <img [src]="noDataIllustration" [alt]="noDataAlt" />
</div>
<div class="content-text">
  <h4>{{ modelType }} data not found!</h4>
  <p>Try to find another {{ modelType }} data on search bar</p>
</div>
}

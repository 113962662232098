import { Injectable, signal } from '@angular/core';
import { PositiveMessageInterface } from '@app/feature/positive-message/models';
import { HackInterface } from '@app/feature/hack/models';
import { InsightInterface } from '@app/feature/insight/models';
import { ChallengeInterface } from '@app/feature/challenge/models';
import { MilestoneInterface } from '@app/feature/milestone/models';

@Injectable({
  providedIn: 'root',
})
export class ContentUpdateService {
  private positiveMessageData = signal<PositiveMessageInterface | undefined>(
    undefined
  );
  private hackData = signal<HackInterface | undefined>(undefined);
  private insightData = signal<InsightInterface | undefined>(undefined);
  private challengeData = signal<any | undefined>(undefined);
  private milestoneData = signal<MilestoneInterface | undefined>(undefined);

  constructor() {}

  public getCurrentPositiveMessageData() {
    return this.positiveMessageData();
  }

  public setCurrentPositiveMessageData(data: PositiveMessageInterface) {
    this.positiveMessageData.set(data);
  }

  public getCurrentHackData() {
    return this.hackData();
  }

  public setCurrentHackData(data: HackInterface) {
    this.hackData.set(data);
  }

  public getCurrentInsightData() {
    return this.insightData();
  }

  public setCurrentInsightData(data: InsightInterface) {
    this.insightData.set(data);
  }

  public getCurrentChallengeData() {
    return this.challengeData();
  }

  public setCurrentChallengeData(data: any) {
    this.challengeData.set(data);
  }

  public getCurrentMilestoneData() {
    return this.milestoneData();
  }

  public setCurrentMilestoneData(data: MilestoneInterface) {
    this.milestoneData.set(data);
  }
}

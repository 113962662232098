@if (filterActive()) {
  <div class="content-wrap">
    <section class="filter-bar-left">
      @if (hackPlacementFilterActive()) {
        <div class="component-filter-wrap">
          <span class="component-filter-label">Hack Placement</span>
          <div class="applied-component-wrap">
            @for (hackPlacement of getCurrentHackPlacementFilter(); track $index) {
            @if(hackPlacement.isEnabled){
            <app-chip [removable]="false" [color]="hackPlacement.color">
              <span class="component-text">{{ hackPlacement.title }}</span>
            </app-chip>
            } }
          </div>
          <div class="component-filter-close-icon" (click)="resetCurrentHackPlacementFilter()">
            <ng-icon name="saxCloseCircleOutline"></ng-icon>
          </div>
        </div>
        }

      @if (tagFilterActive()) {
        <div class="component-filter-wrap">
          <span class="component-filter-label">Tag</span>
          <div class="applied-component-wrap">
            @for (tag of getCurrentTagFilter(); track $index) {
            @if(tag.isEnabled){
            <app-chip [removable]="false" [color]="tag.color">
              <span class="component-text">{{ tag.title }}</span>
            </app-chip>
            } }
          </div>
          <div class="component-filter-close-icon" (click)="resetCurrentTagFilter()">
            <ng-icon name="saxCloseCircleOutline"></ng-icon>
          </div>
        </div>
        }

      @if (feelingTypeFilterActive()) {
        <div class="component-filter-wrap">
          <span class="component-filter-label">Feeling Type</span>
          <div class="applied-component-wrap">
            @for (feelingType of getCurrentFeelingTypeFilter(); track $index) {
            @if(feelingType.isEnabled){
            <app-chip [removable]="false" [color]="feelingType.color">
              <span class="component-text">{{ feelingType.title }}</span>
            </app-chip>
            } }
          </div>
          <div class="component-filter-close-icon" (click)="resetCurrentFeelingTypeFilter()">
            <ng-icon name="saxCloseCircleOutline"></ng-icon>
          </div>
        </div>
        }

      @if (statusFilterActive()) {
      <div class="component-filter-wrap">
        <span class="component-filter-label">Status</span>
        <div class="applied-component-wrap">
          @for (status of getCurrentStatusFilter(); track $index) {
          @if(status.isEnabled){
          <app-chip [removable]="false" [color]="status.color">
            <span class="component-text">{{ status.title }}</span>
          </app-chip>
          } }
        </div>
        <div class="component-filter-close-icon" (click)="resetCurrentStatusFilter()">
          <ng-icon name="saxCloseCircleOutline"></ng-icon>
        </div>
      </div>
      }

      
    </section>
    
    <section class="filter-bar-right">
      <button appButton="white" size="sm" (click)="resetAllFilter()">Clear Sort</button>
    </section>
  </div>
}@else{
}

@switch (type) { @case (contentDataValueType.TEXT) {
{{ value }}
} @case (contentDataValueType.IMAGE) {
<div class="image-wrap">
  <app-image [path]="value.url" [title]="value"></app-image>
  
</div>
} @case (contentDataValueType.IMAGE_COLLECTION) {
<div class="collection-wrap">
  @for (item of value; track $index) {
  <div class="image-wrap collection">
    <app-image [path]="item.url" [title]="item.url"></app-image>
  </div>
  }
</div>
} @case (contentDataValueType.CHIP) { @switch (value) { @case
(typeService.status.DRAFT) {
<div class="chip-draft">Draft</div>
} @case (typeService.status.PUBLISH) {
<div class="chip-publish">Publish</div>
} @case ('happy' || 'very happy') {
<div class="chip-publish">Happy - Very Happy</div>
} @case ('neutral') {
<div class="chip-neutral">Neutral</div>
} @case ('sad' || 'very sad') {
<div class="chip-sad">Sad - Very Sad</div>
} } } }

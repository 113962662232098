import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ChallengeAttributes } from './enums/challenge-attributes.enum';
import { ChallengeInterface, HacksInChallenge, InsightInChallenge } from './interfaces/challenge.interface';
import { LanguageType, TagType } from '@enums';
import { MediaInterface } from '@interfaces';

const field = ChallengeAttributes;
export type ChallengeCreateInterface = Pick<
  ChallengeInterface,
  | ChallengeAttributes.TITLE
  | ChallengeAttributes.LOCALE
  | ChallengeAttributes.THUMBNAIL
  | ChallengeAttributes.TAGS
  | ChallengeAttributes.HACKS
  | ChallengeAttributes.INSIGHTS
  | ChallengeAttributes.STATUS
  | ChallengeAttributes.SEARCH_KEY
  | ChallengeAttributes.TYPE
  | ChallengeAttributes.PUBLISHED_AT
>;

export type ChallengeUpdateInterface = Pick<
  ChallengeInterface,
  | ChallengeAttributes.ID
  | ChallengeAttributes.TITLE
  | ChallengeAttributes.LOCALE
  | ChallengeAttributes.THUMBNAIL
  | ChallengeAttributes.TAGS
  | ChallengeAttributes.HACKS
  | ChallengeAttributes.INSIGHTS
  | ChallengeAttributes.STATUS
  | ChallengeAttributes.SEARCH_KEY
  | ChallengeAttributes.TYPE
  | ChallengeAttributes.PUBLISHED_AT
>;

export const ChallengeFormField = new FormGroup({
  [field.TITLE]: new FormControl<string | null>(null, [
    Validators.required,
  ]),
  [field.LOCALE]: new FormControl<LanguageType>(LanguageType.DEFAULT, [
    Validators.required,
  ]),
  [field.THUMBNAIL]: new FormControl<MediaInterface | ''>('', [
    // Validators.required,
  ]),
  [field.TAGS]: new FormControl<TagType[]>([], [
    Validators.required,
  ]),
  [field.HACKS]: new FormControl<HacksInChallenge[] | string[]>(
    [],
    [Validators.required]
  ),
  [field.INSIGHTS]: new FormControl<InsightInChallenge[] | string[]>(
    [],
    [Validators.required]
  ),
  [field.TYPE]: new FormControl<'Challenges'>('Challenges'),
  [field.PUBLISHED_AT]: new FormControl<string|null>(null),
});

export const ChallengeLocaleFormField = new FormGroup({
  [field.LOCALE]: new FormControl<LanguageType>(
    LanguageType.DEFAULT,
    [Validators.required]
  ),
});

// Update Purpose
export const ChallengeCurrentData: {
    [field.THUMBNAIL]: MediaInterface | undefined;
  } = {
    [field.THUMBNAIL]: undefined,
  };

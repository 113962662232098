import { Injectable } from '@angular/core';
import { concatMap, forkJoin, from, map, Observable, of } from 'rxjs';
import {
  ProgressService,
  BaseService,
  HackService,
  InsightService,
} from '@services';
import { LanguageType, ModelType, StatusType, TagType } from '@enums';
import { MediaInterface } from '@interfaces';
import {
  ChallengeAttributes,
  ChallengeInterface,
  ChallengeCreateInterface,
  ChallengeUpdateInterface,
} from '@app/feature/challenge/models';
import { listChallengeQuery } from './challenge.query';

@Injectable({
  providedIn: 'root',
})
export class ChallengeService extends BaseService<ChallengeInterface> {
  readonly modelType = ModelType.CHALLENGE;
  readonly hackInChallengeType = ModelType.HACK_IN_CHALLENGE;
  readonly insightInChallengeType = ModelType.INSIGHT_IN_CHALLENGE;

  constructor(
    private progressService: ProgressService,
    private hackService: HackService,
    private insightService: InsightService
  ) {
    super();
  }

  public getListByQuery(filter?: {
    locale?: LanguageType;
    tag?: TagType[];
    status?: StatusType;
  }): Observable<ChallengeInterface[]>{
    return this.getAllDataByQuery(listChallengeQuery(filter)).pipe(
      map((res:any) => {
        return res.data.listChallenges.items;
      })
    )
  }

  public getAllChallenges(): Observable<ChallengeInterface[]> {
    return this.getAllDataChallenge(staticQuery, this.modelType).pipe(
      map((res) => {
        return res.data.listChallenges.items;
      })
    );
  }

  public getChallengeById(id: string): Observable<ChallengeInterface> {
    return this.getDataById(id, this.modelType).pipe(map((res) => res.data));
  }

  public createChallenge(data: ChallengeCreateInterface): Observable<any> {
    this.progressService.setProgress(27, `Uploading challenge thumbnail...`);

    return this.uploadChallengeThumbnail(
      data[ChallengeAttributes.THUMBNAIL]
    ).pipe(
      concatMap((res) => {
        data[ChallengeAttributes.THUMBNAIL].url = res.path;
        delete data[ChallengeAttributes.THUMBNAIL].value;
        this.progressService.setProgress(42, `Uploading challenge data...`);
        return this.addData(data, this.modelType);
      }),
      concatMap((res) => {
        this.progressService.setProgress(
          88,
          `Set up hack and insight in challenge...`
        );
        const reqHacks = data.hacks.map((hackId) =>
          this.addData(
            {
              challengeId: res.data.id,
              hackId: hackId,
            },
            this.hackInChallengeType
          )
        );

        const reqInsights = data.insights.map((insightId) =>
          this.addData(
            {
              challengeId: res.data.id,
              insightId: insightId,
            },
            this.insightInChallengeType
          )
        );

        return forkJoin({
          hackInChallenge: forkJoin(reqHacks),
          insightInChallenge: forkJoin(reqInsights),
        });
      }),
      concatMap((res) => {
        this.progressService.setProgress(100, 'create challenge success!');
        return of(res);
      })
    );
  }

  public updateChallenge(data: ChallengeUpdateInterface): Observable<any> {
    this.progressService.setProgress(27, `Update challenge thumbnail...`);

    return this.deleteHackInsightChallenge(data.id).pipe(
      concatMap(() => {
        return this.uploadChallengeThumbnail(
          data[ChallengeAttributes.THUMBNAIL]
        )
      }),
      concatMap((res) => {
        data[ChallengeAttributes.THUMBNAIL].url = res.path;
        delete data[ChallengeAttributes.THUMBNAIL].value;
        this.progressService.setProgress(42, `Update challenge data...`);
        return this.updateData(data, this.modelType);
      }),
      concatMap((res) => {
        this.progressService.setProgress(
          88,
          `Set up hack and insight in challenge...`
        );

        const reqHacks = data.hacks.map((hackId) =>
          this.addData(
            {
              challengeId: res.data.id,
              hackId: hackId,
            },
            this.hackInChallengeType
          )
        );

        const reqInsights = data.insights.map((insightId) =>
          this.addData(
            {
              challengeId: res.data.id,
              insightId: insightId,
            },
            this.insightInChallengeType
          )
        );

        return forkJoin({
          hackInChallenge: forkJoin(reqHacks),
          insightInChallenge: forkJoin(reqInsights),
        });
      }),
      concatMap((res) => {
        this.progressService.setProgress(100, 'update challenge success!');
        return of(res);
      })
    )
  }

  public deleteHackInsightChallenge(id:string): Observable<any>{
    return this.getAllDataChallenge(
      this.getFullChallengeByIdQuery(id),
      this.modelType
    ).pipe(
      concatMap((res) => {
        const deleteHackInChallenge = res.data.listChallenges.items[0].hacks.items.map(
          (data: any) => this.deleteData(data.id, this.hackInChallengeType)
        );

        const deleteInsightInChallenge = res.data.listChallenges.items[0].insights.items.map(
          (data: any) => this.deleteData(data.id, this.insightInChallengeType)
        );

        return forkJoin({
          hackInChallengeType: forkJoin(deleteHackInChallenge),
          insightInChallengeType: forkJoin(deleteInsightInChallenge),
        })
      })
    );
  }

  public deleteChallenge(data: ChallengeInterface): Observable<any> {
    return this.getAllDataChallenge(
      this.getFullChallengeByIdQuery(data.id),
      this.modelType
    ).pipe(
      concatMap((res) => {
        this.progressService.setProgress(44, 'removing hack and insight...');
        const deleteHackInChallenge = res.data.listChallenges.items[0].hacks.items.map(
          (data: any) => this.deleteData(data.id, this.hackInChallengeType)
        );
        
        const deleteInsightInChallenge = res.data.listChallenges.items[0].insights.items.map(
          (data: any) => this.deleteData(data.id, this.insightInChallengeType)
        );
        
        return forkJoin({
          hackInChallengeType: forkJoin(deleteHackInChallenge),
          insightInChallengeType: forkJoin(deleteInsightInChallenge),
        })
      }),
      concatMap(() => {
        this.progressService.setProgress(68, 'removing thumbnail...');
        if(data.thumbnail.url !== undefined){
          return this.removeFile(data.thumbnail.url)
        }else{
          return of([])
        }
      }),
      concatMap(() => {
        this.progressService.setProgress(82, 'delete challenge data...');
        return this.deleteData(data.id, this.modelType)
      }),
      concatMap((res) => {
        return of(res)
      })

    );
  }

  public uploadChallengeThumbnail(thumbnailData: MediaInterface) {
    const basePath = 'challenge/thumbnails/';
    return this.uploadFile(basePath, thumbnailData);
  }

  private getFullChallengeByIdQuery(id: string) {
    return `
      query GetListChallenges {
        listChallenges(id: "${id}") {
          items {
            createdAt
            id
            locale
            searchKey
            status
            tags
            title
            updatedAt
            thumbnail {
              duration
              size
              title
              url
            }
            insights {
              items {
                challengeId
                createdAt
                id
                insightId
                updatedAt
                insight {
                  id
                  tags
                  status
                  searchKey
                  publishOn
                  locale
                  createdAt
                  reflectionPoll {
                    prompt
                    totalParticipants
                    options {
                      count
                      id
                      option
                    }
                  }
                  bookmarkedBy {
                    items {
                      id
                      createdAt
                      insightId
                      updatedAt
                      userId
                    }
                  }
                  video {
                    duration
                    size
                    title
                    url
                  }
                  title
                  updatedAt
                  thumbnail {
                    duration
                    size
                    title
                    url
                  }
                  likedBy {
                    items {
                      createdAt
                      id
                      insightId
                      updatedAt
                      userId
                    }
                  }
                }
              }
            }
            hacks {
              nextToken
              items {
                challengeId
                createdAt
                hackId
                hack {
                  video {
                    url
                    title
                    size
                    duration
                  }
                  updatedAt
                  title
                  thumbnail {
                    duration
                    title
                    size
                    url
                  }
                  tags
                  status
                  searchKey
                  publishOn
                  quiz {
                    options {
                      id
                      isCorrect
                      option
                    }
                    question
                  }
                  locale
                  id
                  hackPlacement
                  createdAt
                }
                id
                updatedAt
              }
            }
          }
        }
      }
      `;
  }
}
const staticQuery = `
query GetListChallenges {
      listChallenges {
        items {
          createdAt
          id
          locale
          searchKey
          status
          tags
          title
          updatedAt
          thumbnail {
            duration
            size
            title
            url
          }
          insights {
            items {
              challengeId
              createdAt
              id
              insightId
              updatedAt
              insight {
                id
                tags
                status
                searchKey
                publishOn
                locale
                createdAt
                reflectionPoll {
                  prompt
                  totalParticipants
                  options {
                    count
                    id
                    option
                  }
                }
                bookmarkedBy {
                  items {
                    id
                    createdAt
                    insightId
                    updatedAt
                    userId
                  }
                }
                video {
                  duration
                  size
                  title
                  url
                }
                title
                updatedAt
                thumbnail {
                  duration
                  size
                  title
                  url
                }
                likedBy {
                  items {
                    createdAt
                    id
                    insightId
                    updatedAt
                    userId
                  }
                }
              }
            }
          }
          hacks {
            nextToken
            items {
              challengeId
              createdAt
              hackId
              hack {
                video {
                  url
                  title
                  size
                  duration
                }
                updatedAt
                title
                thumbnail {
                  duration
                  title
                  size
                  url
                }
                tags
                status
                searchKey
                publishOn
                quiz {
                  options {
                    id
                    isCorrect
                    option
                  }
                  question
                }
                locale
                id
                hackPlacement
                createdAt
              }
              id
              updatedAt
            }
          }
        }
      }
    }
`;

import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, input } from '@angular/core';
import { NgIconComponent, provideIcons } from '@ng-icons/core';
import { saxInfoCircleOutline } from '@ng-icons/iconsax/outline';
@Component({
  selector: 'app-input-info-message',
  standalone: true,
  imports: [CommonModule, NgIconComponent],
  templateUrl: './input-info-message.component.html',
  styleUrl: './input-info-message.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  viewProviders: [
    provideIcons({
      saxInfoCircleOutline,
    })
  ],
})
export class InputInfoMessageComponent {
  public readonly message = input.required<string>();
  public readonly showMessage = input.required<boolean>();
}

<table mat-table [dataSource]="dataSource" matSort>
  @for (column of columns; track $index) {
  <ng-container [matColumnDef]="column">
    <th *matHeaderCellDef class="header-left" mat-sort-header>
      <div class="header-title-wrap">
        <span class="header-title-text">{{ getTableHeader(column) }}</span>
        @if(column === 'status' || column === 'feelingType' || column === 'tags'
        || column === 'hackPlacement'){
        <div class="header-title-icon" [appDropdown]="dropdown">
          <ng-icon name="saxFilterAddOutline"></ng-icon>
        </div>
        }
      </div>
      <ng-template #dropdown>
        @if (column === 'status') {
        <app-dropdown>
          <app-status-dropdown></app-status-dropdown>
        </app-dropdown>
        }@else if (column === 'feelingType') {
        <app-dropdown>
          <app-feeling-type-dropdown></app-feeling-type-dropdown>
        </app-dropdown>
        }@else if (column === 'tags') {
        <app-dropdown>
          <app-tag-dropdown></app-tag-dropdown>
        </app-dropdown>
        }@else if (column === 'hackPlacement') {
          <app-dropdown>
            <app-hack-placement-dropdown></app-hack-placement-dropdown>
          </app-dropdown>
          }
      </ng-template>
    </th>
    @if(column === 'action'){
    <td *matCellDef="let row">
      <app-table-action-data-value
        (viewDetail)="viewContentDetail(row)"
        (update)="updateContent(row)"
        (delete)="deleteContent(row)"
      />
    </td>
    }@else {
    <td *matCellDef="let row" class="truncate">
      <app-table-data-value
        [type]="getValueType(column)"
        [value]="getTableValue(column, row[column])"
      ></app-table-data-value>
    </td>
    }
  </ng-container>
  }
  <tr mat-header-row *matHeaderRowDef="columns"></tr>
  <tr mat-row *matRowDef="let row; columns: columns"></tr>
  <tr class="mat-row" *matNoDataRow>
    <td class="mat-cell" [colSpan]="getColSpan()">
      <app-table-no-data-value
        [dataCount]="dataSource.data.length"
        [modelType]="modelType"
        [buttonUrl]="getButtonUrl(modelType)"
      />
    </td>
  </tr>
</table>

@if(!dataSource){
<div class="loading-wrap">
  <div class="loading-image">
    <img src="assets/images/illustrations/loading.png" alt="" />
  </div>
</div>
}

import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';

@Component({
  selector: 'app-chip',
  standalone: true,
  imports: [FontAwesomeModule, CommonModule],
  templateUrl: './chip.component.html',
  styleUrl: './chip.component.scss',
})
export class ChipComponent {

  @Input()
  public color: 'default' | 'yellow' | 'green' | 'gray' | 'orange' | 'blue' | 'red' | 'white' = 'default';
  
  @Input()
  public removable: boolean = true;
  
  @Output()
  public removed = new EventEmitter<void>();

  public removeChip() {
    if (this.removable) {
      this.removed.emit();
    }
  }
}

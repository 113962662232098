import { TagType } from '@enums';

export const getTagsValue = (tags: TagType[]): string => {
  let newTags: string[] = [];

  tags.map((tag: TagType) => {
    newTags.push(capitalizeTag(tag));
  });

  if (newTags.length !== 0) {
    return newTags.join(', ');
  }

  return '';
};

export const generateSearchKeyByTags = (tags: TagType[] | null): string => {
  if (tags !== null) {
    const newTagsValue = tags.map((tag) => {
      switch (tag) {
        case TagType.STIMULUS_PROCESSING:
          return 'stimulus processing,stimulus,processing,prikkelverwerking';

        case TagType.SLEEP:
          return 'sleep,slaap';

        case TagType.SELF_ESTEEM:
          return 'self esteem,self,esteem,eigenwaarde';

        case TagType.ADDICTION:
          return 'addiction,verslaving';

        case TagType.RESILIENCE:
          return 'resilence,weerbaarheid';

        case TagType.FOCUS:
          return 'focus';

        case TagType.EMOTION:
          return 'emotion,emotie';

        case TagType.LIFESTYLES:
          return 'lifestyles,levensstijl';

        default:
          return '';
      }
    });

    return newTagsValue.join(',');
  } else {
    return '';
  }
};

const capitalizeTag = (tag: TagType): string => {
  switch (tag) {
    case TagType.EMOTION:
      return 'Emotion';
    case TagType.FOCUS:
      return 'Focus';
    case TagType.LIFESTYLES:
      return 'Lifestyle';
    case TagType.SELF_ESTEEM:
      return 'Self Esteem';
    case TagType.SLEEP:
      return 'Sleep';
    case TagType.STIMULUS_PROCESSING:
      return 'Stimulus Processing';
    case TagType.ADDICTION:
      return 'Addiction';
    case TagType.RESILIENCE:
      return 'Resilience';
    default:
      return tag;
  }
};

import { Component, Input } from '@angular/core';
import { ContentDataValueType } from '@enums';
import { TypeService } from '@services';
import { ImageComponent } from '../../media/image/image.component';

@Component({
  selector: 'app-table-data-value',
  standalone: true,
  imports: [ImageComponent],
  templateUrl: './table-data-value.component.html',
  styleUrl: './table-data-value.component.scss',
})
export class TableDataValueComponent {

  @Input()
  public type:
    | ContentDataValueType
    | 'text'
    | 'image'
    | 'chip'
    | 'imageCollection' = ContentDataValueType.TEXT;

  @Input({ required: true })
  public value: any;

  public contentDataValueType = ContentDataValueType;

  constructor(
    public typeService: TypeService
  ) {}

}

export enum HackAttributes {
  ID = 'id',
  TITLE = 'title',
  VIDEO = 'video',
  THUMBNAIL = 'thumbnail',
  TAGS = 'tags',
  SEARCH_KEY = 'searchKey',
  STATUS = 'status',
  PUBLISH_ON = 'publishOn',
  HACK_PLACEMENT = 'hackPlacement',
  LOCALE = 'locale',
  REFLECTION_POLL = 'reflectionPoll',
  BOOKMARKED_BY = 'bookmarkedBy',
  LIKED_BY = 'likedBy',
  CHALLENGES = 'challenges',
  ARTICLE = 'article',
  RELATED_CONTENTS = 'relatedContents',
  TYPE = 'type',
  CREATED_AT = 'createdAt',
  UPDATED_AT = 'updatedAt',
  PUBLISHED_AT = 'publishedAt'
}

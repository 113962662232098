import { Component } from '@angular/core';
import { ChipComponent } from '../../chip/chip.component';
import { FilterService } from '@services';
import { FeelingType } from '@enums';

@Component({
  selector: 'app-feeling-type-dropdown',
  standalone: true,
  imports: [ChipComponent],
  templateUrl: './feeling-type-dropdown.component.html',
  styleUrl: './feeling-type-dropdown.component.scss'
})
export class FeelingTypeDropdownComponent {
  public readonly feelingTypeFilter = this.filterService.getFeelingTypeFilter();

  constructor(private filterService: FilterService) {}

  public checkboxChange(feelingType: FeelingType, event: Event): void {
    const inputElement = event.target as HTMLInputElement;
    this.filterService.setFeelingTypeFilter(feelingType, inputElement.checked);
  }
}

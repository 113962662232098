import {
  Component,
  output,
  Input,
  ViewChild,
  SimpleChanges
} from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';

import { MatInputModule } from '@angular/material/input';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import { MatCheckboxModule } from '@angular/material/checkbox';

import { ContentDataValueType } from '@enums';
import {
  getFormattedDate,
  getTagsValue,
  getPlacementValue,
  getTimePeriod
} from '@functions';

import {
  TableActionDataValueComponent,
  TableNoDataValueComponent
} from '@components';
import { TableDataValueComponent } from '@app/shared/components/table/table-data-value/table-data-value.component';
import { MatSort, MatSortModule, Sort } from '@angular/material/sort';
import { DropdownComponent, DropdownDirective } from '@directives';

import {
  StatusDropdownComponent,
  FeelingTypeDropdownComponent,
  TagDropdownComponent,
  HackPlacementDropdownComponent
} from '@app/shared/components/dropdown';
import { NgIconComponent, provideIcons } from '@ng-icons/core';
import { saxFilterAddOutline } from '@ng-icons/iconsax/outline';
import { MilestoneAttributes, StickerPackInterface } from '@app/feature/milestone/models';
import { MediaInterface } from '@interfaces';

@Component({
  selector: 'app-table',
  standalone: true,
  imports: [
    TranslateModule,
    MatTableModule,
    MatCheckboxModule,
    MatInputModule,
    TableDataValueComponent,
    TableActionDataValueComponent,
    TableNoDataValueComponent,
    MatSort,
    MatSortModule,
    DropdownDirective,
    DropdownComponent,
    StatusDropdownComponent,
    FeelingTypeDropdownComponent,
    TagDropdownComponent,
    HackPlacementDropdownComponent,
    NgIconComponent
  ],
  templateUrl: './table.component.html',
  styleUrl: './table.component.scss',
  viewProviders: [
    provideIcons({
      saxFilterAddOutline
    })
  ]
})
export class TableComponent {
  @Input({ required: true })
  public modelType!:
    | 'positive message'
    | 'hack'
    | 'insight'
    | 'challenge'
    | 'milestone';

  @Input({ required: true })
  public dataSource!: MatTableDataSource<any>;

  @Input({ required: true })
  public headers!: any;

  @Input({ required: true })
  public columns!: any[];

  @Input({ required: true })
  public valueTypeList!: any[];

  public readonly viewDetail = output<any>();

  public readonly update = output<any>();

  public readonly delete = output<any>();

  @ViewChild(MatSort) sort!: MatSort;

  ngOnChanges(changes: SimpleChanges) {
    if (changes['dataSource'] && !changes['dataSource'].firstChange) {
      this.dataSource.sort = this.sort;
      const sortState: Sort = { active: 'createdAt', direction: 'desc' };
      this.sort.active = sortState.active;
      this.sort.direction = sortState.direction;
      this.sort.sortChange.emit(sortState);
    }
  }

  public viewContentDetail(data: any) {
    this.viewDetail.emit(data);
  }

  public updateContent(data: any) {
    this.update.emit(data);
  }

  public deleteContent(data: any) {
    this.delete.emit(data);
  }

  public getValueType(columnName: string): ContentDataValueType {
    const { type } = this.valueTypeList.find(
      (value) => value.columnName === columnName
    ) || { type: ContentDataValueType.TEXT };

    return type;
  }

  public getTableValue(header: string, value: any) {
    if (header === 'createdAt') {
      return getFormattedDate(value);
    } else if (header === 'tags') {
      return getTagsValue(value);
    } else if (header === 'hackPlacement') {
      return getPlacementValue(value);
    } else if (header === 'startDate') {
      return getTimePeriod(value);
    } else if (header === MilestoneAttributes.STICKER_PACKS){
      return this.getSticker(value);
    }

    return value;
  }

  public getTableHeader(header: string) {
    const headerData = this.headers.find(
      (col: any) => col.attribute === header
    );

    if (headerData) {
      return headerData.headerTitle;
    }

    return header;
  }

  public getColSpan() {
    return this.columns.length;
  }

  public getButtonUrl(modelType: string) {
    switch (modelType) {
      case 'positive message': {
        return 'content-management/positive-messages/create';
      }
      case 'hack': {
        return 'content-management/hacks/create';
      }
      case 'insight': {
        return 'content-management/insights/create';
      }
      case 'challenge': {
        return 'content-management/challenges/create';
      }
      case 'milestone': {
        return 'content-management/milestones/create';
      }
      default: {
        return 'content-managemenet';
      }
    }
  }

  private getSticker(stickerPacks: StickerPackInterface): MediaInterface[]{
    const rawStickers = stickerPacks.stickers;
    const stickers = rawStickers.map(data => data.sticker)

    return stickers;
  }
}

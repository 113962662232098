import { FeelingType } from "@enums"
export const feelingOptions = [
    {
        name: 'Sad - Very Sad',
        value: FeelingType.SAD
    },
    {
        name: 'Neutral',
        value: FeelingType.NEUTRAL
    },
    {
        name: 'Happy - Very Happy',
        value: FeelingType.HAPPY
    },
]
import { ContentDataValueType } from '@enums';
import { MilestoneAttributes } from './enums/milestone-attributes.enum';

interface tableHeaderInterface {
  attribute: MilestoneAttributes | 'action';
  headerTitle: string;
}

interface tableUniqueTableValue {
  columnName: MilestoneAttributes;
  type: ContentDataValueType;
}

export const MilestoneHeaders: tableHeaderInterface[] = [
  {
    attribute: MilestoneAttributes.START_DATE,
    headerTitle: 'Time Period',
  },
  {
    attribute: MilestoneAttributes.STICKER_PACKS,
    headerTitle: 'Reward Stickers',
  },
  {
    attribute: MilestoneAttributes.CREATED_AT,
    headerTitle: 'Date Created',
  },
  {
    attribute: MilestoneAttributes.STATUS,
    headerTitle: 'Status',
  },
  {
    attribute: 'action',
    headerTitle: 'Action',
  },
];

export const MilestoneColumns = [
  MilestoneAttributes.START_DATE,
  MilestoneAttributes.STICKER_PACKS,
  MilestoneAttributes.CREATED_AT,
  MilestoneAttributes.STATUS,
  'action',
];

export const MilestoneUniqueTableValue: tableUniqueTableValue[] = [
    {
      columnName: MilestoneAttributes.STICKER_PACKS,
      type: ContentDataValueType.IMAGE_COLLECTION,
    },
    {
      columnName: MilestoneAttributes.STATUS,
      type: ContentDataValueType.CHIP,
    },
  ];
  


import { ContentDataValueType } from '@enums';
import { HackAttributes } from './enums/hack-attributes.enum';

interface tableHeaderInterface {
  attribute: HackAttributes | 'action';
  headerTitle: string;
}

interface tableUniqueTableValue {
    columnName: HackAttributes,
    type: ContentDataValueType
}

export const HackHeaders: tableHeaderInterface[] = [
  {
    attribute: HackAttributes.TITLE,
    headerTitle: 'Hack Title',
  },
  {
    attribute: HackAttributes.THUMBNAIL,
    headerTitle: 'Thumbnail',
  },
  {
    attribute: HackAttributes.HACK_PLACEMENT,
    headerTitle: 'Hack Placement',
  },
  {
    attribute: HackAttributes.TAGS,
    headerTitle: 'Tags',
  },
  {
    attribute: HackAttributes.CREATED_AT,
    headerTitle: 'Date Created',
  },
  {
    attribute: HackAttributes.STATUS,
    headerTitle: 'Status',
  },
  {
    attribute: 'action',
    headerTitle: 'Action',
  },
];

export const HackColumns = [
  HackAttributes.TITLE,
  HackAttributes.THUMBNAIL,
  HackAttributes.HACK_PLACEMENT,
  HackAttributes.TAGS,
  HackAttributes.CREATED_AT,
  HackAttributes.STATUS,
  'action',
];

export const HackUniqueTableValue: tableUniqueTableValue[] = [
    {
        columnName: HackAttributes.THUMBNAIL,
        type: ContentDataValueType.IMAGE
    },
    {
        columnName: HackAttributes.STATUS,
        type: ContentDataValueType.CHIP
    },
]

import { Component, computed } from '@angular/core';
import { ButtonDirective } from '@directives';
import { ChipComponent } from '../chip/chip.component';
import { FilterService } from '@services';
import { NgIconComponent, provideIcons } from '@ng-icons/core';
import { saxCloseCircleOutline } from '@ng-icons/iconsax/outline';

@Component({
  selector: 'app-filter-bar',
  standalone: true,
  imports: [ButtonDirective, ChipComponent, NgIconComponent],
  templateUrl: './filter-bar.component.html',
  styleUrl: './filter-bar.component.scss',
  viewProviders: [
    provideIcons({
      saxCloseCircleOutline,
    }),
  ],
})
export class FilterBarComponent {

  public hackPlacementFilterActive = computed(() => {
    const hackPlacementChoosedLength = this.filterService
      .currentHackPlacementFilter()
      .filter((hp: any) => {
        if (hp.isEnabled) {
          return true;
        }
        return false;
      });

    if (hackPlacementChoosedLength.length > 0) {
      return true;
    }

    return false;
  });

  public feelingTypeFilterActive = computed(() => {
    const feelingTypeChoosedLength = this.filterService
      .currentFeelingTypeFilter()
      .filter((ft: any) => {
        if (ft.isEnabled) {
          return true;
        }
        return false;
      });

    if (feelingTypeChoosedLength.length > 0) {
      return true;
    }

    return false;
  });

  public tagFilterActive = computed(() => {
    const tagChoosedLength = this.filterService
      .currentTagFilter()
      .filter((t: any) => {
        if (t.isEnabled) {
          return true;
        }
        return false;
      });

    if (tagChoosedLength.length > 0) {
      return true;
    }

    return false;
  });
  
  public statusFilterActive = computed(() => {
    const statusChoosedLength = this.filterService
      .currentStatusFilter()
      .filter((s: any) => {
        if (s.isEnabled) {
          return true;
        }
        return false;
      });

    if (statusChoosedLength.length > 0) {
      return true;
    }

    return false;
  });
  
  
  public filterActive = computed(() => {
    if(this.statusFilterActive()){
      return true;
    }

    if(this.feelingTypeFilterActive()){
      return true;
    }

    if(this.tagFilterActive()){
      return true;
    }

    if(this.hackPlacementFilterActive()){
      return true;
    }

    return false;
  })

  constructor(private filterService: FilterService) {}

  public getCurrentHackPlacementFilter() {
    return this.filterService.currentHackPlacementFilter();
  }

  public resetCurrentHackPlacementFilter(){
    this.filterService.resetHackPlacementFilter();
  }

  public getCurrentTagFilter() {
    return this.filterService.currentTagFilter();
  }

  public resetCurrentTagFilter(){
    this.filterService.resetTagFilter();
  }

  public getCurrentStatusFilter() {
    return this.filterService.currentStatusFilter();
  }

  public resetCurrentStatusFilter(){
    this.filterService.resetStatusFilter();
  }

  public getCurrentFeelingTypeFilter() {
    return this.filterService.currentFeelingTypeFilter();
  }

  public resetCurrentFeelingTypeFilter(){
    this.filterService.resetFeelingTypeFilter();
  }

  public resetAllFilter(){
    this.resetCurrentStatusFilter();
    this.resetCurrentFeelingTypeFilter();
    this.resetCurrentTagFilter();
    this.resetCurrentHackPlacementFilter();
  }
}

import { ContentDataValueType } from '@enums';
import { InsightAttributes } from './enums/insight-attributes.enum';

interface tableHeaderInterface {
  attribute: InsightAttributes | 'action';
  headerTitle: string;
}

interface tableUniqueTableValue {
  columnName: InsightAttributes;
  type: ContentDataValueType;
}

export const InsightHeaders: tableHeaderInterface[] = [
  {
    attribute: InsightAttributes.TITLE,
    headerTitle: 'Insight Title',
  },
  {
    attribute: InsightAttributes.THUMBNAIL,
    headerTitle: 'Thumbnail',
  },
  {
    attribute: InsightAttributes.TAGS,
    headerTitle: 'Tags',
  },
  {
    attribute: InsightAttributes.CREATED_AT,
    headerTitle: 'Date Created',
  },
  {
    attribute: InsightAttributes.STATUS,
    headerTitle: 'Status',
  },
  {
    attribute: 'action',
    headerTitle: 'Action',
  },
];

export const InsightColumns = [
  InsightAttributes.TITLE,
  InsightAttributes.THUMBNAIL,
  InsightAttributes.TAGS,
  InsightAttributes.CREATED_AT,
  InsightAttributes.STATUS,
  'action',
];

export const InsightUniqueTableValue: tableUniqueTableValue[] = [
  {
    columnName: InsightAttributes.THUMBNAIL,
    type: ContentDataValueType.IMAGE,
  },
  {
    columnName: InsightAttributes.STATUS,
    type: ContentDataValueType.CHIP,
  },
];

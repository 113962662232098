import { Injectable } from '@angular/core';
import { concatMap, forkJoin, map, Observable, of } from 'rxjs';
import { ProgressService, BaseService } from '@services';
import { ModelType, StatusType } from '@enums';
import { MediaInterface } from '@interfaces';

import {
  MilestoneInterface,
  MilestoneCreateInterface,
  MilestoneUpdateInterface,
  MilestoneAttributes,
  StickerPackInterface,
} from '@app/feature/milestone/models';
import { listMilestoneQuery } from './milestone.query';

@Injectable({
  providedIn: 'root'
})
export class MilestoneService extends BaseService<MilestoneInterface> {
  private readonly modelType = ModelType.MILESTONE;
  public readonly field = MilestoneAttributes;

  constructor(private progressService: ProgressService) {
    super();
  }

  public getListByQuery(filter?: {
    status?: StatusType;
  }): Observable<MilestoneInterface[]> {
    return this.getAllDataByQuery(listMilestoneQuery(filter)).pipe(
      map((res: any) => {
        return res.data.listMilestones.items;
      })
    );
  }

  public getAllMilestones(): Observable<MilestoneInterface[]> {
    return this.getAllData(this.modelType).pipe(
      map((res) => {
        return res.data;
      })
    );
  }

  public getMilestoneById(id: string): Observable<MilestoneInterface> {
    return this.getDataById(id, this.modelType).pipe(map((res) => res.data));
  }

  public createMilestone(data: MilestoneCreateInterface): Observable<any> {
    this.progressService.setProgress(27, `Uploading Stickers...`);

    let stickerPack: StickerPackInterface;

    if (data.stickerPacks != null) {
      stickerPack = data.stickerPacks;
      const reqSticker = stickerPack.stickers.map((sticker) => {
        return this.uploadMilestoneSticker(sticker.sticker);
      });

      const tray = stickerPack.tray;

      return forkJoin(reqSticker).pipe(
        concatMap((res) => {
          console.log('res1', res);
          res.map((sticker: any, index: number) => {
            stickerPack.stickers[index].sticker.url = sticker.path;
            delete stickerPack.stickers[index].sticker.value;
            delete stickerPack.stickers[index].sticker.duration;
          });

          this.progressService.setProgress(42, `Uploading tray data...`);
          return this.uploadStickerTray(tray);
        }),
        concatMap((res) => {
          console.log('res2', res);
          tray.url = res.path;
          delete tray.value;

          this.progressService.setProgress(85, `Uploading milestone data...`);
          return this.addData(data, this.modelType);
        }),
        concatMap((res) => {
          console.log('res3', res);
          this.progressService.setProgress(100, 'Create milestone success!');
          return of(res);
        })
      );
    } else {
      return of([]);
    }
  }

  public updateMilestone(data: MilestoneUpdateInterface): Observable<any> {
    this.progressService.setProgress(27, `Updating milestone data...`);

    let stickerPack: StickerPackInterface;

    if (data.stickerPacks != null) {
      stickerPack = data.stickerPacks;
      const reqSticker = stickerPack.stickers.map((sticker) => {
        return this.uploadMilestoneSticker(sticker.sticker);
      });

      const tray = stickerPack.tray;

      return forkJoin(reqSticker).pipe(
        concatMap((res) => {
          console.log('res1', res);
          res.map((sticker: any, index: number) => {
            stickerPack.stickers[index].sticker.url = sticker.path;
            delete stickerPack.stickers[index].sticker.value;
            delete stickerPack.stickers[index].sticker.duration;
          });

          this.progressService.setProgress(42, `Updating tray data...`);
          return this.uploadStickerTray(tray);
        }),
        concatMap((res) => {
          console.log('res2', res);
          tray.url = res.path;
          delete tray.value;

          this.progressService.setProgress(85, `Updating milestone data...`);
          return this.updateData(data, this.modelType);
        }),
        concatMap((res) => {
          console.log('res3', res);
          this.progressService.setProgress(100, 'Update milestone success!');
          return of(res);
        })
      );
    } else {
      return of([]);
    }
  }

  public deleteMilestone(id: string): Observable<any> {
    return this.deleteData(id, this.modelType);
  }

  public uploadMilestoneSticker(sticker: MediaInterface): Observable<any> {
    const basePath = 'milestone/stickers/';
    return this.uploadFile(basePath, sticker);
  }

  public uploadStickerTray(tray: MediaInterface): Observable<any> {
    const basePath = 'milestone/trays/';
    return this.uploadFile(basePath, tray);
  }
}

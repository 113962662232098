@for (hackPlacement of hackPlacementFilter; track $index) {
    <div class="checkbox-wrap">
      <input
        type="checkbox"
        class="checkbox-input"
        (change)="checkboxChange(hackPlacement.value, $event)"
        [checked]="hackPlacement.isEnabled"
      />
      <span class="checkbox-title">
          {{ hackPlacement.title }}
    </span>
    </div>
    }
    
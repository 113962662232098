export enum ModelType {
  LICENSE = 'License',
  PACKAGE = 'Package',
  COMPANY = 'Company',
  POSITIVE_MESSAGE = 'PositiveMessage',
  HACK = 'Hacks',
  INSIGHT = 'Insights',
  CHALLENGE = 'Challenges',
  MILESTONE = 'Milestones',
  HACK_IN_CHALLENGE = 'HackInChallenge',
  INSIGHT_IN_CHALLENGE = 'InsightInChallenge'
}

import { PlacementType } from '@enums';

export const placementOptions = [
  {
    name: 'All Type',
    value: PlacementType.ALL_TYPE,
  },
  {
    name: 'Home',
    value: PlacementType.HOME,
  },
  {
    name: 'Library',
    value: PlacementType.LIBRARY,
  },
];

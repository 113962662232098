export const companyOptions = [
  {
    name: 'All Company',
    value: 'all_company',
  },
  {
    name: 'Zone College',
    value: 'zone_college',
  },
  {
    name: 'MOOVD B.V',
    value: 'moovd',
  },
];

import { Component } from '@angular/core';
import { TagType } from '@enums';
import { FilterService } from '@services';

@Component({
  selector: 'app-tag-dropdown',
  standalone: true,
  imports: [],
  templateUrl: './tag-dropdown.component.html',
  styleUrl: './tag-dropdown.component.scss'
})
export class TagDropdownComponent {
  public readonly tagFilter = this.filterService.getTagFilter();

  constructor(private filterService: FilterService) {}

  public checkboxChange(tag: TagType, event: Event): void {
    const inputElement = event.target as HTMLInputElement;
    this.filterService.setTagFilter(tag, inputElement.checked);
  }
}

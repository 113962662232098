{
  "auth": {
    "user_pool_id": "us-east-1_ZLkLDr0tx",
    "aws_region": "us-east-1",
    "user_pool_client_id": "4tblethokqouh20nvv9ci0s7iu",
    "identity_pool_id": "us-east-1:3f348fd4-b37e-455f-a24b-d0fc39305df3",
    "mfa_methods": [
      "TOTP"
    ],
    "standard_required_attributes": [
      "email",
      "phone_number",
      "given_name",
      "family_name",
      "gender",
      "address",
      "locale",
      "updated_at"
    ],
    "username_attributes": [
      "email"
    ],
    "user_verification_types": [
      "email"
    ],
    "mfa_configuration": "OPTIONAL",
    "password_policy": {
      "min_length": 8,
      "require_lowercase": true,
      "require_numbers": true,
      "require_symbols": true,
      "require_uppercase": true
    },
    "unauthenticated_identities_enabled": true
  },
  "data": {
    "url": "https://jxzpusiedfhjvnh25xi3vfltuq.appsync-api.us-east-1.amazonaws.com/graphql",
    "aws_region": "us-east-1",
    "api_key": "da2-ls22e5zmb5hsjpy7cxtxi3t44i",
    "default_authorization_type": "API_KEY",
    "authorization_types": [
      "AMAZON_COGNITO_USER_POOLS",
      "AWS_IAM"
    ],
    "model_introspection": {
      "version": 1,
      "models": {
        "License": {
          "name": "License",
          "fields": {
            "id": {
              "name": "id",
              "isArray": false,
              "type": "ID",
              "isRequired": true,
              "attributes": []
            },
            "companyId": {
              "name": "companyId",
              "isArray": false,
              "type": "ID",
              "isRequired": false,
              "attributes": []
            },
            "company": {
              "name": "company",
              "isArray": false,
              "type": {
                "model": "Company"
              },
              "isRequired": false,
              "attributes": [],
              "association": {
                "connectionType": "BELONGS_TO",
                "targetNames": [
                  "companyId"
                ]
              }
            },
            "endDate": {
              "name": "endDate",
              "isArray": false,
              "type": "AWSTimestamp",
              "isRequired": false,
              "attributes": []
            },
            "freeLimitUsed": {
              "name": "freeLimitUsed",
              "isArray": false,
              "type": "Int",
              "isRequired": false,
              "attributes": []
            },
            "product": {
              "name": "product",
              "isArray": false,
              "type": {
                "nonModel": "LicenseProduct"
              },
              "isRequired": true,
              "attributes": []
            },
            "paymentTerm": {
              "name": "paymentTerm",
              "isArray": false,
              "type": {
                "enum": "IntervalEnum"
              },
              "isRequired": true,
              "attributes": []
            },
            "seats": {
              "name": "seats",
              "isArray": false,
              "type": "Int",
              "isRequired": false,
              "attributes": []
            },
            "startDate": {
              "name": "startDate",
              "isArray": false,
              "type": "AWSTimestamp",
              "isRequired": true,
              "attributes": []
            },
            "subscriptionId": {
              "name": "subscriptionId",
              "isArray": false,
              "type": "ID",
              "isRequired": false,
              "attributes": []
            },
            "type": {
              "name": "type",
              "isArray": false,
              "type": {
                "enum": "LicenseTypeEnum"
              },
              "isRequired": true,
              "attributes": []
            },
            "userId": {
              "name": "userId",
              "isArray": false,
              "type": "ID",
              "isRequired": false,
              "attributes": []
            },
            "licenseUsers": {
              "name": "licenseUsers",
              "isArray": true,
              "type": {
                "model": "UserLicenses"
              },
              "isRequired": false,
              "attributes": [],
              "isArrayNullable": true,
              "association": {
                "connectionType": "HAS_MANY",
                "associatedWith": [
                  "licenseId"
                ]
              }
            },
            "status": {
              "name": "status",
              "isArray": false,
              "type": "String",
              "isRequired": false,
              "attributes": []
            },
            "createdAt": {
              "name": "createdAt",
              "isArray": false,
              "type": "AWSDateTime",
              "isRequired": false,
              "attributes": [],
              "isReadOnly": true
            },
            "updatedAt": {
              "name": "updatedAt",
              "isArray": false,
              "type": "AWSDateTime",
              "isRequired": false,
              "attributes": [],
              "isReadOnly": true
            }
          },
          "syncable": true,
          "pluralName": "Licenses",
          "attributes": [
            {
              "type": "model",
              "properties": {}
            },
            {
              "type": "key",
              "properties": {
                "fields": [
                  "id"
                ]
              }
            },
            {
              "type": "key",
              "properties": {
                "name": "licensesBySubscriptionId",
                "queryField": "listLicenseBySubscriptionId",
                "fields": [
                  "subscriptionId"
                ]
              }
            },
            {
              "type": "key",
              "properties": {
                "name": "licensesByUserId",
                "queryField": "listLicenseByUserId",
                "fields": [
                  "userId"
                ]
              }
            },
            {
              "type": "auth",
              "properties": {
                "rules": [
                  {
                    "groupClaim": "cognito:groups",
                    "provider": "userPools",
                    "allow": "groups",
                    "groups": [
                      "SUPERADMINS"
                    ],
                    "operations": [
                      "create",
                      "update",
                      "delete",
                      "read"
                    ]
                  },
                  {
                    "provider": "userPools",
                    "ownerField": "userId",
                    "allow": "owner",
                    "identityClaim": "cognito:username",
                    "operations": [
                      "create",
                      "update",
                      "delete",
                      "read"
                    ]
                  },
                  {
                    "provider": "userPools",
                    "ownerField": "users",
                    "allow": "owner",
                    "operations": [
                      "read"
                    ],
                    "identityClaim": "cognito:username"
                  }
                ]
              }
            }
          ],
          "primaryKeyInfo": {
            "isCustomPrimaryKey": false,
            "primaryKeyFieldName": "id",
            "sortKeyFieldNames": []
          }
        },
        "UserLicenses": {
          "name": "UserLicenses",
          "fields": {
            "id": {
              "name": "id",
              "isArray": false,
              "type": "ID",
              "isRequired": true,
              "attributes": []
            },
            "cognitoId": {
              "name": "cognitoId",
              "isArray": false,
              "type": "String",
              "isRequired": true,
              "attributes": []
            },
            "licenseId": {
              "name": "licenseId",
              "isArray": false,
              "type": "String",
              "isRequired": true,
              "attributes": []
            },
            "licenses": {
              "name": "licenses",
              "isArray": false,
              "type": {
                "model": "License"
              },
              "isRequired": false,
              "attributes": [],
              "association": {
                "connectionType": "BELONGS_TO",
                "targetNames": [
                  "licenseId"
                ]
              }
            },
            "status": {
              "name": "status",
              "isArray": false,
              "type": "String",
              "isRequired": false,
              "attributes": []
            },
            "activateDate": {
              "name": "activateDate",
              "isArray": false,
              "type": "AWSTimestamp",
              "isRequired": false,
              "attributes": []
            },
            "createdAt": {
              "name": "createdAt",
              "isArray": false,
              "type": "AWSDateTime",
              "isRequired": false,
              "attributes": [],
              "isReadOnly": true
            },
            "updatedAt": {
              "name": "updatedAt",
              "isArray": false,
              "type": "AWSDateTime",
              "isRequired": false,
              "attributes": [],
              "isReadOnly": true
            }
          },
          "syncable": true,
          "pluralName": "UserLicenses",
          "attributes": [
            {
              "type": "model",
              "properties": {}
            },
            {
              "type": "key",
              "properties": {
                "fields": [
                  "id"
                ]
              }
            },
            {
              "type": "auth",
              "properties": {
                "rules": [
                  {
                    "groupClaim": "cognito:groups",
                    "provider": "userPools",
                    "allow": "groups",
                    "groups": [
                      "SUPERADMINS"
                    ],
                    "operations": [
                      "create",
                      "update",
                      "delete",
                      "read"
                    ]
                  },
                  {
                    "provider": "userPools",
                    "ownerField": "userId",
                    "allow": "owner",
                    "identityClaim": "cognito:username",
                    "operations": [
                      "create",
                      "update",
                      "delete",
                      "read"
                    ]
                  },
                  {
                    "provider": "userPools",
                    "ownerField": "users",
                    "allow": "owner",
                    "operations": [
                      "read"
                    ],
                    "identityClaim": "cognito:username"
                  },
                  {
                    "allow": "public",
                    "provider": "apiKey",
                    "operations": [
                      "read",
                      "create"
                    ]
                  }
                ]
              }
            }
          ],
          "primaryKeyInfo": {
            "isCustomPrimaryKey": false,
            "primaryKeyFieldName": "id",
            "sortKeyFieldNames": []
          }
        },
        "Company": {
          "name": "Company",
          "fields": {
            "id": {
              "name": "id",
              "isArray": false,
              "type": "ID",
              "isRequired": true,
              "attributes": []
            },
            "name": {
              "name": "name",
              "isArray": false,
              "type": "String",
              "isRequired": true,
              "attributes": []
            },
            "ownerIds": {
              "name": "ownerIds",
              "isArray": true,
              "type": "ID",
              "isRequired": false,
              "attributes": [],
              "isArrayNullable": false
            },
            "address": {
              "name": "address",
              "isArray": false,
              "type": {
                "nonModel": "CompanyAddress"
              },
              "isRequired": true,
              "attributes": []
            },
            "phoneNumber": {
              "name": "phoneNumber",
              "isArray": false,
              "type": "AWSPhone",
              "isRequired": true,
              "attributes": []
            },
            "licenses": {
              "name": "licenses",
              "isArray": true,
              "type": {
                "model": "License"
              },
              "isRequired": false,
              "attributes": [],
              "isArrayNullable": true,
              "association": {
                "connectionType": "HAS_MANY",
                "associatedWith": [
                  "companyId"
                ]
              }
            },
            "users": {
              "name": "users",
              "isArray": true,
              "type": "ID",
              "isRequired": false,
              "attributes": [],
              "isArrayNullable": false
            },
            "companyEmail": {
              "name": "companyEmail",
              "isArray": false,
              "type": "AWSEmail",
              "isRequired": true,
              "attributes": []
            },
            "billingEmail": {
              "name": "billingEmail",
              "isArray": false,
              "type": "AWSEmail",
              "isRequired": true,
              "attributes": []
            },
            "companyLogo": {
              "name": "companyLogo",
              "isArray": false,
              "type": {
                "nonModel": "Media"
              },
              "isRequired": false,
              "attributes": []
            },
            "userCompanyRoles": {
              "name": "userCompanyRoles",
              "isArray": true,
              "type": {
                "model": "UserCompanyRole"
              },
              "isRequired": false,
              "attributes": [],
              "isArrayNullable": true,
              "association": {
                "connectionType": "HAS_MANY",
                "associatedWith": [
                  "companyId"
                ]
              }
            },
            "accessCodes": {
              "name": "accessCodes",
              "isArray": true,
              "type": {
                "model": "CompanyAccessCode"
              },
              "isRequired": false,
              "attributes": [],
              "isArrayNullable": true,
              "association": {
                "connectionType": "HAS_MANY",
                "associatedWith": [
                  "companyId"
                ]
              }
            },
            "departments": {
              "name": "departments",
              "isArray": true,
              "type": {
                "model": "CompanyDepartment"
              },
              "isRequired": false,
              "attributes": [],
              "isArrayNullable": true,
              "association": {
                "connectionType": "HAS_MANY",
                "associatedWith": [
                  "companyId"
                ]
              }
            },
            "isCreationComplete": {
              "name": "isCreationComplete",
              "isArray": false,
              "type": "Boolean",
              "isRequired": false,
              "attributes": []
            },
            "createdAt": {
              "name": "createdAt",
              "isArray": false,
              "type": "AWSDateTime",
              "isRequired": false,
              "attributes": [],
              "isReadOnly": true
            },
            "updatedAt": {
              "name": "updatedAt",
              "isArray": false,
              "type": "AWSDateTime",
              "isRequired": false,
              "attributes": [],
              "isReadOnly": true
            }
          },
          "syncable": true,
          "pluralName": "Companies",
          "attributes": [
            {
              "type": "model",
              "properties": {}
            },
            {
              "type": "key",
              "properties": {
                "fields": [
                  "id"
                ]
              }
            },
            {
              "type": "key",
              "properties": {
                "name": "companiesByName",
                "queryField": "listCompanyByName",
                "fields": [
                  "name"
                ]
              }
            },
            {
              "type": "auth",
              "properties": {
                "rules": [
                  {
                    "groupClaim": "cognito:groups",
                    "provider": "userPools",
                    "allow": "groups",
                    "groups": [
                      "SUPERADMINS"
                    ],
                    "operations": [
                      "create",
                      "update",
                      "delete",
                      "read"
                    ]
                  },
                  {
                    "provider": "userPools",
                    "ownerField": "ownerIds",
                    "allow": "owner",
                    "identityClaim": "cognito:username",
                    "operations": [
                      "create",
                      "update",
                      "delete",
                      "read"
                    ]
                  },
                  {
                    "provider": "userPools",
                    "ownerField": "users",
                    "allow": "owner",
                    "operations": [
                      "read"
                    ],
                    "identityClaim": "cognito:username"
                  },
                  {
                    "allow": "public",
                    "provider": "apiKey",
                    "operations": [
                      "read",
                      "create"
                    ]
                  }
                ]
              }
            }
          ],
          "primaryKeyInfo": {
            "isCustomPrimaryKey": false,
            "primaryKeyFieldName": "id",
            "sortKeyFieldNames": []
          }
        },
        "UserCompanyRole": {
          "name": "UserCompanyRole",
          "fields": {
            "id": {
              "name": "id",
              "isArray": false,
              "type": "ID",
              "isRequired": true,
              "attributes": []
            },
            "cognitoId": {
              "name": "cognitoId",
              "isArray": false,
              "type": "String",
              "isRequired": true,
              "attributes": []
            },
            "companyId": {
              "name": "companyId",
              "isArray": false,
              "type": "String",
              "isRequired": true,
              "attributes": []
            },
            "companies": {
              "name": "companies",
              "isArray": false,
              "type": {
                "model": "Company"
              },
              "isRequired": false,
              "attributes": [],
              "association": {
                "connectionType": "BELONGS_TO",
                "targetNames": [
                  "companyId"
                ]
              }
            },
            "role": {
              "name": "role",
              "isArray": false,
              "type": "String",
              "isRequired": true,
              "attributes": []
            },
            "createdAt": {
              "name": "createdAt",
              "isArray": false,
              "type": "AWSDateTime",
              "isRequired": false,
              "attributes": [],
              "isReadOnly": true
            },
            "updatedAt": {
              "name": "updatedAt",
              "isArray": false,
              "type": "AWSDateTime",
              "isRequired": false,
              "attributes": [],
              "isReadOnly": true
            }
          },
          "syncable": true,
          "pluralName": "UserCompanyRoles",
          "attributes": [
            {
              "type": "model",
              "properties": {}
            },
            {
              "type": "key",
              "properties": {
                "fields": [
                  "id"
                ]
              }
            },
            {
              "type": "auth",
              "properties": {
                "rules": [
                  {
                    "groupClaim": "cognito:groups",
                    "provider": "userPools",
                    "allow": "groups",
                    "groups": [
                      "SUPERADMINS"
                    ],
                    "operations": [
                      "create",
                      "update",
                      "delete",
                      "read"
                    ]
                  },
                  {
                    "provider": "userPools",
                    "ownerField": "ownerIds",
                    "allow": "owner",
                    "identityClaim": "cognito:username",
                    "operations": [
                      "create",
                      "update",
                      "delete",
                      "read"
                    ]
                  },
                  {
                    "provider": "userPools",
                    "ownerField": "users",
                    "allow": "owner",
                    "operations": [
                      "read"
                    ],
                    "identityClaim": "cognito:username"
                  },
                  {
                    "allow": "public",
                    "provider": "apiKey",
                    "operations": [
                      "read",
                      "create"
                    ]
                  }
                ]
              }
            }
          ],
          "primaryKeyInfo": {
            "isCustomPrimaryKey": false,
            "primaryKeyFieldName": "id",
            "sortKeyFieldNames": []
          }
        },
        "CompanyAccessCode": {
          "name": "CompanyAccessCode",
          "fields": {
            "id": {
              "name": "id",
              "isArray": false,
              "type": "ID",
              "isRequired": true,
              "attributes": []
            },
            "code": {
              "name": "code",
              "isArray": false,
              "type": "String",
              "isRequired": true,
              "attributes": []
            },
            "appType": {
              "name": "appType",
              "isArray": false,
              "type": {
                "enum": "AppType"
              },
              "isRequired": true,
              "attributes": []
            },
            "companyId": {
              "name": "companyId",
              "isArray": false,
              "type": "ID",
              "isRequired": true,
              "attributes": []
            },
            "company": {
              "name": "company",
              "isArray": false,
              "type": {
                "model": "Company"
              },
              "isRequired": false,
              "attributes": [],
              "association": {
                "connectionType": "BELONGS_TO",
                "targetNames": [
                  "companyId"
                ]
              }
            },
            "createdAt": {
              "name": "createdAt",
              "isArray": false,
              "type": "AWSDateTime",
              "isRequired": false,
              "attributes": [],
              "isReadOnly": true
            },
            "updatedAt": {
              "name": "updatedAt",
              "isArray": false,
              "type": "AWSDateTime",
              "isRequired": false,
              "attributes": [],
              "isReadOnly": true
            }
          },
          "syncable": true,
          "pluralName": "CompanyAccessCodes",
          "attributes": [
            {
              "type": "model",
              "properties": {}
            },
            {
              "type": "key",
              "properties": {
                "fields": [
                  "companyId",
                  "appType"
                ]
              }
            },
            {
              "type": "auth",
              "properties": {
                "rules": [
                  {
                    "groupClaim": "cognito:groups",
                    "provider": "userPools",
                    "allow": "groups",
                    "groups": [
                      "SUPERADMINS"
                    ],
                    "operations": [
                      "create",
                      "update",
                      "delete",
                      "read"
                    ]
                  }
                ]
              }
            }
          ],
          "primaryKeyInfo": {
            "isCustomPrimaryKey": true,
            "primaryKeyFieldName": "companyId",
            "sortKeyFieldNames": [
              "appType"
            ]
          }
        },
        "CompanyDepartment": {
          "name": "CompanyDepartment",
          "fields": {
            "id": {
              "name": "id",
              "isArray": false,
              "type": "ID",
              "isRequired": true,
              "attributes": []
            },
            "name": {
              "name": "name",
              "isArray": false,
              "type": "String",
              "isRequired": true,
              "attributes": []
            },
            "users": {
              "name": "users",
              "isArray": true,
              "type": "ID",
              "isRequired": false,
              "attributes": [],
              "isArrayNullable": true
            },
            "userDetails": {
              "name": "userDetails",
              "isArray": true,
              "type": {
                "model": "UserDetail"
              },
              "isRequired": false,
              "attributes": [],
              "isArrayNullable": true,
              "association": {
                "connectionType": "HAS_MANY",
                "associatedWith": [
                  "departmentId"
                ]
              }
            },
            "companyId": {
              "name": "companyId",
              "isArray": false,
              "type": "ID",
              "isRequired": true,
              "attributes": []
            },
            "company": {
              "name": "company",
              "isArray": false,
              "type": {
                "model": "Company"
              },
              "isRequired": false,
              "attributes": [],
              "association": {
                "connectionType": "BELONGS_TO",
                "targetNames": [
                  "companyId"
                ]
              }
            },
            "createdAt": {
              "name": "createdAt",
              "isArray": false,
              "type": "AWSDateTime",
              "isRequired": false,
              "attributes": [],
              "isReadOnly": true
            },
            "updatedAt": {
              "name": "updatedAt",
              "isArray": false,
              "type": "AWSDateTime",
              "isRequired": false,
              "attributes": [],
              "isReadOnly": true
            }
          },
          "syncable": true,
          "pluralName": "CompanyDepartments",
          "attributes": [
            {
              "type": "model",
              "properties": {}
            },
            {
              "type": "key",
              "properties": {
                "fields": [
                  "id"
                ]
              }
            },
            {
              "type": "auth",
              "properties": {
                "rules": [
                  {
                    "groupClaim": "cognito:groups",
                    "provider": "userPools",
                    "allow": "groups",
                    "groups": [
                      "SUPERADMINS"
                    ],
                    "operations": [
                      "create",
                      "update",
                      "delete",
                      "read"
                    ]
                  },
                  {
                    "allow": "private",
                    "operations": [
                      "create",
                      "update",
                      "delete",
                      "read"
                    ]
                  },
                  {
                    "allow": "public",
                    "provider": "apiKey",
                    "operations": [
                      "create",
                      "update",
                      "delete",
                      "read"
                    ]
                  }
                ]
              }
            }
          ],
          "primaryKeyInfo": {
            "isCustomPrimaryKey": false,
            "primaryKeyFieldName": "id",
            "sortKeyFieldNames": []
          }
        },
        "Session": {
          "name": "Session",
          "fields": {
            "id": {
              "name": "id",
              "isArray": false,
              "type": "ID",
              "isRequired": true,
              "attributes": []
            },
            "therapistId": {
              "name": "therapistId",
              "isArray": false,
              "type": "ID",
              "isRequired": true,
              "attributes": []
            },
            "sessionType": {
              "name": "sessionType",
              "isArray": false,
              "type": {
                "enum": "SessionTypeEnum"
              },
              "isRequired": true,
              "attributes": []
            },
            "therapyType": {
              "name": "therapyType",
              "isArray": false,
              "type": {
                "enum": "TherapyTypeEnum"
              },
              "isRequired": true,
              "attributes": []
            },
            "status": {
              "name": "status",
              "isArray": false,
              "type": {
                "enum": "SessionStatusEnum"
              },
              "isRequired": true,
              "attributes": []
            },
            "title": {
              "name": "title",
              "isArray": false,
              "type": "String",
              "isRequired": true,
              "attributes": []
            },
            "description": {
              "name": "description",
              "isArray": false,
              "type": "String",
              "isRequired": false,
              "attributes": []
            },
            "scheduledStartTime": {
              "name": "scheduledStartTime",
              "isArray": false,
              "type": "AWSDateTime",
              "isRequired": true,
              "attributes": []
            },
            "scheduledEndTime": {
              "name": "scheduledEndTime",
              "isArray": false,
              "type": "AWSDateTime",
              "isRequired": true,
              "attributes": []
            },
            "actualStartTime": {
              "name": "actualStartTime",
              "isArray": false,
              "type": "AWSDateTime",
              "isRequired": false,
              "attributes": []
            },
            "actualEndTime": {
              "name": "actualEndTime",
              "isArray": false,
              "type": "AWSDateTime",
              "isRequired": false,
              "attributes": []
            },
            "timeZone": {
              "name": "timeZone",
              "isArray": false,
              "type": "String",
              "isRequired": true,
              "attributes": []
            },
            "chimeMeetingId": {
              "name": "chimeMeetingId",
              "isArray": false,
              "type": "String",
              "isRequired": false,
              "attributes": []
            },
            "chimeExternalMeetingId": {
              "name": "chimeExternalMeetingId",
              "isArray": false,
              "type": "String",
              "isRequired": false,
              "attributes": []
            },
            "chimeMeetingData": {
              "name": "chimeMeetingData",
              "isArray": false,
              "type": "AWSJSON",
              "isRequired": false,
              "attributes": []
            },
            "settings": {
              "name": "settings",
              "isArray": false,
              "type": "AWSJSON",
              "isRequired": false,
              "attributes": []
            },
            "metadata": {
              "name": "metadata",
              "isArray": false,
              "type": "AWSJSON",
              "isRequired": false,
              "attributes": []
            },
            "participants": {
              "name": "participants",
              "isArray": true,
              "type": {
                "model": "SessionParticipant"
              },
              "isRequired": false,
              "attributes": [],
              "isArrayNullable": true,
              "association": {
                "connectionType": "HAS_MANY",
                "associatedWith": [
                  "sessionId"
                ]
              }
            },
            "createdAt": {
              "name": "createdAt",
              "isArray": false,
              "type": "AWSDateTime",
              "isRequired": true,
              "attributes": []
            },
            "updatedAt": {
              "name": "updatedAt",
              "isArray": false,
              "type": "AWSDateTime",
              "isRequired": true,
              "attributes": []
            },
            "isDeleted": {
              "name": "isDeleted",
              "isArray": false,
              "type": "Boolean",
              "isRequired": false,
              "attributes": []
            }
          },
          "syncable": true,
          "pluralName": "Sessions",
          "attributes": [
            {
              "type": "model",
              "properties": {}
            },
            {
              "type": "key",
              "properties": {
                "fields": [
                  "id"
                ]
              }
            },
            {
              "type": "key",
              "properties": {
                "name": "sessionsByTherapistId",
                "queryField": "listSessionByTherapistId",
                "fields": [
                  "therapistId"
                ]
              }
            },
            {
              "type": "auth",
              "properties": {
                "rules": [
                  {
                    "allow": "public",
                    "provider": "apiKey",
                    "operations": [
                      "create",
                      "update",
                      "delete",
                      "read"
                    ]
                  },
                  {
                    "provider": "userPools",
                    "ownerField": "therapistId",
                    "allow": "owner",
                    "identityClaim": "cognito:username",
                    "operations": [
                      "create",
                      "update",
                      "delete",
                      "read"
                    ]
                  }
                ]
              }
            }
          ],
          "primaryKeyInfo": {
            "isCustomPrimaryKey": false,
            "primaryKeyFieldName": "id",
            "sortKeyFieldNames": []
          }
        },
        "SessionParticipant": {
          "name": "SessionParticipant",
          "fields": {
            "id": {
              "name": "id",
              "isArray": false,
              "type": "ID",
              "isRequired": true,
              "attributes": []
            },
            "sessionId": {
              "name": "sessionId",
              "isArray": false,
              "type": "ID",
              "isRequired": true,
              "attributes": []
            },
            "userId": {
              "name": "userId",
              "isArray": false,
              "type": "ID",
              "isRequired": true,
              "attributes": []
            },
            "role": {
              "name": "role",
              "isArray": false,
              "type": {
                "enum": "SessionParticipantRoleEnum"
              },
              "isRequired": true,
              "attributes": []
            },
            "status": {
              "name": "status",
              "isArray": false,
              "type": {
                "enum": "SessionParticipantStatusEnum"
              },
              "isRequired": true,
              "attributes": []
            },
            "chimeAttendeeId": {
              "name": "chimeAttendeeId",
              "isArray": false,
              "type": "String",
              "isRequired": false,
              "attributes": []
            },
            "chimeExternalUserId": {
              "name": "chimeExternalUserId",
              "isArray": false,
              "type": "String",
              "isRequired": false,
              "attributes": []
            },
            "joinToken": {
              "name": "joinToken",
              "isArray": false,
              "type": "String",
              "isRequired": false,
              "attributes": []
            },
            "invitedAt": {
              "name": "invitedAt",
              "isArray": false,
              "type": "AWSDateTime",
              "isRequired": false,
              "attributes": []
            },
            "respondedAt": {
              "name": "respondedAt",
              "isArray": false,
              "type": "AWSDateTime",
              "isRequired": false,
              "attributes": []
            },
            "joinedAt": {
              "name": "joinedAt",
              "isArray": false,
              "type": "AWSDateTime",
              "isRequired": false,
              "attributes": []
            },
            "leftAt": {
              "name": "leftAt",
              "isArray": false,
              "type": "AWSDateTime",
              "isRequired": false,
              "attributes": []
            },
            "session": {
              "name": "session",
              "isArray": false,
              "type": {
                "model": "Session"
              },
              "isRequired": false,
              "attributes": [],
              "association": {
                "connectionType": "BELONGS_TO",
                "targetNames": [
                  "sessionId"
                ]
              }
            },
            "metadata": {
              "name": "metadata",
              "isArray": false,
              "type": "AWSJSON",
              "isRequired": false,
              "attributes": []
            },
            "createdAt": {
              "name": "createdAt",
              "isArray": false,
              "type": "AWSDateTime",
              "isRequired": true,
              "attributes": []
            },
            "updatedAt": {
              "name": "updatedAt",
              "isArray": false,
              "type": "AWSDateTime",
              "isRequired": true,
              "attributes": []
            },
            "isDeleted": {
              "name": "isDeleted",
              "isArray": false,
              "type": "Boolean",
              "isRequired": false,
              "attributes": []
            }
          },
          "syncable": true,
          "pluralName": "SessionParticipants",
          "attributes": [
            {
              "type": "model",
              "properties": {}
            },
            {
              "type": "key",
              "properties": {
                "fields": [
                  "id"
                ]
              }
            },
            {
              "type": "key",
              "properties": {
                "name": "sessionParticipantsBySessionId",
                "queryField": "listSessionParticipantBySessionId",
                "fields": [
                  "sessionId"
                ]
              }
            },
            {
              "type": "auth",
              "properties": {
                "rules": [
                  {
                    "allow": "public",
                    "provider": "apiKey",
                    "operations": [
                      "create",
                      "update",
                      "delete",
                      "read"
                    ]
                  }
                ]
              }
            }
          ],
          "primaryKeyInfo": {
            "isCustomPrimaryKey": false,
            "primaryKeyFieldName": "id",
            "sortKeyFieldNames": []
          }
        },
        "SessionClient": {
          "name": "SessionClient",
          "fields": {
            "id": {
              "name": "id",
              "isArray": false,
              "type": "ID",
              "isRequired": true,
              "attributes": []
            },
            "createdBy": {
              "name": "createdBy",
              "isArray": false,
              "type": "ID",
              "isRequired": true,
              "attributes": []
            },
            "name": {
              "name": "name",
              "isArray": false,
              "type": "String",
              "isRequired": true,
              "attributes": []
            },
            "email": {
              "name": "email",
              "isArray": false,
              "type": "String",
              "isRequired": true,
              "attributes": []
            },
            "status": {
              "name": "status",
              "isArray": false,
              "type": {
                "enum": "ClientStatusEnum"
              },
              "isRequired": true,
              "attributes": []
            },
            "preferences": {
              "name": "preferences",
              "isArray": false,
              "type": "AWSJSON",
              "isRequired": false,
              "attributes": []
            },
            "memberOfGroups": {
              "name": "memberOfGroups",
              "isArray": true,
              "type": {
                "model": "GroupMember"
              },
              "isRequired": false,
              "attributes": [],
              "isArrayNullable": true,
              "association": {
                "connectionType": "HAS_MANY",
                "associatedWith": [
                  "clientId"
                ]
              }
            },
            "metadata": {
              "name": "metadata",
              "isArray": false,
              "type": "AWSJSON",
              "isRequired": false,
              "attributes": []
            },
            "createdAt": {
              "name": "createdAt",
              "isArray": false,
              "type": "AWSDateTime",
              "isRequired": true,
              "attributes": []
            },
            "updatedAt": {
              "name": "updatedAt",
              "isArray": false,
              "type": "AWSDateTime",
              "isRequired": true,
              "attributes": []
            },
            "lastSessionAt": {
              "name": "lastSessionAt",
              "isArray": false,
              "type": "AWSDateTime",
              "isRequired": false,
              "attributes": []
            },
            "updatedBy": {
              "name": "updatedBy",
              "isArray": false,
              "type": "ID",
              "isRequired": false,
              "attributes": []
            },
            "isDeleted": {
              "name": "isDeleted",
              "isArray": false,
              "type": "Boolean",
              "isRequired": false,
              "attributes": []
            }
          },
          "syncable": true,
          "pluralName": "SessionClients",
          "attributes": [
            {
              "type": "model",
              "properties": {}
            },
            {
              "type": "key",
              "properties": {
                "fields": [
                  "id"
                ]
              }
            },
            {
              "type": "key",
              "properties": {
                "name": "sessionClientsByCreatedBy",
                "queryField": "listSessionClientByCreatedBy",
                "fields": [
                  "createdBy"
                ]
              }
            },
            {
              "type": "key",
              "properties": {
                "name": "sessionClientsByEmail",
                "queryField": "listSessionClientByEmail",
                "fields": [
                  "email"
                ]
              }
            },
            {
              "type": "auth",
              "properties": {
                "rules": [
                  {
                    "allow": "public",
                    "provider": "apiKey",
                    "operations": [
                      "create",
                      "update",
                      "delete",
                      "read"
                    ]
                  },
                  {
                    "provider": "userPools",
                    "ownerField": "createdBy",
                    "allow": "owner",
                    "identityClaim": "cognito:username",
                    "operations": [
                      "create",
                      "update",
                      "delete",
                      "read"
                    ]
                  }
                ]
              }
            }
          ],
          "primaryKeyInfo": {
            "isCustomPrimaryKey": false,
            "primaryKeyFieldName": "id",
            "sortKeyFieldNames": []
          }
        },
        "SessionGroup": {
          "name": "SessionGroup",
          "fields": {
            "id": {
              "name": "id",
              "isArray": false,
              "type": "ID",
              "isRequired": true,
              "attributes": []
            },
            "createdBy": {
              "name": "createdBy",
              "isArray": false,
              "type": "ID",
              "isRequired": true,
              "attributes": []
            },
            "name": {
              "name": "name",
              "isArray": false,
              "type": "String",
              "isRequired": true,
              "attributes": []
            },
            "description": {
              "name": "description",
              "isArray": false,
              "type": "String",
              "isRequired": false,
              "attributes": []
            },
            "maxParticipants": {
              "name": "maxParticipants",
              "isArray": false,
              "type": "Int",
              "isRequired": false,
              "attributes": []
            },
            "status": {
              "name": "status",
              "isArray": false,
              "type": {
                "enum": "GroupStatusEnum"
              },
              "isRequired": true,
              "attributes": []
            },
            "members": {
              "name": "members",
              "isArray": true,
              "type": {
                "model": "GroupMember"
              },
              "isRequired": false,
              "attributes": [],
              "isArrayNullable": true,
              "association": {
                "connectionType": "HAS_MANY",
                "associatedWith": [
                  "groupId"
                ]
              }
            },
            "metadata": {
              "name": "metadata",
              "isArray": false,
              "type": "AWSJSON",
              "isRequired": false,
              "attributes": []
            },
            "createdAt": {
              "name": "createdAt",
              "isArray": false,
              "type": "AWSDateTime",
              "isRequired": true,
              "attributes": []
            },
            "updatedAt": {
              "name": "updatedAt",
              "isArray": false,
              "type": "AWSDateTime",
              "isRequired": true,
              "attributes": []
            },
            "lastSessionAt": {
              "name": "lastSessionAt",
              "isArray": false,
              "type": "AWSDateTime",
              "isRequired": false,
              "attributes": []
            },
            "updatedBy": {
              "name": "updatedBy",
              "isArray": false,
              "type": "ID",
              "isRequired": false,
              "attributes": []
            },
            "isDeleted": {
              "name": "isDeleted",
              "isArray": false,
              "type": "Boolean",
              "isRequired": false,
              "attributes": []
            }
          },
          "syncable": true,
          "pluralName": "SessionGroups",
          "attributes": [
            {
              "type": "model",
              "properties": {}
            },
            {
              "type": "key",
              "properties": {
                "fields": [
                  "id"
                ]
              }
            },
            {
              "type": "key",
              "properties": {
                "name": "sessionGroupsByCreatedBy",
                "queryField": "listSessionGroupByCreatedBy",
                "fields": [
                  "createdBy"
                ]
              }
            },
            {
              "type": "auth",
              "properties": {
                "rules": [
                  {
                    "allow": "public",
                    "provider": "apiKey",
                    "operations": [
                      "create",
                      "update",
                      "delete",
                      "read"
                    ]
                  },
                  {
                    "provider": "userPools",
                    "ownerField": "createdBy",
                    "allow": "owner",
                    "identityClaim": "cognito:username",
                    "operations": [
                      "create",
                      "update",
                      "delete",
                      "read"
                    ]
                  }
                ]
              }
            }
          ],
          "primaryKeyInfo": {
            "isCustomPrimaryKey": false,
            "primaryKeyFieldName": "id",
            "sortKeyFieldNames": []
          }
        },
        "GroupMember": {
          "name": "GroupMember",
          "fields": {
            "id": {
              "name": "id",
              "isArray": false,
              "type": "ID",
              "isRequired": true,
              "attributes": []
            },
            "groupId": {
              "name": "groupId",
              "isArray": false,
              "type": "ID",
              "isRequired": true,
              "attributes": []
            },
            "clientId": {
              "name": "clientId",
              "isArray": false,
              "type": "ID",
              "isRequired": true,
              "attributes": []
            },
            "status": {
              "name": "status",
              "isArray": false,
              "type": {
                "enum": "GroupMemberStatusEnum"
              },
              "isRequired": true,
              "attributes": []
            },
            "group": {
              "name": "group",
              "isArray": false,
              "type": {
                "model": "SessionGroup"
              },
              "isRequired": false,
              "attributes": [],
              "association": {
                "connectionType": "BELONGS_TO",
                "targetNames": [
                  "groupId"
                ]
              }
            },
            "client": {
              "name": "client",
              "isArray": false,
              "type": {
                "model": "SessionClient"
              },
              "isRequired": false,
              "attributes": [],
              "association": {
                "connectionType": "BELONGS_TO",
                "targetNames": [
                  "clientId"
                ]
              }
            },
            "joinedAt": {
              "name": "joinedAt",
              "isArray": false,
              "type": "AWSDateTime",
              "isRequired": true,
              "attributes": []
            },
            "metadata": {
              "name": "metadata",
              "isArray": false,
              "type": "AWSJSON",
              "isRequired": false,
              "attributes": []
            },
            "createdAt": {
              "name": "createdAt",
              "isArray": false,
              "type": "AWSDateTime",
              "isRequired": true,
              "attributes": []
            },
            "updatedAt": {
              "name": "updatedAt",
              "isArray": false,
              "type": "AWSDateTime",
              "isRequired": true,
              "attributes": []
            },
            "isDeleted": {
              "name": "isDeleted",
              "isArray": false,
              "type": "Boolean",
              "isRequired": false,
              "attributes": []
            }
          },
          "syncable": true,
          "pluralName": "GroupMembers",
          "attributes": [
            {
              "type": "model",
              "properties": {}
            },
            {
              "type": "key",
              "properties": {
                "fields": [
                  "id"
                ]
              }
            },
            {
              "type": "key",
              "properties": {
                "name": "groupMembersByGroupId",
                "queryField": "listGroupMemberByGroupId",
                "fields": [
                  "groupId"
                ]
              }
            },
            {
              "type": "key",
              "properties": {
                "name": "groupMembersByClientId",
                "queryField": "listGroupMemberByClientId",
                "fields": [
                  "clientId"
                ]
              }
            },
            {
              "type": "auth",
              "properties": {
                "rules": [
                  {
                    "allow": "public",
                    "provider": "apiKey",
                    "operations": [
                      "create",
                      "update",
                      "delete",
                      "read"
                    ]
                  },
                  {
                    "provider": "userPools",
                    "ownerField": "groupId",
                    "allow": "owner",
                    "identityClaim": "cognito:username",
                    "operations": [
                      "create",
                      "update",
                      "delete",
                      "read"
                    ]
                  }
                ]
              }
            }
          ],
          "primaryKeyInfo": {
            "isCustomPrimaryKey": false,
            "primaryKeyFieldName": "id",
            "sortKeyFieldNames": []
          }
        },
        "UserDetail": {
          "name": "UserDetail",
          "fields": {
            "id": {
              "name": "id",
              "isArray": false,
              "type": "ID",
              "isRequired": true,
              "attributes": []
            },
            "cognitoUserId": {
              "name": "cognitoUserId",
              "isArray": false,
              "type": "ID",
              "isRequired": false,
              "attributes": []
            },
            "name": {
              "name": "name",
              "isArray": false,
              "type": "String",
              "isRequired": false,
              "attributes": []
            },
            "profile_picture": {
              "name": "profile_picture",
              "isArray": false,
              "type": "String",
              "isRequired": false,
              "attributes": []
            },
            "appTypes": {
              "name": "appTypes",
              "isArray": true,
              "type": {
                "enum": "AppType"
              },
              "isRequired": false,
              "attributes": [],
              "isArrayNullable": true
            },
            "deviceToken": {
              "name": "deviceToken",
              "isArray": false,
              "type": "String",
              "isRequired": false,
              "attributes": []
            },
            "moods": {
              "name": "moods",
              "isArray": true,
              "type": {
                "model": "UserMoods"
              },
              "isRequired": false,
              "attributes": [],
              "isArrayNullable": true,
              "association": {
                "connectionType": "HAS_MANY",
                "associatedWith": [
                  "userId"
                ]
              }
            },
            "weeklyGratitudes": {
              "name": "weeklyGratitudes",
              "isArray": true,
              "type": {
                "model": "UserWeeklyGratitude"
              },
              "isRequired": false,
              "attributes": [],
              "isArrayNullable": true,
              "association": {
                "connectionType": "HAS_MANY",
                "associatedWith": [
                  "userId"
                ]
              }
            },
            "bookmarkedHacks": {
              "name": "bookmarkedHacks",
              "isArray": true,
              "type": {
                "model": "HackBookmarks"
              },
              "isRequired": false,
              "attributes": [],
              "isArrayNullable": true,
              "association": {
                "connectionType": "HAS_MANY",
                "associatedWith": [
                  "userId"
                ]
              }
            },
            "likedHacks": {
              "name": "likedHacks",
              "isArray": true,
              "type": {
                "model": "HackLikes"
              },
              "isRequired": false,
              "attributes": [],
              "isArrayNullable": true,
              "association": {
                "connectionType": "HAS_MANY",
                "associatedWith": [
                  "userId"
                ]
              }
            },
            "bookmarkedInsights": {
              "name": "bookmarkedInsights",
              "isArray": true,
              "type": {
                "model": "InsightBookmarks"
              },
              "isRequired": false,
              "attributes": [],
              "isArrayNullable": true,
              "association": {
                "connectionType": "HAS_MANY",
                "associatedWith": [
                  "userId"
                ]
              }
            },
            "likedInsights": {
              "name": "likedInsights",
              "isArray": true,
              "type": {
                "model": "InsightLikes"
              },
              "isRequired": false,
              "attributes": [],
              "isArrayNullable": true,
              "association": {
                "connectionType": "HAS_MANY",
                "associatedWith": [
                  "userId"
                ]
              }
            },
            "bookmarkedChallenges": {
              "name": "bookmarkedChallenges",
              "isArray": true,
              "type": {
                "model": "ChallengeBookmarks"
              },
              "isRequired": false,
              "attributes": [],
              "isArrayNullable": true,
              "association": {
                "connectionType": "HAS_MANY",
                "associatedWith": [
                  "userId"
                ]
              }
            },
            "notifications": {
              "name": "notifications",
              "isArray": true,
              "type": {
                "model": "NotificationHistories"
              },
              "isRequired": false,
              "attributes": [],
              "isArrayNullable": true,
              "association": {
                "connectionType": "HAS_MANY",
                "associatedWith": [
                  "userId"
                ]
              }
            },
            "departmentId": {
              "name": "departmentId",
              "isArray": false,
              "type": "ID",
              "isRequired": false,
              "attributes": []
            },
            "departments": {
              "name": "departments",
              "isArray": false,
              "type": {
                "model": "CompanyDepartment"
              },
              "isRequired": false,
              "attributes": [],
              "association": {
                "connectionType": "BELONGS_TO",
                "targetNames": [
                  "departmentId"
                ]
              }
            },
            "boostSchoolId": {
              "name": "boostSchoolId",
              "isArray": false,
              "type": "ID",
              "isRequired": false,
              "attributes": []
            },
            "createdAt": {
              "name": "createdAt",
              "isArray": false,
              "type": "AWSDateTime",
              "isRequired": false,
              "attributes": [],
              "isReadOnly": true
            },
            "updatedAt": {
              "name": "updatedAt",
              "isArray": false,
              "type": "AWSDateTime",
              "isRequired": false,
              "attributes": [],
              "isReadOnly": true
            }
          },
          "syncable": true,
          "pluralName": "UserDetails",
          "attributes": [
            {
              "type": "model",
              "properties": {}
            },
            {
              "type": "key",
              "properties": {
                "fields": [
                  "id"
                ]
              }
            },
            {
              "type": "auth",
              "properties": {
                "rules": [
                  {
                    "allow": "private",
                    "operations": [
                      "create",
                      "update",
                      "delete",
                      "read"
                    ]
                  }
                ]
              }
            }
          ],
          "primaryKeyInfo": {
            "isCustomPrimaryKey": false,
            "primaryKeyFieldName": "id",
            "sortKeyFieldNames": []
          }
        },
        "PositiveMessage": {
          "name": "PositiveMessage",
          "fields": {
            "id": {
              "name": "id",
              "isArray": false,
              "type": "ID",
              "isRequired": true,
              "attributes": []
            },
            "feelingType": {
              "name": "feelingType",
              "isArray": false,
              "type": {
                "enum": "FeelingType"
              },
              "isRequired": true,
              "attributes": []
            },
            "message": {
              "name": "message",
              "isArray": false,
              "type": "String",
              "isRequired": true,
              "attributes": []
            },
            "locale": {
              "name": "locale",
              "isArray": false,
              "type": {
                "enum": "Locale"
              },
              "isRequired": false,
              "attributes": []
            },
            "status": {
              "name": "status",
              "isArray": false,
              "type": {
                "enum": "Status"
              },
              "isRequired": true,
              "attributes": []
            },
            "createdAt": {
              "name": "createdAt",
              "isArray": false,
              "type": "AWSDateTime",
              "isRequired": false,
              "attributes": [],
              "isReadOnly": true
            },
            "updatedAt": {
              "name": "updatedAt",
              "isArray": false,
              "type": "AWSDateTime",
              "isRequired": false,
              "attributes": [],
              "isReadOnly": true
            }
          },
          "syncable": true,
          "pluralName": "PositiveMessages",
          "attributes": [
            {
              "type": "model",
              "properties": {}
            },
            {
              "type": "auth",
              "properties": {
                "rules": [
                  {
                    "allow": "private",
                    "operations": [
                      "create",
                      "update",
                      "delete",
                      "read"
                    ]
                  }
                ]
              }
            }
          ],
          "primaryKeyInfo": {
            "isCustomPrimaryKey": false,
            "primaryKeyFieldName": "id",
            "sortKeyFieldNames": []
          }
        },
        "Hacks": {
          "name": "Hacks",
          "fields": {
            "id": {
              "name": "id",
              "isArray": false,
              "type": "ID",
              "isRequired": true,
              "attributes": []
            },
            "title": {
              "name": "title",
              "isArray": false,
              "type": "String",
              "isRequired": true,
              "attributes": []
            },
            "video": {
              "name": "video",
              "isArray": false,
              "type": {
                "nonModel": "Media"
              },
              "isRequired": true,
              "attributes": []
            },
            "thumbnail": {
              "name": "thumbnail",
              "isArray": false,
              "type": {
                "nonModel": "Media"
              },
              "isRequired": true,
              "attributes": []
            },
            "tags": {
              "name": "tags",
              "isArray": true,
              "type": "String",
              "isRequired": false,
              "attributes": [],
              "isArrayNullable": true
            },
            "searchKey": {
              "name": "searchKey",
              "isArray": false,
              "type": "String",
              "isRequired": false,
              "attributes": []
            },
            "status": {
              "name": "status",
              "isArray": false,
              "type": {
                "enum": "Status"
              },
              "isRequired": true,
              "attributes": []
            },
            "publishOn": {
              "name": "publishOn",
              "isArray": true,
              "type": "String",
              "isRequired": false,
              "attributes": [],
              "isArrayNullable": false
            },
            "hackPlacement": {
              "name": "hackPlacement",
              "isArray": false,
              "type": {
                "enum": "Placement"
              },
              "isRequired": true,
              "attributes": []
            },
            "locale": {
              "name": "locale",
              "isArray": false,
              "type": {
                "enum": "Locale"
              },
              "isRequired": true,
              "attributes": []
            },
            "quiz": {
              "name": "quiz",
              "isArray": false,
              "type": {
                "nonModel": "Quiz"
              },
              "isRequired": false,
              "attributes": []
            },
            "reflectionPoll": {
              "name": "reflectionPoll",
              "isArray": false,
              "type": {
                "nonModel": "ReflectionPoll"
              },
              "isRequired": false,
              "attributes": []
            },
            "bookmarkedBy": {
              "name": "bookmarkedBy",
              "isArray": true,
              "type": {
                "model": "HackBookmarks"
              },
              "isRequired": false,
              "attributes": [],
              "isArrayNullable": true,
              "association": {
                "connectionType": "HAS_MANY",
                "associatedWith": [
                  "hackId"
                ]
              }
            },
            "likedBy": {
              "name": "likedBy",
              "isArray": true,
              "type": {
                "model": "HackLikes"
              },
              "isRequired": false,
              "attributes": [],
              "isArrayNullable": true,
              "association": {
                "connectionType": "HAS_MANY",
                "associatedWith": [
                  "hackId"
                ]
              }
            },
            "challenges": {
              "name": "challenges",
              "isArray": true,
              "type": {
                "model": "HackInChallenge"
              },
              "isRequired": false,
              "attributes": [],
              "isArrayNullable": true,
              "association": {
                "connectionType": "HAS_MANY",
                "associatedWith": [
                  "hackId"
                ]
              }
            },
            "article": {
              "name": "article",
              "isArray": false,
              "type": {
                "nonModel": "Article"
              },
              "isRequired": false,
              "attributes": []
            },
            "relatedContents": {
              "name": "relatedContents",
              "isArray": false,
              "type": {
                "nonModel": "HacksRelatedContents"
              },
              "isRequired": false,
              "attributes": []
            },
            "type": {
              "name": "type",
              "isArray": false,
              "type": "String",
              "isRequired": false,
              "attributes": []
            },
            "publishedAt": {
              "name": "publishedAt",
              "isArray": false,
              "type": "AWSDateTime",
              "isRequired": false,
              "attributes": []
            },
            "createdAt": {
              "name": "createdAt",
              "isArray": false,
              "type": "AWSDateTime",
              "isRequired": false,
              "attributes": [],
              "isReadOnly": true
            },
            "updatedAt": {
              "name": "updatedAt",
              "isArray": false,
              "type": "AWSDateTime",
              "isRequired": false,
              "attributes": [],
              "isReadOnly": true
            }
          },
          "syncable": true,
          "pluralName": "Hacks",
          "attributes": [
            {
              "type": "model",
              "properties": {}
            },
            {
              "type": "key",
              "properties": {
                "fields": [
                  "id"
                ]
              }
            },
            {
              "type": "key",
              "properties": {
                "name": "hacksByTypeAndPublishedAt",
                "queryField": "listHacksByDate",
                "fields": [
                  "type",
                  "publishedAt"
                ]
              }
            },
            {
              "type": "auth",
              "properties": {
                "rules": [
                  {
                    "allow": "private",
                    "operations": [
                      "create",
                      "update",
                      "delete",
                      "read"
                    ]
                  }
                ]
              }
            }
          ],
          "primaryKeyInfo": {
            "isCustomPrimaryKey": false,
            "primaryKeyFieldName": "id",
            "sortKeyFieldNames": []
          }
        },
        "Insights": {
          "name": "Insights",
          "fields": {
            "id": {
              "name": "id",
              "isArray": false,
              "type": "ID",
              "isRequired": true,
              "attributes": []
            },
            "title": {
              "name": "title",
              "isArray": false,
              "type": "String",
              "isRequired": true,
              "attributes": []
            },
            "video": {
              "name": "video",
              "isArray": false,
              "type": {
                "nonModel": "Media"
              },
              "isRequired": true,
              "attributes": []
            },
            "thumbnail": {
              "name": "thumbnail",
              "isArray": false,
              "type": {
                "nonModel": "Media"
              },
              "isRequired": true,
              "attributes": []
            },
            "tags": {
              "name": "tags",
              "isArray": true,
              "type": "String",
              "isRequired": false,
              "attributes": [],
              "isArrayNullable": true
            },
            "searchKey": {
              "name": "searchKey",
              "isArray": false,
              "type": "String",
              "isRequired": false,
              "attributes": []
            },
            "status": {
              "name": "status",
              "isArray": false,
              "type": {
                "enum": "Status"
              },
              "isRequired": true,
              "attributes": []
            },
            "publishOn": {
              "name": "publishOn",
              "isArray": true,
              "type": "String",
              "isRequired": false,
              "attributes": [],
              "isArrayNullable": false
            },
            "locale": {
              "name": "locale",
              "isArray": false,
              "type": {
                "enum": "Locale"
              },
              "isRequired": true,
              "attributes": []
            },
            "reflectionPoll": {
              "name": "reflectionPoll",
              "isArray": false,
              "type": {
                "nonModel": "ReflectionPoll"
              },
              "isRequired": false,
              "attributes": []
            },
            "quiz": {
              "name": "quiz",
              "isArray": false,
              "type": {
                "nonModel": "Quiz"
              },
              "isRequired": false,
              "attributes": []
            },
            "bookmarkedBy": {
              "name": "bookmarkedBy",
              "isArray": true,
              "type": {
                "model": "InsightBookmarks"
              },
              "isRequired": false,
              "attributes": [],
              "isArrayNullable": true,
              "association": {
                "connectionType": "HAS_MANY",
                "associatedWith": [
                  "insightId"
                ]
              }
            },
            "likedBy": {
              "name": "likedBy",
              "isArray": true,
              "type": {
                "model": "InsightLikes"
              },
              "isRequired": false,
              "attributes": [],
              "isArrayNullable": true,
              "association": {
                "connectionType": "HAS_MANY",
                "associatedWith": [
                  "insightId"
                ]
              }
            },
            "challenges": {
              "name": "challenges",
              "isArray": true,
              "type": {
                "model": "InsightInChallenge"
              },
              "isRequired": false,
              "attributes": [],
              "isArrayNullable": true,
              "association": {
                "connectionType": "HAS_MANY",
                "associatedWith": [
                  "insightId"
                ]
              }
            },
            "type": {
              "name": "type",
              "isArray": false,
              "type": "String",
              "isRequired": false,
              "attributes": []
            },
            "publishedAt": {
              "name": "publishedAt",
              "isArray": false,
              "type": "AWSDateTime",
              "isRequired": false,
              "attributes": []
            },
            "createdAt": {
              "name": "createdAt",
              "isArray": false,
              "type": "AWSDateTime",
              "isRequired": false,
              "attributes": [],
              "isReadOnly": true
            },
            "updatedAt": {
              "name": "updatedAt",
              "isArray": false,
              "type": "AWSDateTime",
              "isRequired": false,
              "attributes": [],
              "isReadOnly": true
            }
          },
          "syncable": true,
          "pluralName": "Insights",
          "attributes": [
            {
              "type": "model",
              "properties": {}
            },
            {
              "type": "key",
              "properties": {
                "fields": [
                  "id"
                ]
              }
            },
            {
              "type": "key",
              "properties": {
                "name": "insightsByTypeAndPublishedAt",
                "queryField": "listInsightsByDate",
                "fields": [
                  "type",
                  "publishedAt"
                ]
              }
            },
            {
              "type": "auth",
              "properties": {
                "rules": [
                  {
                    "allow": "private",
                    "operations": [
                      "create",
                      "update",
                      "delete",
                      "read"
                    ]
                  }
                ]
              }
            }
          ],
          "primaryKeyInfo": {
            "isCustomPrimaryKey": false,
            "primaryKeyFieldName": "id",
            "sortKeyFieldNames": []
          }
        },
        "Challenges": {
          "name": "Challenges",
          "fields": {
            "id": {
              "name": "id",
              "isArray": false,
              "type": "ID",
              "isRequired": true,
              "attributes": []
            },
            "title": {
              "name": "title",
              "isArray": false,
              "type": "String",
              "isRequired": true,
              "attributes": []
            },
            "thumbnail": {
              "name": "thumbnail",
              "isArray": false,
              "type": {
                "nonModel": "Media"
              },
              "isRequired": true,
              "attributes": []
            },
            "tags": {
              "name": "tags",
              "isArray": true,
              "type": "String",
              "isRequired": false,
              "attributes": [],
              "isArrayNullable": false
            },
            "searchKey": {
              "name": "searchKey",
              "isArray": false,
              "type": "String",
              "isRequired": false,
              "attributes": []
            },
            "locale": {
              "name": "locale",
              "isArray": false,
              "type": {
                "enum": "Locale"
              },
              "isRequired": true,
              "attributes": []
            },
            "status": {
              "name": "status",
              "isArray": false,
              "type": {
                "enum": "Status"
              },
              "isRequired": true,
              "attributes": []
            },
            "hacks": {
              "name": "hacks",
              "isArray": true,
              "type": {
                "model": "HackInChallenge"
              },
              "isRequired": false,
              "attributes": [],
              "isArrayNullable": true,
              "association": {
                "connectionType": "HAS_MANY",
                "associatedWith": [
                  "challengeId"
                ]
              }
            },
            "insights": {
              "name": "insights",
              "isArray": true,
              "type": {
                "model": "InsightInChallenge"
              },
              "isRequired": false,
              "attributes": [],
              "isArrayNullable": true,
              "association": {
                "connectionType": "HAS_MANY",
                "associatedWith": [
                  "challengeId"
                ]
              }
            },
            "bookmarkedBy": {
              "name": "bookmarkedBy",
              "isArray": true,
              "type": {
                "model": "ChallengeBookmarks"
              },
              "isRequired": false,
              "attributes": [],
              "isArrayNullable": true,
              "association": {
                "connectionType": "HAS_MANY",
                "associatedWith": [
                  "challengeId"
                ]
              }
            },
            "type": {
              "name": "type",
              "isArray": false,
              "type": "String",
              "isRequired": false,
              "attributes": []
            },
            "publishedAt": {
              "name": "publishedAt",
              "isArray": false,
              "type": "AWSDateTime",
              "isRequired": false,
              "attributes": []
            },
            "createdAt": {
              "name": "createdAt",
              "isArray": false,
              "type": "AWSDateTime",
              "isRequired": false,
              "attributes": [],
              "isReadOnly": true
            },
            "updatedAt": {
              "name": "updatedAt",
              "isArray": false,
              "type": "AWSDateTime",
              "isRequired": false,
              "attributes": [],
              "isReadOnly": true
            }
          },
          "syncable": true,
          "pluralName": "Challenges",
          "attributes": [
            {
              "type": "model",
              "properties": {}
            },
            {
              "type": "key",
              "properties": {
                "fields": [
                  "id"
                ]
              }
            },
            {
              "type": "key",
              "properties": {
                "name": "challengesByTypeAndPublishedAt",
                "queryField": "listChallengesByDate",
                "fields": [
                  "type",
                  "publishedAt"
                ]
              }
            },
            {
              "type": "auth",
              "properties": {
                "rules": [
                  {
                    "allow": "private",
                    "operations": [
                      "create",
                      "update",
                      "delete",
                      "read"
                    ]
                  }
                ]
              }
            }
          ],
          "primaryKeyInfo": {
            "isCustomPrimaryKey": false,
            "primaryKeyFieldName": "id",
            "sortKeyFieldNames": []
          }
        },
        "HackInChallenge": {
          "name": "HackInChallenge",
          "fields": {
            "id": {
              "name": "id",
              "isArray": false,
              "type": "ID",
              "isRequired": true,
              "attributes": []
            },
            "challengeId": {
              "name": "challengeId",
              "isArray": false,
              "type": "ID",
              "isRequired": false,
              "attributes": []
            },
            "hackId": {
              "name": "hackId",
              "isArray": false,
              "type": "ID",
              "isRequired": false,
              "attributes": []
            },
            "challenge": {
              "name": "challenge",
              "isArray": false,
              "type": {
                "model": "Challenges"
              },
              "isRequired": false,
              "attributes": [],
              "association": {
                "connectionType": "BELONGS_TO",
                "targetNames": [
                  "challengeId"
                ]
              }
            },
            "hack": {
              "name": "hack",
              "isArray": false,
              "type": {
                "model": "Hacks"
              },
              "isRequired": false,
              "attributes": [],
              "association": {
                "connectionType": "BELONGS_TO",
                "targetNames": [
                  "hackId"
                ]
              }
            },
            "createdAt": {
              "name": "createdAt",
              "isArray": false,
              "type": "AWSDateTime",
              "isRequired": false,
              "attributes": [],
              "isReadOnly": true
            },
            "updatedAt": {
              "name": "updatedAt",
              "isArray": false,
              "type": "AWSDateTime",
              "isRequired": false,
              "attributes": [],
              "isReadOnly": true
            }
          },
          "syncable": true,
          "pluralName": "HackInChallenges",
          "attributes": [
            {
              "type": "model",
              "properties": {}
            },
            {
              "type": "auth",
              "properties": {
                "rules": [
                  {
                    "allow": "private",
                    "operations": [
                      "create",
                      "update",
                      "delete",
                      "read"
                    ]
                  }
                ]
              }
            }
          ],
          "primaryKeyInfo": {
            "isCustomPrimaryKey": false,
            "primaryKeyFieldName": "id",
            "sortKeyFieldNames": []
          }
        },
        "InsightInChallenge": {
          "name": "InsightInChallenge",
          "fields": {
            "id": {
              "name": "id",
              "isArray": false,
              "type": "ID",
              "isRequired": true,
              "attributes": []
            },
            "challengeId": {
              "name": "challengeId",
              "isArray": false,
              "type": "ID",
              "isRequired": false,
              "attributes": []
            },
            "insightId": {
              "name": "insightId",
              "isArray": false,
              "type": "ID",
              "isRequired": false,
              "attributes": []
            },
            "challenge": {
              "name": "challenge",
              "isArray": false,
              "type": {
                "model": "Challenges"
              },
              "isRequired": false,
              "attributes": [],
              "association": {
                "connectionType": "BELONGS_TO",
                "targetNames": [
                  "challengeId"
                ]
              }
            },
            "insight": {
              "name": "insight",
              "isArray": false,
              "type": {
                "model": "Insights"
              },
              "isRequired": false,
              "attributes": [],
              "association": {
                "connectionType": "BELONGS_TO",
                "targetNames": [
                  "insightId"
                ]
              }
            },
            "createdAt": {
              "name": "createdAt",
              "isArray": false,
              "type": "AWSDateTime",
              "isRequired": false,
              "attributes": [],
              "isReadOnly": true
            },
            "updatedAt": {
              "name": "updatedAt",
              "isArray": false,
              "type": "AWSDateTime",
              "isRequired": false,
              "attributes": [],
              "isReadOnly": true
            }
          },
          "syncable": true,
          "pluralName": "InsightInChallenges",
          "attributes": [
            {
              "type": "model",
              "properties": {}
            },
            {
              "type": "auth",
              "properties": {
                "rules": [
                  {
                    "allow": "private",
                    "operations": [
                      "create",
                      "update",
                      "delete",
                      "read"
                    ]
                  }
                ]
              }
            }
          ],
          "primaryKeyInfo": {
            "isCustomPrimaryKey": false,
            "primaryKeyFieldName": "id",
            "sortKeyFieldNames": []
          }
        },
        "Milestones": {
          "name": "Milestones",
          "fields": {
            "id": {
              "name": "id",
              "isArray": false,
              "type": "ID",
              "isRequired": true,
              "attributes": []
            },
            "startDate": {
              "name": "startDate",
              "isArray": false,
              "type": "AWSDate",
              "isRequired": true,
              "attributes": []
            },
            "endDate": {
              "name": "endDate",
              "isArray": false,
              "type": "AWSDate",
              "isRequired": true,
              "attributes": []
            },
            "stickers": {
              "name": "stickers",
              "isArray": true,
              "type": {
                "nonModel": "Media"
              },
              "isRequired": false,
              "attributes": [],
              "isArrayNullable": true
            },
            "stickerPacks": {
              "name": "stickerPacks",
              "isArray": false,
              "type": {
                "nonModel": "StickerPack"
              },
              "isRequired": false,
              "attributes": []
            },
            "missions": {
              "name": "missions",
              "isArray": true,
              "type": {
                "nonModel": "Mission"
              },
              "isRequired": false,
              "attributes": [],
              "isArrayNullable": true
            },
            "status": {
              "name": "status",
              "isArray": false,
              "type": {
                "enum": "Status"
              },
              "isRequired": true,
              "attributes": []
            },
            "createdAt": {
              "name": "createdAt",
              "isArray": false,
              "type": "AWSDateTime",
              "isRequired": false,
              "attributes": [],
              "isReadOnly": true
            },
            "updatedAt": {
              "name": "updatedAt",
              "isArray": false,
              "type": "AWSDateTime",
              "isRequired": false,
              "attributes": [],
              "isReadOnly": true
            }
          },
          "syncable": true,
          "pluralName": "Milestones",
          "attributes": [
            {
              "type": "model",
              "properties": {}
            },
            {
              "type": "auth",
              "properties": {
                "rules": [
                  {
                    "allow": "private",
                    "operations": [
                      "create",
                      "update",
                      "delete",
                      "read"
                    ]
                  }
                ]
              }
            }
          ],
          "primaryKeyInfo": {
            "isCustomPrimaryKey": false,
            "primaryKeyFieldName": "id",
            "sortKeyFieldNames": []
          }
        },
        "UserMoods": {
          "name": "UserMoods",
          "fields": {
            "id": {
              "name": "id",
              "isArray": false,
              "type": "ID",
              "isRequired": true,
              "attributes": []
            },
            "indicator": {
              "name": "indicator",
              "isArray": false,
              "type": "Int",
              "isRequired": true,
              "attributes": []
            },
            "feeling": {
              "name": "feeling",
              "isArray": false,
              "type": "String",
              "isRequired": true,
              "attributes": []
            },
            "thoughts": {
              "name": "thoughts",
              "isArray": false,
              "type": "String",
              "isRequired": true,
              "attributes": []
            },
            "userId": {
              "name": "userId",
              "isArray": false,
              "type": "ID",
              "isRequired": false,
              "attributes": []
            },
            "users": {
              "name": "users",
              "isArray": false,
              "type": {
                "model": "UserDetail"
              },
              "isRequired": false,
              "attributes": [],
              "association": {
                "connectionType": "BELONGS_TO",
                "targetNames": [
                  "userId"
                ]
              }
            },
            "createdAt": {
              "name": "createdAt",
              "isArray": false,
              "type": "AWSDateTime",
              "isRequired": false,
              "attributes": [],
              "isReadOnly": true
            },
            "updatedAt": {
              "name": "updatedAt",
              "isArray": false,
              "type": "AWSDateTime",
              "isRequired": false,
              "attributes": [],
              "isReadOnly": true
            }
          },
          "syncable": true,
          "pluralName": "UserMoods",
          "attributes": [
            {
              "type": "model",
              "properties": {}
            },
            {
              "type": "auth",
              "properties": {
                "rules": [
                  {
                    "allow": "private",
                    "operations": [
                      "create",
                      "update",
                      "delete",
                      "read"
                    ]
                  }
                ]
              }
            }
          ],
          "primaryKeyInfo": {
            "isCustomPrimaryKey": false,
            "primaryKeyFieldName": "id",
            "sortKeyFieldNames": []
          }
        },
        "UserWeeklyGratitude": {
          "name": "UserWeeklyGratitude",
          "fields": {
            "id": {
              "name": "id",
              "isArray": false,
              "type": "ID",
              "isRequired": true,
              "attributes": []
            },
            "gratitude": {
              "name": "gratitude",
              "isArray": false,
              "type": "String",
              "isRequired": true,
              "attributes": []
            },
            "userId": {
              "name": "userId",
              "isArray": false,
              "type": "ID",
              "isRequired": false,
              "attributes": []
            },
            "users": {
              "name": "users",
              "isArray": false,
              "type": {
                "model": "UserDetail"
              },
              "isRequired": false,
              "attributes": [],
              "association": {
                "connectionType": "BELONGS_TO",
                "targetNames": [
                  "userId"
                ]
              }
            },
            "createdAt": {
              "name": "createdAt",
              "isArray": false,
              "type": "AWSDateTime",
              "isRequired": false,
              "attributes": [],
              "isReadOnly": true
            },
            "updatedAt": {
              "name": "updatedAt",
              "isArray": false,
              "type": "AWSDateTime",
              "isRequired": false,
              "attributes": [],
              "isReadOnly": true
            }
          },
          "syncable": true,
          "pluralName": "UserWeeklyGratitudes",
          "attributes": [
            {
              "type": "model",
              "properties": {}
            },
            {
              "type": "auth",
              "properties": {
                "rules": [
                  {
                    "allow": "private",
                    "operations": [
                      "create",
                      "update",
                      "delete",
                      "read"
                    ]
                  }
                ]
              }
            }
          ],
          "primaryKeyInfo": {
            "isCustomPrimaryKey": false,
            "primaryKeyFieldName": "id",
            "sortKeyFieldNames": []
          }
        },
        "BoostActivityLogs": {
          "name": "BoostActivityLogs",
          "fields": {
            "id": {
              "name": "id",
              "isArray": false,
              "type": "ID",
              "isRequired": true,
              "attributes": []
            },
            "userId": {
              "name": "userId",
              "isArray": false,
              "type": "ID",
              "isRequired": false,
              "attributes": []
            },
            "eventType": {
              "name": "eventType",
              "isArray": false,
              "type": {
                "enum": "EventType"
              },
              "isRequired": false,
              "attributes": []
            },
            "contentType": {
              "name": "contentType",
              "isArray": false,
              "type": {
                "enum": "ContentType"
              },
              "isRequired": false,
              "attributes": []
            },
            "contentId": {
              "name": "contentId",
              "isArray": false,
              "type": "ID",
              "isRequired": false,
              "attributes": []
            },
            "hackInChallengeId": {
              "name": "hackInChallengeId",
              "isArray": false,
              "type": "ID",
              "isRequired": false,
              "attributes": []
            },
            "insightInChallengeId": {
              "name": "insightInChallengeId",
              "isArray": false,
              "type": "ID",
              "isRequired": false,
              "attributes": []
            },
            "featureType": {
              "name": "featureType",
              "isArray": false,
              "type": {
                "enum": "FeatureType"
              },
              "isRequired": false,
              "attributes": []
            },
            "createdAt": {
              "name": "createdAt",
              "isArray": false,
              "type": "AWSDateTime",
              "isRequired": false,
              "attributes": [],
              "isReadOnly": true
            },
            "updatedAt": {
              "name": "updatedAt",
              "isArray": false,
              "type": "AWSDateTime",
              "isRequired": false,
              "attributes": [],
              "isReadOnly": true
            }
          },
          "syncable": true,
          "pluralName": "BoostActivityLogs",
          "attributes": [
            {
              "type": "model",
              "properties": {}
            },
            {
              "type": "key",
              "properties": {
                "fields": [
                  "id"
                ]
              }
            },
            {
              "type": "auth",
              "properties": {
                "rules": [
                  {
                    "allow": "private",
                    "operations": [
                      "create",
                      "update",
                      "delete",
                      "read"
                    ]
                  }
                ]
              }
            }
          ],
          "primaryKeyInfo": {
            "isCustomPrimaryKey": false,
            "primaryKeyFieldName": "id",
            "sortKeyFieldNames": []
          }
        },
        "HackBookmarks": {
          "name": "HackBookmarks",
          "fields": {
            "id": {
              "name": "id",
              "isArray": false,
              "type": "ID",
              "isRequired": true,
              "attributes": []
            },
            "hackId": {
              "name": "hackId",
              "isArray": false,
              "type": "ID",
              "isRequired": false,
              "attributes": []
            },
            "userId": {
              "name": "userId",
              "isArray": false,
              "type": "ID",
              "isRequired": false,
              "attributes": []
            },
            "hack": {
              "name": "hack",
              "isArray": false,
              "type": {
                "model": "Hacks"
              },
              "isRequired": false,
              "attributes": [],
              "association": {
                "connectionType": "BELONGS_TO",
                "targetNames": [
                  "hackId"
                ]
              }
            },
            "user": {
              "name": "user",
              "isArray": false,
              "type": {
                "model": "UserDetail"
              },
              "isRequired": false,
              "attributes": [],
              "association": {
                "connectionType": "BELONGS_TO",
                "targetNames": [
                  "userId"
                ]
              }
            },
            "createdAt": {
              "name": "createdAt",
              "isArray": false,
              "type": "AWSDateTime",
              "isRequired": false,
              "attributes": [],
              "isReadOnly": true
            },
            "updatedAt": {
              "name": "updatedAt",
              "isArray": false,
              "type": "AWSDateTime",
              "isRequired": false,
              "attributes": [],
              "isReadOnly": true
            }
          },
          "syncable": true,
          "pluralName": "HackBookmarks",
          "attributes": [
            {
              "type": "model",
              "properties": {}
            },
            {
              "type": "auth",
              "properties": {
                "rules": [
                  {
                    "allow": "private",
                    "operations": [
                      "create",
                      "update",
                      "delete",
                      "read"
                    ]
                  }
                ]
              }
            }
          ],
          "primaryKeyInfo": {
            "isCustomPrimaryKey": false,
            "primaryKeyFieldName": "id",
            "sortKeyFieldNames": []
          }
        },
        "HackLikes": {
          "name": "HackLikes",
          "fields": {
            "id": {
              "name": "id",
              "isArray": false,
              "type": "ID",
              "isRequired": true,
              "attributes": []
            },
            "hackId": {
              "name": "hackId",
              "isArray": false,
              "type": "ID",
              "isRequired": false,
              "attributes": []
            },
            "userId": {
              "name": "userId",
              "isArray": false,
              "type": "ID",
              "isRequired": false,
              "attributes": []
            },
            "hack": {
              "name": "hack",
              "isArray": false,
              "type": {
                "model": "Hacks"
              },
              "isRequired": false,
              "attributes": [],
              "association": {
                "connectionType": "BELONGS_TO",
                "targetNames": [
                  "hackId"
                ]
              }
            },
            "user": {
              "name": "user",
              "isArray": false,
              "type": {
                "model": "UserDetail"
              },
              "isRequired": false,
              "attributes": [],
              "association": {
                "connectionType": "BELONGS_TO",
                "targetNames": [
                  "userId"
                ]
              }
            },
            "createdAt": {
              "name": "createdAt",
              "isArray": false,
              "type": "AWSDateTime",
              "isRequired": false,
              "attributes": [],
              "isReadOnly": true
            },
            "updatedAt": {
              "name": "updatedAt",
              "isArray": false,
              "type": "AWSDateTime",
              "isRequired": false,
              "attributes": [],
              "isReadOnly": true
            }
          },
          "syncable": true,
          "pluralName": "HackLikes",
          "attributes": [
            {
              "type": "model",
              "properties": {}
            },
            {
              "type": "auth",
              "properties": {
                "rules": [
                  {
                    "allow": "private",
                    "operations": [
                      "create",
                      "update",
                      "delete",
                      "read"
                    ]
                  }
                ]
              }
            }
          ],
          "primaryKeyInfo": {
            "isCustomPrimaryKey": false,
            "primaryKeyFieldName": "id",
            "sortKeyFieldNames": []
          }
        },
        "HackQuizAnswer": {
          "name": "HackQuizAnswer",
          "fields": {
            "hackId": {
              "name": "hackId",
              "isArray": false,
              "type": "String",
              "isRequired": true,
              "attributes": []
            },
            "userId": {
              "name": "userId",
              "isArray": false,
              "type": "String",
              "isRequired": true,
              "attributes": []
            },
            "answerId": {
              "name": "answerId",
              "isArray": false,
              "type": "String",
              "isRequired": true,
              "attributes": []
            },
            "createdAt": {
              "name": "createdAt",
              "isArray": false,
              "type": "AWSDateTime",
              "isRequired": false,
              "attributes": [],
              "isReadOnly": true
            },
            "updatedAt": {
              "name": "updatedAt",
              "isArray": false,
              "type": "AWSDateTime",
              "isRequired": false,
              "attributes": [],
              "isReadOnly": true
            }
          },
          "syncable": true,
          "pluralName": "HackQuizAnswers",
          "attributes": [
            {
              "type": "model",
              "properties": {}
            },
            {
              "type": "key",
              "properties": {
                "fields": [
                  "hackId",
                  "userId"
                ]
              }
            },
            {
              "type": "auth",
              "properties": {
                "rules": [
                  {
                    "allow": "private",
                    "operations": [
                      "create",
                      "update",
                      "delete",
                      "read"
                    ]
                  }
                ]
              }
            }
          ],
          "primaryKeyInfo": {
            "isCustomPrimaryKey": true,
            "primaryKeyFieldName": "hackId",
            "sortKeyFieldNames": [
              "userId"
            ]
          }
        },
        "HackPollAnswer": {
          "name": "HackPollAnswer",
          "fields": {
            "hackId": {
              "name": "hackId",
              "isArray": false,
              "type": "String",
              "isRequired": true,
              "attributes": []
            },
            "userId": {
              "name": "userId",
              "isArray": false,
              "type": "String",
              "isRequired": true,
              "attributes": []
            },
            "answerId": {
              "name": "answerId",
              "isArray": false,
              "type": "String",
              "isRequired": true,
              "attributes": []
            },
            "createdAt": {
              "name": "createdAt",
              "isArray": false,
              "type": "AWSDateTime",
              "isRequired": false,
              "attributes": [],
              "isReadOnly": true
            },
            "updatedAt": {
              "name": "updatedAt",
              "isArray": false,
              "type": "AWSDateTime",
              "isRequired": false,
              "attributes": [],
              "isReadOnly": true
            }
          },
          "syncable": true,
          "pluralName": "HackPollAnswers",
          "attributes": [
            {
              "type": "model",
              "properties": {}
            },
            {
              "type": "key",
              "properties": {
                "fields": [
                  "hackId",
                  "userId"
                ]
              }
            },
            {
              "type": "auth",
              "properties": {
                "rules": [
                  {
                    "allow": "private",
                    "operations": [
                      "create",
                      "update",
                      "delete",
                      "read"
                    ]
                  }
                ]
              }
            }
          ],
          "primaryKeyInfo": {
            "isCustomPrimaryKey": true,
            "primaryKeyFieldName": "hackId",
            "sortKeyFieldNames": [
              "userId"
            ]
          }
        },
        "InsightBookmarks": {
          "name": "InsightBookmarks",
          "fields": {
            "id": {
              "name": "id",
              "isArray": false,
              "type": "ID",
              "isRequired": true,
              "attributes": []
            },
            "insightId": {
              "name": "insightId",
              "isArray": false,
              "type": "ID",
              "isRequired": false,
              "attributes": []
            },
            "userId": {
              "name": "userId",
              "isArray": false,
              "type": "ID",
              "isRequired": false,
              "attributes": []
            },
            "insight": {
              "name": "insight",
              "isArray": false,
              "type": {
                "model": "Insights"
              },
              "isRequired": false,
              "attributes": [],
              "association": {
                "connectionType": "BELONGS_TO",
                "targetNames": [
                  "insightId"
                ]
              }
            },
            "user": {
              "name": "user",
              "isArray": false,
              "type": {
                "model": "UserDetail"
              },
              "isRequired": false,
              "attributes": [],
              "association": {
                "connectionType": "BELONGS_TO",
                "targetNames": [
                  "userId"
                ]
              }
            },
            "createdAt": {
              "name": "createdAt",
              "isArray": false,
              "type": "AWSDateTime",
              "isRequired": false,
              "attributes": [],
              "isReadOnly": true
            },
            "updatedAt": {
              "name": "updatedAt",
              "isArray": false,
              "type": "AWSDateTime",
              "isRequired": false,
              "attributes": [],
              "isReadOnly": true
            }
          },
          "syncable": true,
          "pluralName": "InsightBookmarks",
          "attributes": [
            {
              "type": "model",
              "properties": {}
            },
            {
              "type": "auth",
              "properties": {
                "rules": [
                  {
                    "allow": "private",
                    "operations": [
                      "create",
                      "update",
                      "delete",
                      "read"
                    ]
                  }
                ]
              }
            }
          ],
          "primaryKeyInfo": {
            "isCustomPrimaryKey": false,
            "primaryKeyFieldName": "id",
            "sortKeyFieldNames": []
          }
        },
        "InsightLikes": {
          "name": "InsightLikes",
          "fields": {
            "id": {
              "name": "id",
              "isArray": false,
              "type": "ID",
              "isRequired": true,
              "attributes": []
            },
            "insightId": {
              "name": "insightId",
              "isArray": false,
              "type": "ID",
              "isRequired": false,
              "attributes": []
            },
            "userId": {
              "name": "userId",
              "isArray": false,
              "type": "ID",
              "isRequired": false,
              "attributes": []
            },
            "insight": {
              "name": "insight",
              "isArray": false,
              "type": {
                "model": "Insights"
              },
              "isRequired": false,
              "attributes": [],
              "association": {
                "connectionType": "BELONGS_TO",
                "targetNames": [
                  "insightId"
                ]
              }
            },
            "user": {
              "name": "user",
              "isArray": false,
              "type": {
                "model": "UserDetail"
              },
              "isRequired": false,
              "attributes": [],
              "association": {
                "connectionType": "BELONGS_TO",
                "targetNames": [
                  "userId"
                ]
              }
            },
            "createdAt": {
              "name": "createdAt",
              "isArray": false,
              "type": "AWSDateTime",
              "isRequired": false,
              "attributes": [],
              "isReadOnly": true
            },
            "updatedAt": {
              "name": "updatedAt",
              "isArray": false,
              "type": "AWSDateTime",
              "isRequired": false,
              "attributes": [],
              "isReadOnly": true
            }
          },
          "syncable": true,
          "pluralName": "InsightLikes",
          "attributes": [
            {
              "type": "model",
              "properties": {}
            },
            {
              "type": "auth",
              "properties": {
                "rules": [
                  {
                    "allow": "private",
                    "operations": [
                      "create",
                      "update",
                      "delete",
                      "read"
                    ]
                  }
                ]
              }
            }
          ],
          "primaryKeyInfo": {
            "isCustomPrimaryKey": false,
            "primaryKeyFieldName": "id",
            "sortKeyFieldNames": []
          }
        },
        "InsightPollAnswer": {
          "name": "InsightPollAnswer",
          "fields": {
            "insightId": {
              "name": "insightId",
              "isArray": false,
              "type": "String",
              "isRequired": true,
              "attributes": []
            },
            "userId": {
              "name": "userId",
              "isArray": false,
              "type": "String",
              "isRequired": true,
              "attributes": []
            },
            "answerId": {
              "name": "answerId",
              "isArray": false,
              "type": "String",
              "isRequired": true,
              "attributes": []
            },
            "createdAt": {
              "name": "createdAt",
              "isArray": false,
              "type": "AWSDateTime",
              "isRequired": false,
              "attributes": [],
              "isReadOnly": true
            },
            "updatedAt": {
              "name": "updatedAt",
              "isArray": false,
              "type": "AWSDateTime",
              "isRequired": false,
              "attributes": [],
              "isReadOnly": true
            }
          },
          "syncable": true,
          "pluralName": "InsightPollAnswers",
          "attributes": [
            {
              "type": "model",
              "properties": {}
            },
            {
              "type": "key",
              "properties": {
                "fields": [
                  "insightId",
                  "userId"
                ]
              }
            },
            {
              "type": "auth",
              "properties": {
                "rules": [
                  {
                    "allow": "private",
                    "operations": [
                      "create",
                      "update",
                      "delete",
                      "read"
                    ]
                  }
                ]
              }
            }
          ],
          "primaryKeyInfo": {
            "isCustomPrimaryKey": true,
            "primaryKeyFieldName": "insightId",
            "sortKeyFieldNames": [
              "userId"
            ]
          }
        },
        "InsightQuizAnswer": {
          "name": "InsightQuizAnswer",
          "fields": {
            "insightId": {
              "name": "insightId",
              "isArray": false,
              "type": "String",
              "isRequired": true,
              "attributes": []
            },
            "userId": {
              "name": "userId",
              "isArray": false,
              "type": "String",
              "isRequired": true,
              "attributes": []
            },
            "answerId": {
              "name": "answerId",
              "isArray": false,
              "type": "String",
              "isRequired": true,
              "attributes": []
            },
            "createdAt": {
              "name": "createdAt",
              "isArray": false,
              "type": "AWSDateTime",
              "isRequired": false,
              "attributes": [],
              "isReadOnly": true
            },
            "updatedAt": {
              "name": "updatedAt",
              "isArray": false,
              "type": "AWSDateTime",
              "isRequired": false,
              "attributes": [],
              "isReadOnly": true
            }
          },
          "syncable": true,
          "pluralName": "InsightQuizAnswers",
          "attributes": [
            {
              "type": "model",
              "properties": {}
            },
            {
              "type": "key",
              "properties": {
                "fields": [
                  "insightId",
                  "userId"
                ]
              }
            },
            {
              "type": "auth",
              "properties": {
                "rules": [
                  {
                    "allow": "private",
                    "operations": [
                      "create",
                      "update",
                      "delete",
                      "read"
                    ]
                  }
                ]
              }
            }
          ],
          "primaryKeyInfo": {
            "isCustomPrimaryKey": true,
            "primaryKeyFieldName": "insightId",
            "sortKeyFieldNames": [
              "userId"
            ]
          }
        },
        "ChallengeBookmarks": {
          "name": "ChallengeBookmarks",
          "fields": {
            "id": {
              "name": "id",
              "isArray": false,
              "type": "ID",
              "isRequired": true,
              "attributes": []
            },
            "challengeId": {
              "name": "challengeId",
              "isArray": false,
              "type": "ID",
              "isRequired": false,
              "attributes": []
            },
            "userId": {
              "name": "userId",
              "isArray": false,
              "type": "ID",
              "isRequired": false,
              "attributes": []
            },
            "challenge": {
              "name": "challenge",
              "isArray": false,
              "type": {
                "model": "Challenges"
              },
              "isRequired": false,
              "attributes": [],
              "association": {
                "connectionType": "BELONGS_TO",
                "targetNames": [
                  "challengeId"
                ]
              }
            },
            "user": {
              "name": "user",
              "isArray": false,
              "type": {
                "model": "UserDetail"
              },
              "isRequired": false,
              "attributes": [],
              "association": {
                "connectionType": "BELONGS_TO",
                "targetNames": [
                  "userId"
                ]
              }
            },
            "createdAt": {
              "name": "createdAt",
              "isArray": false,
              "type": "AWSDateTime",
              "isRequired": false,
              "attributes": [],
              "isReadOnly": true
            },
            "updatedAt": {
              "name": "updatedAt",
              "isArray": false,
              "type": "AWSDateTime",
              "isRequired": false,
              "attributes": [],
              "isReadOnly": true
            }
          },
          "syncable": true,
          "pluralName": "ChallengeBookmarks",
          "attributes": [
            {
              "type": "model",
              "properties": {}
            },
            {
              "type": "auth",
              "properties": {
                "rules": [
                  {
                    "allow": "private",
                    "operations": [
                      "create",
                      "update",
                      "delete",
                      "read"
                    ]
                  }
                ]
              }
            }
          ],
          "primaryKeyInfo": {
            "isCustomPrimaryKey": false,
            "primaryKeyFieldName": "id",
            "sortKeyFieldNames": []
          }
        },
        "ChallengeProgress": {
          "name": "ChallengeProgress",
          "fields": {
            "challengeId": {
              "name": "challengeId",
              "isArray": false,
              "type": "String",
              "isRequired": true,
              "attributes": []
            },
            "userId": {
              "name": "userId",
              "isArray": false,
              "type": "String",
              "isRequired": true,
              "attributes": []
            },
            "hacks": {
              "name": "hacks",
              "isArray": true,
              "type": {
                "nonModel": "ChallengeHackProgress"
              },
              "isRequired": false,
              "attributes": [],
              "isArrayNullable": true
            },
            "insights": {
              "name": "insights",
              "isArray": true,
              "type": {
                "nonModel": "ChallengeInsightProgress"
              },
              "isRequired": false,
              "attributes": [],
              "isArrayNullable": true
            },
            "progressPercentage": {
              "name": "progressPercentage",
              "isArray": false,
              "type": "Float",
              "isRequired": false,
              "attributes": []
            },
            "createdAt": {
              "name": "createdAt",
              "isArray": false,
              "type": "AWSDateTime",
              "isRequired": false,
              "attributes": [],
              "isReadOnly": true
            },
            "updatedAt": {
              "name": "updatedAt",
              "isArray": false,
              "type": "AWSDateTime",
              "isRequired": false,
              "attributes": [],
              "isReadOnly": true
            }
          },
          "syncable": true,
          "pluralName": "ChallengeProgresses",
          "attributes": [
            {
              "type": "model",
              "properties": {}
            },
            {
              "type": "key",
              "properties": {
                "fields": [
                  "challengeId",
                  "userId"
                ]
              }
            },
            {
              "type": "auth",
              "properties": {
                "rules": [
                  {
                    "allow": "private",
                    "operations": [
                      "create",
                      "update",
                      "delete",
                      "read"
                    ]
                  }
                ]
              }
            }
          ],
          "primaryKeyInfo": {
            "isCustomPrimaryKey": true,
            "primaryKeyFieldName": "challengeId",
            "sortKeyFieldNames": [
              "userId"
            ]
          }
        },
        "UserMilestones": {
          "name": "UserMilestones",
          "fields": {
            "milestoneId": {
              "name": "milestoneId",
              "isArray": false,
              "type": "String",
              "isRequired": true,
              "attributes": []
            },
            "userId": {
              "name": "userId",
              "isArray": false,
              "type": "String",
              "isRequired": true,
              "attributes": []
            },
            "startDate": {
              "name": "startDate",
              "isArray": false,
              "type": "AWSDate",
              "isRequired": false,
              "attributes": []
            },
            "endDate": {
              "name": "endDate",
              "isArray": false,
              "type": "AWSDate",
              "isRequired": false,
              "attributes": []
            },
            "week1": {
              "name": "week1",
              "isArray": true,
              "type": {
                "nonModel": "WeeklyMissionAchievement"
              },
              "isRequired": false,
              "attributes": [],
              "isArrayNullable": true
            },
            "week2": {
              "name": "week2",
              "isArray": true,
              "type": {
                "nonModel": "WeeklyMissionAchievement"
              },
              "isRequired": false,
              "attributes": [],
              "isArrayNullable": true
            },
            "week3": {
              "name": "week3",
              "isArray": true,
              "type": {
                "nonModel": "WeeklyMissionAchievement"
              },
              "isRequired": false,
              "attributes": [],
              "isArrayNullable": true
            },
            "week4": {
              "name": "week4",
              "isArray": true,
              "type": {
                "nonModel": "WeeklyMissionAchievement"
              },
              "isRequired": false,
              "attributes": [],
              "isArrayNullable": true
            },
            "points": {
              "name": "points",
              "isArray": false,
              "type": {
                "nonModel": "Points"
              },
              "isRequired": false,
              "attributes": []
            },
            "weeklyPoints": {
              "name": "weeklyPoints",
              "isArray": false,
              "type": {
                "nonModel": "WeeklyPoints"
              },
              "isRequired": false,
              "attributes": []
            },
            "createdAt": {
              "name": "createdAt",
              "isArray": false,
              "type": "AWSDateTime",
              "isRequired": false,
              "attributes": [],
              "isReadOnly": true
            },
            "updatedAt": {
              "name": "updatedAt",
              "isArray": false,
              "type": "AWSDateTime",
              "isRequired": false,
              "attributes": [],
              "isReadOnly": true
            }
          },
          "syncable": true,
          "pluralName": "UserMilestones",
          "attributes": [
            {
              "type": "model",
              "properties": {}
            },
            {
              "type": "key",
              "properties": {
                "fields": [
                  "milestoneId",
                  "userId"
                ]
              }
            },
            {
              "type": "auth",
              "properties": {
                "rules": [
                  {
                    "allow": "private",
                    "operations": [
                      "create",
                      "update",
                      "delete",
                      "read"
                    ]
                  }
                ]
              }
            }
          ],
          "primaryKeyInfo": {
            "isCustomPrimaryKey": true,
            "primaryKeyFieldName": "milestoneId",
            "sortKeyFieldNames": [
              "userId"
            ]
          }
        },
        "Notification": {
          "name": "Notification",
          "fields": {
            "id": {
              "name": "id",
              "isArray": false,
              "type": "ID",
              "isRequired": true,
              "attributes": []
            },
            "type": {
              "name": "type",
              "isArray": false,
              "type": "String",
              "isRequired": false,
              "attributes": []
            },
            "title": {
              "name": "title",
              "isArray": false,
              "type": "String",
              "isRequired": false,
              "attributes": []
            },
            "message": {
              "name": "message",
              "isArray": false,
              "type": "String",
              "isRequired": false,
              "attributes": []
            },
            "data": {
              "name": "data",
              "isArray": false,
              "type": "String",
              "isRequired": false,
              "attributes": []
            },
            "notificationHistories": {
              "name": "notificationHistories",
              "isArray": true,
              "type": {
                "model": "NotificationHistories"
              },
              "isRequired": false,
              "attributes": [],
              "isArrayNullable": true,
              "association": {
                "connectionType": "HAS_MANY",
                "associatedWith": [
                  "notificationId"
                ]
              }
            },
            "createdAt": {
              "name": "createdAt",
              "isArray": false,
              "type": "AWSDateTime",
              "isRequired": false,
              "attributes": [],
              "isReadOnly": true
            },
            "updatedAt": {
              "name": "updatedAt",
              "isArray": false,
              "type": "AWSDateTime",
              "isRequired": false,
              "attributes": [],
              "isReadOnly": true
            }
          },
          "syncable": true,
          "pluralName": "Notifications",
          "attributes": [
            {
              "type": "model",
              "properties": {}
            },
            {
              "type": "key",
              "properties": {
                "fields": [
                  "id"
                ]
              }
            },
            {
              "type": "auth",
              "properties": {
                "rules": [
                  {
                    "allow": "private",
                    "operations": [
                      "create",
                      "update",
                      "delete",
                      "read"
                    ]
                  }
                ]
              }
            }
          ],
          "primaryKeyInfo": {
            "isCustomPrimaryKey": false,
            "primaryKeyFieldName": "id",
            "sortKeyFieldNames": []
          }
        },
        "NotificationHistories": {
          "name": "NotificationHistories",
          "fields": {
            "id": {
              "name": "id",
              "isArray": false,
              "type": "ID",
              "isRequired": false,
              "attributes": []
            },
            "notificationId": {
              "name": "notificationId",
              "isArray": false,
              "type": "String",
              "isRequired": true,
              "attributes": []
            },
            "notification": {
              "name": "notification",
              "isArray": false,
              "type": {
                "model": "Notification"
              },
              "isRequired": false,
              "attributes": [],
              "association": {
                "connectionType": "BELONGS_TO",
                "targetNames": [
                  "notificationId"
                ]
              }
            },
            "userId": {
              "name": "userId",
              "isArray": false,
              "type": "String",
              "isRequired": true,
              "attributes": []
            },
            "user": {
              "name": "user",
              "isArray": false,
              "type": {
                "model": "UserDetail"
              },
              "isRequired": false,
              "attributes": [],
              "association": {
                "connectionType": "BELONGS_TO",
                "targetNames": [
                  "userId"
                ]
              }
            },
            "isRead": {
              "name": "isRead",
              "isArray": false,
              "type": "Boolean",
              "isRequired": false,
              "attributes": []
            },
            "createdAt": {
              "name": "createdAt",
              "isArray": false,
              "type": "AWSDateTime",
              "isRequired": false,
              "attributes": [],
              "isReadOnly": true
            },
            "updatedAt": {
              "name": "updatedAt",
              "isArray": false,
              "type": "AWSDateTime",
              "isRequired": false,
              "attributes": [],
              "isReadOnly": true
            }
          },
          "syncable": true,
          "pluralName": "NotificationHistories",
          "attributes": [
            {
              "type": "model",
              "properties": {}
            },
            {
              "type": "key",
              "properties": {
                "fields": [
                  "notificationId",
                  "userId"
                ]
              }
            },
            {
              "type": "auth",
              "properties": {
                "rules": [
                  {
                    "allow": "private",
                    "operations": [
                      "create",
                      "update",
                      "delete",
                      "read"
                    ]
                  }
                ]
              }
            }
          ],
          "primaryKeyInfo": {
            "isCustomPrimaryKey": true,
            "primaryKeyFieldName": "notificationId",
            "sortKeyFieldNames": [
              "userId"
            ]
          }
        }
      },
      "enums": {
        "Gender": {
          "name": "Gender",
          "values": [
            "female",
            "male",
            "non_binary",
            "prefer_not_to_say"
          ]
        },
        "PaymentIntervalEnum": {
          "name": "PaymentIntervalEnum",
          "values": [
            "monthly",
            "yearly"
          ]
        },
        "LicenseTypeEnum": {
          "name": "LicenseTypeEnum",
          "values": [
            "trial",
            "trial_pm",
            "paid"
          ]
        },
        "CancellationDetailsFeedbackEnum": {
          "name": "CancellationDetailsFeedbackEnum",
          "values": [
            "customer_service",
            "low_quality",
            "missing_features",
            "other",
            "switched_service",
            "too_complex",
            "too_expensive",
            "unused"
          ]
        },
        "CancellationDetailsReasonEnum": {
          "name": "CancellationDetailsReasonEnum",
          "values": [
            "cancellation_requested",
            "payment_disputed",
            "payment_failed"
          ]
        },
        "CheckoutAcssDebitMandateOptionsDefaultForEnum": {
          "name": "CheckoutAcssDebitMandateOptionsDefaultForEnum",
          "values": [
            "invoice",
            "subscription"
          ]
        },
        "CheckoutAcssDebitMandateOptionsPaymentScheduleEnum": {
          "name": "CheckoutAcssDebitMandateOptionsPaymentScheduleEnum",
          "values": [
            "combined",
            "interval",
            "sporadic"
          ]
        },
        "CheckoutAcssDebitMandateOptionsTransactionTypeEnum": {
          "name": "CheckoutAcssDebitMandateOptionsTransactionTypeEnum",
          "values": [
            "business",
            "personal"
          ]
        },
        "CheckoutAcssDebitPaymentMethodOptionsCurrencyEnum": {
          "name": "CheckoutAcssDebitPaymentMethodOptionsCurrencyEnum",
          "values": [
            "cad",
            "usd"
          ]
        },
        "CheckoutAcssDebitPaymentMethodOptionsSetupFutureUsageEnum": {
          "name": "CheckoutAcssDebitPaymentMethodOptionsSetupFutureUsageEnum",
          "values": [
            "none",
            "off_session",
            "on_session"
          ]
        },
        "CheckoutAcssDebitPaymentMethodOptionsVerificationMethodEnum": {
          "name": "CheckoutAcssDebitPaymentMethodOptionsVerificationMethodEnum",
          "values": [
            "automatic",
            "instant",
            "microdeposits"
          ]
        },
        "CheckoutAffirmPaymentMethodOptionsSetupFutureUsageEnum": {
          "name": "CheckoutAffirmPaymentMethodOptionsSetupFutureUsageEnum",
          "values": [
            "none"
          ]
        },
        "CheckoutAfterpayClearpayPaymentMethodOptionsSetupFutureUsageEnum": {
          "name": "CheckoutAfterpayClearpayPaymentMethodOptionsSetupFutureUsageEnum",
          "values": [
            "none"
          ]
        },
        "CheckoutAlipayPaymentMethodOptionsSetupFutureUsageEnum": {
          "name": "CheckoutAlipayPaymentMethodOptionsSetupFutureUsageEnum",
          "values": [
            "none"
          ]
        },
        "CheckoutAmazonPayPaymentMethodOptionsSetupFutureUsageEnum": {
          "name": "CheckoutAmazonPayPaymentMethodOptionsSetupFutureUsageEnum",
          "values": [
            "none",
            "off_session"
          ]
        },
        "CheckoutAuBecsDebitPaymentMethodOptionsSetupFutureUsageEnum": {
          "name": "CheckoutAuBecsDebitPaymentMethodOptionsSetupFutureUsageEnum",
          "values": [
            "none"
          ]
        },
        "CheckoutBacsDebitPaymentMethodOptionsSetupFutureUsageEnum": {
          "name": "CheckoutBacsDebitPaymentMethodOptionsSetupFutureUsageEnum",
          "values": [
            "none",
            "off_session",
            "on_session"
          ]
        },
        "CheckoutBancontactPaymentMethodOptionsSetupFutureUsageEnum": {
          "name": "CheckoutBancontactPaymentMethodOptionsSetupFutureUsageEnum",
          "values": [
            "none"
          ]
        },
        "CheckoutBoletoPaymentMethodOptionsSetupFutureUsageEnum": {
          "name": "CheckoutBoletoPaymentMethodOptionsSetupFutureUsageEnum",
          "values": [
            "none",
            "off_session",
            "on_session"
          ]
        },
        "CheckoutCardPaymentMethodOptionsRequestThreeDSecureEnum": {
          "name": "CheckoutCardPaymentMethodOptionsRequestThreeDSecureEnum",
          "values": [
            "any",
            "automatic",
            "challenge"
          ]
        },
        "CheckoutCardPaymentMethodOptionsSetupFutureUsageEnum": {
          "name": "CheckoutCardPaymentMethodOptionsSetupFutureUsageEnum",
          "values": [
            "none",
            "off_session",
            "on_session"
          ]
        },
        "CheckoutCashappPaymentMethodOptionsSetupFutureUsageEnum": {
          "name": "CheckoutCashappPaymentMethodOptionsSetupFutureUsageEnum",
          "values": [
            "none"
          ]
        },
        "CheckoutCustomerBalanceBankTransferPaymentMethodOptionsRequestedAddressTypesEnum": {
          "name": "CheckoutCustomerBalanceBankTransferPaymentMethodOptionsRequestedAddressTypesEnum",
          "values": [
            "aba",
            "iban",
            "sepa",
            "sort_code",
            "spei",
            "swift",
            "zengin"
          ]
        },
        "CheckoutCustomerBalanceBankTransferPaymentMethodOptionsTypeEnum": {
          "name": "CheckoutCustomerBalanceBankTransferPaymentMethodOptionsTypeEnum",
          "values": [
            "eu_bank_transfer",
            "gb_bank_transfer",
            "jp_bank_transfer",
            "mx_bank_transfer",
            "us_bank_transfer"
          ]
        },
        "CheckoutCustomerBalancePaymentMethodOptionsFundingTypeEnum": {
          "name": "CheckoutCustomerBalancePaymentMethodOptionsFundingTypeEnum",
          "values": [
            "bank_transfer"
          ]
        },
        "CheckoutCustomerBalancePaymentMethodOptionsSetupFutureUsageEnum": {
          "name": "CheckoutCustomerBalancePaymentMethodOptionsSetupFutureUsageEnum",
          "values": [
            "none"
          ]
        },
        "CheckoutEpsPaymentMethodOptionsSetupFutureUsageEnum": {
          "name": "CheckoutEpsPaymentMethodOptionsSetupFutureUsageEnum",
          "values": [
            "none"
          ]
        },
        "CheckoutFpxPaymentMethodOptionsSetupFutureUsageEnum": {
          "name": "CheckoutFpxPaymentMethodOptionsSetupFutureUsageEnum",
          "values": [
            "none"
          ]
        },
        "CheckoutGiropayPaymentMethodOptionsSetupFutureUsageEnum": {
          "name": "CheckoutGiropayPaymentMethodOptionsSetupFutureUsageEnum",
          "values": [
            "none"
          ]
        },
        "CheckoutGrabPayPaymentMethodOptionsSetupFutureUsageEnum": {
          "name": "CheckoutGrabPayPaymentMethodOptionsSetupFutureUsageEnum",
          "values": [
            "none"
          ]
        },
        "CheckoutIdealPaymentMethodOptionsSetupFutureUsageEnum": {
          "name": "CheckoutIdealPaymentMethodOptionsSetupFutureUsageEnum",
          "values": [
            "none"
          ]
        },
        "CheckoutKlarnaPaymentMethodOptionsSetupFutureUsageEnum": {
          "name": "CheckoutKlarnaPaymentMethodOptionsSetupFutureUsageEnum",
          "values": [
            "none",
            "off_session",
            "on_session"
          ]
        },
        "CheckoutKonbiniPaymentMethodOptionsSetupFutureUsageEnum": {
          "name": "CheckoutKonbiniPaymentMethodOptionsSetupFutureUsageEnum",
          "values": [
            "none"
          ]
        },
        "CheckoutLinkPaymentMethodOptionsSetupFutureUsageEnum": {
          "name": "CheckoutLinkPaymentMethodOptionsSetupFutureUsageEnum",
          "values": [
            "none",
            "off_session"
          ]
        },
        "CheckoutMobilepayPaymentMethodOptionsSetupFutureUsageEnum": {
          "name": "CheckoutMobilepayPaymentMethodOptionsSetupFutureUsageEnum",
          "values": [
            "none"
          ]
        },
        "CheckoutMultibancoPaymentMethodOptionsSetupFutureUsageEnum": {
          "name": "CheckoutMultibancoPaymentMethodOptionsSetupFutureUsageEnum",
          "values": [
            "none"
          ]
        },
        "CheckoutOxxoPaymentMethodOptionsSetupFutureUsageEnum": {
          "name": "CheckoutOxxoPaymentMethodOptionsSetupFutureUsageEnum",
          "values": [
            "none"
          ]
        },
        "CheckoutP24PaymentMethodOptionsSetupFutureUsageEnum": {
          "name": "CheckoutP24PaymentMethodOptionsSetupFutureUsageEnum",
          "values": [
            "none"
          ]
        },
        "CheckoutPaynowPaymentMethodOptionsSetupFutureUsageEnum": {
          "name": "CheckoutPaynowPaymentMethodOptionsSetupFutureUsageEnum",
          "values": [
            "none"
          ]
        },
        "CheckoutPaypalPaymentMethodOptionsCaptureMethodEnum": {
          "name": "CheckoutPaypalPaymentMethodOptionsCaptureMethodEnum",
          "values": [
            "manual"
          ]
        },
        "CheckoutPaypalPaymentMethodOptionsSetupFutureUsageEnum": {
          "name": "CheckoutPaypalPaymentMethodOptionsSetupFutureUsageEnum",
          "values": [
            "none",
            "off_session"
          ]
        },
        "CheckoutRevolutPayPaymentMethodOptionsSetupFutureUsageEnum": {
          "name": "CheckoutRevolutPayPaymentMethodOptionsSetupFutureUsageEnum",
          "values": [
            "none",
            "off_session"
          ]
        },
        "CheckoutSepaDebitPaymentMethodOptionsSetupFutureUsageEnum": {
          "name": "CheckoutSepaDebitPaymentMethodOptionsSetupFutureUsageEnum",
          "values": [
            "none",
            "off_session",
            "on_session"
          ]
        },
        "CheckoutSessionBillingAddressCollectionEnum": {
          "name": "CheckoutSessionBillingAddressCollectionEnum",
          "values": [
            "auto",
            "required"
          ]
        },
        "CheckoutSessionCustomerCreationEnum": {
          "name": "CheckoutSessionCustomerCreationEnum",
          "values": [
            "always",
            "if_required"
          ]
        },
        "CheckoutSessionModeEnum": {
          "name": "CheckoutSessionModeEnum",
          "values": [
            "payment",
            "setup",
            "subscription"
          ]
        },
        "CheckoutSessionPaymentMethodCollectionEnum": {
          "name": "CheckoutSessionPaymentMethodCollectionEnum",
          "values": [
            "always",
            "if_required"
          ]
        },
        "CheckoutSessionPaymentStatusEnum": {
          "name": "CheckoutSessionPaymentStatusEnum",
          "values": [
            "no_payment_required",
            "paid",
            "unpaid"
          ]
        },
        "CheckoutSessionRedirectOnCompletionEnum": {
          "name": "CheckoutSessionRedirectOnCompletionEnum",
          "values": [
            "always",
            "if_required",
            "never"
          ]
        },
        "CheckoutSessionStatusEnum": {
          "name": "CheckoutSessionStatusEnum",
          "values": [
            "complete",
            "expired",
            "open"
          ]
        },
        "CheckoutSessionSubmitTypeEnum": {
          "name": "CheckoutSessionSubmitTypeEnum",
          "values": [
            "auto",
            "book",
            "donate",
            "pay"
          ]
        },
        "CheckoutSessionUiModeEnum": {
          "name": "CheckoutSessionUiModeEnum",
          "values": [
            "embedded",
            "hosted"
          ]
        },
        "CheckoutSofortPaymentMethodOptionsSetupFutureUsageEnum": {
          "name": "CheckoutSofortPaymentMethodOptionsSetupFutureUsageEnum",
          "values": [
            "none"
          ]
        },
        "CheckoutUsBankAccountPaymentMethodOptionsSetupFutureUsageEnum": {
          "name": "CheckoutUsBankAccountPaymentMethodOptionsSetupFutureUsageEnum",
          "values": [
            "none",
            "off_session",
            "on_session"
          ]
        },
        "CheckoutUsBankAccountPaymentMethodOptionsVerificationMethodEnum": {
          "name": "CheckoutUsBankAccountPaymentMethodOptionsVerificationMethodEnum",
          "values": [
            "automatic",
            "instant"
          ]
        },
        "CollectionMethodInvoiceEnum": {
          "name": "CollectionMethodInvoiceEnum",
          "values": [
            "charge_automatically",
            "send_invoice"
          ]
        },
        "ConnectAccountReferenceTypeEnum": {
          "name": "ConnectAccountReferenceTypeEnum",
          "values": [
            "account",
            "self"
          ]
        },
        "CouponDurationEnum": {
          "name": "CouponDurationEnum",
          "values": [
            "forever",
            "once",
            "repeating"
          ]
        },
        "CustomerBalanceCustomerBalanceSettingsReconciliationModeEnum": {
          "name": "CustomerBalanceCustomerBalanceSettingsReconciliationModeEnum",
          "values": [
            "automatic",
            "manual"
          ]
        },
        "CustomerTaxAutomaticTaxEnum": {
          "name": "CustomerTaxAutomaticTaxEnum",
          "values": [
            "failed",
            "not_collecting",
            "supported",
            "unrecognized_location"
          ]
        },
        "CustomerTaxExemptEnum": {
          "name": "CustomerTaxExemptEnum",
          "values": [
            "exempt",
            "none",
            "reverse"
          ]
        },
        "CustomerTaxLocationSourceEnum": {
          "name": "CustomerTaxLocationSourceEnum",
          "values": [
            "billing_address",
            "ip_address",
            "payment_method",
            "shipping_destination"
          ]
        },
        "InvoiceBillingReasonEnum": {
          "name": "InvoiceBillingReasonEnum",
          "values": [
            "automatic_pending_invoice_item_invoice",
            "manual",
            "quote_accept",
            "subscription",
            "subscription_create",
            "subscription_cycle",
            "subscription_threshold",
            "subscription_update",
            "upcoming"
          ]
        },
        "InvoiceCustomerTaxExemptEnum": {
          "name": "InvoiceCustomerTaxExemptEnum",
          "values": [
            "exempt",
            "none",
            "reverse"
          ]
        },
        "InvoiceStatusEnum": {
          "name": "InvoiceStatusEnum",
          "values": [
            "draft",
            "open",
            "paid",
            "uncollectible",
            "void"
          ]
        },
        "InvoicePretaxCreditEnum": {
          "name": "InvoicePretaxCreditEnum",
          "values": [
            "credit_balance_transaction",
            "discount"
          ]
        },
        "InvoiceTaxabilityReasonEnum": {
          "name": "InvoiceTaxabilityReasonEnum",
          "values": [
            "customer_exempt",
            "not_collecting",
            "not_subject_to_tax",
            "not_supported",
            "portion_product_exempt",
            "portion_reduced_rated",
            "portion_standard_rated",
            "product_exempt",
            "product_exempt_holiday",
            "proportionally_rated",
            "reduced_rated",
            "reverse_charge",
            "standard_rated",
            "taxable_basis_reduced",
            "zero_rated"
          ]
        },
        "InvoiceMandateOptionsCardAmountTypeEnum": {
          "name": "InvoiceMandateOptionsCardAmountTypeEnum",
          "values": [
            "fixed",
            "maximum"
          ]
        },
        "InvoicePaymentMethodOptionsAcssDebitMandateOptionsTransactionTypeEnum": {
          "name": "InvoicePaymentMethodOptionsAcssDebitMandateOptionsTransactionTypeEnum",
          "values": [
            "business",
            "personal"
          ]
        },
        "InvoicePaymentMethodOptionsAcssDebitVerificationMethodEnum": {
          "name": "InvoicePaymentMethodOptionsAcssDebitVerificationMethodEnum",
          "values": [
            "automatic",
            "instant",
            "microdeposits"
          ]
        },
        "InvoicePaymentMethodOptionsBancontactPreferredLanguageEnum": {
          "name": "InvoicePaymentMethodOptionsBancontactPreferredLanguageEnum",
          "values": [
            "de",
            "en",
            "fr",
            "nl"
          ]
        },
        "InvoicePaymentMethodOptionsCustomerBalanceBankTransferEuBankTransferCountryEnum": {
          "name": "InvoicePaymentMethodOptionsCustomerBalanceBankTransferEuBankTransferCountryEnum",
          "values": [
            "BE",
            "DE",
            "ES",
            "FR",
            "IE",
            "NL"
          ]
        },
        "InvoicePaymentMethodOptionsCustomerBalanceFundingTypeEnum": {
          "name": "InvoicePaymentMethodOptionsCustomerBalanceFundingTypeEnum",
          "values": [
            "bank_transfer"
          ]
        },
        "InvoicePaymentMethodOptionsUsBankAccountLinkedAccountOptionsFiltersAccountSubcategoriesEnum": {
          "name": "InvoicePaymentMethodOptionsUsBankAccountLinkedAccountOptionsFiltersAccountSubcategoriesEnum",
          "values": [
            "checking",
            "savings"
          ]
        },
        "InvoicePaymentMethodOptionsUsBankAccountLinkedAccountOptionsPermissionsEnum": {
          "name": "InvoicePaymentMethodOptionsUsBankAccountLinkedAccountOptionsPermissionsEnum",
          "values": [
            "balances",
            "ownership",
            "payment_method",
            "transactions"
          ]
        },
        "InvoicePaymentMethodOptionsUsBankAccountLinkedAccountOptionsPrefetchEnum": {
          "name": "InvoicePaymentMethodOptionsUsBankAccountLinkedAccountOptionsPrefetchEnum",
          "values": [
            "balances",
            "ownership",
            "transactions"
          ]
        },
        "InvoicePaymentMethodOptionsUsBankAccountVerificationMethodEnum": {
          "name": "InvoicePaymentMethodOptionsUsBankAccountVerificationMethodEnum",
          "values": [
            "automatic",
            "instant",
            "microdeposits"
          ]
        },
        "LineItemsTaxAmountTaxabilityReasonEnum": {
          "name": "LineItemsTaxAmountTaxabilityReasonEnum",
          "values": [
            "customer_exempt",
            "not_collecting",
            "not_subject_to_tax",
            "not_supported",
            "portion_product_exempt",
            "portion_reduced_rated",
            "portion_standard_rated",
            "product_exempt",
            "product_exempt_holiday",
            "proportionally_rated",
            "reduced_rated",
            "reverse_charge",
            "standard_rated",
            "taxable_basis_reduced",
            "zero_rated"
          ]
        },
        "LinkedAccountOptionsUsBankAccountPermissionsEnum": {
          "name": "LinkedAccountOptionsUsBankAccountPermissionsEnum",
          "values": [
            "balances",
            "ownership",
            "payment_method",
            "transactions"
          ]
        },
        "LinkedAccountOptionsUsBankAccountPrefetchEnum": {
          "name": "LinkedAccountOptionsUsBankAccountPrefetchEnum",
          "values": [
            "balances",
            "ownership",
            "transactions"
          ]
        },
        "PaymentFlowsPrivatePaymentMethodsUsBankAccountLinkedAccountOptionsFiltersAccountSubcategoriesEnum": {
          "name": "PaymentFlowsPrivatePaymentMethodsUsBankAccountLinkedAccountOptionsFiltersAccountSubcategoriesEnum",
          "values": [
            "checking",
            "savings"
          ]
        },
        "PaymentMethodAllowRedisplayEnum": {
          "name": "PaymentMethodAllowRedisplayEnum",
          "values": [
            "always",
            "limited",
            "unspecified"
          ]
        },
        "PaymentMethodCardPresentReadMethodEnum": {
          "name": "PaymentMethodCardPresentReadMethodEnum",
          "values": [
            "contact_emv",
            "contactless_emv",
            "contactless_magstripe_mode",
            "magnetic_stripe_fallback",
            "magnetic_stripe_track2"
          ]
        },
        "PaymentMethodCardWalletTypeEnum": {
          "name": "PaymentMethodCardWalletTypeEnum",
          "values": [
            "amex_express_checkout",
            "apple_pay",
            "google_pay",
            "link",
            "masterpass",
            "samsung_pay",
            "visa_checkout"
          ]
        },
        "PaymentMethodDetailsCardPresentReadMethodEnum": {
          "name": "PaymentMethodDetailsCardPresentReadMethodEnum",
          "values": [
            "contact_emv",
            "contactless_emv",
            "contactless_magstripe_mode",
            "magnetic_stripe_fallback",
            "magnetic_stripe_track2"
          ]
        },
        "PaymentMethodDetailsCardPresentReceiptAccountTypeEnum": {
          "name": "PaymentMethodDetailsCardPresentReceiptAccountTypeEnum",
          "values": [
            "checking",
            "credit",
            "prepaid",
            "unknown"
          ]
        },
        "PaymentMethodEpsBankEnum": {
          "name": "PaymentMethodEpsBankEnum",
          "values": [
            "arzte_und_apotheker_bank",
            "austrian_anadi_bank_ag",
            "bank_austria",
            "bankhaus_carl_spangler",
            "bankhaus_schelhammer_und_schattera_ag",
            "bawag_psk_ag",
            "bks_bank_ag",
            "brull_kallmus_bank_ag",
            "btv_vier_lander_bank",
            "capital_bank_grawe_gruppe_ag",
            "deutsche_bank_ag",
            "dolomitenbank",
            "easybank_ag",
            "erste_bank_und_sparkassen",
            "hypo_alpeadriabank_international_ag",
            "hypo_bank_burgenland_aktiengesellschaft",
            "hypo_noe_lb_fur_niederosterreich_u_wien",
            "hypo_oberosterreich_salzburg_steiermark",
            "hypo_tirol_bank_ag",
            "hypo_vorarlberg_bank_ag",
            "marchfelder_bank",
            "oberbank_ag",
            "raiffeisen_bankengruppe_osterreich",
            "schoellerbank_ag",
            "sparda_bank_wien",
            "volksbank_gruppe",
            "volkskreditbank_ag",
            "vr_bank_braunau"
          ]
        },
        "PaymentMethodFpxBankEnum": {
          "name": "PaymentMethodFpxBankEnum",
          "values": [
            "affin_bank",
            "agrobank",
            "alliance_bank",
            "ambank",
            "bank_islam",
            "bank_muamalat",
            "bank_of_china",
            "bank_rakyat",
            "bsn",
            "cimb",
            "deutsche_bank",
            "hong_leong_bank",
            "hsbc",
            "kfh",
            "maybank2e",
            "maybank2u",
            "ocbc",
            "pb_enterprise",
            "public_bank",
            "rhb",
            "standard_chartered",
            "uob"
          ]
        },
        "PaymentMethodIdealBankEnum": {
          "name": "PaymentMethodIdealBankEnum",
          "values": [
            "abn_amro",
            "asn_bank",
            "bunq",
            "handelsbanken",
            "ing",
            "knab",
            "moneyou",
            "n26",
            "nn",
            "rabobank",
            "regiobank",
            "revolut",
            "sns_bank",
            "triodos_bank",
            "van_lanschot",
            "yoursafe"
          ]
        },
        "PaymentMethodIdealBicEnum": {
          "name": "PaymentMethodIdealBicEnum",
          "values": [
            "ABNANL2A",
            "ASNBNL21",
            "BITSNL2A",
            "BUNQNL2A",
            "FVLBNL22",
            "HANDNL2A",
            "INGBNL2A",
            "KNABNL2H",
            "MOYONL21",
            "NNBANL2G",
            "NTSBDEB1",
            "RABONL2U",
            "RBRBNL21",
            "REVOIE23",
            "REVOLT21",
            "SNSBNL2A",
            "TRIONL2U"
          ]
        },
        "PaymentMethodInteracPresentReadMethodEnum": {
          "name": "PaymentMethodInteracPresentReadMethodEnum",
          "values": [
            "contact_emv",
            "contactless_emv",
            "contactless_magstripe_mode",
            "magnetic_stripe_fallback",
            "magnetic_stripe_track2"
          ]
        },
        "PaymentMethodOptionsCustomerBalanceEuBankAccountCountryEnum": {
          "name": "PaymentMethodOptionsCustomerBalanceEuBankAccountCountryEnum",
          "values": [
            "BE",
            "DE",
            "ES",
            "FR",
            "IE",
            "NL"
          ]
        },
        "PaymentMethodP24BankEnum": {
          "name": "PaymentMethodP24BankEnum",
          "values": [
            "alior_bank",
            "bank_millennium",
            "bank_nowy_bfg_sa",
            "bank_pekao_sa",
            "banki_spbdzielcze",
            "blik",
            "bnp_paribas",
            "boz",
            "citi_handlowy",
            "credit_agricole",
            "envelobank",
            "etransfer_pocztowy24",
            "getin_bank",
            "ideabank",
            "ing",
            "inteligo",
            "mbank_mtransfer",
            "nest_przelew",
            "noble_pay",
            "pbac_z_ipko",
            "plus_bank",
            "santander_przelew24",
            "tmobile_usbugi_bankowe",
            "toyota_bank",
            "velobank",
            "volkswagen_bank"
          ]
        },
        "PaymentMethodTypeEnum": {
          "name": "PaymentMethodTypeEnum",
          "values": [
            "acss_debit",
            "affirm",
            "afterpay_clearpay",
            "alipay",
            "amazon_pay",
            "au_becs_debit",
            "bacs_debit",
            "bancontact",
            "blik",
            "boleto",
            "card",
            "card_present",
            "cashapp",
            "customer_balance",
            "eps",
            "fpx",
            "giropay",
            "grabpay",
            "ideal",
            "interac_present",
            "klarna",
            "konbini",
            "link",
            "mobilepay",
            "multibanco",
            "oxxo",
            "p24",
            "paynow",
            "paypal",
            "pix",
            "promptpay",
            "revolut_pay",
            "sepa_debit",
            "sofort",
            "swish",
            "twint",
            "us_bank_account",
            "wechat_pay",
            "zip"
          ]
        },
        "PaymentMethodUsBankAccountAccountHolderTypeEnum": {
          "name": "PaymentMethodUsBankAccountAccountHolderTypeEnum",
          "values": [
            "company",
            "individual"
          ]
        },
        "PaymentMethodUsBankAccountAccountTypeEnum": {
          "name": "PaymentMethodUsBankAccountAccountTypeEnum",
          "values": [
            "checking",
            "savings"
          ]
        },
        "PaymentMethodUsBankAccountBlockedNetworkCodeEnum": {
          "name": "PaymentMethodUsBankAccountBlockedNetworkCodeEnum",
          "values": [
            "R02",
            "R03",
            "R04",
            "R05",
            "R07",
            "R08",
            "R10",
            "R11",
            "R16",
            "R20",
            "R29",
            "R31"
          ]
        },
        "PaymentMethodUsBankAccountBlockedReasonEnum": {
          "name": "PaymentMethodUsBankAccountBlockedReasonEnum",
          "values": [
            "bank_account_closed",
            "bank_account_frozen",
            "bank_account_invalid_details",
            "bank_account_restricted",
            "bank_account_unusable",
            "debit_not_authorized"
          ]
        },
        "PaymentPagesCheckoutSessionAutomaticTaxStatusEnum": {
          "name": "PaymentPagesCheckoutSessionAutomaticTaxStatusEnum",
          "values": [
            "complete",
            "failed",
            "requires_location_inputs"
          ]
        },
        "PaymentPagesCheckoutSessionConsentCollectionPromotionsEnum": {
          "name": "PaymentPagesCheckoutSessionConsentCollectionPromotionsEnum",
          "values": [
            "auto",
            "none"
          ]
        },
        "PaymentPagesCheckoutSessionConsentCollectionTermsOfServiceEnum": {
          "name": "PaymentPagesCheckoutSessionConsentCollectionTermsOfServiceEnum",
          "values": [
            "none",
            "required"
          ]
        },
        "PaymentPagesCheckoutSessionConsentPromotionsEnum": {
          "name": "PaymentPagesCheckoutSessionConsentPromotionsEnum",
          "values": [
            "opt_in",
            "opt_out"
          ]
        },
        "PaymentPagesCheckoutSessionConsentTermsOfServiceEnum": {
          "name": "PaymentPagesCheckoutSessionConsentTermsOfServiceEnum",
          "values": [
            "accepted"
          ]
        },
        "PaymentPagesCheckoutSessionCustomFieldsLabelTypeEnum": {
          "name": "PaymentPagesCheckoutSessionCustomFieldsLabelTypeEnum",
          "values": [
            "custom"
          ]
        },
        "PaymentPagesCheckoutSessionCustomFieldsTypeEnum": {
          "name": "PaymentPagesCheckoutSessionCustomFieldsTypeEnum",
          "values": [
            "dropdown",
            "numeric",
            "text"
          ]
        },
        "PaymentPagesCheckoutSessionCustomerDetailsTaxExemptEnum": {
          "name": "PaymentPagesCheckoutSessionCustomerDetailsTaxExemptEnum",
          "values": [
            "exempt",
            "none",
            "reverse"
          ]
        },
        "PaymentPagesCheckoutSessionPaymentMethodReuseAgreementPositionEnum": {
          "name": "PaymentPagesCheckoutSessionPaymentMethodReuseAgreementPositionEnum",
          "values": [
            "auto",
            "hidden"
          ]
        },
        "PaymentPagesCheckoutSessionSavedPaymentMethodOptionsAllowRedisplayFiltersEnum": {
          "name": "PaymentPagesCheckoutSessionSavedPaymentMethodOptionsAllowRedisplayFiltersEnum",
          "values": [
            "always",
            "limited",
            "unspecified"
          ]
        },
        "PaymentPagesCheckoutSessionSavedPaymentMethodOptionsPaymentMethodRemoveEnum": {
          "name": "PaymentPagesCheckoutSessionSavedPaymentMethodOptionsPaymentMethodRemoveEnum",
          "values": [
            "disabled",
            "enabled"
          ]
        },
        "PaymentPagesCheckoutSessionSavedPaymentMethodOptionsPaymentMethodSaveEnum": {
          "name": "PaymentPagesCheckoutSessionSavedPaymentMethodOptionsPaymentMethodSaveEnum",
          "values": [
            "disabled",
            "enabled"
          ]
        },
        "PaymentPagesCheckoutSessionShippingAddressCollectionAllowedCountriesEnum": {
          "name": "PaymentPagesCheckoutSessionShippingAddressCollectionAllowedCountriesEnum",
          "values": [
            "AC",
            "AD",
            "AE",
            "AF",
            "AG",
            "AI",
            "AL",
            "AM",
            "AO",
            "AQ",
            "AR",
            "AT",
            "AU",
            "AW",
            "AX",
            "AZ",
            "BA",
            "BB",
            "BD",
            "BE",
            "BF",
            "BG",
            "BH",
            "BI",
            "BJ",
            "BL",
            "BM",
            "BN",
            "BO",
            "BQ",
            "BR",
            "BS",
            "BT",
            "BV",
            "BW",
            "BY",
            "BZ",
            "CA",
            "CD",
            "CF",
            "CG",
            "CH",
            "CI",
            "CK",
            "CL",
            "CM",
            "CN",
            "CO",
            "CR",
            "CV",
            "CW",
            "CY",
            "CZ",
            "DE",
            "DJ",
            "DK",
            "DM",
            "DO",
            "DZ",
            "EC",
            "EE",
            "EG",
            "EH",
            "ER",
            "ES",
            "ET",
            "FI",
            "FJ",
            "FK",
            "FO",
            "FR",
            "GA",
            "GB",
            "GD",
            "GE",
            "GF",
            "GG",
            "GH",
            "GI",
            "GL",
            "GM",
            "GN",
            "GP",
            "GQ",
            "GR",
            "GS",
            "GT",
            "GU",
            "GW",
            "GY",
            "HK",
            "HN",
            "HR",
            "HT",
            "HU",
            "ID",
            "IE",
            "IL",
            "IM",
            "IN",
            "IO",
            "IQ",
            "IS",
            "IT",
            "JE",
            "JM",
            "JO",
            "JP",
            "KE",
            "KG",
            "KH",
            "KI",
            "KM",
            "KN",
            "KR",
            "KW",
            "KY",
            "KZ",
            "LA",
            "LB",
            "LC",
            "LI",
            "LK",
            "LR",
            "LS",
            "LT",
            "LU",
            "LV",
            "LY",
            "MA",
            "MC",
            "MD",
            "ME",
            "MF",
            "MG",
            "MK",
            "ML",
            "MM",
            "MN",
            "MO",
            "MQ",
            "MR",
            "MS",
            "MT",
            "MU",
            "MV",
            "MW",
            "MX",
            "MY",
            "MZ",
            "NA",
            "NC",
            "NE",
            "NG",
            "NI",
            "NL",
            "NO",
            "NP",
            "NR",
            "NU",
            "NZ",
            "OM",
            "PA",
            "PE",
            "PF",
            "PG",
            "PH",
            "PK",
            "PL",
            "PM",
            "PN",
            "PR",
            "PS",
            "PT",
            "PY",
            "QA",
            "RE",
            "RO",
            "RS",
            "RU",
            "RW",
            "SA",
            "SB",
            "SC",
            "SE",
            "SG",
            "SH",
            "SI",
            "SJ",
            "SK",
            "SL",
            "SM",
            "SN",
            "SO",
            "SR",
            "SS",
            "ST",
            "SV",
            "SX",
            "SZ",
            "TA",
            "TC",
            "TD",
            "TF",
            "TG",
            "TH",
            "TJ",
            "TK",
            "TL",
            "TM",
            "TN",
            "TO",
            "TR",
            "TT",
            "TV",
            "TW",
            "TZ",
            "UA",
            "UG",
            "US",
            "UY",
            "UZ",
            "VA",
            "VC",
            "VE",
            "VG",
            "VN",
            "VU",
            "WF",
            "WS",
            "XK",
            "YE",
            "YT",
            "ZA",
            "ZM",
            "ZW",
            "ZZ"
          ]
        },
        "PaymentPagesCheckoutSessionTaxIdTypeEnum": {
          "name": "PaymentPagesCheckoutSessionTaxIdTypeEnum",
          "values": [
            "ad_nrt",
            "ae_trn",
            "ar_cuit",
            "au_abn",
            "au_arn",
            "bg_uic",
            "bh_vat",
            "bo_tin",
            "br_cnpj",
            "br_cpf",
            "ca_bn",
            "ca_gst_hst",
            "ca_pst_bc",
            "ca_pst_mb",
            "ca_pst_sk",
            "ca_qst",
            "ch_uid",
            "ch_vat",
            "cl_tin",
            "cn_tin",
            "co_nit",
            "cr_tin",
            "de_stn",
            "do_rcn",
            "ec_ruc",
            "eg_tin",
            "es_cif",
            "eu_oss_vat",
            "eu_vat",
            "gb_vat",
            "ge_vat",
            "hk_br",
            "hu_tin",
            "id_npwp",
            "il_vat",
            "in_gst",
            "is_vat",
            "jp_cn",
            "jp_rn",
            "jp_trn",
            "ke_pin",
            "kr_brn",
            "kz_bin",
            "li_uid",
            "mx_rfc",
            "my_frp",
            "my_itn",
            "my_sst",
            "ng_tin",
            "no_vat",
            "no_voec",
            "nz_gst",
            "om_vat",
            "pe_ruc",
            "ph_tin",
            "ro_tin",
            "rs_pib",
            "ru_inn",
            "ru_kpp",
            "sa_vat",
            "sg_gst",
            "sg_uen",
            "si_tin",
            "sv_nit",
            "th_vat",
            "tr_tin",
            "tw_vat",
            "ua_vat",
            "unknown",
            "us_ein",
            "uy_ruc",
            "ve_rif",
            "vn_tin",
            "za_vat"
          ]
        },
        "PlanAggregateUsageEnum": {
          "name": "PlanAggregateUsageEnum",
          "values": [
            "last_during_period",
            "last_ever",
            "max",
            "sum"
          ]
        },
        "PlanBillingSchemeEnum": {
          "name": "PlanBillingSchemeEnum",
          "values": [
            "per_unit",
            "tiered"
          ]
        },
        "PlanIntervalEnum": {
          "name": "PlanIntervalEnum",
          "values": [
            "day",
            "month",
            "week",
            "year"
          ]
        },
        "PlanTiersModeEnum": {
          "name": "PlanTiersModeEnum",
          "values": [
            "graduated",
            "volume"
          ]
        },
        "PlanUsageTypeEnum": {
          "name": "PlanUsageTypeEnum",
          "values": [
            "licensed",
            "metered"
          ]
        },
        "PortalCustomerUpdateAllowedUpdatesEnum": {
          "name": "PortalCustomerUpdateAllowedUpdatesEnum",
          "values": [
            "address",
            "email",
            "name",
            "phone",
            "shipping",
            "tax_id"
          ]
        },
        "PortalFlowsFlowAfterCompletionTypeEnum": {
          "name": "PortalFlowsFlowAfterCompletionTypeEnum",
          "values": [
            "hosted_confirmation",
            "portal_homepage",
            "redirect"
          ]
        },
        "PortalFlowsFlowTypeEnum": {
          "name": "PortalFlowsFlowTypeEnum",
          "values": [
            "payment_method_update",
            "subscription_cancel",
            "subscription_update",
            "subscription_update_confirm"
          ]
        },
        "PortalFlowsRetentionTypeEnum": {
          "name": "PortalFlowsRetentionTypeEnum",
          "values": [
            "coupon_offer"
          ]
        },
        "PortalSubscriptionCancelModeEnum": {
          "name": "PortalSubscriptionCancelModeEnum",
          "values": [
            "at_period_end",
            "immediately"
          ]
        },
        "PortalSubscriptionCancelProrationBehaviorEnum": {
          "name": "PortalSubscriptionCancelProrationBehaviorEnum",
          "values": [
            "always_invoice",
            "create_prorations",
            "none"
          ]
        },
        "PortalSubscriptionCancellationReasonOptionsEnum": {
          "name": "PortalSubscriptionCancellationReasonOptionsEnum",
          "values": [
            "customer_service",
            "low_quality",
            "missing_features",
            "other",
            "switched_service",
            "too_complex",
            "too_expensive",
            "unused"
          ]
        },
        "PortalSubscriptionUpdateDefaultAllowedUpdatesEnum": {
          "name": "PortalSubscriptionUpdateDefaultAllowedUpdatesEnum",
          "values": [
            "price",
            "promotion_code",
            "quantity"
          ]
        },
        "PortalSubscriptionUpdateProrationBehaviorEnum": {
          "name": "PortalSubscriptionUpdateProrationBehaviorEnum",
          "values": [
            "always_invoice",
            "create_prorations",
            "none"
          ]
        },
        "PriceBillingSchemeEnum": {
          "name": "PriceBillingSchemeEnum",
          "values": [
            "per_unit",
            "tiered"
          ]
        },
        "PriceTaxBehaviorEnum": {
          "name": "PriceTaxBehaviorEnum",
          "values": [
            "exclusive",
            "inclusive",
            "unspecified"
          ]
        },
        "PriceTiersModeEnum": {
          "name": "PriceTiersModeEnum",
          "values": [
            "graduated",
            "volume"
          ]
        },
        "PriceTypeEnum": {
          "name": "PriceTypeEnum",
          "values": [
            "one_time",
            "recurring"
          ]
        },
        "RecurringAggregateUsageEnum": {
          "name": "RecurringAggregateUsageEnum",
          "values": [
            "last_during_period",
            "last_ever",
            "max",
            "sum"
          ]
        },
        "RecurringIntervalEnum": {
          "name": "RecurringIntervalEnum",
          "values": [
            "day",
            "month",
            "week",
            "year"
          ]
        },
        "RecurringUsageTypeEnum": {
          "name": "RecurringUsageTypeEnum",
          "values": [
            "licensed",
            "metered"
          ]
        },
        "SubscriptionPaymentMethodOptionsCardNetworkEnum": {
          "name": "SubscriptionPaymentMethodOptionsCardNetworkEnum",
          "values": [
            "amex",
            "cartes_bancaires",
            "diners",
            "discover",
            "eftpos_au",
            "interac",
            "jcb",
            "mastercard",
            "unionpay",
            "unknown",
            "visa"
          ]
        },
        "SubscriptionPaymentMethodOptionsCardRequestThreeDSecureEnum": {
          "name": "SubscriptionPaymentMethodOptionsCardRequestThreeDSecureEnum",
          "values": [
            "any",
            "automatic",
            "challenge"
          ]
        },
        "SubscriptionPendingInvoiceItemIntervalIntervalEnum": {
          "name": "SubscriptionPendingInvoiceItemIntervalIntervalEnum",
          "values": [
            "day",
            "month",
            "week",
            "year"
          ]
        },
        "SubscriptionTypeCollectionMethodEnum": {
          "name": "SubscriptionTypeCollectionMethodEnum",
          "values": [
            "charge_automatically",
            "send_invoice"
          ]
        },
        "SubscriptionTypeStatusEnum": {
          "name": "SubscriptionTypeStatusEnum",
          "values": [
            "active",
            "canceled",
            "incomplete",
            "incomplete_expired",
            "past_due",
            "paused",
            "trialing",
            "unpaid"
          ]
        },
        "SubscriptionsResourcePauseCollectionBehaviorEnum": {
          "name": "SubscriptionsResourcePauseCollectionBehaviorEnum",
          "values": [
            "keep_as_draft",
            "mark_uncollectible",
            "void"
          ]
        },
        "SubscriptionsResourcePaymentSettingsPaymentMethodTypesEnum": {
          "name": "SubscriptionsResourcePaymentSettingsPaymentMethodTypesEnum",
          "values": [
            "ach_credit_transfer",
            "ach_debit",
            "acss_debit",
            "amazon_pay",
            "au_becs_debit",
            "bacs_debit",
            "bancontact",
            "boleto",
            "card",
            "cashapp",
            "customer_balance",
            "eps",
            "fpx",
            "giropay",
            "grabpay",
            "ideal",
            "konbini",
            "link",
            "p24",
            "paynow",
            "paypal",
            "promptpay",
            "revolut_pay",
            "sepa_debit",
            "sofort",
            "swish",
            "us_bank_account",
            "wechat_pay"
          ]
        },
        "SubscriptionsResourcePaymentSettingsSaveDefaultPaymentMethodEnum": {
          "name": "SubscriptionsResourcePaymentSettingsSaveDefaultPaymentMethodEnum",
          "values": [
            "off",
            "on_subscription"
          ]
        },
        "SubscriptionsTrialsResourceEndBehaviorMissingPaymentMethodEnum": {
          "name": "SubscriptionsTrialsResourceEndBehaviorMissingPaymentMethodEnum",
          "values": [
            "cancel",
            "create_invoice",
            "pause"
          ]
        },
        "TaxIDsOwnerTypeEnum": {
          "name": "TaxIDsOwnerTypeEnum",
          "values": [
            "account",
            "application",
            "customer",
            "self"
          ]
        },
        "TaxIdTypeEnum": {
          "name": "TaxIdTypeEnum",
          "values": [
            "ad_nrt",
            "ae_trn",
            "ar_cuit",
            "au_abn",
            "au_arn",
            "bg_uic",
            "bh_vat",
            "bo_tin",
            "br_cnpj",
            "br_cpf",
            "ca_bn",
            "ca_gst_hst",
            "ca_pst_bc",
            "ca_pst_mb",
            "ca_pst_sk",
            "ca_qst",
            "ch_uid",
            "ch_vat",
            "cl_tin",
            "cn_tin",
            "co_nit",
            "cr_tin",
            "de_stn",
            "do_rcn",
            "ec_ruc",
            "eg_tin",
            "es_cif",
            "eu_oss_vat",
            "eu_vat",
            "gb_vat",
            "ge_vat",
            "hk_br",
            "hu_tin",
            "id_npwp",
            "il_vat",
            "in_gst",
            "is_vat",
            "jp_cn",
            "jp_rn",
            "jp_trn",
            "ke_pin",
            "kr_brn",
            "kz_bin",
            "li_uid",
            "mx_rfc",
            "my_frp",
            "my_itn",
            "my_sst",
            "ng_tin",
            "no_vat",
            "no_voec",
            "nz_gst",
            "om_vat",
            "pe_ruc",
            "ph_tin",
            "ro_tin",
            "rs_pib",
            "ru_inn",
            "ru_kpp",
            "sa_vat",
            "sg_gst",
            "sg_uen",
            "si_tin",
            "sv_nit",
            "th_vat",
            "tr_tin",
            "tw_vat",
            "ua_vat",
            "unknown",
            "us_ein",
            "uy_ruc",
            "ve_rif",
            "vn_tin",
            "za_vat"
          ]
        },
        "TaxIdVerificationStatusEnum": {
          "name": "TaxIdVerificationStatusEnum",
          "values": [
            "pending",
            "unavailable",
            "unverified",
            "verified"
          ]
        },
        "TaxRateJurisdictionLevelEnum": {
          "name": "TaxRateJurisdictionLevelEnum",
          "values": [
            "city",
            "country",
            "county",
            "district",
            "multiple",
            "state"
          ]
        },
        "TaxRateTaxTypeEnum": {
          "name": "TaxRateTaxTypeEnum",
          "values": [
            "amusement_tax",
            "communications_tax",
            "gst",
            "hst",
            "igst",
            "jct",
            "lease_tax",
            "pst",
            "qst",
            "rst",
            "sales_tax",
            "vat"
          ]
        },
        "TransformQuantityRoundEnum": {
          "name": "TransformQuantityRoundEnum",
          "values": [
            "down",
            "up"
          ]
        },
        "TransformUsageRoundEnum": {
          "name": "TransformUsageRoundEnum",
          "values": [
            "down",
            "up"
          ]
        },
        "UsBankAccountNetworksSupportedEnum": {
          "name": "UsBankAccountNetworksSupportedEnum",
          "values": [
            "ach",
            "us_domestic_wire"
          ]
        },
        "IntervalEnum": {
          "name": "IntervalEnum",
          "values": [
            "monthly",
            "yearly"
          ]
        },
        "SessionTypeEnum": {
          "name": "SessionTypeEnum",
          "values": [
            "INDIVIDUAL",
            "GROUP"
          ]
        },
        "SessionStatusEnum": {
          "name": "SessionStatusEnum",
          "values": [
            "SCHEDULED",
            "CONFIRMED",
            "IN_PROGRESS",
            "COMPLETED",
            "CANCELLED"
          ]
        },
        "TherapyTypeEnum": {
          "name": "TherapyTypeEnum",
          "values": [
            "EMDR",
            "VRET",
            "ECHO"
          ]
        },
        "SessionParticipantRoleEnum": {
          "name": "SessionParticipantRoleEnum",
          "values": [
            "THERAPIST",
            "CLIENT"
          ]
        },
        "SessionParticipantStatusEnum": {
          "name": "SessionParticipantStatusEnum",
          "values": [
            "INVITED",
            "ACCEPTED",
            "DECLINED",
            "PENDING",
            "JOINED",
            "LEFT"
          ]
        },
        "ClientStatusEnum": {
          "name": "ClientStatusEnum",
          "values": [
            "ACTIVE",
            "INACTIVE",
            "ARCHIVED"
          ]
        },
        "GroupStatusEnum": {
          "name": "GroupStatusEnum",
          "values": [
            "ACTIVE",
            "INACTIVE",
            "ARCHIVED"
          ]
        },
        "GroupMemberStatusEnum": {
          "name": "GroupMemberStatusEnum",
          "values": [
            "ACTIVE",
            "INACTIVE"
          ]
        },
        "AppType": {
          "name": "AppType",
          "values": [
            "wemind_boost",
            "wemind_mosy"
          ]
        },
        "FeelingType": {
          "name": "FeelingType",
          "values": [
            "happy",
            "neutral",
            "sad"
          ]
        },
        "Locale": {
          "name": "Locale",
          "values": [
            "english",
            "dutch"
          ]
        },
        "Tags": {
          "name": "Tags",
          "values": [
            "sleep",
            "focus",
            "emotion",
            "lifestyle",
            "self_esteem"
          ]
        },
        "Status": {
          "name": "Status",
          "values": [
            "publish",
            "draft",
            "hold"
          ]
        },
        "Placement": {
          "name": "Placement",
          "values": [
            "all_type",
            "home",
            "library"
          ]
        },
        "EventType": {
          "name": "EventType",
          "values": [
            "login",
            "logout",
            "open_app",
            "watch",
            "quiz",
            "poll",
            "bookmark",
            "track_mood",
            "grateful",
            "click"
          ]
        },
        "ContentType": {
          "name": "ContentType",
          "values": [
            "hack",
            "insight",
            "challenge"
          ]
        },
        "FeatureType": {
          "name": "FeatureType",
          "values": [
            "mission",
            "home",
            "discover",
            "profile",
            "insight",
            "hack",
            "challenge"
          ]
        },
        "CreateCompanyByAdminGender": {
          "name": "CreateCompanyByAdminGender",
          "values": [
            "female",
            "male",
            "non_binary",
            "prefer_not_to_say"
          ]
        },
        "CreateCompanyByAdminPaymentTerm": {
          "name": "CreateCompanyByAdminPaymentTerm",
          "values": [
            "monthly",
            "yearly"
          ]
        },
        "CreateCompanyByAdminLicenseType": {
          "name": "CreateCompanyByAdminLicenseType",
          "values": [
            "trial",
            "trial_pm",
            "paid"
          ]
        },
        "CreateCheckoutSessionTrialDate": {
          "name": "CreateCheckoutSessionTrialDate",
          "values": [
            "no_trial",
            "fourteen_day"
          ]
        },
        "CreateNewBoostUsersAppTypes": {
          "name": "CreateNewBoostUsersAppTypes",
          "values": [
            "wemind_boost",
            "wemind_mosy"
          ]
        },
        "CustomerObject": {
          "name": "CustomerObject",
          "values": [
            "customer"
          ]
        },
        "PaymentMethodObject": {
          "name": "PaymentMethodObject",
          "values": [
            "payment_method"
          ]
        },
        "PlanObject": {
          "name": "PlanObject",
          "values": [
            "plan"
          ]
        },
        "PriceObject": {
          "name": "PriceObject",
          "values": [
            "price"
          ]
        },
        "ProductObject": {
          "name": "ProductObject",
          "values": [
            "product"
          ]
        },
        "SubscriptionTypeObject": {
          "name": "SubscriptionTypeObject",
          "values": [
            "subscription"
          ]
        },
        "SubscriptionItemObject": {
          "name": "SubscriptionItemObject",
          "values": [
            "subscription_item"
          ]
        },
        "CashBalanceObject": {
          "name": "CashBalanceObject",
          "values": [
            "cash_balance"
          ]
        },
        "CouponObject": {
          "name": "CouponObject",
          "values": [
            "coupon"
          ]
        },
        "DiscountObject": {
          "name": "DiscountObject",
          "values": [
            "discount"
          ]
        },
        "ItemObject": {
          "name": "ItemObject",
          "values": [
            "item"
          ]
        },
        "TaxIdObject": {
          "name": "TaxIdObject",
          "values": [
            "tax_id"
          ]
        },
        "TaxRateObject": {
          "name": "TaxRateObject",
          "values": [
            "tax_rate"
          ]
        },
        "CheckoutSessionLineItemsObject": {
          "name": "CheckoutSessionLineItemsObject",
          "values": [
            "list"
          ]
        },
        "CustomerSourcesObject": {
          "name": "CustomerSourcesObject",
          "values": [
            "list"
          ]
        },
        "CustomerSubscriptionsObject": {
          "name": "CustomerSubscriptionsObject",
          "values": [
            "list"
          ]
        },
        "CustomerTaxIdsObject": {
          "name": "CustomerTaxIdsObject",
          "values": [
            "list"
          ]
        },
        "SubscriptionTypeItemsObject": {
          "name": "SubscriptionTypeItemsObject",
          "values": [
            "list"
          ]
        }
      },
      "nonModels": {
        "CreateCompanyByAdminResponse": {
          "name": "CreateCompanyByAdminResponse",
          "fields": {
            "firstName": {
              "name": "firstName",
              "isArray": false,
              "type": "String",
              "isRequired": true,
              "attributes": []
            },
            "lastName": {
              "name": "lastName",
              "isArray": false,
              "type": "String",
              "isRequired": true,
              "attributes": []
            },
            "email": {
              "name": "email",
              "isArray": false,
              "type": "String",
              "isRequired": true,
              "attributes": []
            },
            "companyEmail": {
              "name": "companyEmail",
              "isArray": false,
              "type": "String",
              "isRequired": true,
              "attributes": []
            },
            "billingEmail": {
              "name": "billingEmail",
              "isArray": false,
              "type": "String",
              "isRequired": true,
              "attributes": []
            },
            "companyPhoneNumber": {
              "name": "companyPhoneNumber",
              "isArray": false,
              "type": "AWSPhone",
              "isRequired": true,
              "attributes": []
            },
            "phoneNumber": {
              "name": "phoneNumber",
              "isArray": false,
              "type": "AWSPhone",
              "isRequired": true,
              "attributes": []
            },
            "gender": {
              "name": "gender",
              "isArray": false,
              "type": {
                "enum": "Gender"
              },
              "isRequired": false,
              "attributes": []
            },
            "locale": {
              "name": "locale",
              "isArray": false,
              "type": "String",
              "isRequired": true,
              "attributes": []
            },
            "streetAddress": {
              "name": "streetAddress",
              "isArray": false,
              "type": "String",
              "isRequired": false,
              "attributes": []
            },
            "postalCode": {
              "name": "postalCode",
              "isArray": false,
              "type": "String",
              "isRequired": false,
              "attributes": []
            },
            "region": {
              "name": "region",
              "isArray": false,
              "type": "String",
              "isRequired": false,
              "attributes": []
            },
            "locality": {
              "name": "locality",
              "isArray": false,
              "type": "String",
              "isRequired": false,
              "attributes": []
            },
            "country": {
              "name": "country",
              "isArray": false,
              "type": "String",
              "isRequired": false,
              "attributes": []
            },
            "companyName": {
              "name": "companyName",
              "isArray": false,
              "type": "String",
              "isRequired": false,
              "attributes": []
            },
            "roles": {
              "name": "roles",
              "isArray": false,
              "type": "String",
              "isRequired": true,
              "attributes": []
            },
            "paymentTerm": {
              "name": "paymentTerm",
              "isArray": false,
              "type": {
                "enum": "PaymentIntervalEnum"
              },
              "isRequired": false,
              "attributes": []
            },
            "productId": {
              "name": "productId",
              "isArray": false,
              "type": "String",
              "isRequired": false,
              "attributes": []
            },
            "productName": {
              "name": "productName",
              "isArray": false,
              "type": "String",
              "isRequired": false,
              "attributes": []
            },
            "endDate": {
              "name": "endDate",
              "isArray": false,
              "type": "AWSDateTime",
              "isRequired": false,
              "attributes": []
            },
            "startDate": {
              "name": "startDate",
              "isArray": false,
              "type": "AWSDateTime",
              "isRequired": false,
              "attributes": []
            },
            "freeLimitUsed": {
              "name": "freeLimitUsed",
              "isArray": false,
              "type": "Int",
              "isRequired": false,
              "attributes": []
            },
            "seats": {
              "name": "seats",
              "isArray": false,
              "type": "Int",
              "isRequired": false,
              "attributes": []
            },
            "licenseType": {
              "name": "licenseType",
              "isArray": false,
              "type": {
                "enum": "LicenseTypeEnum"
              },
              "isRequired": false,
              "attributes": []
            }
          }
        },
        "AddUserToCompanyResponse": {
          "name": "AddUserToCompanyResponse",
          "fields": {
            "companyName": {
              "name": "companyName",
              "isArray": false,
              "type": "String",
              "isRequired": true,
              "attributes": []
            },
            "email": {
              "name": "email",
              "isArray": false,
              "type": "AWSEmail",
              "isRequired": true,
              "attributes": []
            },
            "phoneNumber": {
              "name": "phoneNumber",
              "isArray": false,
              "type": "AWSPhone",
              "isRequired": true,
              "attributes": []
            },
            "firstName": {
              "name": "firstName",
              "isArray": false,
              "type": "String",
              "isRequired": true,
              "attributes": []
            },
            "lastName": {
              "name": "lastName",
              "isArray": false,
              "type": "String",
              "isRequired": true,
              "attributes": []
            },
            "roles": {
              "name": "roles",
              "isArray": false,
              "type": "String",
              "isRequired": true,
              "attributes": []
            },
            "locale": {
              "name": "locale",
              "isArray": false,
              "type": "String",
              "isRequired": true,
              "attributes": []
            },
            "createdAt": {
              "name": "createdAt",
              "isArray": false,
              "type": "AWSDateTime",
              "isRequired": false,
              "attributes": []
            },
            "gender": {
              "name": "gender",
              "isArray": false,
              "type": {
                "enum": "Gender"
              },
              "isRequired": true,
              "attributes": []
            },
            "address": {
              "name": "address",
              "isArray": false,
              "type": {
                "nonModel": "UserAddress"
              },
              "isRequired": true,
              "attributes": []
            }
          }
        },
        "getSeatCalculationResult": {
          "name": "getSeatCalculationResult",
          "fields": {
            "licenseId": {
              "name": "licenseId",
              "isArray": false,
              "type": "ID",
              "isRequired": false,
              "attributes": []
            },
            "maxSeats": {
              "name": "maxSeats",
              "isArray": false,
              "type": "Int",
              "isRequired": false,
              "attributes": []
            },
            "seatsAvailable": {
              "name": "seatsAvailable",
              "isArray": false,
              "type": "Int",
              "isRequired": false,
              "attributes": []
            },
            "seatsUsed": {
              "name": "seatsUsed",
              "isArray": false,
              "type": "Int",
              "isRequired": false,
              "attributes": []
            }
          }
        },
        "ListCompaniesOverviewResponse": {
          "name": "ListCompaniesOverviewResponse",
          "fields": {
            "companyId": {
              "name": "companyId",
              "isArray": false,
              "type": "String",
              "isRequired": true,
              "attributes": []
            },
            "licenseId": {
              "name": "licenseId",
              "isArray": false,
              "type": "String",
              "isRequired": true,
              "attributes": []
            },
            "companiesName": {
              "name": "companiesName",
              "isArray": false,
              "type": "String",
              "isRequired": true,
              "attributes": []
            },
            "departments": {
              "name": "departments",
              "isArray": false,
              "type": "Int",
              "isRequired": false,
              "attributes": []
            },
            "productName": {
              "name": "productName",
              "isArray": false,
              "type": "String",
              "isRequired": false,
              "attributes": []
            },
            "totalSeats": {
              "name": "totalSeats",
              "isArray": false,
              "type": "Int",
              "isRequired": false,
              "attributes": []
            },
            "licenseStatus": {
              "name": "licenseStatus",
              "isArray": false,
              "type": "String",
              "isRequired": false,
              "attributes": []
            },
            "companyAdmin": {
              "name": "companyAdmin",
              "isArray": false,
              "type": "String",
              "isRequired": false,
              "attributes": []
            }
          }
        },
        "getCompanyDetailsByIdResult": {
          "name": "getCompanyDetailsByIdResult",
          "fields": {
            "companyName": {
              "name": "companyName",
              "isArray": false,
              "type": "String",
              "isRequired": true,
              "attributes": []
            },
            "companyEmail": {
              "name": "companyEmail",
              "isArray": false,
              "type": "AWSEmail",
              "isRequired": true,
              "attributes": []
            },
            "phoneNumber": {
              "name": "phoneNumber",
              "isArray": false,
              "type": "AWSPhone",
              "isRequired": true,
              "attributes": []
            },
            "billingEmail": {
              "name": "billingEmail",
              "isArray": false,
              "type": "AWSEmail",
              "isRequired": true,
              "attributes": []
            },
            "companyLogo": {
              "name": "companyLogo",
              "isArray": false,
              "type": {
                "nonModel": "Media"
              },
              "isRequired": false,
              "attributes": []
            },
            "companyAddress": {
              "name": "companyAddress",
              "isArray": false,
              "type": {
                "nonModel": "GetCompanyAddress"
              },
              "isRequired": false,
              "attributes": []
            },
            "companyAdmins": {
              "name": "companyAdmins",
              "isArray": false,
              "type": {
                "nonModel": "GetCompanyAdmins"
              },
              "isRequired": false,
              "attributes": []
            },
            "departments": {
              "name": "departments",
              "isArray": true,
              "type": {
                "nonModel": "GetCompanyDepartment"
              },
              "isRequired": false,
              "attributes": [],
              "isArrayNullable": true
            },
            "product": {
              "name": "product",
              "isArray": false,
              "type": {
                "nonModel": "GetCompanyProduct"
              },
              "isRequired": false,
              "attributes": []
            }
          }
        },
        "GetCompanyAddress": {
          "name": "GetCompanyAddress",
          "fields": {
            "streetAddress": {
              "name": "streetAddress",
              "isArray": false,
              "type": "String",
              "isRequired": true,
              "attributes": []
            },
            "postalCode": {
              "name": "postalCode",
              "isArray": false,
              "type": "String",
              "isRequired": true,
              "attributes": []
            },
            "province": {
              "name": "province",
              "isArray": false,
              "type": "String",
              "isRequired": false,
              "attributes": []
            },
            "city": {
              "name": "city",
              "isArray": false,
              "type": "String",
              "isRequired": true,
              "attributes": []
            },
            "country": {
              "name": "country",
              "isArray": false,
              "type": "String",
              "isRequired": true,
              "attributes": []
            }
          }
        },
        "GetCompanyAdmins": {
          "name": "GetCompanyAdmins",
          "fields": {
            "firstName": {
              "name": "firstName",
              "isArray": false,
              "type": "String",
              "isRequired": true,
              "attributes": []
            },
            "lastName": {
              "name": "lastName",
              "isArray": false,
              "type": "String",
              "isRequired": true,
              "attributes": []
            },
            "email": {
              "name": "email",
              "isArray": false,
              "type": "AWSEmail",
              "isRequired": true,
              "attributes": []
            },
            "phoneNumber": {
              "name": "phoneNumber",
              "isArray": false,
              "type": "String",
              "isRequired": true,
              "attributes": []
            },
            "gender": {
              "name": "gender",
              "isArray": false,
              "type": "String",
              "isRequired": true,
              "attributes": []
            },
            "address": {
              "name": "address",
              "isArray": false,
              "type": "String",
              "isRequired": true,
              "attributes": []
            }
          }
        },
        "GetCompanyDepartment": {
          "name": "GetCompanyDepartment",
          "fields": {
            "name": {
              "name": "name",
              "isArray": false,
              "type": "String",
              "isRequired": true,
              "attributes": []
            }
          }
        },
        "GetCompanyProduct": {
          "name": "GetCompanyProduct",
          "fields": {
            "productName": {
              "name": "productName",
              "isArray": false,
              "type": "String",
              "isRequired": true,
              "attributes": []
            },
            "licenseType": {
              "name": "licenseType",
              "isArray": false,
              "type": "String",
              "isRequired": false,
              "attributes": []
            },
            "billingPeriod": {
              "name": "billingPeriod",
              "isArray": false,
              "type": "String",
              "isRequired": true,
              "attributes": []
            },
            "billingType": {
              "name": "billingType",
              "isArray": false,
              "type": "String",
              "isRequired": false,
              "attributes": []
            },
            "seats": {
              "name": "seats",
              "isArray": false,
              "type": "Int",
              "isRequired": true,
              "attributes": []
            }
          }
        },
        "UserAddress": {
          "name": "UserAddress",
          "fields": {
            "streetAddress": {
              "name": "streetAddress",
              "isArray": false,
              "type": "String",
              "isRequired": true,
              "attributes": []
            },
            "postalCode": {
              "name": "postalCode",
              "isArray": false,
              "type": "String",
              "isRequired": true,
              "attributes": []
            },
            "locality": {
              "name": "locality",
              "isArray": false,
              "type": "String",
              "isRequired": true,
              "attributes": []
            },
            "region": {
              "name": "region",
              "isArray": false,
              "type": "String",
              "isRequired": false,
              "attributes": []
            },
            "country": {
              "name": "country",
              "isArray": false,
              "type": "String",
              "isRequired": true,
              "attributes": []
            }
          }
        },
        "GetLicenseDetailByIdResponse": {
          "name": "GetLicenseDetailByIdResponse",
          "fields": {
            "id": {
              "name": "id",
              "isArray": false,
              "type": "String",
              "isRequired": true,
              "attributes": []
            },
            "startDate": {
              "name": "startDate",
              "isArray": false,
              "type": "String",
              "isRequired": true,
              "attributes": []
            },
            "endDate": {
              "name": "endDate",
              "isArray": false,
              "type": "String",
              "isRequired": false,
              "attributes": []
            },
            "companyId": {
              "name": "companyId",
              "isArray": false,
              "type": "String",
              "isRequired": true,
              "attributes": []
            },
            "freeLimitUsed": {
              "name": "freeLimitUsed",
              "isArray": false,
              "type": "Int",
              "isRequired": false,
              "attributes": []
            },
            "seats": {
              "name": "seats",
              "isArray": false,
              "type": "Int",
              "isRequired": false,
              "attributes": []
            },
            "paymentTerm": {
              "name": "paymentTerm",
              "isArray": false,
              "type": "String",
              "isRequired": true,
              "attributes": []
            },
            "admins": {
              "name": "admins",
              "isArray": true,
              "type": {
                "nonModel": "GetLicenseDetailAdmins"
              },
              "isRequired": false,
              "attributes": [],
              "isArrayNullable": true
            }
          }
        },
        "GetLicenseDetailAdmins": {
          "name": "GetLicenseDetailAdmins",
          "fields": {
            "name": {
              "name": "name",
              "isArray": false,
              "type": "String",
              "isRequired": true,
              "attributes": []
            },
            "phoneNumber": {
              "name": "phoneNumber",
              "isArray": false,
              "type": "String",
              "isRequired": true,
              "attributes": []
            }
          }
        },
        "DeactivateUserSeatResponse": {
          "name": "DeactivateUserSeatResponse",
          "fields": {
            "id": {
              "name": "id",
              "isArray": false,
              "type": "String",
              "isRequired": true,
              "attributes": []
            },
            "message": {
              "name": "message",
              "isArray": false,
              "type": "String",
              "isRequired": true,
              "attributes": []
            }
          }
        },
        "AISessionResponse": {
          "name": "AISessionResponse",
          "fields": {
            "statusCode": {
              "name": "statusCode",
              "isArray": false,
              "type": "Int",
              "isRequired": false,
              "attributes": []
            },
            "message": {
              "name": "message",
              "isArray": false,
              "type": "String",
              "isRequired": false,
              "attributes": []
            },
            "data": {
              "name": "data",
              "isArray": false,
              "type": "AWSJSON",
              "isRequired": false,
              "attributes": []
            }
          }
        },
        "BillingPortalConfiguration": {
          "name": "BillingPortalConfiguration",
          "fields": {
            "active": {
              "name": "active",
              "isArray": false,
              "type": "Boolean",
              "isRequired": true,
              "attributes": []
            },
            "application": {
              "name": "application",
              "isArray": false,
              "type": "AWSJSON",
              "isRequired": false,
              "attributes": []
            },
            "business_profile": {
              "name": "business_profile",
              "isArray": false,
              "type": {
                "nonModel": "PortalBusinessProfile"
              },
              "isRequired": true,
              "attributes": []
            },
            "created": {
              "name": "created",
              "isArray": false,
              "type": "AWSTimestamp",
              "isRequired": true,
              "attributes": []
            },
            "default_return_url": {
              "name": "default_return_url",
              "isArray": false,
              "type": "String",
              "isRequired": false,
              "attributes": []
            },
            "features": {
              "name": "features",
              "isArray": false,
              "type": {
                "nonModel": "PortalFeatures"
              },
              "isRequired": true,
              "attributes": []
            },
            "id": {
              "name": "id",
              "isArray": false,
              "type": "String",
              "isRequired": true,
              "attributes": []
            },
            "is_default": {
              "name": "is_default",
              "isArray": false,
              "type": "Boolean",
              "isRequired": true,
              "attributes": []
            },
            "livemode": {
              "name": "livemode",
              "isArray": false,
              "type": "Boolean",
              "isRequired": true,
              "attributes": []
            },
            "login_page": {
              "name": "login_page",
              "isArray": false,
              "type": {
                "nonModel": "PortalLoginPage"
              },
              "isRequired": true,
              "attributes": []
            },
            "metadata": {
              "name": "metadata",
              "isArray": false,
              "type": "AWSJSON",
              "isRequired": false,
              "attributes": []
            },
            "object": {
              "name": "object",
              "isArray": false,
              "type": "String",
              "isRequired": true,
              "attributes": []
            },
            "updated": {
              "name": "updated",
              "isArray": false,
              "type": "AWSTimestamp",
              "isRequired": true,
              "attributes": []
            }
          }
        },
        "BillingPortalSession": {
          "name": "BillingPortalSession",
          "fields": {
            "configuration": {
              "name": "configuration",
              "isArray": false,
              "type": "AWSJSON",
              "isRequired": true,
              "attributes": []
            },
            "created": {
              "name": "created",
              "isArray": false,
              "type": "AWSTimestamp",
              "isRequired": true,
              "attributes": []
            },
            "customer": {
              "name": "customer",
              "isArray": false,
              "type": "String",
              "isRequired": true,
              "attributes": []
            },
            "flow": {
              "name": "flow",
              "isArray": false,
              "type": {
                "nonModel": "PortalFlowsFlow"
              },
              "isRequired": false,
              "attributes": []
            },
            "id": {
              "name": "id",
              "isArray": false,
              "type": "String",
              "isRequired": true,
              "attributes": []
            },
            "livemode": {
              "name": "livemode",
              "isArray": false,
              "type": "Boolean",
              "isRequired": true,
              "attributes": []
            },
            "locale": {
              "name": "locale",
              "isArray": false,
              "type": "String",
              "isRequired": false,
              "attributes": []
            },
            "object": {
              "name": "object",
              "isArray": false,
              "type": "String",
              "isRequired": true,
              "attributes": []
            },
            "on_behalf_of": {
              "name": "on_behalf_of",
              "isArray": false,
              "type": "String",
              "isRequired": false,
              "attributes": []
            },
            "return_url": {
              "name": "return_url",
              "isArray": false,
              "type": "String",
              "isRequired": false,
              "attributes": []
            },
            "url": {
              "name": "url",
              "isArray": false,
              "type": "String",
              "isRequired": true,
              "attributes": []
            }
          }
        },
        "CheckoutSession": {
          "name": "CheckoutSession",
          "fields": {
            "after_expiration": {
              "name": "after_expiration",
              "isArray": false,
              "type": {
                "nonModel": "PaymentPagesCheckoutSessionAfterExpiration"
              },
              "isRequired": false,
              "attributes": []
            },
            "allow_promotion_codes": {
              "name": "allow_promotion_codes",
              "isArray": false,
              "type": "Boolean",
              "isRequired": false,
              "attributes": []
            },
            "amount_subtotal": {
              "name": "amount_subtotal",
              "isArray": false,
              "type": "Int",
              "isRequired": false,
              "attributes": []
            },
            "amount_total": {
              "name": "amount_total",
              "isArray": false,
              "type": "Int",
              "isRequired": false,
              "attributes": []
            },
            "automatic_tax": {
              "name": "automatic_tax",
              "isArray": false,
              "type": {
                "nonModel": "PaymentPagesCheckoutSessionAutomaticTax"
              },
              "isRequired": true,
              "attributes": []
            },
            "billing_address_collection": {
              "name": "billing_address_collection",
              "isArray": false,
              "type": {
                "enum": "CheckoutSessionBillingAddressCollectionEnum"
              },
              "isRequired": false,
              "attributes": []
            },
            "cancel_url": {
              "name": "cancel_url",
              "isArray": false,
              "type": "String",
              "isRequired": false,
              "attributes": []
            },
            "client_reference_id": {
              "name": "client_reference_id",
              "isArray": false,
              "type": "String",
              "isRequired": false,
              "attributes": []
            },
            "client_secret": {
              "name": "client_secret",
              "isArray": false,
              "type": "String",
              "isRequired": false,
              "attributes": []
            },
            "consent": {
              "name": "consent",
              "isArray": false,
              "type": {
                "nonModel": "PaymentPagesCheckoutSessionConsent"
              },
              "isRequired": false,
              "attributes": []
            },
            "consent_collection": {
              "name": "consent_collection",
              "isArray": false,
              "type": {
                "nonModel": "PaymentPagesCheckoutSessionConsentCollection"
              },
              "isRequired": false,
              "attributes": []
            },
            "created": {
              "name": "created",
              "isArray": false,
              "type": "AWSTimestamp",
              "isRequired": true,
              "attributes": []
            },
            "currency": {
              "name": "currency",
              "isArray": false,
              "type": "String",
              "isRequired": false,
              "attributes": []
            },
            "currency_conversion": {
              "name": "currency_conversion",
              "isArray": false,
              "type": {
                "nonModel": "PaymentPagesCheckoutSessionCurrencyConversion"
              },
              "isRequired": false,
              "attributes": []
            },
            "custom_fields": {
              "name": "custom_fields",
              "isArray": true,
              "type": {
                "nonModel": "PaymentPagesCheckoutSessionCustomFields"
              },
              "isRequired": false,
              "attributes": [],
              "isArrayNullable": false
            },
            "custom_text": {
              "name": "custom_text",
              "isArray": false,
              "type": {
                "nonModel": "PaymentPagesCheckoutSessionCustomText"
              },
              "isRequired": true,
              "attributes": []
            },
            "customer": {
              "name": "customer",
              "isArray": false,
              "type": "AWSJSON",
              "isRequired": false,
              "attributes": []
            },
            "customer_creation": {
              "name": "customer_creation",
              "isArray": false,
              "type": {
                "enum": "CheckoutSessionCustomerCreationEnum"
              },
              "isRequired": false,
              "attributes": []
            },
            "customer_details": {
              "name": "customer_details",
              "isArray": false,
              "type": {
                "nonModel": "PaymentPagesCheckoutSessionCustomerDetails"
              },
              "isRequired": false,
              "attributes": []
            },
            "customer_email": {
              "name": "customer_email",
              "isArray": false,
              "type": "String",
              "isRequired": false,
              "attributes": []
            },
            "expires_at": {
              "name": "expires_at",
              "isArray": false,
              "type": "AWSTimestamp",
              "isRequired": true,
              "attributes": []
            },
            "id": {
              "name": "id",
              "isArray": false,
              "type": "String",
              "isRequired": true,
              "attributes": []
            },
            "invoice": {
              "name": "invoice",
              "isArray": false,
              "type": "AWSJSON",
              "isRequired": false,
              "attributes": []
            },
            "invoice_creation": {
              "name": "invoice_creation",
              "isArray": false,
              "type": {
                "nonModel": "PaymentPagesCheckoutSessionInvoiceCreation"
              },
              "isRequired": false,
              "attributes": []
            },
            "line_items": {
              "name": "line_items",
              "isArray": false,
              "type": {
                "nonModel": "CheckoutSessionLine_items"
              },
              "isRequired": false,
              "attributes": []
            },
            "livemode": {
              "name": "livemode",
              "isArray": false,
              "type": "Boolean",
              "isRequired": true,
              "attributes": []
            },
            "locale": {
              "name": "locale",
              "isArray": false,
              "type": "String",
              "isRequired": false,
              "attributes": []
            },
            "metadata": {
              "name": "metadata",
              "isArray": false,
              "type": "AWSJSON",
              "isRequired": false,
              "attributes": []
            },
            "mode": {
              "name": "mode",
              "isArray": false,
              "type": {
                "enum": "CheckoutSessionModeEnum"
              },
              "isRequired": true,
              "attributes": []
            },
            "object": {
              "name": "object",
              "isArray": false,
              "type": "String",
              "isRequired": true,
              "attributes": []
            },
            "payment_intent": {
              "name": "payment_intent",
              "isArray": false,
              "type": "AWSJSON",
              "isRequired": false,
              "attributes": []
            },
            "payment_link": {
              "name": "payment_link",
              "isArray": false,
              "type": "AWSJSON",
              "isRequired": false,
              "attributes": []
            },
            "payment_method_collection": {
              "name": "payment_method_collection",
              "isArray": false,
              "type": {
                "enum": "CheckoutSessionPaymentMethodCollectionEnum"
              },
              "isRequired": false,
              "attributes": []
            },
            "payment_method_configuration_details": {
              "name": "payment_method_configuration_details",
              "isArray": false,
              "type": {
                "nonModel": "PaymentMethodConfigBizPaymentMethodConfigurationDetails"
              },
              "isRequired": false,
              "attributes": []
            },
            "payment_method_options": {
              "name": "payment_method_options",
              "isArray": false,
              "type": {
                "nonModel": "CheckoutSessionPaymentMethodOptions"
              },
              "isRequired": false,
              "attributes": []
            },
            "payment_method_types": {
              "name": "payment_method_types",
              "isArray": true,
              "type": "String",
              "isRequired": false,
              "attributes": [],
              "isArrayNullable": false
            },
            "payment_status": {
              "name": "payment_status",
              "isArray": false,
              "type": {
                "enum": "CheckoutSessionPaymentStatusEnum"
              },
              "isRequired": true,
              "attributes": []
            },
            "phone_number_collection": {
              "name": "phone_number_collection",
              "isArray": false,
              "type": {
                "nonModel": "PaymentPagesCheckoutSessionPhoneNumberCollection"
              },
              "isRequired": false,
              "attributes": []
            },
            "recovered_from": {
              "name": "recovered_from",
              "isArray": false,
              "type": "String",
              "isRequired": false,
              "attributes": []
            },
            "redirect_on_completion": {
              "name": "redirect_on_completion",
              "isArray": false,
              "type": {
                "enum": "CheckoutSessionRedirectOnCompletionEnum"
              },
              "isRequired": false,
              "attributes": []
            },
            "return_url": {
              "name": "return_url",
              "isArray": false,
              "type": "String",
              "isRequired": false,
              "attributes": []
            },
            "saved_payment_method_options": {
              "name": "saved_payment_method_options",
              "isArray": false,
              "type": {
                "nonModel": "PaymentPagesCheckoutSessionSavedPaymentMethodOptions"
              },
              "isRequired": false,
              "attributes": []
            },
            "setup_intent": {
              "name": "setup_intent",
              "isArray": false,
              "type": "AWSJSON",
              "isRequired": false,
              "attributes": []
            },
            "shipping_address_collection": {
              "name": "shipping_address_collection",
              "isArray": false,
              "type": {
                "nonModel": "PaymentPagesCheckoutSessionShippingAddressCollection"
              },
              "isRequired": false,
              "attributes": []
            },
            "shipping_cost": {
              "name": "shipping_cost",
              "isArray": false,
              "type": {
                "nonModel": "PaymentPagesCheckoutSessionShippingCost"
              },
              "isRequired": false,
              "attributes": []
            },
            "shipping_details": {
              "name": "shipping_details",
              "isArray": false,
              "type": {
                "nonModel": "Shipping"
              },
              "isRequired": false,
              "attributes": []
            },
            "shipping_options": {
              "name": "shipping_options",
              "isArray": true,
              "type": {
                "nonModel": "PaymentPagesCheckoutSessionShippingOption"
              },
              "isRequired": false,
              "attributes": [],
              "isArrayNullable": false
            },
            "status": {
              "name": "status",
              "isArray": false,
              "type": {
                "enum": "CheckoutSessionStatusEnum"
              },
              "isRequired": false,
              "attributes": []
            },
            "submit_type": {
              "name": "submit_type",
              "isArray": false,
              "type": {
                "enum": "CheckoutSessionSubmitTypeEnum"
              },
              "isRequired": false,
              "attributes": []
            },
            "subscription": {
              "name": "subscription",
              "isArray": false,
              "type": "AWSJSON",
              "isRequired": false,
              "attributes": []
            },
            "success_url": {
              "name": "success_url",
              "isArray": false,
              "type": "String",
              "isRequired": false,
              "attributes": []
            },
            "tax_id_collection": {
              "name": "tax_id_collection",
              "isArray": false,
              "type": {
                "nonModel": "PaymentPagesCheckoutSessionTaxIdCollection"
              },
              "isRequired": false,
              "attributes": []
            },
            "total_details": {
              "name": "total_details",
              "isArray": false,
              "type": {
                "nonModel": "PaymentPagesCheckoutSessionTotalDetails"
              },
              "isRequired": false,
              "attributes": []
            },
            "ui_mode": {
              "name": "ui_mode",
              "isArray": false,
              "type": {
                "enum": "CheckoutSessionUiModeEnum"
              },
              "isRequired": false,
              "attributes": []
            },
            "url": {
              "name": "url",
              "isArray": false,
              "type": "String",
              "isRequired": false,
              "attributes": []
            }
          }
        },
        "Customer": {
          "name": "Customer",
          "fields": {
            "address": {
              "name": "address",
              "isArray": false,
              "type": {
                "nonModel": "Address"
              },
              "isRequired": false,
              "attributes": []
            },
            "balance": {
              "name": "balance",
              "isArray": false,
              "type": "Int",
              "isRequired": false,
              "attributes": []
            },
            "cash_balance": {
              "name": "cash_balance",
              "isArray": false,
              "type": {
                "nonModel": "CashBalance"
              },
              "isRequired": false,
              "attributes": []
            },
            "created": {
              "name": "created",
              "isArray": false,
              "type": "AWSTimestamp",
              "isRequired": true,
              "attributes": []
            },
            "currency": {
              "name": "currency",
              "isArray": false,
              "type": "String",
              "isRequired": false,
              "attributes": []
            },
            "default_source": {
              "name": "default_source",
              "isArray": false,
              "type": "AWSJSON",
              "isRequired": false,
              "attributes": []
            },
            "delinquent": {
              "name": "delinquent",
              "isArray": false,
              "type": "Boolean",
              "isRequired": false,
              "attributes": []
            },
            "description": {
              "name": "description",
              "isArray": false,
              "type": "String",
              "isRequired": false,
              "attributes": []
            },
            "discount": {
              "name": "discount",
              "isArray": false,
              "type": {
                "nonModel": "Discount"
              },
              "isRequired": false,
              "attributes": []
            },
            "email": {
              "name": "email",
              "isArray": false,
              "type": "String",
              "isRequired": false,
              "attributes": []
            },
            "id": {
              "name": "id",
              "isArray": false,
              "type": "String",
              "isRequired": true,
              "attributes": []
            },
            "invoice_credit_balance": {
              "name": "invoice_credit_balance",
              "isArray": false,
              "type": "AWSJSON",
              "isRequired": false,
              "attributes": []
            },
            "invoice_prefix": {
              "name": "invoice_prefix",
              "isArray": false,
              "type": "String",
              "isRequired": false,
              "attributes": []
            },
            "invoice_settings": {
              "name": "invoice_settings",
              "isArray": false,
              "type": {
                "nonModel": "InvoiceSettingCustomerSetting"
              },
              "isRequired": false,
              "attributes": []
            },
            "livemode": {
              "name": "livemode",
              "isArray": false,
              "type": "Boolean",
              "isRequired": true,
              "attributes": []
            },
            "metadata": {
              "name": "metadata",
              "isArray": false,
              "type": "AWSJSON",
              "isRequired": false,
              "attributes": []
            },
            "name": {
              "name": "name",
              "isArray": false,
              "type": "String",
              "isRequired": false,
              "attributes": []
            },
            "next_invoice_sequence": {
              "name": "next_invoice_sequence",
              "isArray": false,
              "type": "Int",
              "isRequired": false,
              "attributes": []
            },
            "object": {
              "name": "object",
              "isArray": false,
              "type": {
                "enum": "CustomerObject"
              },
              "isRequired": false,
              "attributes": []
            },
            "phone": {
              "name": "phone",
              "isArray": false,
              "type": "String",
              "isRequired": false,
              "attributes": []
            },
            "preferred_locales": {
              "name": "preferred_locales",
              "isArray": true,
              "type": "String",
              "isRequired": false,
              "attributes": [],
              "isArrayNullable": true
            },
            "shipping": {
              "name": "shipping",
              "isArray": false,
              "type": {
                "nonModel": "Shipping"
              },
              "isRequired": false,
              "attributes": []
            },
            "sources": {
              "name": "sources",
              "isArray": false,
              "type": {
                "nonModel": "CustomerSources"
              },
              "isRequired": false,
              "attributes": []
            },
            "subscriptions": {
              "name": "subscriptions",
              "isArray": false,
              "type": {
                "nonModel": "CustomerSubscriptions"
              },
              "isRequired": false,
              "attributes": []
            },
            "tax": {
              "name": "tax",
              "isArray": false,
              "type": {
                "nonModel": "CustomerTax"
              },
              "isRequired": false,
              "attributes": []
            },
            "tax_exempt": {
              "name": "tax_exempt",
              "isArray": false,
              "type": {
                "enum": "CustomerTaxExemptEnum"
              },
              "isRequired": false,
              "attributes": []
            },
            "tax_ids": {
              "name": "tax_ids",
              "isArray": false,
              "type": {
                "nonModel": "CustomerTax_ids"
              },
              "isRequired": false,
              "attributes": []
            },
            "test_clock": {
              "name": "test_clock",
              "isArray": false,
              "type": "AWSJSON",
              "isRequired": false,
              "attributes": []
            }
          }
        },
        "PaymentMethod": {
          "name": "PaymentMethod",
          "fields": {
            "acss_debit": {
              "name": "acss_debit",
              "isArray": false,
              "type": {
                "nonModel": "PaymentMethodAcssDebit"
              },
              "isRequired": false,
              "attributes": []
            },
            "affirm": {
              "name": "affirm",
              "isArray": false,
              "type": "AWSJSON",
              "isRequired": false,
              "attributes": []
            },
            "afterpay_clearpay": {
              "name": "afterpay_clearpay",
              "isArray": false,
              "type": "AWSJSON",
              "isRequired": false,
              "attributes": []
            },
            "alipay": {
              "name": "alipay",
              "isArray": false,
              "type": "AWSJSON",
              "isRequired": false,
              "attributes": []
            },
            "allow_redisplay": {
              "name": "allow_redisplay",
              "isArray": false,
              "type": {
                "enum": "PaymentMethodAllowRedisplayEnum"
              },
              "isRequired": false,
              "attributes": []
            },
            "amazon_pay": {
              "name": "amazon_pay",
              "isArray": false,
              "type": "AWSJSON",
              "isRequired": false,
              "attributes": []
            },
            "au_becs_debit": {
              "name": "au_becs_debit",
              "isArray": false,
              "type": {
                "nonModel": "PaymentMethodAuBecsDebit"
              },
              "isRequired": false,
              "attributes": []
            },
            "bacs_debit": {
              "name": "bacs_debit",
              "isArray": false,
              "type": {
                "nonModel": "PaymentMethodBacsDebit"
              },
              "isRequired": false,
              "attributes": []
            },
            "bancontact": {
              "name": "bancontact",
              "isArray": false,
              "type": "AWSJSON",
              "isRequired": false,
              "attributes": []
            },
            "billing_details": {
              "name": "billing_details",
              "isArray": false,
              "type": {
                "nonModel": "BillingDetails"
              },
              "isRequired": true,
              "attributes": []
            },
            "blik": {
              "name": "blik",
              "isArray": false,
              "type": "AWSJSON",
              "isRequired": false,
              "attributes": []
            },
            "boleto": {
              "name": "boleto",
              "isArray": false,
              "type": {
                "nonModel": "PaymentMethodBoleto"
              },
              "isRequired": false,
              "attributes": []
            },
            "card": {
              "name": "card",
              "isArray": false,
              "type": {
                "nonModel": "PaymentMethodCard"
              },
              "isRequired": false,
              "attributes": []
            },
            "card_present": {
              "name": "card_present",
              "isArray": false,
              "type": {
                "nonModel": "PaymentMethodCardPresent"
              },
              "isRequired": false,
              "attributes": []
            },
            "cashapp": {
              "name": "cashapp",
              "isArray": false,
              "type": {
                "nonModel": "PaymentMethodCashapp"
              },
              "isRequired": false,
              "attributes": []
            },
            "created": {
              "name": "created",
              "isArray": false,
              "type": "AWSTimestamp",
              "isRequired": true,
              "attributes": []
            },
            "customer": {
              "name": "customer",
              "isArray": false,
              "type": "AWSJSON",
              "isRequired": false,
              "attributes": []
            },
            "customer_balance": {
              "name": "customer_balance",
              "isArray": false,
              "type": "AWSJSON",
              "isRequired": false,
              "attributes": []
            },
            "eps": {
              "name": "eps",
              "isArray": false,
              "type": {
                "nonModel": "PaymentMethodEps"
              },
              "isRequired": false,
              "attributes": []
            },
            "fpx": {
              "name": "fpx",
              "isArray": false,
              "type": {
                "nonModel": "PaymentMethodFpx"
              },
              "isRequired": false,
              "attributes": []
            },
            "giropay": {
              "name": "giropay",
              "isArray": false,
              "type": "AWSJSON",
              "isRequired": false,
              "attributes": []
            },
            "grabpay": {
              "name": "grabpay",
              "isArray": false,
              "type": "AWSJSON",
              "isRequired": false,
              "attributes": []
            },
            "id": {
              "name": "id",
              "isArray": false,
              "type": "String",
              "isRequired": true,
              "attributes": []
            },
            "ideal": {
              "name": "ideal",
              "isArray": false,
              "type": {
                "nonModel": "PaymentMethodIdeal"
              },
              "isRequired": false,
              "attributes": []
            },
            "interac_present": {
              "name": "interac_present",
              "isArray": false,
              "type": {
                "nonModel": "PaymentMethodInteracPresent"
              },
              "isRequired": false,
              "attributes": []
            },
            "klarna": {
              "name": "klarna",
              "isArray": false,
              "type": {
                "nonModel": "PaymentMethodKlarna"
              },
              "isRequired": false,
              "attributes": []
            },
            "konbini": {
              "name": "konbini",
              "isArray": false,
              "type": "AWSJSON",
              "isRequired": false,
              "attributes": []
            },
            "link": {
              "name": "link",
              "isArray": false,
              "type": {
                "nonModel": "PaymentMethodLink"
              },
              "isRequired": false,
              "attributes": []
            },
            "livemode": {
              "name": "livemode",
              "isArray": false,
              "type": "Boolean",
              "isRequired": true,
              "attributes": []
            },
            "metadata": {
              "name": "metadata",
              "isArray": false,
              "type": "AWSJSON",
              "isRequired": false,
              "attributes": []
            },
            "mobilepay": {
              "name": "mobilepay",
              "isArray": false,
              "type": "AWSJSON",
              "isRequired": false,
              "attributes": []
            },
            "multibanco": {
              "name": "multibanco",
              "isArray": false,
              "type": "AWSJSON",
              "isRequired": false,
              "attributes": []
            },
            "object": {
              "name": "object",
              "isArray": false,
              "type": {
                "enum": "PaymentMethodObject"
              },
              "isRequired": false,
              "attributes": []
            },
            "oxxo": {
              "name": "oxxo",
              "isArray": false,
              "type": "AWSJSON",
              "isRequired": false,
              "attributes": []
            },
            "p24": {
              "name": "p24",
              "isArray": false,
              "type": {
                "nonModel": "PaymentMethodP24"
              },
              "isRequired": false,
              "attributes": []
            },
            "paynow": {
              "name": "paynow",
              "isArray": false,
              "type": "AWSJSON",
              "isRequired": false,
              "attributes": []
            },
            "paypal": {
              "name": "paypal",
              "isArray": false,
              "type": {
                "nonModel": "PaymentMethodPaypal"
              },
              "isRequired": false,
              "attributes": []
            },
            "pix": {
              "name": "pix",
              "isArray": false,
              "type": "AWSJSON",
              "isRequired": false,
              "attributes": []
            },
            "promptpay": {
              "name": "promptpay",
              "isArray": false,
              "type": "AWSJSON",
              "isRequired": false,
              "attributes": []
            },
            "radar_options": {
              "name": "radar_options",
              "isArray": false,
              "type": {
                "nonModel": "RadarRadarOptions"
              },
              "isRequired": false,
              "attributes": []
            },
            "revolut_pay": {
              "name": "revolut_pay",
              "isArray": false,
              "type": "AWSJSON",
              "isRequired": false,
              "attributes": []
            },
            "sepa_debit": {
              "name": "sepa_debit",
              "isArray": false,
              "type": {
                "nonModel": "PaymentMethodSepaDebit"
              },
              "isRequired": false,
              "attributes": []
            },
            "sofort": {
              "name": "sofort",
              "isArray": false,
              "type": {
                "nonModel": "PaymentMethodSofort"
              },
              "isRequired": false,
              "attributes": []
            },
            "swish": {
              "name": "swish",
              "isArray": false,
              "type": "AWSJSON",
              "isRequired": false,
              "attributes": []
            },
            "twint": {
              "name": "twint",
              "isArray": false,
              "type": "AWSJSON",
              "isRequired": false,
              "attributes": []
            },
            "type": {
              "name": "type",
              "isArray": false,
              "type": {
                "enum": "PaymentMethodTypeEnum"
              },
              "isRequired": true,
              "attributes": []
            },
            "us_bank_account": {
              "name": "us_bank_account",
              "isArray": false,
              "type": {
                "nonModel": "PaymentMethodUsBankAccount"
              },
              "isRequired": false,
              "attributes": []
            },
            "wechat_pay": {
              "name": "wechat_pay",
              "isArray": false,
              "type": "AWSJSON",
              "isRequired": false,
              "attributes": []
            },
            "zip": {
              "name": "zip",
              "isArray": false,
              "type": "AWSJSON",
              "isRequired": false,
              "attributes": []
            }
          }
        },
        "Plan": {
          "name": "Plan",
          "fields": {
            "active": {
              "name": "active",
              "isArray": false,
              "type": "Boolean",
              "isRequired": true,
              "attributes": []
            },
            "aggregate_usage": {
              "name": "aggregate_usage",
              "isArray": false,
              "type": {
                "enum": "PlanAggregateUsageEnum"
              },
              "isRequired": false,
              "attributes": []
            },
            "amount": {
              "name": "amount",
              "isArray": false,
              "type": "Int",
              "isRequired": false,
              "attributes": []
            },
            "amount_decimal": {
              "name": "amount_decimal",
              "isArray": false,
              "type": "String",
              "isRequired": false,
              "attributes": []
            },
            "billing_scheme": {
              "name": "billing_scheme",
              "isArray": false,
              "type": {
                "enum": "PlanBillingSchemeEnum"
              },
              "isRequired": true,
              "attributes": []
            },
            "created": {
              "name": "created",
              "isArray": false,
              "type": "AWSTimestamp",
              "isRequired": true,
              "attributes": []
            },
            "currency": {
              "name": "currency",
              "isArray": false,
              "type": "String",
              "isRequired": true,
              "attributes": []
            },
            "id": {
              "name": "id",
              "isArray": false,
              "type": "String",
              "isRequired": true,
              "attributes": []
            },
            "interval": {
              "name": "interval",
              "isArray": false,
              "type": {
                "enum": "PlanIntervalEnum"
              },
              "isRequired": true,
              "attributes": []
            },
            "interval_count": {
              "name": "interval_count",
              "isArray": false,
              "type": "Int",
              "isRequired": true,
              "attributes": []
            },
            "livemode": {
              "name": "livemode",
              "isArray": false,
              "type": "Boolean",
              "isRequired": true,
              "attributes": []
            },
            "metadata": {
              "name": "metadata",
              "isArray": false,
              "type": "AWSJSON",
              "isRequired": false,
              "attributes": []
            },
            "meter": {
              "name": "meter",
              "isArray": false,
              "type": "String",
              "isRequired": false,
              "attributes": []
            },
            "nickname": {
              "name": "nickname",
              "isArray": false,
              "type": "String",
              "isRequired": false,
              "attributes": []
            },
            "object": {
              "name": "object",
              "isArray": false,
              "type": {
                "enum": "PlanObject"
              },
              "isRequired": false,
              "attributes": []
            },
            "product": {
              "name": "product",
              "isArray": false,
              "type": "AWSJSON",
              "isRequired": false,
              "attributes": []
            },
            "tiers": {
              "name": "tiers",
              "isArray": true,
              "type": {
                "nonModel": "PlanTier"
              },
              "isRequired": false,
              "attributes": [],
              "isArrayNullable": true
            },
            "tiers_mode": {
              "name": "tiers_mode",
              "isArray": false,
              "type": {
                "enum": "PlanTiersModeEnum"
              },
              "isRequired": false,
              "attributes": []
            },
            "transform_usage": {
              "name": "transform_usage",
              "isArray": false,
              "type": {
                "nonModel": "TransformUsage"
              },
              "isRequired": false,
              "attributes": []
            },
            "trial_period_days": {
              "name": "trial_period_days",
              "isArray": false,
              "type": "Int",
              "isRequired": false,
              "attributes": []
            },
            "usage_type": {
              "name": "usage_type",
              "isArray": false,
              "type": {
                "enum": "PlanUsageTypeEnum"
              },
              "isRequired": true,
              "attributes": []
            }
          }
        },
        "Price": {
          "name": "Price",
          "fields": {
            "active": {
              "name": "active",
              "isArray": false,
              "type": "Boolean",
              "isRequired": true,
              "attributes": []
            },
            "billing_scheme": {
              "name": "billing_scheme",
              "isArray": false,
              "type": {
                "enum": "PriceBillingSchemeEnum"
              },
              "isRequired": true,
              "attributes": []
            },
            "created": {
              "name": "created",
              "isArray": false,
              "type": "AWSTimestamp",
              "isRequired": true,
              "attributes": []
            },
            "currency": {
              "name": "currency",
              "isArray": false,
              "type": "String",
              "isRequired": true,
              "attributes": []
            },
            "currency_options": {
              "name": "currency_options",
              "isArray": false,
              "type": "AWSJSON",
              "isRequired": false,
              "attributes": []
            },
            "custom_unit_amount": {
              "name": "custom_unit_amount",
              "isArray": false,
              "type": {
                "nonModel": "CustomUnitAmount"
              },
              "isRequired": false,
              "attributes": []
            },
            "id": {
              "name": "id",
              "isArray": false,
              "type": "String",
              "isRequired": true,
              "attributes": []
            },
            "livemode": {
              "name": "livemode",
              "isArray": false,
              "type": "Boolean",
              "isRequired": true,
              "attributes": []
            },
            "lookup_key": {
              "name": "lookup_key",
              "isArray": false,
              "type": "String",
              "isRequired": false,
              "attributes": []
            },
            "metadata": {
              "name": "metadata",
              "isArray": false,
              "type": "AWSJSON",
              "isRequired": true,
              "attributes": []
            },
            "nickname": {
              "name": "nickname",
              "isArray": false,
              "type": "String",
              "isRequired": false,
              "attributes": []
            },
            "object": {
              "name": "object",
              "isArray": false,
              "type": {
                "enum": "PriceObject"
              },
              "isRequired": false,
              "attributes": []
            },
            "product": {
              "name": "product",
              "isArray": false,
              "type": "AWSJSON",
              "isRequired": true,
              "attributes": []
            },
            "recurring": {
              "name": "recurring",
              "isArray": false,
              "type": {
                "nonModel": "Recurring"
              },
              "isRequired": false,
              "attributes": []
            },
            "tax_behavior": {
              "name": "tax_behavior",
              "isArray": false,
              "type": {
                "enum": "PriceTaxBehaviorEnum"
              },
              "isRequired": false,
              "attributes": []
            },
            "tiers": {
              "name": "tiers",
              "isArray": true,
              "type": {
                "nonModel": "PriceTier"
              },
              "isRequired": false,
              "attributes": [],
              "isArrayNullable": true
            },
            "tiers_mode": {
              "name": "tiers_mode",
              "isArray": false,
              "type": {
                "enum": "PriceTiersModeEnum"
              },
              "isRequired": false,
              "attributes": []
            },
            "transform_quantity": {
              "name": "transform_quantity",
              "isArray": false,
              "type": {
                "nonModel": "TransformQuantity"
              },
              "isRequired": false,
              "attributes": []
            },
            "type": {
              "name": "type",
              "isArray": false,
              "type": {
                "enum": "PriceTypeEnum"
              },
              "isRequired": true,
              "attributes": []
            },
            "unit_amount": {
              "name": "unit_amount",
              "isArray": false,
              "type": "Int",
              "isRequired": false,
              "attributes": []
            },
            "unit_amount_decimal": {
              "name": "unit_amount_decimal",
              "isArray": false,
              "type": "String",
              "isRequired": false,
              "attributes": []
            }
          }
        },
        "Product": {
          "name": "Product",
          "fields": {
            "active": {
              "name": "active",
              "isArray": false,
              "type": "Boolean",
              "isRequired": true,
              "attributes": []
            },
            "created": {
              "name": "created",
              "isArray": false,
              "type": "AWSTimestamp",
              "isRequired": true,
              "attributes": []
            },
            "default_price": {
              "name": "default_price",
              "isArray": false,
              "type": "AWSJSON",
              "isRequired": false,
              "attributes": []
            },
            "description": {
              "name": "description",
              "isArray": false,
              "type": "String",
              "isRequired": false,
              "attributes": []
            },
            "id": {
              "name": "id",
              "isArray": false,
              "type": "String",
              "isRequired": true,
              "attributes": []
            },
            "images": {
              "name": "images",
              "isArray": true,
              "type": "String",
              "isRequired": false,
              "attributes": [],
              "isArrayNullable": false
            },
            "livemode": {
              "name": "livemode",
              "isArray": false,
              "type": "Boolean",
              "isRequired": true,
              "attributes": []
            },
            "marketing_features": {
              "name": "marketing_features",
              "isArray": true,
              "type": {
                "nonModel": "ProductMarketingFeature"
              },
              "isRequired": false,
              "attributes": [],
              "isArrayNullable": false
            },
            "metadata": {
              "name": "metadata",
              "isArray": false,
              "type": "AWSJSON",
              "isRequired": true,
              "attributes": []
            },
            "name": {
              "name": "name",
              "isArray": false,
              "type": "String",
              "isRequired": true,
              "attributes": []
            },
            "object": {
              "name": "object",
              "isArray": false,
              "type": {
                "enum": "ProductObject"
              },
              "isRequired": false,
              "attributes": []
            },
            "package_dimensions": {
              "name": "package_dimensions",
              "isArray": false,
              "type": {
                "nonModel": "PackageDimensions"
              },
              "isRequired": false,
              "attributes": []
            },
            "shippable": {
              "name": "shippable",
              "isArray": false,
              "type": "Boolean",
              "isRequired": false,
              "attributes": []
            },
            "statement_descriptor": {
              "name": "statement_descriptor",
              "isArray": false,
              "type": "String",
              "isRequired": false,
              "attributes": []
            },
            "tax_code": {
              "name": "tax_code",
              "isArray": false,
              "type": "AWSJSON",
              "isRequired": false,
              "attributes": []
            },
            "unit_label": {
              "name": "unit_label",
              "isArray": false,
              "type": "String",
              "isRequired": false,
              "attributes": []
            },
            "updated": {
              "name": "updated",
              "isArray": false,
              "type": "AWSTimestamp",
              "isRequired": true,
              "attributes": []
            },
            "url": {
              "name": "url",
              "isArray": false,
              "type": "String",
              "isRequired": false,
              "attributes": []
            }
          }
        },
        "SubscriptionType": {
          "name": "SubscriptionType",
          "fields": {
            "application": {
              "name": "application",
              "isArray": false,
              "type": "AWSJSON",
              "isRequired": false,
              "attributes": []
            },
            "application_fee_percent": {
              "name": "application_fee_percent",
              "isArray": false,
              "type": "Float",
              "isRequired": false,
              "attributes": []
            },
            "automatic_tax": {
              "name": "automatic_tax",
              "isArray": false,
              "type": {
                "nonModel": "SubscriptionAutomaticTax"
              },
              "isRequired": true,
              "attributes": []
            },
            "billing_cycle_anchor": {
              "name": "billing_cycle_anchor",
              "isArray": false,
              "type": "AWSTimestamp",
              "isRequired": true,
              "attributes": []
            },
            "billing_cycle_anchor_config": {
              "name": "billing_cycle_anchor_config",
              "isArray": false,
              "type": {
                "nonModel": "SubscriptionsResourceBillingCycleAnchorConfig"
              },
              "isRequired": false,
              "attributes": []
            },
            "billing_thresholds": {
              "name": "billing_thresholds",
              "isArray": false,
              "type": {
                "nonModel": "SubscriptionBillingThresholds"
              },
              "isRequired": false,
              "attributes": []
            },
            "cancel_at": {
              "name": "cancel_at",
              "isArray": false,
              "type": "AWSTimestamp",
              "isRequired": false,
              "attributes": []
            },
            "cancel_at_period_end": {
              "name": "cancel_at_period_end",
              "isArray": false,
              "type": "Boolean",
              "isRequired": true,
              "attributes": []
            },
            "canceled_at": {
              "name": "canceled_at",
              "isArray": false,
              "type": "AWSTimestamp",
              "isRequired": false,
              "attributes": []
            },
            "cancellation_details": {
              "name": "cancellation_details",
              "isArray": false,
              "type": {
                "nonModel": "CancellationDetails"
              },
              "isRequired": false,
              "attributes": []
            },
            "collection_method": {
              "name": "collection_method",
              "isArray": false,
              "type": {
                "enum": "SubscriptionTypeCollectionMethodEnum"
              },
              "isRequired": true,
              "attributes": []
            },
            "created": {
              "name": "created",
              "isArray": false,
              "type": "AWSTimestamp",
              "isRequired": true,
              "attributes": []
            },
            "currency": {
              "name": "currency",
              "isArray": false,
              "type": "String",
              "isRequired": true,
              "attributes": []
            },
            "current_period_end": {
              "name": "current_period_end",
              "isArray": false,
              "type": "AWSTimestamp",
              "isRequired": true,
              "attributes": []
            },
            "current_period_start": {
              "name": "current_period_start",
              "isArray": false,
              "type": "AWSTimestamp",
              "isRequired": true,
              "attributes": []
            },
            "customer": {
              "name": "customer",
              "isArray": false,
              "type": "AWSJSON",
              "isRequired": true,
              "attributes": []
            },
            "days_until_due": {
              "name": "days_until_due",
              "isArray": false,
              "type": "Int",
              "isRequired": false,
              "attributes": []
            },
            "default_payment_method": {
              "name": "default_payment_method",
              "isArray": false,
              "type": "AWSJSON",
              "isRequired": false,
              "attributes": []
            },
            "default_source": {
              "name": "default_source",
              "isArray": false,
              "type": "AWSJSON",
              "isRequired": false,
              "attributes": []
            },
            "default_tax_rates": {
              "name": "default_tax_rates",
              "isArray": true,
              "type": {
                "nonModel": "TaxRate"
              },
              "isRequired": false,
              "attributes": [],
              "isArrayNullable": true
            },
            "description": {
              "name": "description",
              "isArray": false,
              "type": "String",
              "isRequired": false,
              "attributes": []
            },
            "discount": {
              "name": "discount",
              "isArray": false,
              "type": {
                "nonModel": "Discount"
              },
              "isRequired": false,
              "attributes": []
            },
            "discounts": {
              "name": "discounts",
              "isArray": true,
              "type": "AWSJSON",
              "isRequired": false,
              "attributes": [],
              "isArrayNullable": false
            },
            "ended_at": {
              "name": "ended_at",
              "isArray": false,
              "type": "AWSTimestamp",
              "isRequired": false,
              "attributes": []
            },
            "id": {
              "name": "id",
              "isArray": false,
              "type": "String",
              "isRequired": true,
              "attributes": []
            },
            "invoice_settings": {
              "name": "invoice_settings",
              "isArray": false,
              "type": {
                "nonModel": "SubscriptionsResourceSubscriptionInvoiceSettings"
              },
              "isRequired": true,
              "attributes": []
            },
            "items": {
              "name": "items",
              "isArray": false,
              "type": {
                "nonModel": "SubscriptionTypeItems"
              },
              "isRequired": false,
              "attributes": []
            },
            "latest_invoice": {
              "name": "latest_invoice",
              "isArray": false,
              "type": "AWSJSON",
              "isRequired": false,
              "attributes": []
            },
            "livemode": {
              "name": "livemode",
              "isArray": false,
              "type": "Boolean",
              "isRequired": true,
              "attributes": []
            },
            "metadata": {
              "name": "metadata",
              "isArray": false,
              "type": "AWSJSON",
              "isRequired": true,
              "attributes": []
            },
            "next_pending_invoice_item_invoice": {
              "name": "next_pending_invoice_item_invoice",
              "isArray": false,
              "type": "AWSTimestamp",
              "isRequired": false,
              "attributes": []
            },
            "object": {
              "name": "object",
              "isArray": false,
              "type": {
                "enum": "SubscriptionTypeObject"
              },
              "isRequired": false,
              "attributes": []
            },
            "on_behalf_of": {
              "name": "on_behalf_of",
              "isArray": false,
              "type": "AWSJSON",
              "isRequired": false,
              "attributes": []
            },
            "pause_collection": {
              "name": "pause_collection",
              "isArray": false,
              "type": {
                "nonModel": "SubscriptionsResourcePauseCollection"
              },
              "isRequired": false,
              "attributes": []
            },
            "payment_settings": {
              "name": "payment_settings",
              "isArray": false,
              "type": {
                "nonModel": "SubscriptionsResourcePaymentSettings"
              },
              "isRequired": false,
              "attributes": []
            },
            "pending_invoice_item_interval": {
              "name": "pending_invoice_item_interval",
              "isArray": false,
              "type": {
                "nonModel": "SubscriptionPendingInvoiceItemInterval"
              },
              "isRequired": false,
              "attributes": []
            },
            "pending_setup_intent": {
              "name": "pending_setup_intent",
              "isArray": false,
              "type": "AWSJSON",
              "isRequired": false,
              "attributes": []
            },
            "pending_update": {
              "name": "pending_update",
              "isArray": false,
              "type": {
                "nonModel": "SubscriptionsResourcePendingUpdate"
              },
              "isRequired": false,
              "attributes": []
            },
            "schedule": {
              "name": "schedule",
              "isArray": false,
              "type": "AWSJSON",
              "isRequired": false,
              "attributes": []
            },
            "start_date": {
              "name": "start_date",
              "isArray": false,
              "type": "AWSTimestamp",
              "isRequired": true,
              "attributes": []
            },
            "status": {
              "name": "status",
              "isArray": false,
              "type": {
                "enum": "SubscriptionTypeStatusEnum"
              },
              "isRequired": true,
              "attributes": []
            },
            "test_clock": {
              "name": "test_clock",
              "isArray": false,
              "type": "AWSJSON",
              "isRequired": false,
              "attributes": []
            },
            "transfer_data": {
              "name": "transfer_data",
              "isArray": false,
              "type": {
                "nonModel": "SubscriptionTransferData"
              },
              "isRequired": false,
              "attributes": []
            },
            "trial_end": {
              "name": "trial_end",
              "isArray": false,
              "type": "AWSTimestamp",
              "isRequired": false,
              "attributes": []
            },
            "trial_settings": {
              "name": "trial_settings",
              "isArray": false,
              "type": {
                "nonModel": "SubscriptionsTrialsResourceTrialSettings"
              },
              "isRequired": false,
              "attributes": []
            },
            "trial_start": {
              "name": "trial_start",
              "isArray": false,
              "type": "AWSTimestamp",
              "isRequired": false,
              "attributes": []
            }
          }
        },
        "SubscriptionItem": {
          "name": "SubscriptionItem",
          "fields": {
            "billing_thresholds": {
              "name": "billing_thresholds",
              "isArray": false,
              "type": {
                "nonModel": "SubscriptionItemBillingThresholds"
              },
              "isRequired": false,
              "attributes": []
            },
            "created": {
              "name": "created",
              "isArray": false,
              "type": "Int",
              "isRequired": true,
              "attributes": []
            },
            "discounts": {
              "name": "discounts",
              "isArray": true,
              "type": "AWSJSON",
              "isRequired": false,
              "attributes": [],
              "isArrayNullable": false
            },
            "id": {
              "name": "id",
              "isArray": false,
              "type": "String",
              "isRequired": true,
              "attributes": []
            },
            "metadata": {
              "name": "metadata",
              "isArray": false,
              "type": "AWSJSON",
              "isRequired": true,
              "attributes": []
            },
            "object": {
              "name": "object",
              "isArray": false,
              "type": {
                "enum": "SubscriptionItemObject"
              },
              "isRequired": false,
              "attributes": []
            },
            "price": {
              "name": "price",
              "isArray": false,
              "type": {
                "nonModel": "Price"
              },
              "isRequired": true,
              "attributes": []
            },
            "quantity": {
              "name": "quantity",
              "isArray": false,
              "type": "Int",
              "isRequired": false,
              "attributes": []
            },
            "subscription": {
              "name": "subscription",
              "isArray": false,
              "type": "String",
              "isRequired": true,
              "attributes": []
            },
            "tax_rates": {
              "name": "tax_rates",
              "isArray": true,
              "type": {
                "nonModel": "TaxRate"
              },
              "isRequired": false,
              "attributes": [],
              "isArrayNullable": true
            }
          }
        },
        "Invoice": {
          "name": "Invoice",
          "fields": {
            "account_country": {
              "name": "account_country",
              "isArray": false,
              "type": "String",
              "isRequired": false,
              "attributes": []
            },
            "account_name": {
              "name": "account_name",
              "isArray": false,
              "type": "String",
              "isRequired": false,
              "attributes": []
            },
            "account_tax_ids": {
              "name": "account_tax_ids",
              "isArray": false,
              "type": "AWSJSON",
              "isRequired": false,
              "attributes": []
            },
            "amount_due": {
              "name": "amount_due",
              "isArray": false,
              "type": "Int",
              "isRequired": true,
              "attributes": []
            },
            "amount_paid": {
              "name": "amount_paid",
              "isArray": false,
              "type": "Int",
              "isRequired": true,
              "attributes": []
            },
            "amount_remaining": {
              "name": "amount_remaining",
              "isArray": false,
              "type": "Int",
              "isRequired": true,
              "attributes": []
            },
            "amount_shipping": {
              "name": "amount_shipping",
              "isArray": false,
              "type": "Int",
              "isRequired": true,
              "attributes": []
            },
            "application": {
              "name": "application",
              "isArray": false,
              "type": "AWSJSON",
              "isRequired": false,
              "attributes": []
            },
            "application_fee_amount": {
              "name": "application_fee_amount",
              "isArray": false,
              "type": "Int",
              "isRequired": false,
              "attributes": []
            },
            "attempt_count": {
              "name": "attempt_count",
              "isArray": false,
              "type": "Int",
              "isRequired": true,
              "attributes": []
            },
            "attempted": {
              "name": "attempted",
              "isArray": false,
              "type": "Boolean",
              "isRequired": true,
              "attributes": []
            },
            "automatic_tax": {
              "name": "automatic_tax",
              "isArray": false,
              "type": {
                "nonModel": "SubscriptionAutomaticTax"
              },
              "isRequired": true,
              "attributes": []
            },
            "automatically_finalizes_at": {
              "name": "automatically_finalizes_at",
              "isArray": false,
              "type": "AWSTimestamp",
              "isRequired": false,
              "attributes": []
            },
            "billing_reason": {
              "name": "billing_reason",
              "isArray": false,
              "type": {
                "enum": "InvoiceBillingReasonEnum"
              },
              "isRequired": false,
              "attributes": []
            },
            "charge": {
              "name": "charge",
              "isArray": false,
              "type": "AWSJSON",
              "isRequired": false,
              "attributes": []
            },
            "collection_method": {
              "name": "collection_method",
              "isArray": false,
              "type": {
                "enum": "CollectionMethodInvoiceEnum"
              },
              "isRequired": false,
              "attributes": []
            },
            "created": {
              "name": "created",
              "isArray": false,
              "type": "AWSTimestamp",
              "isRequired": true,
              "attributes": []
            },
            "currency": {
              "name": "currency",
              "isArray": false,
              "type": "String",
              "isRequired": false,
              "attributes": []
            },
            "custom_fields": {
              "name": "custom_fields",
              "isArray": false,
              "type": {
                "nonModel": "InvoiceCustomFields"
              },
              "isRequired": false,
              "attributes": []
            },
            "customer": {
              "name": "customer",
              "isArray": false,
              "type": "AWSJSON",
              "isRequired": false,
              "attributes": []
            },
            "customer_address": {
              "name": "customer_address",
              "isArray": false,
              "type": {
                "nonModel": "InvoiceCustomerAddress"
              },
              "isRequired": false,
              "attributes": []
            },
            "customer_email": {
              "name": "customer_email",
              "isArray": false,
              "type": "String",
              "isRequired": false,
              "attributes": []
            },
            "customer_name": {
              "name": "customer_name",
              "isArray": false,
              "type": "String",
              "isRequired": false,
              "attributes": []
            },
            "customer_phone": {
              "name": "customer_phone",
              "isArray": false,
              "type": "String",
              "isRequired": false,
              "attributes": []
            },
            "customer_shipping": {
              "name": "customer_shipping",
              "isArray": false,
              "type": {
                "nonModel": "InvoiceCustomerShipping"
              },
              "isRequired": false,
              "attributes": []
            },
            "customer_tax_exempt": {
              "name": "customer_tax_exempt",
              "isArray": false,
              "type": {
                "enum": "InvoiceCustomerTaxExemptEnum"
              },
              "isRequired": false,
              "attributes": []
            },
            "default_payment_method": {
              "name": "default_payment_method",
              "isArray": false,
              "type": "AWSJSON",
              "isRequired": false,
              "attributes": []
            },
            "default_source": {
              "name": "default_source",
              "isArray": false,
              "type": "String",
              "isRequired": false,
              "attributes": []
            },
            "description": {
              "name": "description",
              "isArray": false,
              "type": "String",
              "isRequired": false,
              "attributes": []
            },
            "discount": {
              "name": "discount",
              "isArray": false,
              "type": {
                "nonModel": "Discount"
              },
              "isRequired": false,
              "attributes": []
            },
            "discounts": {
              "name": "discounts",
              "isArray": true,
              "type": "AWSJSON",
              "isRequired": false,
              "attributes": [],
              "isArrayNullable": false
            },
            "due_date": {
              "name": "due_date",
              "isArray": false,
              "type": "AWSTimestamp",
              "isRequired": false,
              "attributes": []
            },
            "effective_at": {
              "name": "effective_at",
              "isArray": false,
              "type": "AWSTimestamp",
              "isRequired": false,
              "attributes": []
            },
            "ending_balance": {
              "name": "ending_balance",
              "isArray": false,
              "type": "Int",
              "isRequired": false,
              "attributes": []
            },
            "footer": {
              "name": "footer",
              "isArray": false,
              "type": "String",
              "isRequired": false,
              "attributes": []
            },
            "from_invoice": {
              "name": "from_invoice",
              "isArray": false,
              "type": {
                "nonModel": "InvoiceFromInvoice"
              },
              "isRequired": false,
              "attributes": []
            },
            "invoice_pdf": {
              "name": "invoice_pdf",
              "isArray": false,
              "type": "String",
              "isRequired": false,
              "attributes": []
            },
            "latest_revision": {
              "name": "latest_revision",
              "isArray": false,
              "type": "AWSJSON",
              "isRequired": false,
              "attributes": []
            },
            "livemode": {
              "name": "livemode",
              "isArray": false,
              "type": "Boolean",
              "isRequired": true,
              "attributes": []
            },
            "metadata": {
              "name": "metadata",
              "isArray": false,
              "type": "AWSJSON",
              "isRequired": false,
              "attributes": []
            },
            "next_payment_attempt": {
              "name": "next_payment_attempt",
              "isArray": false,
              "type": "AWSTimestamp",
              "isRequired": false,
              "attributes": []
            },
            "number": {
              "name": "number",
              "isArray": false,
              "type": "String",
              "isRequired": false,
              "attributes": []
            },
            "on_behalf_of": {
              "name": "on_behalf_of",
              "isArray": false,
              "type": "AWSJSON",
              "isRequired": false,
              "attributes": []
            },
            "paid": {
              "name": "paid",
              "isArray": false,
              "type": "Boolean",
              "isRequired": true,
              "attributes": []
            },
            "paid_out_of_band": {
              "name": "paid_out_of_band",
              "isArray": false,
              "type": "Boolean",
              "isRequired": true,
              "attributes": []
            },
            "payment_intent": {
              "name": "payment_intent",
              "isArray": false,
              "type": "AWSJSON",
              "isRequired": false,
              "attributes": []
            },
            "period_end": {
              "name": "period_end",
              "isArray": false,
              "type": "AWSTimestamp",
              "isRequired": true,
              "attributes": []
            },
            "period_start": {
              "name": "period_start",
              "isArray": false,
              "type": "AWSTimestamp",
              "isRequired": true,
              "attributes": []
            },
            "post_payment_credit_notes_amount": {
              "name": "post_payment_credit_notes_amount",
              "isArray": false,
              "type": "Int",
              "isRequired": true,
              "attributes": []
            },
            "pre_payment_credit_notes_amount": {
              "name": "pre_payment_credit_notes_amount",
              "isArray": false,
              "type": "Int",
              "isRequired": true,
              "attributes": []
            },
            "quote": {
              "name": "quote",
              "isArray": false,
              "type": "AWSJSON",
              "isRequired": false,
              "attributes": []
            },
            "receipt_number": {
              "name": "receipt_number",
              "isArray": false,
              "type": "String",
              "isRequired": false,
              "attributes": []
            },
            "starting_balance": {
              "name": "starting_balance",
              "isArray": false,
              "type": "Int",
              "isRequired": true,
              "attributes": []
            },
            "statement_descriptor": {
              "name": "statement_descriptor",
              "isArray": false,
              "type": "String",
              "isRequired": false,
              "attributes": []
            },
            "status": {
              "name": "status",
              "isArray": false,
              "type": {
                "enum": "InvoiceStatusEnum"
              },
              "isRequired": false,
              "attributes": []
            },
            "subscription": {
              "name": "subscription",
              "isArray": false,
              "type": "AWSJSON",
              "isRequired": false,
              "attributes": []
            },
            "subscription_proration_date": {
              "name": "subscription_proration_date",
              "isArray": false,
              "type": "Int",
              "isRequired": false,
              "attributes": []
            },
            "subtotal": {
              "name": "subtotal",
              "isArray": false,
              "type": "Int",
              "isRequired": true,
              "attributes": []
            },
            "subtotal_excluding_tax": {
              "name": "subtotal_excluding_tax",
              "isArray": false,
              "type": "Int",
              "isRequired": false,
              "attributes": []
            },
            "tax": {
              "name": "tax",
              "isArray": false,
              "type": "Int",
              "isRequired": false,
              "attributes": []
            },
            "test_clock": {
              "name": "test_clock",
              "isArray": false,
              "type": "String",
              "isRequired": false,
              "attributes": []
            },
            "total": {
              "name": "total",
              "isArray": false,
              "type": "Int",
              "isRequired": true,
              "attributes": []
            },
            "total_discount_amounts": {
              "name": "total_discount_amounts",
              "isArray": false,
              "type": {
                "nonModel": "InvoiceTotalDiscountAmounts"
              },
              "isRequired": false,
              "attributes": []
            },
            "total_excluding_tax": {
              "name": "total_excluding_tax",
              "isArray": false,
              "type": "Int",
              "isRequired": false,
              "attributes": []
            },
            "total_pretax_credit_amounts": {
              "name": "total_pretax_credit_amounts",
              "isArray": false,
              "type": {
                "nonModel": "InvoiceTotalPretaxCreditAmounts"
              },
              "isRequired": false,
              "attributes": []
            },
            "total_tax_amounts": {
              "name": "total_tax_amounts",
              "isArray": false,
              "type": {
                "nonModel": "InvoiceTotalTaxAmounts"
              },
              "isRequired": false,
              "attributes": []
            },
            "transfer_data": {
              "name": "transfer_data",
              "isArray": false,
              "type": {
                "nonModel": "InvoiceTransferData"
              },
              "isRequired": false,
              "attributes": []
            },
            "webhooks_delivered_at": {
              "name": "webhooks_delivered_at",
              "isArray": false,
              "type": "AWSTimestamp",
              "isRequired": false,
              "attributes": []
            }
          }
        },
        "Address": {
          "name": "Address",
          "fields": {
            "city": {
              "name": "city",
              "isArray": false,
              "type": "String",
              "isRequired": false,
              "attributes": []
            },
            "country": {
              "name": "country",
              "isArray": false,
              "type": "String",
              "isRequired": false,
              "attributes": []
            },
            "line1": {
              "name": "line1",
              "isArray": false,
              "type": "String",
              "isRequired": false,
              "attributes": []
            },
            "line2": {
              "name": "line2",
              "isArray": false,
              "type": "String",
              "isRequired": false,
              "attributes": []
            },
            "postal_code": {
              "name": "postal_code",
              "isArray": false,
              "type": "String",
              "isRequired": false,
              "attributes": []
            },
            "state": {
              "name": "state",
              "isArray": false,
              "type": "String",
              "isRequired": false,
              "attributes": []
            }
          }
        },
        "BillingDetails": {
          "name": "BillingDetails",
          "fields": {
            "address": {
              "name": "address",
              "isArray": false,
              "type": {
                "nonModel": "Address"
              },
              "isRequired": false,
              "attributes": []
            },
            "email": {
              "name": "email",
              "isArray": false,
              "type": "String",
              "isRequired": false,
              "attributes": []
            },
            "name": {
              "name": "name",
              "isArray": false,
              "type": "String",
              "isRequired": false,
              "attributes": []
            },
            "phone": {
              "name": "phone",
              "isArray": false,
              "type": "String",
              "isRequired": false,
              "attributes": []
            }
          }
        },
        "CancellationDetails": {
          "name": "CancellationDetails",
          "fields": {
            "comment": {
              "name": "comment",
              "isArray": false,
              "type": "String",
              "isRequired": false,
              "attributes": []
            },
            "feedback": {
              "name": "feedback",
              "isArray": false,
              "type": {
                "enum": "CancellationDetailsFeedbackEnum"
              },
              "isRequired": false,
              "attributes": []
            },
            "reason": {
              "name": "reason",
              "isArray": false,
              "type": {
                "enum": "CancellationDetailsReasonEnum"
              },
              "isRequired": false,
              "attributes": []
            }
          }
        },
        "CardGeneratedFromPaymentMethodDetails": {
          "name": "CardGeneratedFromPaymentMethodDetails",
          "fields": {
            "card_present": {
              "name": "card_present",
              "isArray": false,
              "type": {
                "nonModel": "PaymentMethodDetailsCardPresent"
              },
              "isRequired": false,
              "attributes": []
            },
            "type": {
              "name": "type",
              "isArray": false,
              "type": "String",
              "isRequired": true,
              "attributes": []
            }
          }
        },
        "CashBalance": {
          "name": "CashBalance",
          "fields": {
            "available": {
              "name": "available",
              "isArray": false,
              "type": "AWSJSON",
              "isRequired": false,
              "attributes": []
            },
            "customer": {
              "name": "customer",
              "isArray": false,
              "type": "String",
              "isRequired": true,
              "attributes": []
            },
            "livemode": {
              "name": "livemode",
              "isArray": false,
              "type": "Boolean",
              "isRequired": true,
              "attributes": []
            },
            "object": {
              "name": "object",
              "isArray": false,
              "type": {
                "enum": "CashBalanceObject"
              },
              "isRequired": false,
              "attributes": []
            },
            "settings": {
              "name": "settings",
              "isArray": false,
              "type": {
                "nonModel": "CustomerBalanceCustomerBalanceSettings"
              },
              "isRequired": true,
              "attributes": []
            }
          }
        },
        "CheckoutAcssDebitMandateOptions": {
          "name": "CheckoutAcssDebitMandateOptions",
          "fields": {
            "custom_mandate_url": {
              "name": "custom_mandate_url",
              "isArray": false,
              "type": "String",
              "isRequired": false,
              "attributes": []
            },
            "default_for": {
              "name": "default_for",
              "isArray": true,
              "type": {
                "enum": "CheckoutAcssDebitMandateOptionsDefaultForEnum"
              },
              "isRequired": false,
              "attributes": [],
              "isArrayNullable": true
            },
            "interval_description": {
              "name": "interval_description",
              "isArray": false,
              "type": "String",
              "isRequired": false,
              "attributes": []
            },
            "payment_schedule": {
              "name": "payment_schedule",
              "isArray": false,
              "type": {
                "enum": "CheckoutAcssDebitMandateOptionsPaymentScheduleEnum"
              },
              "isRequired": false,
              "attributes": []
            },
            "transaction_type": {
              "name": "transaction_type",
              "isArray": false,
              "type": {
                "enum": "CheckoutAcssDebitMandateOptionsTransactionTypeEnum"
              },
              "isRequired": false,
              "attributes": []
            }
          }
        },
        "CheckoutAcssDebitPaymentMethodOptions": {
          "name": "CheckoutAcssDebitPaymentMethodOptions",
          "fields": {
            "currency": {
              "name": "currency",
              "isArray": false,
              "type": {
                "enum": "CheckoutAcssDebitPaymentMethodOptionsCurrencyEnum"
              },
              "isRequired": false,
              "attributes": []
            },
            "mandate_options": {
              "name": "mandate_options",
              "isArray": false,
              "type": {
                "nonModel": "CheckoutAcssDebitMandateOptions"
              },
              "isRequired": false,
              "attributes": []
            },
            "setup_future_usage": {
              "name": "setup_future_usage",
              "isArray": false,
              "type": {
                "enum": "CheckoutAcssDebitPaymentMethodOptionsSetupFutureUsageEnum"
              },
              "isRequired": false,
              "attributes": []
            },
            "verification_method": {
              "name": "verification_method",
              "isArray": false,
              "type": {
                "enum": "CheckoutAcssDebitPaymentMethodOptionsVerificationMethodEnum"
              },
              "isRequired": false,
              "attributes": []
            }
          }
        },
        "CheckoutAffirmPaymentMethodOptions": {
          "name": "CheckoutAffirmPaymentMethodOptions",
          "fields": {
            "setup_future_usage": {
              "name": "setup_future_usage",
              "isArray": false,
              "type": {
                "enum": "CheckoutAffirmPaymentMethodOptionsSetupFutureUsageEnum"
              },
              "isRequired": false,
              "attributes": []
            }
          }
        },
        "CheckoutAfterpayClearpayPaymentMethodOptions": {
          "name": "CheckoutAfterpayClearpayPaymentMethodOptions",
          "fields": {
            "setup_future_usage": {
              "name": "setup_future_usage",
              "isArray": false,
              "type": {
                "enum": "CheckoutAfterpayClearpayPaymentMethodOptionsSetupFutureUsageEnum"
              },
              "isRequired": false,
              "attributes": []
            }
          }
        },
        "CheckoutAlipayPaymentMethodOptions": {
          "name": "CheckoutAlipayPaymentMethodOptions",
          "fields": {
            "setup_future_usage": {
              "name": "setup_future_usage",
              "isArray": false,
              "type": {
                "enum": "CheckoutAlipayPaymentMethodOptionsSetupFutureUsageEnum"
              },
              "isRequired": false,
              "attributes": []
            }
          }
        },
        "CheckoutAmazonPayPaymentMethodOptions": {
          "name": "CheckoutAmazonPayPaymentMethodOptions",
          "fields": {
            "setup_future_usage": {
              "name": "setup_future_usage",
              "isArray": false,
              "type": {
                "enum": "CheckoutAmazonPayPaymentMethodOptionsSetupFutureUsageEnum"
              },
              "isRequired": false,
              "attributes": []
            }
          }
        },
        "CheckoutAuBecsDebitPaymentMethodOptions": {
          "name": "CheckoutAuBecsDebitPaymentMethodOptions",
          "fields": {
            "setup_future_usage": {
              "name": "setup_future_usage",
              "isArray": false,
              "type": {
                "enum": "CheckoutAuBecsDebitPaymentMethodOptionsSetupFutureUsageEnum"
              },
              "isRequired": false,
              "attributes": []
            }
          }
        },
        "CheckoutBacsDebitPaymentMethodOptions": {
          "name": "CheckoutBacsDebitPaymentMethodOptions",
          "fields": {
            "setup_future_usage": {
              "name": "setup_future_usage",
              "isArray": false,
              "type": {
                "enum": "CheckoutBacsDebitPaymentMethodOptionsSetupFutureUsageEnum"
              },
              "isRequired": false,
              "attributes": []
            }
          }
        },
        "CheckoutBancontactPaymentMethodOptions": {
          "name": "CheckoutBancontactPaymentMethodOptions",
          "fields": {
            "setup_future_usage": {
              "name": "setup_future_usage",
              "isArray": false,
              "type": {
                "enum": "CheckoutBancontactPaymentMethodOptionsSetupFutureUsageEnum"
              },
              "isRequired": false,
              "attributes": []
            }
          }
        },
        "CheckoutBoletoPaymentMethodOptions": {
          "name": "CheckoutBoletoPaymentMethodOptions",
          "fields": {
            "expires_after_days": {
              "name": "expires_after_days",
              "isArray": false,
              "type": "Int",
              "isRequired": true,
              "attributes": []
            },
            "setup_future_usage": {
              "name": "setup_future_usage",
              "isArray": false,
              "type": {
                "enum": "CheckoutBoletoPaymentMethodOptionsSetupFutureUsageEnum"
              },
              "isRequired": false,
              "attributes": []
            }
          }
        },
        "CheckoutCardInstallmentsOptions": {
          "name": "CheckoutCardInstallmentsOptions",
          "fields": {
            "enabled": {
              "name": "enabled",
              "isArray": false,
              "type": "Boolean",
              "isRequired": false,
              "attributes": []
            }
          }
        },
        "CheckoutCardPaymentMethodOptions": {
          "name": "CheckoutCardPaymentMethodOptions",
          "fields": {
            "installments": {
              "name": "installments",
              "isArray": false,
              "type": {
                "nonModel": "CheckoutCardInstallmentsOptions"
              },
              "isRequired": false,
              "attributes": []
            },
            "request_three_d_secure": {
              "name": "request_three_d_secure",
              "isArray": false,
              "type": {
                "enum": "CheckoutCardPaymentMethodOptionsRequestThreeDSecureEnum"
              },
              "isRequired": true,
              "attributes": []
            },
            "setup_future_usage": {
              "name": "setup_future_usage",
              "isArray": false,
              "type": {
                "enum": "CheckoutCardPaymentMethodOptionsSetupFutureUsageEnum"
              },
              "isRequired": false,
              "attributes": []
            },
            "statement_descriptor_suffix_kana": {
              "name": "statement_descriptor_suffix_kana",
              "isArray": false,
              "type": "String",
              "isRequired": false,
              "attributes": []
            },
            "statement_descriptor_suffix_kanji": {
              "name": "statement_descriptor_suffix_kanji",
              "isArray": false,
              "type": "String",
              "isRequired": false,
              "attributes": []
            }
          }
        },
        "CheckoutCashappPaymentMethodOptions": {
          "name": "CheckoutCashappPaymentMethodOptions",
          "fields": {
            "setup_future_usage": {
              "name": "setup_future_usage",
              "isArray": false,
              "type": {
                "enum": "CheckoutCashappPaymentMethodOptionsSetupFutureUsageEnum"
              },
              "isRequired": false,
              "attributes": []
            }
          }
        },
        "CheckoutCustomerBalanceBankTransferPaymentMethodOptions": {
          "name": "CheckoutCustomerBalanceBankTransferPaymentMethodOptions",
          "fields": {
            "eu_bank_transfer": {
              "name": "eu_bank_transfer",
              "isArray": false,
              "type": {
                "nonModel": "PaymentMethodOptionsCustomerBalanceEuBankAccount"
              },
              "isRequired": false,
              "attributes": []
            },
            "requested_address_types": {
              "name": "requested_address_types",
              "isArray": true,
              "type": {
                "enum": "CheckoutCustomerBalanceBankTransferPaymentMethodOptionsRequestedAddressTypesEnum"
              },
              "isRequired": false,
              "attributes": [],
              "isArrayNullable": true
            },
            "type": {
              "name": "type",
              "isArray": false,
              "type": {
                "enum": "CheckoutCustomerBalanceBankTransferPaymentMethodOptionsTypeEnum"
              },
              "isRequired": false,
              "attributes": []
            }
          }
        },
        "CheckoutCustomerBalancePaymentMethodOptions": {
          "name": "CheckoutCustomerBalancePaymentMethodOptions",
          "fields": {
            "bank_transfer": {
              "name": "bank_transfer",
              "isArray": false,
              "type": {
                "nonModel": "CheckoutCustomerBalanceBankTransferPaymentMethodOptions"
              },
              "isRequired": false,
              "attributes": []
            },
            "funding_type": {
              "name": "funding_type",
              "isArray": false,
              "type": {
                "enum": "CheckoutCustomerBalancePaymentMethodOptionsFundingTypeEnum"
              },
              "isRequired": false,
              "attributes": []
            },
            "setup_future_usage": {
              "name": "setup_future_usage",
              "isArray": false,
              "type": {
                "enum": "CheckoutCustomerBalancePaymentMethodOptionsSetupFutureUsageEnum"
              },
              "isRequired": false,
              "attributes": []
            }
          }
        },
        "CheckoutEpsPaymentMethodOptions": {
          "name": "CheckoutEpsPaymentMethodOptions",
          "fields": {
            "setup_future_usage": {
              "name": "setup_future_usage",
              "isArray": false,
              "type": {
                "enum": "CheckoutEpsPaymentMethodOptionsSetupFutureUsageEnum"
              },
              "isRequired": false,
              "attributes": []
            }
          }
        },
        "CheckoutFpxPaymentMethodOptions": {
          "name": "CheckoutFpxPaymentMethodOptions",
          "fields": {
            "setup_future_usage": {
              "name": "setup_future_usage",
              "isArray": false,
              "type": {
                "enum": "CheckoutFpxPaymentMethodOptionsSetupFutureUsageEnum"
              },
              "isRequired": false,
              "attributes": []
            }
          }
        },
        "CheckoutGiropayPaymentMethodOptions": {
          "name": "CheckoutGiropayPaymentMethodOptions",
          "fields": {
            "setup_future_usage": {
              "name": "setup_future_usage",
              "isArray": false,
              "type": {
                "enum": "CheckoutGiropayPaymentMethodOptionsSetupFutureUsageEnum"
              },
              "isRequired": false,
              "attributes": []
            }
          }
        },
        "CheckoutGrabPayPaymentMethodOptions": {
          "name": "CheckoutGrabPayPaymentMethodOptions",
          "fields": {
            "setup_future_usage": {
              "name": "setup_future_usage",
              "isArray": false,
              "type": {
                "enum": "CheckoutGrabPayPaymentMethodOptionsSetupFutureUsageEnum"
              },
              "isRequired": false,
              "attributes": []
            }
          }
        },
        "CheckoutIdealPaymentMethodOptions": {
          "name": "CheckoutIdealPaymentMethodOptions",
          "fields": {
            "setup_future_usage": {
              "name": "setup_future_usage",
              "isArray": false,
              "type": {
                "enum": "CheckoutIdealPaymentMethodOptionsSetupFutureUsageEnum"
              },
              "isRequired": false,
              "attributes": []
            }
          }
        },
        "CheckoutKlarnaPaymentMethodOptions": {
          "name": "CheckoutKlarnaPaymentMethodOptions",
          "fields": {
            "setup_future_usage": {
              "name": "setup_future_usage",
              "isArray": false,
              "type": {
                "enum": "CheckoutKlarnaPaymentMethodOptionsSetupFutureUsageEnum"
              },
              "isRequired": false,
              "attributes": []
            }
          }
        },
        "CheckoutKonbiniPaymentMethodOptions": {
          "name": "CheckoutKonbiniPaymentMethodOptions",
          "fields": {
            "expires_after_days": {
              "name": "expires_after_days",
              "isArray": false,
              "type": "Int",
              "isRequired": false,
              "attributes": []
            },
            "setup_future_usage": {
              "name": "setup_future_usage",
              "isArray": false,
              "type": {
                "enum": "CheckoutKonbiniPaymentMethodOptionsSetupFutureUsageEnum"
              },
              "isRequired": false,
              "attributes": []
            }
          }
        },
        "CheckoutLinkPaymentMethodOptions": {
          "name": "CheckoutLinkPaymentMethodOptions",
          "fields": {
            "setup_future_usage": {
              "name": "setup_future_usage",
              "isArray": false,
              "type": {
                "enum": "CheckoutLinkPaymentMethodOptionsSetupFutureUsageEnum"
              },
              "isRequired": false,
              "attributes": []
            }
          }
        },
        "CheckoutMobilepayPaymentMethodOptions": {
          "name": "CheckoutMobilepayPaymentMethodOptions",
          "fields": {
            "setup_future_usage": {
              "name": "setup_future_usage",
              "isArray": false,
              "type": {
                "enum": "CheckoutMobilepayPaymentMethodOptionsSetupFutureUsageEnum"
              },
              "isRequired": false,
              "attributes": []
            }
          }
        },
        "CheckoutMultibancoPaymentMethodOptions": {
          "name": "CheckoutMultibancoPaymentMethodOptions",
          "fields": {
            "setup_future_usage": {
              "name": "setup_future_usage",
              "isArray": false,
              "type": {
                "enum": "CheckoutMultibancoPaymentMethodOptionsSetupFutureUsageEnum"
              },
              "isRequired": false,
              "attributes": []
            }
          }
        },
        "CheckoutOxxoPaymentMethodOptions": {
          "name": "CheckoutOxxoPaymentMethodOptions",
          "fields": {
            "expires_after_days": {
              "name": "expires_after_days",
              "isArray": false,
              "type": "Int",
              "isRequired": true,
              "attributes": []
            },
            "setup_future_usage": {
              "name": "setup_future_usage",
              "isArray": false,
              "type": {
                "enum": "CheckoutOxxoPaymentMethodOptionsSetupFutureUsageEnum"
              },
              "isRequired": false,
              "attributes": []
            }
          }
        },
        "CheckoutP24PaymentMethodOptions": {
          "name": "CheckoutP24PaymentMethodOptions",
          "fields": {
            "setup_future_usage": {
              "name": "setup_future_usage",
              "isArray": false,
              "type": {
                "enum": "CheckoutP24PaymentMethodOptionsSetupFutureUsageEnum"
              },
              "isRequired": false,
              "attributes": []
            }
          }
        },
        "CheckoutPaynowPaymentMethodOptions": {
          "name": "CheckoutPaynowPaymentMethodOptions",
          "fields": {
            "setup_future_usage": {
              "name": "setup_future_usage",
              "isArray": false,
              "type": {
                "enum": "CheckoutPaynowPaymentMethodOptionsSetupFutureUsageEnum"
              },
              "isRequired": false,
              "attributes": []
            }
          }
        },
        "CheckoutPaypalPaymentMethodOptions": {
          "name": "CheckoutPaypalPaymentMethodOptions",
          "fields": {
            "capture_method": {
              "name": "capture_method",
              "isArray": false,
              "type": {
                "enum": "CheckoutPaypalPaymentMethodOptionsCaptureMethodEnum"
              },
              "isRequired": false,
              "attributes": []
            },
            "preferred_locale": {
              "name": "preferred_locale",
              "isArray": false,
              "type": "String",
              "isRequired": false,
              "attributes": []
            },
            "reference": {
              "name": "reference",
              "isArray": false,
              "type": "String",
              "isRequired": false,
              "attributes": []
            },
            "setup_future_usage": {
              "name": "setup_future_usage",
              "isArray": false,
              "type": {
                "enum": "CheckoutPaypalPaymentMethodOptionsSetupFutureUsageEnum"
              },
              "isRequired": false,
              "attributes": []
            }
          }
        },
        "CheckoutPixPaymentMethodOptions": {
          "name": "CheckoutPixPaymentMethodOptions",
          "fields": {
            "expires_after_seconds": {
              "name": "expires_after_seconds",
              "isArray": false,
              "type": "Int",
              "isRequired": false,
              "attributes": []
            }
          }
        },
        "CheckoutRevolutPayPaymentMethodOptions": {
          "name": "CheckoutRevolutPayPaymentMethodOptions",
          "fields": {
            "setup_future_usage": {
              "name": "setup_future_usage",
              "isArray": false,
              "type": {
                "enum": "CheckoutRevolutPayPaymentMethodOptionsSetupFutureUsageEnum"
              },
              "isRequired": false,
              "attributes": []
            }
          }
        },
        "CheckoutSepaDebitPaymentMethodOptions": {
          "name": "CheckoutSepaDebitPaymentMethodOptions",
          "fields": {
            "setup_future_usage": {
              "name": "setup_future_usage",
              "isArray": false,
              "type": {
                "enum": "CheckoutSepaDebitPaymentMethodOptionsSetupFutureUsageEnum"
              },
              "isRequired": false,
              "attributes": []
            }
          }
        },
        "CheckoutSessionPaymentMethodOptions": {
          "name": "CheckoutSessionPaymentMethodOptions",
          "fields": {
            "acss_debit": {
              "name": "acss_debit",
              "isArray": false,
              "type": {
                "nonModel": "CheckoutAcssDebitPaymentMethodOptions"
              },
              "isRequired": false,
              "attributes": []
            },
            "affirm": {
              "name": "affirm",
              "isArray": false,
              "type": {
                "nonModel": "CheckoutAffirmPaymentMethodOptions"
              },
              "isRequired": false,
              "attributes": []
            },
            "afterpay_clearpay": {
              "name": "afterpay_clearpay",
              "isArray": false,
              "type": {
                "nonModel": "CheckoutAfterpayClearpayPaymentMethodOptions"
              },
              "isRequired": false,
              "attributes": []
            },
            "alipay": {
              "name": "alipay",
              "isArray": false,
              "type": {
                "nonModel": "CheckoutAlipayPaymentMethodOptions"
              },
              "isRequired": false,
              "attributes": []
            },
            "amazon_pay": {
              "name": "amazon_pay",
              "isArray": false,
              "type": {
                "nonModel": "CheckoutAmazonPayPaymentMethodOptions"
              },
              "isRequired": false,
              "attributes": []
            },
            "au_becs_debit": {
              "name": "au_becs_debit",
              "isArray": false,
              "type": {
                "nonModel": "CheckoutAuBecsDebitPaymentMethodOptions"
              },
              "isRequired": false,
              "attributes": []
            },
            "bacs_debit": {
              "name": "bacs_debit",
              "isArray": false,
              "type": {
                "nonModel": "CheckoutBacsDebitPaymentMethodOptions"
              },
              "isRequired": false,
              "attributes": []
            },
            "bancontact": {
              "name": "bancontact",
              "isArray": false,
              "type": {
                "nonModel": "CheckoutBancontactPaymentMethodOptions"
              },
              "isRequired": false,
              "attributes": []
            },
            "boleto": {
              "name": "boleto",
              "isArray": false,
              "type": {
                "nonModel": "CheckoutBoletoPaymentMethodOptions"
              },
              "isRequired": false,
              "attributes": []
            },
            "card": {
              "name": "card",
              "isArray": false,
              "type": {
                "nonModel": "CheckoutCardPaymentMethodOptions"
              },
              "isRequired": false,
              "attributes": []
            },
            "cashapp": {
              "name": "cashapp",
              "isArray": false,
              "type": {
                "nonModel": "CheckoutCashappPaymentMethodOptions"
              },
              "isRequired": false,
              "attributes": []
            },
            "customer_balance": {
              "name": "customer_balance",
              "isArray": false,
              "type": {
                "nonModel": "CheckoutCustomerBalancePaymentMethodOptions"
              },
              "isRequired": false,
              "attributes": []
            },
            "eps": {
              "name": "eps",
              "isArray": false,
              "type": {
                "nonModel": "CheckoutEpsPaymentMethodOptions"
              },
              "isRequired": false,
              "attributes": []
            },
            "fpx": {
              "name": "fpx",
              "isArray": false,
              "type": {
                "nonModel": "CheckoutFpxPaymentMethodOptions"
              },
              "isRequired": false,
              "attributes": []
            },
            "giropay": {
              "name": "giropay",
              "isArray": false,
              "type": {
                "nonModel": "CheckoutGiropayPaymentMethodOptions"
              },
              "isRequired": false,
              "attributes": []
            },
            "grabpay": {
              "name": "grabpay",
              "isArray": false,
              "type": {
                "nonModel": "CheckoutGrabPayPaymentMethodOptions"
              },
              "isRequired": false,
              "attributes": []
            },
            "ideal": {
              "name": "ideal",
              "isArray": false,
              "type": {
                "nonModel": "CheckoutIdealPaymentMethodOptions"
              },
              "isRequired": false,
              "attributes": []
            },
            "klarna": {
              "name": "klarna",
              "isArray": false,
              "type": {
                "nonModel": "CheckoutKlarnaPaymentMethodOptions"
              },
              "isRequired": false,
              "attributes": []
            },
            "konbini": {
              "name": "konbini",
              "isArray": false,
              "type": {
                "nonModel": "CheckoutKonbiniPaymentMethodOptions"
              },
              "isRequired": false,
              "attributes": []
            },
            "link": {
              "name": "link",
              "isArray": false,
              "type": {
                "nonModel": "CheckoutLinkPaymentMethodOptions"
              },
              "isRequired": false,
              "attributes": []
            },
            "mobilepay": {
              "name": "mobilepay",
              "isArray": false,
              "type": {
                "nonModel": "CheckoutMobilepayPaymentMethodOptions"
              },
              "isRequired": false,
              "attributes": []
            },
            "multibanco": {
              "name": "multibanco",
              "isArray": false,
              "type": {
                "nonModel": "CheckoutMultibancoPaymentMethodOptions"
              },
              "isRequired": false,
              "attributes": []
            },
            "oxxo": {
              "name": "oxxo",
              "isArray": false,
              "type": {
                "nonModel": "CheckoutOxxoPaymentMethodOptions"
              },
              "isRequired": false,
              "attributes": []
            },
            "p24": {
              "name": "p24",
              "isArray": false,
              "type": {
                "nonModel": "CheckoutP24PaymentMethodOptions"
              },
              "isRequired": false,
              "attributes": []
            },
            "paynow": {
              "name": "paynow",
              "isArray": false,
              "type": {
                "nonModel": "CheckoutPaynowPaymentMethodOptions"
              },
              "isRequired": false,
              "attributes": []
            },
            "paypal": {
              "name": "paypal",
              "isArray": false,
              "type": {
                "nonModel": "CheckoutPaypalPaymentMethodOptions"
              },
              "isRequired": false,
              "attributes": []
            },
            "pix": {
              "name": "pix",
              "isArray": false,
              "type": {
                "nonModel": "CheckoutPixPaymentMethodOptions"
              },
              "isRequired": false,
              "attributes": []
            },
            "revolut_pay": {
              "name": "revolut_pay",
              "isArray": false,
              "type": {
                "nonModel": "CheckoutRevolutPayPaymentMethodOptions"
              },
              "isRequired": false,
              "attributes": []
            },
            "sepa_debit": {
              "name": "sepa_debit",
              "isArray": false,
              "type": {
                "nonModel": "CheckoutSepaDebitPaymentMethodOptions"
              },
              "isRequired": false,
              "attributes": []
            },
            "sofort": {
              "name": "sofort",
              "isArray": false,
              "type": {
                "nonModel": "CheckoutSofortPaymentMethodOptions"
              },
              "isRequired": false,
              "attributes": []
            },
            "swish": {
              "name": "swish",
              "isArray": false,
              "type": {
                "nonModel": "CheckoutSwishPaymentMethodOptions"
              },
              "isRequired": false,
              "attributes": []
            },
            "us_bank_account": {
              "name": "us_bank_account",
              "isArray": false,
              "type": {
                "nonModel": "CheckoutUsBankAccountPaymentMethodOptions"
              },
              "isRequired": false,
              "attributes": []
            }
          }
        },
        "CheckoutSofortPaymentMethodOptions": {
          "name": "CheckoutSofortPaymentMethodOptions",
          "fields": {
            "setup_future_usage": {
              "name": "setup_future_usage",
              "isArray": false,
              "type": {
                "enum": "CheckoutSofortPaymentMethodOptionsSetupFutureUsageEnum"
              },
              "isRequired": false,
              "attributes": []
            }
          }
        },
        "CheckoutSwishPaymentMethodOptions": {
          "name": "CheckoutSwishPaymentMethodOptions",
          "fields": {
            "reference": {
              "name": "reference",
              "isArray": false,
              "type": "String",
              "isRequired": false,
              "attributes": []
            }
          }
        },
        "CheckoutUsBankAccountPaymentMethodOptions": {
          "name": "CheckoutUsBankAccountPaymentMethodOptions",
          "fields": {
            "financial_connections": {
              "name": "financial_connections",
              "isArray": false,
              "type": {
                "nonModel": "LinkedAccountOptionsUsBankAccount"
              },
              "isRequired": false,
              "attributes": []
            },
            "setup_future_usage": {
              "name": "setup_future_usage",
              "isArray": false,
              "type": {
                "enum": "CheckoutUsBankAccountPaymentMethodOptionsSetupFutureUsageEnum"
              },
              "isRequired": false,
              "attributes": []
            },
            "verification_method": {
              "name": "verification_method",
              "isArray": false,
              "type": {
                "enum": "CheckoutUsBankAccountPaymentMethodOptionsVerificationMethodEnum"
              },
              "isRequired": false,
              "attributes": []
            }
          }
        },
        "ConnectAccountReference": {
          "name": "ConnectAccountReference",
          "fields": {
            "account": {
              "name": "account",
              "isArray": false,
              "type": "AWSJSON",
              "isRequired": false,
              "attributes": []
            },
            "type": {
              "name": "type",
              "isArray": false,
              "type": {
                "enum": "ConnectAccountReferenceTypeEnum"
              },
              "isRequired": true,
              "attributes": []
            }
          }
        },
        "Coupon": {
          "name": "Coupon",
          "fields": {
            "amount_off": {
              "name": "amount_off",
              "isArray": false,
              "type": "Int",
              "isRequired": false,
              "attributes": []
            },
            "applies_to": {
              "name": "applies_to",
              "isArray": false,
              "type": {
                "nonModel": "CouponAppliesTo"
              },
              "isRequired": false,
              "attributes": []
            },
            "created": {
              "name": "created",
              "isArray": false,
              "type": "AWSTimestamp",
              "isRequired": true,
              "attributes": []
            },
            "currency": {
              "name": "currency",
              "isArray": false,
              "type": "String",
              "isRequired": false,
              "attributes": []
            },
            "currency_options": {
              "name": "currency_options",
              "isArray": false,
              "type": "AWSJSON",
              "isRequired": false,
              "attributes": []
            },
            "duration": {
              "name": "duration",
              "isArray": false,
              "type": {
                "enum": "CouponDurationEnum"
              },
              "isRequired": true,
              "attributes": []
            },
            "duration_in_months": {
              "name": "duration_in_months",
              "isArray": false,
              "type": "Int",
              "isRequired": false,
              "attributes": []
            },
            "id": {
              "name": "id",
              "isArray": false,
              "type": "String",
              "isRequired": true,
              "attributes": []
            },
            "livemode": {
              "name": "livemode",
              "isArray": false,
              "type": "Boolean",
              "isRequired": true,
              "attributes": []
            },
            "max_redemptions": {
              "name": "max_redemptions",
              "isArray": false,
              "type": "Int",
              "isRequired": false,
              "attributes": []
            },
            "metadata": {
              "name": "metadata",
              "isArray": false,
              "type": "AWSJSON",
              "isRequired": false,
              "attributes": []
            },
            "name": {
              "name": "name",
              "isArray": false,
              "type": "String",
              "isRequired": false,
              "attributes": []
            },
            "object": {
              "name": "object",
              "isArray": false,
              "type": {
                "enum": "CouponObject"
              },
              "isRequired": false,
              "attributes": []
            },
            "percent_off": {
              "name": "percent_off",
              "isArray": false,
              "type": "Float",
              "isRequired": false,
              "attributes": []
            },
            "redeem_by": {
              "name": "redeem_by",
              "isArray": false,
              "type": "AWSTimestamp",
              "isRequired": false,
              "attributes": []
            },
            "times_redeemed": {
              "name": "times_redeemed",
              "isArray": false,
              "type": "Int",
              "isRequired": true,
              "attributes": []
            },
            "valid": {
              "name": "valid",
              "isArray": false,
              "type": "Boolean",
              "isRequired": true,
              "attributes": []
            }
          }
        },
        "CouponAppliesTo": {
          "name": "CouponAppliesTo",
          "fields": {
            "products": {
              "name": "products",
              "isArray": true,
              "type": "String",
              "isRequired": false,
              "attributes": [],
              "isArrayNullable": false
            }
          }
        },
        "CustomUnitAmount": {
          "name": "CustomUnitAmount",
          "fields": {
            "maximum": {
              "name": "maximum",
              "isArray": false,
              "type": "Int",
              "isRequired": false,
              "attributes": []
            },
            "minimum": {
              "name": "minimum",
              "isArray": false,
              "type": "Int",
              "isRequired": false,
              "attributes": []
            },
            "preset": {
              "name": "preset",
              "isArray": false,
              "type": "Int",
              "isRequired": false,
              "attributes": []
            }
          }
        },
        "CustomerBalanceCustomerBalanceSettings": {
          "name": "CustomerBalanceCustomerBalanceSettings",
          "fields": {
            "reconciliation_mode": {
              "name": "reconciliation_mode",
              "isArray": false,
              "type": {
                "enum": "CustomerBalanceCustomerBalanceSettingsReconciliationModeEnum"
              },
              "isRequired": true,
              "attributes": []
            },
            "using_merchant_default": {
              "name": "using_merchant_default",
              "isArray": false,
              "type": "Boolean",
              "isRequired": true,
              "attributes": []
            }
          }
        },
        "CustomerTax": {
          "name": "CustomerTax",
          "fields": {
            "automatic_tax": {
              "name": "automatic_tax",
              "isArray": false,
              "type": {
                "enum": "CustomerTaxAutomaticTaxEnum"
              },
              "isRequired": true,
              "attributes": []
            },
            "ip_address": {
              "name": "ip_address",
              "isArray": false,
              "type": "String",
              "isRequired": false,
              "attributes": []
            },
            "location": {
              "name": "location",
              "isArray": false,
              "type": {
                "nonModel": "CustomerTaxLocation"
              },
              "isRequired": false,
              "attributes": []
            }
          }
        },
        "CustomerTaxLocation": {
          "name": "CustomerTaxLocation",
          "fields": {
            "country": {
              "name": "country",
              "isArray": false,
              "type": "String",
              "isRequired": true,
              "attributes": []
            },
            "source": {
              "name": "source",
              "isArray": false,
              "type": {
                "enum": "CustomerTaxLocationSourceEnum"
              },
              "isRequired": true,
              "attributes": []
            },
            "state": {
              "name": "state",
              "isArray": false,
              "type": "String",
              "isRequired": false,
              "attributes": []
            }
          }
        },
        "Discount": {
          "name": "Discount",
          "fields": {
            "checkout_session": {
              "name": "checkout_session",
              "isArray": false,
              "type": "String",
              "isRequired": false,
              "attributes": []
            },
            "coupon": {
              "name": "coupon",
              "isArray": false,
              "type": {
                "nonModel": "Coupon"
              },
              "isRequired": true,
              "attributes": []
            },
            "customer": {
              "name": "customer",
              "isArray": false,
              "type": "AWSJSON",
              "isRequired": false,
              "attributes": []
            },
            "end": {
              "name": "end",
              "isArray": false,
              "type": "AWSTimestamp",
              "isRequired": false,
              "attributes": []
            },
            "id": {
              "name": "id",
              "isArray": false,
              "type": "String",
              "isRequired": true,
              "attributes": []
            },
            "invoice": {
              "name": "invoice",
              "isArray": false,
              "type": "String",
              "isRequired": false,
              "attributes": []
            },
            "invoice_item": {
              "name": "invoice_item",
              "isArray": false,
              "type": "String",
              "isRequired": false,
              "attributes": []
            },
            "object": {
              "name": "object",
              "isArray": false,
              "type": {
                "enum": "DiscountObject"
              },
              "isRequired": false,
              "attributes": []
            },
            "promotion_code": {
              "name": "promotion_code",
              "isArray": false,
              "type": "AWSJSON",
              "isRequired": false,
              "attributes": []
            },
            "start": {
              "name": "start",
              "isArray": false,
              "type": "AWSTimestamp",
              "isRequired": true,
              "attributes": []
            },
            "subscription": {
              "name": "subscription",
              "isArray": false,
              "type": "String",
              "isRequired": false,
              "attributes": []
            },
            "subscription_item": {
              "name": "subscription_item",
              "isArray": false,
              "type": "String",
              "isRequired": false,
              "attributes": []
            }
          }
        },
        "InvoiceCustomerAddress": {
          "name": "InvoiceCustomerAddress",
          "fields": {
            "city": {
              "name": "city",
              "isArray": false,
              "type": "String",
              "isRequired": false,
              "attributes": []
            },
            "country": {
              "name": "country",
              "isArray": false,
              "type": "String",
              "isRequired": false,
              "attributes": []
            },
            "line1": {
              "name": "line1",
              "isArray": false,
              "type": "String",
              "isRequired": false,
              "attributes": []
            },
            "line2": {
              "name": "line2",
              "isArray": false,
              "type": "String",
              "isRequired": false,
              "attributes": []
            },
            "postal_code": {
              "name": "postal_code",
              "isArray": false,
              "type": "String",
              "isRequired": false,
              "attributes": []
            },
            "state": {
              "name": "state",
              "isArray": false,
              "type": "String",
              "isRequired": false,
              "attributes": []
            }
          }
        },
        "InvoiceCustomerShipping": {
          "name": "InvoiceCustomerShipping",
          "fields": {
            "address": {
              "name": "address",
              "isArray": false,
              "type": {
                "nonModel": "Address"
              },
              "isRequired": true,
              "attributes": []
            },
            "name": {
              "name": "name",
              "isArray": false,
              "type": "String",
              "isRequired": true,
              "attributes": []
            },
            "phone": {
              "name": "phone",
              "isArray": false,
              "type": "String",
              "isRequired": false,
              "attributes": []
            }
          }
        },
        "InvoiceCustomFields": {
          "name": "InvoiceCustomFields",
          "fields": {
            "name": {
              "name": "name",
              "isArray": false,
              "type": "String",
              "isRequired": true,
              "attributes": []
            },
            "value": {
              "name": "value",
              "isArray": false,
              "type": "String",
              "isRequired": true,
              "attributes": []
            }
          }
        },
        "InvoiceFromInvoice": {
          "name": "InvoiceFromInvoice",
          "fields": {
            "action": {
              "name": "action",
              "isArray": false,
              "type": "String",
              "isRequired": false,
              "attributes": []
            },
            "invoice": {
              "name": "invoice",
              "isArray": false,
              "type": "AWSJSON",
              "isRequired": true,
              "attributes": []
            }
          }
        },
        "InvoiceTransferData": {
          "name": "InvoiceTransferData",
          "fields": {
            "amount": {
              "name": "amount",
              "isArray": false,
              "type": "Int",
              "isRequired": false,
              "attributes": []
            },
            "destination": {
              "name": "destination",
              "isArray": false,
              "type": "AWSJSON",
              "isRequired": false,
              "attributes": []
            }
          }
        },
        "InvoiceTotalDiscountAmounts": {
          "name": "InvoiceTotalDiscountAmounts",
          "fields": {
            "amount": {
              "name": "amount",
              "isArray": false,
              "type": "Int",
              "isRequired": true,
              "attributes": []
            },
            "discount": {
              "name": "discount",
              "isArray": false,
              "type": "AWSJSON",
              "isRequired": false,
              "attributes": []
            }
          }
        },
        "InvoiceTotalPretaxCreditAmounts": {
          "name": "InvoiceTotalPretaxCreditAmounts",
          "fields": {
            "amount": {
              "name": "amount",
              "isArray": false,
              "type": "Int",
              "isRequired": false,
              "attributes": []
            },
            "credit_balance_transaction": {
              "name": "credit_balance_transaction",
              "isArray": false,
              "type": "String",
              "isRequired": false,
              "attributes": []
            },
            "discount": {
              "name": "discount",
              "isArray": false,
              "type": "String",
              "isRequired": false,
              "attributes": []
            },
            "type": {
              "name": "type",
              "isArray": false,
              "type": {
                "enum": "InvoicePretaxCreditEnum"
              },
              "isRequired": false,
              "attributes": []
            }
          }
        },
        "InvoiceTotalTaxAmounts": {
          "name": "InvoiceTotalTaxAmounts",
          "fields": {
            "amount": {
              "name": "amount",
              "isArray": false,
              "type": "Int",
              "isRequired": false,
              "attributes": []
            },
            "inclusive": {
              "name": "inclusive",
              "isArray": false,
              "type": "Boolean",
              "isRequired": false,
              "attributes": []
            },
            "tax_rate": {
              "name": "tax_rate",
              "isArray": false,
              "type": "AWSJSON",
              "isRequired": false,
              "attributes": []
            },
            "taxability_reason": {
              "name": "taxability_reason",
              "isArray": false,
              "type": {
                "enum": "InvoiceTaxabilityReasonEnum"
              },
              "isRequired": false,
              "attributes": []
            },
            "taxable_amount": {
              "name": "taxable_amount",
              "isArray": false,
              "type": "Int",
              "isRequired": false,
              "attributes": []
            }
          }
        },
        "InvoiceMandateOptionsCard": {
          "name": "InvoiceMandateOptionsCard",
          "fields": {
            "amount": {
              "name": "amount",
              "isArray": false,
              "type": "Int",
              "isRequired": false,
              "attributes": []
            },
            "amount_type": {
              "name": "amount_type",
              "isArray": false,
              "type": {
                "enum": "InvoiceMandateOptionsCardAmountTypeEnum"
              },
              "isRequired": false,
              "attributes": []
            },
            "description": {
              "name": "description",
              "isArray": false,
              "type": "String",
              "isRequired": false,
              "attributes": []
            }
          }
        },
        "InvoicePaymentMethodOptionsAcssDebit": {
          "name": "InvoicePaymentMethodOptionsAcssDebit",
          "fields": {
            "mandate_options": {
              "name": "mandate_options",
              "isArray": false,
              "type": {
                "nonModel": "InvoicePaymentMethodOptionsAcssDebitMandateOptions"
              },
              "isRequired": false,
              "attributes": []
            },
            "verification_method": {
              "name": "verification_method",
              "isArray": false,
              "type": {
                "enum": "InvoicePaymentMethodOptionsAcssDebitVerificationMethodEnum"
              },
              "isRequired": false,
              "attributes": []
            }
          }
        },
        "InvoicePaymentMethodOptionsAcssDebitMandateOptions": {
          "name": "InvoicePaymentMethodOptionsAcssDebitMandateOptions",
          "fields": {
            "transaction_type": {
              "name": "transaction_type",
              "isArray": false,
              "type": {
                "enum": "InvoicePaymentMethodOptionsAcssDebitMandateOptionsTransactionTypeEnum"
              },
              "isRequired": false,
              "attributes": []
            }
          }
        },
        "InvoicePaymentMethodOptionsBancontact": {
          "name": "InvoicePaymentMethodOptionsBancontact",
          "fields": {
            "preferred_language": {
              "name": "preferred_language",
              "isArray": false,
              "type": {
                "enum": "InvoicePaymentMethodOptionsBancontactPreferredLanguageEnum"
              },
              "isRequired": true,
              "attributes": []
            }
          }
        },
        "InvoicePaymentMethodOptionsCustomerBalance": {
          "name": "InvoicePaymentMethodOptionsCustomerBalance",
          "fields": {
            "bank_transfer": {
              "name": "bank_transfer",
              "isArray": false,
              "type": {
                "nonModel": "InvoicePaymentMethodOptionsCustomerBalanceBankTransfer"
              },
              "isRequired": false,
              "attributes": []
            },
            "funding_type": {
              "name": "funding_type",
              "isArray": false,
              "type": {
                "enum": "InvoicePaymentMethodOptionsCustomerBalanceFundingTypeEnum"
              },
              "isRequired": false,
              "attributes": []
            }
          }
        },
        "InvoicePaymentMethodOptionsCustomerBalanceBankTransfer": {
          "name": "InvoicePaymentMethodOptionsCustomerBalanceBankTransfer",
          "fields": {
            "eu_bank_transfer": {
              "name": "eu_bank_transfer",
              "isArray": false,
              "type": {
                "nonModel": "InvoicePaymentMethodOptionsCustomerBalanceBankTransferEuBankTransfer"
              },
              "isRequired": false,
              "attributes": []
            },
            "type": {
              "name": "type",
              "isArray": false,
              "type": "String",
              "isRequired": false,
              "attributes": []
            }
          }
        },
        "InvoicePaymentMethodOptionsCustomerBalanceBankTransferEuBankTransfer": {
          "name": "InvoicePaymentMethodOptionsCustomerBalanceBankTransferEuBankTransfer",
          "fields": {
            "country": {
              "name": "country",
              "isArray": false,
              "type": {
                "enum": "InvoicePaymentMethodOptionsCustomerBalanceBankTransferEuBankTransferCountryEnum"
              },
              "isRequired": true,
              "attributes": []
            }
          }
        },
        "InvoicePaymentMethodOptionsUsBankAccount": {
          "name": "InvoicePaymentMethodOptionsUsBankAccount",
          "fields": {
            "financial_connections": {
              "name": "financial_connections",
              "isArray": false,
              "type": {
                "nonModel": "InvoicePaymentMethodOptionsUsBankAccountLinkedAccountOptions"
              },
              "isRequired": false,
              "attributes": []
            },
            "verification_method": {
              "name": "verification_method",
              "isArray": false,
              "type": {
                "enum": "InvoicePaymentMethodOptionsUsBankAccountVerificationMethodEnum"
              },
              "isRequired": false,
              "attributes": []
            }
          }
        },
        "InvoicePaymentMethodOptionsUsBankAccountLinkedAccountOptions": {
          "name": "InvoicePaymentMethodOptionsUsBankAccountLinkedAccountOptions",
          "fields": {
            "filters": {
              "name": "filters",
              "isArray": false,
              "type": {
                "nonModel": "InvoicePaymentMethodOptionsUsBankAccountLinkedAccountOptionsFilters"
              },
              "isRequired": false,
              "attributes": []
            },
            "permissions": {
              "name": "permissions",
              "isArray": true,
              "type": {
                "enum": "InvoicePaymentMethodOptionsUsBankAccountLinkedAccountOptionsPermissionsEnum"
              },
              "isRequired": false,
              "attributes": [],
              "isArrayNullable": true
            },
            "prefetch": {
              "name": "prefetch",
              "isArray": true,
              "type": {
                "enum": "InvoicePaymentMethodOptionsUsBankAccountLinkedAccountOptionsPrefetchEnum"
              },
              "isRequired": false,
              "attributes": [],
              "isArrayNullable": true
            }
          }
        },
        "InvoicePaymentMethodOptionsUsBankAccountLinkedAccountOptionsFilters": {
          "name": "InvoicePaymentMethodOptionsUsBankAccountLinkedAccountOptionsFilters",
          "fields": {
            "account_subcategories": {
              "name": "account_subcategories",
              "isArray": true,
              "type": {
                "enum": "InvoicePaymentMethodOptionsUsBankAccountLinkedAccountOptionsFiltersAccountSubcategoriesEnum"
              },
              "isRequired": false,
              "attributes": [],
              "isArrayNullable": true
            }
          }
        },
        "InvoiceSettingCustomField": {
          "name": "InvoiceSettingCustomField",
          "fields": {
            "name": {
              "name": "name",
              "isArray": false,
              "type": "String",
              "isRequired": true,
              "attributes": []
            },
            "value": {
              "name": "value",
              "isArray": false,
              "type": "String",
              "isRequired": true,
              "attributes": []
            }
          }
        },
        "InvoiceSettingCustomerRenderingOptions": {
          "name": "InvoiceSettingCustomerRenderingOptions",
          "fields": {
            "amount_tax_display": {
              "name": "amount_tax_display",
              "isArray": false,
              "type": "String",
              "isRequired": false,
              "attributes": []
            }
          }
        },
        "InvoiceSettingCustomerSetting": {
          "name": "InvoiceSettingCustomerSetting",
          "fields": {
            "custom_fields": {
              "name": "custom_fields",
              "isArray": true,
              "type": {
                "nonModel": "InvoiceSettingCustomField"
              },
              "isRequired": false,
              "attributes": [],
              "isArrayNullable": true
            },
            "default_payment_method": {
              "name": "default_payment_method",
              "isArray": false,
              "type": "AWSJSON",
              "isRequired": false,
              "attributes": []
            },
            "footer": {
              "name": "footer",
              "isArray": false,
              "type": "String",
              "isRequired": false,
              "attributes": []
            },
            "rendering_options": {
              "name": "rendering_options",
              "isArray": false,
              "type": {
                "nonModel": "InvoiceSettingCustomerRenderingOptions"
              },
              "isRequired": false,
              "attributes": []
            }
          }
        },
        "InvoiceSettingRenderingOptions": {
          "name": "InvoiceSettingRenderingOptions",
          "fields": {
            "amount_tax_display": {
              "name": "amount_tax_display",
              "isArray": false,
              "type": "String",
              "isRequired": false,
              "attributes": []
            }
          }
        },
        "Item": {
          "name": "Item",
          "fields": {
            "amount_discount": {
              "name": "amount_discount",
              "isArray": false,
              "type": "Int",
              "isRequired": true,
              "attributes": []
            },
            "amount_subtotal": {
              "name": "amount_subtotal",
              "isArray": false,
              "type": "Int",
              "isRequired": true,
              "attributes": []
            },
            "amount_tax": {
              "name": "amount_tax",
              "isArray": false,
              "type": "Int",
              "isRequired": true,
              "attributes": []
            },
            "amount_total": {
              "name": "amount_total",
              "isArray": false,
              "type": "Int",
              "isRequired": true,
              "attributes": []
            },
            "currency": {
              "name": "currency",
              "isArray": false,
              "type": "String",
              "isRequired": true,
              "attributes": []
            },
            "description": {
              "name": "description",
              "isArray": false,
              "type": "String",
              "isRequired": true,
              "attributes": []
            },
            "discounts": {
              "name": "discounts",
              "isArray": true,
              "type": {
                "nonModel": "LineItemsDiscountAmount"
              },
              "isRequired": false,
              "attributes": [],
              "isArrayNullable": true
            },
            "id": {
              "name": "id",
              "isArray": false,
              "type": "String",
              "isRequired": true,
              "attributes": []
            },
            "object": {
              "name": "object",
              "isArray": false,
              "type": {
                "enum": "ItemObject"
              },
              "isRequired": false,
              "attributes": []
            },
            "price": {
              "name": "price",
              "isArray": false,
              "type": {
                "nonModel": "Price"
              },
              "isRequired": false,
              "attributes": []
            },
            "quantity": {
              "name": "quantity",
              "isArray": false,
              "type": "Int",
              "isRequired": false,
              "attributes": []
            },
            "taxes": {
              "name": "taxes",
              "isArray": true,
              "type": {
                "nonModel": "LineItemsTaxAmount"
              },
              "isRequired": false,
              "attributes": [],
              "isArrayNullable": true
            }
          }
        },
        "LineItemsDiscountAmount": {
          "name": "LineItemsDiscountAmount",
          "fields": {
            "amount": {
              "name": "amount",
              "isArray": false,
              "type": "Int",
              "isRequired": true,
              "attributes": []
            },
            "discount": {
              "name": "discount",
              "isArray": false,
              "type": {
                "nonModel": "Discount"
              },
              "isRequired": true,
              "attributes": []
            }
          }
        },
        "LineItemsTaxAmount": {
          "name": "LineItemsTaxAmount",
          "fields": {
            "amount": {
              "name": "amount",
              "isArray": false,
              "type": "Int",
              "isRequired": true,
              "attributes": []
            },
            "rate": {
              "name": "rate",
              "isArray": false,
              "type": {
                "nonModel": "TaxRate"
              },
              "isRequired": true,
              "attributes": []
            },
            "taxability_reason": {
              "name": "taxability_reason",
              "isArray": false,
              "type": {
                "enum": "LineItemsTaxAmountTaxabilityReasonEnum"
              },
              "isRequired": false,
              "attributes": []
            },
            "taxable_amount": {
              "name": "taxable_amount",
              "isArray": false,
              "type": "Int",
              "isRequired": false,
              "attributes": []
            }
          }
        },
        "LinkedAccountOptionsUsBankAccount": {
          "name": "LinkedAccountOptionsUsBankAccount",
          "fields": {
            "filters": {
              "name": "filters",
              "isArray": false,
              "type": {
                "nonModel": "PaymentFlowsPrivatePaymentMethodsUsBankAccountLinkedAccountOptionsFilters"
              },
              "isRequired": false,
              "attributes": []
            },
            "permissions": {
              "name": "permissions",
              "isArray": true,
              "type": {
                "enum": "LinkedAccountOptionsUsBankAccountPermissionsEnum"
              },
              "isRequired": false,
              "attributes": [],
              "isArrayNullable": true
            },
            "prefetch": {
              "name": "prefetch",
              "isArray": true,
              "type": {
                "enum": "LinkedAccountOptionsUsBankAccountPrefetchEnum"
              },
              "isRequired": false,
              "attributes": [],
              "isArrayNullable": true
            },
            "return_url": {
              "name": "return_url",
              "isArray": false,
              "type": "String",
              "isRequired": false,
              "attributes": []
            }
          }
        },
        "Networks": {
          "name": "Networks",
          "fields": {
            "available": {
              "name": "available",
              "isArray": true,
              "type": "String",
              "isRequired": false,
              "attributes": [],
              "isArrayNullable": false
            },
            "preferred": {
              "name": "preferred",
              "isArray": false,
              "type": "String",
              "isRequired": false,
              "attributes": []
            }
          }
        },
        "PackageDimensions": {
          "name": "PackageDimensions",
          "fields": {
            "height": {
              "name": "height",
              "isArray": false,
              "type": "Float",
              "isRequired": true,
              "attributes": []
            },
            "length": {
              "name": "length",
              "isArray": false,
              "type": "Float",
              "isRequired": true,
              "attributes": []
            },
            "weight": {
              "name": "weight",
              "isArray": false,
              "type": "Float",
              "isRequired": true,
              "attributes": []
            },
            "width": {
              "name": "width",
              "isArray": false,
              "type": "Float",
              "isRequired": true,
              "attributes": []
            }
          }
        },
        "PaymentFlowsPrivatePaymentMethodsKlarnaDob": {
          "name": "PaymentFlowsPrivatePaymentMethodsKlarnaDob",
          "fields": {
            "day": {
              "name": "day",
              "isArray": false,
              "type": "Int",
              "isRequired": false,
              "attributes": []
            },
            "month": {
              "name": "month",
              "isArray": false,
              "type": "Int",
              "isRequired": false,
              "attributes": []
            },
            "year": {
              "name": "year",
              "isArray": false,
              "type": "Int",
              "isRequired": false,
              "attributes": []
            }
          }
        },
        "PaymentFlowsPrivatePaymentMethodsUsBankAccountLinkedAccountOptionsFilters": {
          "name": "PaymentFlowsPrivatePaymentMethodsUsBankAccountLinkedAccountOptionsFilters",
          "fields": {
            "account_subcategories": {
              "name": "account_subcategories",
              "isArray": true,
              "type": {
                "enum": "PaymentFlowsPrivatePaymentMethodsUsBankAccountLinkedAccountOptionsFiltersAccountSubcategoriesEnum"
              },
              "isRequired": false,
              "attributes": [],
              "isArrayNullable": true
            }
          }
        },
        "PaymentMethodAcssDebit": {
          "name": "PaymentMethodAcssDebit",
          "fields": {
            "bank_name": {
              "name": "bank_name",
              "isArray": false,
              "type": "String",
              "isRequired": false,
              "attributes": []
            },
            "fingerprint": {
              "name": "fingerprint",
              "isArray": false,
              "type": "String",
              "isRequired": false,
              "attributes": []
            },
            "institution_number": {
              "name": "institution_number",
              "isArray": false,
              "type": "String",
              "isRequired": false,
              "attributes": []
            },
            "last4": {
              "name": "last4",
              "isArray": false,
              "type": "String",
              "isRequired": false,
              "attributes": []
            },
            "transit_number": {
              "name": "transit_number",
              "isArray": false,
              "type": "String",
              "isRequired": false,
              "attributes": []
            }
          }
        },
        "PaymentMethodAuBecsDebit": {
          "name": "PaymentMethodAuBecsDebit",
          "fields": {
            "bsb_number": {
              "name": "bsb_number",
              "isArray": false,
              "type": "String",
              "isRequired": false,
              "attributes": []
            },
            "fingerprint": {
              "name": "fingerprint",
              "isArray": false,
              "type": "String",
              "isRequired": false,
              "attributes": []
            },
            "last4": {
              "name": "last4",
              "isArray": false,
              "type": "String",
              "isRequired": false,
              "attributes": []
            }
          }
        },
        "PaymentMethodBacsDebit": {
          "name": "PaymentMethodBacsDebit",
          "fields": {
            "fingerprint": {
              "name": "fingerprint",
              "isArray": false,
              "type": "String",
              "isRequired": false,
              "attributes": []
            },
            "last4": {
              "name": "last4",
              "isArray": false,
              "type": "String",
              "isRequired": false,
              "attributes": []
            },
            "sort_code": {
              "name": "sort_code",
              "isArray": false,
              "type": "String",
              "isRequired": false,
              "attributes": []
            }
          }
        },
        "PaymentMethodBoleto": {
          "name": "PaymentMethodBoleto",
          "fields": {
            "tax_id": {
              "name": "tax_id",
              "isArray": false,
              "type": "String",
              "isRequired": true,
              "attributes": []
            }
          }
        },
        "PaymentMethodCard": {
          "name": "PaymentMethodCard",
          "fields": {
            "brand": {
              "name": "brand",
              "isArray": false,
              "type": "String",
              "isRequired": true,
              "attributes": []
            },
            "checks": {
              "name": "checks",
              "isArray": false,
              "type": {
                "nonModel": "PaymentMethodCardChecks"
              },
              "isRequired": false,
              "attributes": []
            },
            "country": {
              "name": "country",
              "isArray": false,
              "type": "String",
              "isRequired": false,
              "attributes": []
            },
            "display_brand": {
              "name": "display_brand",
              "isArray": false,
              "type": "String",
              "isRequired": false,
              "attributes": []
            },
            "exp_month": {
              "name": "exp_month",
              "isArray": false,
              "type": "Int",
              "isRequired": true,
              "attributes": []
            },
            "exp_year": {
              "name": "exp_year",
              "isArray": false,
              "type": "Int",
              "isRequired": true,
              "attributes": []
            },
            "fingerprint": {
              "name": "fingerprint",
              "isArray": false,
              "type": "String",
              "isRequired": false,
              "attributes": []
            },
            "funding": {
              "name": "funding",
              "isArray": false,
              "type": "String",
              "isRequired": true,
              "attributes": []
            },
            "generated_from": {
              "name": "generated_from",
              "isArray": false,
              "type": {
                "nonModel": "PaymentMethodCardGeneratedCard"
              },
              "isRequired": false,
              "attributes": []
            },
            "last4": {
              "name": "last4",
              "isArray": false,
              "type": "String",
              "isRequired": true,
              "attributes": []
            },
            "networks": {
              "name": "networks",
              "isArray": false,
              "type": {
                "nonModel": "Networks"
              },
              "isRequired": false,
              "attributes": []
            },
            "three_d_secure_usage": {
              "name": "three_d_secure_usage",
              "isArray": false,
              "type": {
                "nonModel": "ThreeDSecureUsage"
              },
              "isRequired": false,
              "attributes": []
            },
            "wallet": {
              "name": "wallet",
              "isArray": false,
              "type": {
                "nonModel": "PaymentMethodCardWallet"
              },
              "isRequired": false,
              "attributes": []
            }
          }
        },
        "PaymentMethodCardChecks": {
          "name": "PaymentMethodCardChecks",
          "fields": {
            "address_line1_check": {
              "name": "address_line1_check",
              "isArray": false,
              "type": "String",
              "isRequired": false,
              "attributes": []
            },
            "address_postal_code_check": {
              "name": "address_postal_code_check",
              "isArray": false,
              "type": "String",
              "isRequired": false,
              "attributes": []
            },
            "cvc_check": {
              "name": "cvc_check",
              "isArray": false,
              "type": "String",
              "isRequired": false,
              "attributes": []
            }
          }
        },
        "PaymentMethodCardGeneratedCard": {
          "name": "PaymentMethodCardGeneratedCard",
          "fields": {
            "charge": {
              "name": "charge",
              "isArray": false,
              "type": "String",
              "isRequired": false,
              "attributes": []
            },
            "payment_method_details": {
              "name": "payment_method_details",
              "isArray": false,
              "type": {
                "nonModel": "CardGeneratedFromPaymentMethodDetails"
              },
              "isRequired": false,
              "attributes": []
            },
            "setup_attempt": {
              "name": "setup_attempt",
              "isArray": false,
              "type": "AWSJSON",
              "isRequired": false,
              "attributes": []
            }
          }
        },
        "PaymentMethodCardPresent": {
          "name": "PaymentMethodCardPresent",
          "fields": {
            "brand": {
              "name": "brand",
              "isArray": false,
              "type": "String",
              "isRequired": false,
              "attributes": []
            },
            "cardholder_name": {
              "name": "cardholder_name",
              "isArray": false,
              "type": "String",
              "isRequired": false,
              "attributes": []
            },
            "country": {
              "name": "country",
              "isArray": false,
              "type": "String",
              "isRequired": false,
              "attributes": []
            },
            "exp_month": {
              "name": "exp_month",
              "isArray": false,
              "type": "Int",
              "isRequired": true,
              "attributes": []
            },
            "exp_year": {
              "name": "exp_year",
              "isArray": false,
              "type": "Int",
              "isRequired": true,
              "attributes": []
            },
            "fingerprint": {
              "name": "fingerprint",
              "isArray": false,
              "type": "String",
              "isRequired": false,
              "attributes": []
            },
            "funding": {
              "name": "funding",
              "isArray": false,
              "type": "String",
              "isRequired": false,
              "attributes": []
            },
            "last4": {
              "name": "last4",
              "isArray": false,
              "type": "String",
              "isRequired": false,
              "attributes": []
            },
            "networks": {
              "name": "networks",
              "isArray": false,
              "type": {
                "nonModel": "PaymentMethodCardPresentNetworks"
              },
              "isRequired": false,
              "attributes": []
            },
            "preferred_locales": {
              "name": "preferred_locales",
              "isArray": true,
              "type": "String",
              "isRequired": false,
              "attributes": [],
              "isArrayNullable": true
            },
            "read_method": {
              "name": "read_method",
              "isArray": false,
              "type": {
                "enum": "PaymentMethodCardPresentReadMethodEnum"
              },
              "isRequired": false,
              "attributes": []
            }
          }
        },
        "PaymentMethodCardPresentNetworks": {
          "name": "PaymentMethodCardPresentNetworks",
          "fields": {
            "available": {
              "name": "available",
              "isArray": true,
              "type": "String",
              "isRequired": false,
              "attributes": [],
              "isArrayNullable": false
            },
            "preferred": {
              "name": "preferred",
              "isArray": false,
              "type": "String",
              "isRequired": false,
              "attributes": []
            }
          }
        },
        "PaymentMethodCardWallet": {
          "name": "PaymentMethodCardWallet",
          "fields": {
            "amex_express_checkout": {
              "name": "amex_express_checkout",
              "isArray": false,
              "type": "AWSJSON",
              "isRequired": false,
              "attributes": []
            },
            "apple_pay": {
              "name": "apple_pay",
              "isArray": false,
              "type": "AWSJSON",
              "isRequired": false,
              "attributes": []
            },
            "dynamic_last4": {
              "name": "dynamic_last4",
              "isArray": false,
              "type": "String",
              "isRequired": false,
              "attributes": []
            },
            "google_pay": {
              "name": "google_pay",
              "isArray": false,
              "type": "AWSJSON",
              "isRequired": false,
              "attributes": []
            },
            "link": {
              "name": "link",
              "isArray": false,
              "type": "AWSJSON",
              "isRequired": false,
              "attributes": []
            },
            "masterpass": {
              "name": "masterpass",
              "isArray": false,
              "type": {
                "nonModel": "PaymentMethodCardWalletMasterpass"
              },
              "isRequired": false,
              "attributes": []
            },
            "samsung_pay": {
              "name": "samsung_pay",
              "isArray": false,
              "type": "AWSJSON",
              "isRequired": false,
              "attributes": []
            },
            "type": {
              "name": "type",
              "isArray": false,
              "type": {
                "enum": "PaymentMethodCardWalletTypeEnum"
              },
              "isRequired": true,
              "attributes": []
            },
            "visa_checkout": {
              "name": "visa_checkout",
              "isArray": false,
              "type": {
                "nonModel": "PaymentMethodCardWalletVisaCheckout"
              },
              "isRequired": false,
              "attributes": []
            }
          }
        },
        "PaymentMethodCardWalletMasterpass": {
          "name": "PaymentMethodCardWalletMasterpass",
          "fields": {
            "billing_address": {
              "name": "billing_address",
              "isArray": false,
              "type": {
                "nonModel": "Address"
              },
              "isRequired": false,
              "attributes": []
            },
            "email": {
              "name": "email",
              "isArray": false,
              "type": "String",
              "isRequired": false,
              "attributes": []
            },
            "name": {
              "name": "name",
              "isArray": false,
              "type": "String",
              "isRequired": false,
              "attributes": []
            },
            "shipping_address": {
              "name": "shipping_address",
              "isArray": false,
              "type": {
                "nonModel": "Address"
              },
              "isRequired": false,
              "attributes": []
            }
          }
        },
        "PaymentMethodCardWalletVisaCheckout": {
          "name": "PaymentMethodCardWalletVisaCheckout",
          "fields": {
            "billing_address": {
              "name": "billing_address",
              "isArray": false,
              "type": {
                "nonModel": "Address"
              },
              "isRequired": false,
              "attributes": []
            },
            "email": {
              "name": "email",
              "isArray": false,
              "type": "String",
              "isRequired": false,
              "attributes": []
            },
            "name": {
              "name": "name",
              "isArray": false,
              "type": "String",
              "isRequired": false,
              "attributes": []
            },
            "shipping_address": {
              "name": "shipping_address",
              "isArray": false,
              "type": {
                "nonModel": "Address"
              },
              "isRequired": false,
              "attributes": []
            }
          }
        },
        "PaymentMethodCashapp": {
          "name": "PaymentMethodCashapp",
          "fields": {
            "buyer_id": {
              "name": "buyer_id",
              "isArray": false,
              "type": "String",
              "isRequired": false,
              "attributes": []
            },
            "cashtag": {
              "name": "cashtag",
              "isArray": false,
              "type": "String",
              "isRequired": false,
              "attributes": []
            }
          }
        },
        "PaymentMethodConfigBizPaymentMethodConfigurationDetails": {
          "name": "PaymentMethodConfigBizPaymentMethodConfigurationDetails",
          "fields": {
            "id": {
              "name": "id",
              "isArray": false,
              "type": "String",
              "isRequired": true,
              "attributes": []
            },
            "parent": {
              "name": "parent",
              "isArray": false,
              "type": "String",
              "isRequired": false,
              "attributes": []
            }
          }
        },
        "PaymentMethodDetailsCardPresent": {
          "name": "PaymentMethodDetailsCardPresent",
          "fields": {
            "amount_authorized": {
              "name": "amount_authorized",
              "isArray": false,
              "type": "Int",
              "isRequired": false,
              "attributes": []
            },
            "brand": {
              "name": "brand",
              "isArray": false,
              "type": "String",
              "isRequired": false,
              "attributes": []
            },
            "capture_before": {
              "name": "capture_before",
              "isArray": false,
              "type": "AWSTimestamp",
              "isRequired": false,
              "attributes": []
            },
            "cardholder_name": {
              "name": "cardholder_name",
              "isArray": false,
              "type": "String",
              "isRequired": false,
              "attributes": []
            },
            "country": {
              "name": "country",
              "isArray": false,
              "type": "String",
              "isRequired": false,
              "attributes": []
            },
            "emv_auth_data": {
              "name": "emv_auth_data",
              "isArray": false,
              "type": "String",
              "isRequired": false,
              "attributes": []
            },
            "exp_month": {
              "name": "exp_month",
              "isArray": false,
              "type": "Int",
              "isRequired": true,
              "attributes": []
            },
            "exp_year": {
              "name": "exp_year",
              "isArray": false,
              "type": "Int",
              "isRequired": true,
              "attributes": []
            },
            "fingerprint": {
              "name": "fingerprint",
              "isArray": false,
              "type": "String",
              "isRequired": false,
              "attributes": []
            },
            "funding": {
              "name": "funding",
              "isArray": false,
              "type": "String",
              "isRequired": false,
              "attributes": []
            },
            "generated_card": {
              "name": "generated_card",
              "isArray": false,
              "type": "String",
              "isRequired": false,
              "attributes": []
            },
            "incremental_authorization_supported": {
              "name": "incremental_authorization_supported",
              "isArray": false,
              "type": "Boolean",
              "isRequired": true,
              "attributes": []
            },
            "last4": {
              "name": "last4",
              "isArray": false,
              "type": "String",
              "isRequired": false,
              "attributes": []
            },
            "network": {
              "name": "network",
              "isArray": false,
              "type": "String",
              "isRequired": false,
              "attributes": []
            },
            "offline": {
              "name": "offline",
              "isArray": false,
              "type": {
                "nonModel": "PaymentMethodDetailsCardPresentOffline"
              },
              "isRequired": false,
              "attributes": []
            },
            "overcapture_supported": {
              "name": "overcapture_supported",
              "isArray": false,
              "type": "Boolean",
              "isRequired": true,
              "attributes": []
            },
            "preferred_locales": {
              "name": "preferred_locales",
              "isArray": true,
              "type": "String",
              "isRequired": false,
              "attributes": [],
              "isArrayNullable": true
            },
            "read_method": {
              "name": "read_method",
              "isArray": false,
              "type": {
                "enum": "PaymentMethodDetailsCardPresentReadMethodEnum"
              },
              "isRequired": false,
              "attributes": []
            },
            "receipt": {
              "name": "receipt",
              "isArray": false,
              "type": {
                "nonModel": "PaymentMethodDetailsCardPresentReceipt"
              },
              "isRequired": false,
              "attributes": []
            }
          }
        },
        "PaymentMethodDetailsCardPresentOffline": {
          "name": "PaymentMethodDetailsCardPresentOffline",
          "fields": {
            "stored_at": {
              "name": "stored_at",
              "isArray": false,
              "type": "AWSTimestamp",
              "isRequired": false,
              "attributes": []
            }
          }
        },
        "PaymentMethodDetailsCardPresentReceipt": {
          "name": "PaymentMethodDetailsCardPresentReceipt",
          "fields": {
            "account_type": {
              "name": "account_type",
              "isArray": false,
              "type": {
                "enum": "PaymentMethodDetailsCardPresentReceiptAccountTypeEnum"
              },
              "isRequired": false,
              "attributes": []
            },
            "application_cryptogram": {
              "name": "application_cryptogram",
              "isArray": false,
              "type": "String",
              "isRequired": false,
              "attributes": []
            },
            "application_preferred_name": {
              "name": "application_preferred_name",
              "isArray": false,
              "type": "String",
              "isRequired": false,
              "attributes": []
            },
            "authorization_code": {
              "name": "authorization_code",
              "isArray": false,
              "type": "String",
              "isRequired": false,
              "attributes": []
            },
            "authorization_response_code": {
              "name": "authorization_response_code",
              "isArray": false,
              "type": "String",
              "isRequired": false,
              "attributes": []
            },
            "cardholder_verification_method": {
              "name": "cardholder_verification_method",
              "isArray": false,
              "type": "String",
              "isRequired": false,
              "attributes": []
            },
            "dedicated_file_name": {
              "name": "dedicated_file_name",
              "isArray": false,
              "type": "String",
              "isRequired": false,
              "attributes": []
            },
            "terminal_verification_results": {
              "name": "terminal_verification_results",
              "isArray": false,
              "type": "String",
              "isRequired": false,
              "attributes": []
            },
            "transaction_status_information": {
              "name": "transaction_status_information",
              "isArray": false,
              "type": "String",
              "isRequired": false,
              "attributes": []
            }
          }
        },
        "PaymentMethodEps": {
          "name": "PaymentMethodEps",
          "fields": {
            "bank": {
              "name": "bank",
              "isArray": false,
              "type": {
                "enum": "PaymentMethodEpsBankEnum"
              },
              "isRequired": false,
              "attributes": []
            }
          }
        },
        "PaymentMethodFpx": {
          "name": "PaymentMethodFpx",
          "fields": {
            "bank": {
              "name": "bank",
              "isArray": false,
              "type": {
                "enum": "PaymentMethodFpxBankEnum"
              },
              "isRequired": true,
              "attributes": []
            }
          }
        },
        "PaymentMethodIdeal": {
          "name": "PaymentMethodIdeal",
          "fields": {
            "bank": {
              "name": "bank",
              "isArray": false,
              "type": {
                "enum": "PaymentMethodIdealBankEnum"
              },
              "isRequired": false,
              "attributes": []
            },
            "bic": {
              "name": "bic",
              "isArray": false,
              "type": {
                "enum": "PaymentMethodIdealBicEnum"
              },
              "isRequired": false,
              "attributes": []
            }
          }
        },
        "PaymentMethodInteracPresent": {
          "name": "PaymentMethodInteracPresent",
          "fields": {
            "brand": {
              "name": "brand",
              "isArray": false,
              "type": "String",
              "isRequired": false,
              "attributes": []
            },
            "cardholder_name": {
              "name": "cardholder_name",
              "isArray": false,
              "type": "String",
              "isRequired": false,
              "attributes": []
            },
            "country": {
              "name": "country",
              "isArray": false,
              "type": "String",
              "isRequired": false,
              "attributes": []
            },
            "exp_month": {
              "name": "exp_month",
              "isArray": false,
              "type": "Int",
              "isRequired": true,
              "attributes": []
            },
            "exp_year": {
              "name": "exp_year",
              "isArray": false,
              "type": "Int",
              "isRequired": true,
              "attributes": []
            },
            "fingerprint": {
              "name": "fingerprint",
              "isArray": false,
              "type": "String",
              "isRequired": false,
              "attributes": []
            },
            "funding": {
              "name": "funding",
              "isArray": false,
              "type": "String",
              "isRequired": false,
              "attributes": []
            },
            "last4": {
              "name": "last4",
              "isArray": false,
              "type": "String",
              "isRequired": false,
              "attributes": []
            },
            "networks": {
              "name": "networks",
              "isArray": false,
              "type": {
                "nonModel": "PaymentMethodCardPresentNetworks"
              },
              "isRequired": false,
              "attributes": []
            },
            "preferred_locales": {
              "name": "preferred_locales",
              "isArray": true,
              "type": "String",
              "isRequired": false,
              "attributes": [],
              "isArrayNullable": true
            },
            "read_method": {
              "name": "read_method",
              "isArray": false,
              "type": {
                "enum": "PaymentMethodInteracPresentReadMethodEnum"
              },
              "isRequired": false,
              "attributes": []
            }
          }
        },
        "PaymentMethodKlarna": {
          "name": "PaymentMethodKlarna",
          "fields": {
            "dob": {
              "name": "dob",
              "isArray": false,
              "type": {
                "nonModel": "PaymentFlowsPrivatePaymentMethodsKlarnaDob"
              },
              "isRequired": false,
              "attributes": []
            }
          }
        },
        "PaymentMethodLink": {
          "name": "PaymentMethodLink",
          "fields": {
            "email": {
              "name": "email",
              "isArray": false,
              "type": "String",
              "isRequired": false,
              "attributes": []
            }
          }
        },
        "PaymentMethodOptionsCustomerBalanceEuBankAccount": {
          "name": "PaymentMethodOptionsCustomerBalanceEuBankAccount",
          "fields": {
            "country": {
              "name": "country",
              "isArray": false,
              "type": {
                "enum": "PaymentMethodOptionsCustomerBalanceEuBankAccountCountryEnum"
              },
              "isRequired": true,
              "attributes": []
            }
          }
        },
        "PaymentMethodP24": {
          "name": "PaymentMethodP24",
          "fields": {
            "bank": {
              "name": "bank",
              "isArray": false,
              "type": {
                "enum": "PaymentMethodP24BankEnum"
              },
              "isRequired": false,
              "attributes": []
            }
          }
        },
        "PaymentMethodPaypal": {
          "name": "PaymentMethodPaypal",
          "fields": {
            "payer_email": {
              "name": "payer_email",
              "isArray": false,
              "type": "String",
              "isRequired": false,
              "attributes": []
            },
            "payer_id": {
              "name": "payer_id",
              "isArray": false,
              "type": "String",
              "isRequired": false,
              "attributes": []
            }
          }
        },
        "PaymentMethodSepaDebit": {
          "name": "PaymentMethodSepaDebit",
          "fields": {
            "bank_code": {
              "name": "bank_code",
              "isArray": false,
              "type": "String",
              "isRequired": false,
              "attributes": []
            },
            "branch_code": {
              "name": "branch_code",
              "isArray": false,
              "type": "String",
              "isRequired": false,
              "attributes": []
            },
            "country": {
              "name": "country",
              "isArray": false,
              "type": "String",
              "isRequired": false,
              "attributes": []
            },
            "fingerprint": {
              "name": "fingerprint",
              "isArray": false,
              "type": "String",
              "isRequired": false,
              "attributes": []
            },
            "generated_from": {
              "name": "generated_from",
              "isArray": false,
              "type": {
                "nonModel": "SepaDebitGeneratedFrom"
              },
              "isRequired": false,
              "attributes": []
            },
            "last4": {
              "name": "last4",
              "isArray": false,
              "type": "String",
              "isRequired": false,
              "attributes": []
            }
          }
        },
        "PaymentMethodSofort": {
          "name": "PaymentMethodSofort",
          "fields": {
            "country": {
              "name": "country",
              "isArray": false,
              "type": "String",
              "isRequired": false,
              "attributes": []
            }
          }
        },
        "PaymentMethodUsBankAccount": {
          "name": "PaymentMethodUsBankAccount",
          "fields": {
            "account_holder_type": {
              "name": "account_holder_type",
              "isArray": false,
              "type": {
                "enum": "PaymentMethodUsBankAccountAccountHolderTypeEnum"
              },
              "isRequired": false,
              "attributes": []
            },
            "account_type": {
              "name": "account_type",
              "isArray": false,
              "type": {
                "enum": "PaymentMethodUsBankAccountAccountTypeEnum"
              },
              "isRequired": false,
              "attributes": []
            },
            "bank_name": {
              "name": "bank_name",
              "isArray": false,
              "type": "String",
              "isRequired": false,
              "attributes": []
            },
            "financial_connections_account": {
              "name": "financial_connections_account",
              "isArray": false,
              "type": "String",
              "isRequired": false,
              "attributes": []
            },
            "fingerprint": {
              "name": "fingerprint",
              "isArray": false,
              "type": "String",
              "isRequired": false,
              "attributes": []
            },
            "last4": {
              "name": "last4",
              "isArray": false,
              "type": "String",
              "isRequired": false,
              "attributes": []
            },
            "networks": {
              "name": "networks",
              "isArray": false,
              "type": {
                "nonModel": "UsBankAccountNetworks"
              },
              "isRequired": false,
              "attributes": []
            },
            "routing_number": {
              "name": "routing_number",
              "isArray": false,
              "type": "String",
              "isRequired": false,
              "attributes": []
            },
            "status_details": {
              "name": "status_details",
              "isArray": false,
              "type": {
                "nonModel": "PaymentMethodUsBankAccountStatusDetails"
              },
              "isRequired": false,
              "attributes": []
            }
          }
        },
        "PaymentMethodUsBankAccountBlocked": {
          "name": "PaymentMethodUsBankAccountBlocked",
          "fields": {
            "network_code": {
              "name": "network_code",
              "isArray": false,
              "type": {
                "enum": "PaymentMethodUsBankAccountBlockedNetworkCodeEnum"
              },
              "isRequired": false,
              "attributes": []
            },
            "reason": {
              "name": "reason",
              "isArray": false,
              "type": {
                "enum": "PaymentMethodUsBankAccountBlockedReasonEnum"
              },
              "isRequired": false,
              "attributes": []
            }
          }
        },
        "PaymentMethodUsBankAccountStatusDetails": {
          "name": "PaymentMethodUsBankAccountStatusDetails",
          "fields": {
            "blocked": {
              "name": "blocked",
              "isArray": false,
              "type": {
                "nonModel": "PaymentMethodUsBankAccountBlocked"
              },
              "isRequired": false,
              "attributes": []
            }
          }
        },
        "PaymentPagesCheckoutSessionAfterExpiration": {
          "name": "PaymentPagesCheckoutSessionAfterExpiration",
          "fields": {
            "recovery": {
              "name": "recovery",
              "isArray": false,
              "type": {
                "nonModel": "PaymentPagesCheckoutSessionAfterExpirationRecovery"
              },
              "isRequired": false,
              "attributes": []
            }
          }
        },
        "PaymentPagesCheckoutSessionAfterExpirationRecovery": {
          "name": "PaymentPagesCheckoutSessionAfterExpirationRecovery",
          "fields": {
            "allow_promotion_codes": {
              "name": "allow_promotion_codes",
              "isArray": false,
              "type": "Boolean",
              "isRequired": true,
              "attributes": []
            },
            "enabled": {
              "name": "enabled",
              "isArray": false,
              "type": "Boolean",
              "isRequired": true,
              "attributes": []
            },
            "expires_at": {
              "name": "expires_at",
              "isArray": false,
              "type": "AWSTimestamp",
              "isRequired": false,
              "attributes": []
            },
            "url": {
              "name": "url",
              "isArray": false,
              "type": "String",
              "isRequired": false,
              "attributes": []
            }
          }
        },
        "PaymentPagesCheckoutSessionAutomaticTax": {
          "name": "PaymentPagesCheckoutSessionAutomaticTax",
          "fields": {
            "enabled": {
              "name": "enabled",
              "isArray": false,
              "type": "Boolean",
              "isRequired": true,
              "attributes": []
            },
            "liability": {
              "name": "liability",
              "isArray": false,
              "type": {
                "nonModel": "ConnectAccountReference"
              },
              "isRequired": false,
              "attributes": []
            },
            "status": {
              "name": "status",
              "isArray": false,
              "type": {
                "enum": "PaymentPagesCheckoutSessionAutomaticTaxStatusEnum"
              },
              "isRequired": false,
              "attributes": []
            }
          }
        },
        "PaymentPagesCheckoutSessionConsent": {
          "name": "PaymentPagesCheckoutSessionConsent",
          "fields": {
            "promotions": {
              "name": "promotions",
              "isArray": false,
              "type": {
                "enum": "PaymentPagesCheckoutSessionConsentPromotionsEnum"
              },
              "isRequired": false,
              "attributes": []
            },
            "terms_of_service": {
              "name": "terms_of_service",
              "isArray": false,
              "type": {
                "enum": "PaymentPagesCheckoutSessionConsentTermsOfServiceEnum"
              },
              "isRequired": false,
              "attributes": []
            }
          }
        },
        "PaymentPagesCheckoutSessionConsentCollection": {
          "name": "PaymentPagesCheckoutSessionConsentCollection",
          "fields": {
            "payment_method_reuse_agreement": {
              "name": "payment_method_reuse_agreement",
              "isArray": false,
              "type": {
                "nonModel": "PaymentPagesCheckoutSessionPaymentMethodReuseAgreement"
              },
              "isRequired": false,
              "attributes": []
            },
            "promotions": {
              "name": "promotions",
              "isArray": false,
              "type": {
                "enum": "PaymentPagesCheckoutSessionConsentCollectionPromotionsEnum"
              },
              "isRequired": false,
              "attributes": []
            },
            "terms_of_service": {
              "name": "terms_of_service",
              "isArray": false,
              "type": {
                "enum": "PaymentPagesCheckoutSessionConsentCollectionTermsOfServiceEnum"
              },
              "isRequired": false,
              "attributes": []
            }
          }
        },
        "PaymentPagesCheckoutSessionCurrencyConversion": {
          "name": "PaymentPagesCheckoutSessionCurrencyConversion",
          "fields": {
            "amount_subtotal": {
              "name": "amount_subtotal",
              "isArray": false,
              "type": "Int",
              "isRequired": true,
              "attributes": []
            },
            "amount_total": {
              "name": "amount_total",
              "isArray": false,
              "type": "Int",
              "isRequired": true,
              "attributes": []
            },
            "fx_rate": {
              "name": "fx_rate",
              "isArray": false,
              "type": "String",
              "isRequired": true,
              "attributes": []
            },
            "source_currency": {
              "name": "source_currency",
              "isArray": false,
              "type": "String",
              "isRequired": true,
              "attributes": []
            }
          }
        },
        "PaymentPagesCheckoutSessionCustomFields": {
          "name": "PaymentPagesCheckoutSessionCustomFields",
          "fields": {
            "dropdown": {
              "name": "dropdown",
              "isArray": false,
              "type": {
                "nonModel": "PaymentPagesCheckoutSessionCustomFieldsDropdown"
              },
              "isRequired": false,
              "attributes": []
            },
            "key": {
              "name": "key",
              "isArray": false,
              "type": "String",
              "isRequired": true,
              "attributes": []
            },
            "label": {
              "name": "label",
              "isArray": false,
              "type": {
                "nonModel": "PaymentPagesCheckoutSessionCustomFieldsLabel"
              },
              "isRequired": true,
              "attributes": []
            },
            "numeric": {
              "name": "numeric",
              "isArray": false,
              "type": {
                "nonModel": "PaymentPagesCheckoutSessionCustomFieldsNumeric"
              },
              "isRequired": false,
              "attributes": []
            },
            "optional": {
              "name": "optional",
              "isArray": false,
              "type": "Boolean",
              "isRequired": true,
              "attributes": []
            },
            "text": {
              "name": "text",
              "isArray": false,
              "type": {
                "nonModel": "PaymentPagesCheckoutSessionCustomFieldsText"
              },
              "isRequired": false,
              "attributes": []
            },
            "type": {
              "name": "type",
              "isArray": false,
              "type": {
                "enum": "PaymentPagesCheckoutSessionCustomFieldsTypeEnum"
              },
              "isRequired": true,
              "attributes": []
            }
          }
        },
        "PaymentPagesCheckoutSessionCustomFieldsDropdown": {
          "name": "PaymentPagesCheckoutSessionCustomFieldsDropdown",
          "fields": {
            "default_value": {
              "name": "default_value",
              "isArray": false,
              "type": "String",
              "isRequired": false,
              "attributes": []
            },
            "options": {
              "name": "options",
              "isArray": true,
              "type": {
                "nonModel": "PaymentPagesCheckoutSessionCustomFieldsOption"
              },
              "isRequired": false,
              "attributes": [],
              "isArrayNullable": false
            },
            "value": {
              "name": "value",
              "isArray": false,
              "type": "String",
              "isRequired": false,
              "attributes": []
            }
          }
        },
        "PaymentPagesCheckoutSessionCustomFieldsLabel": {
          "name": "PaymentPagesCheckoutSessionCustomFieldsLabel",
          "fields": {
            "custom": {
              "name": "custom",
              "isArray": false,
              "type": "String",
              "isRequired": false,
              "attributes": []
            },
            "type": {
              "name": "type",
              "isArray": false,
              "type": {
                "enum": "PaymentPagesCheckoutSessionCustomFieldsLabelTypeEnum"
              },
              "isRequired": true,
              "attributes": []
            }
          }
        },
        "PaymentPagesCheckoutSessionCustomFieldsNumeric": {
          "name": "PaymentPagesCheckoutSessionCustomFieldsNumeric",
          "fields": {
            "default_value": {
              "name": "default_value",
              "isArray": false,
              "type": "String",
              "isRequired": false,
              "attributes": []
            },
            "maximum_length": {
              "name": "maximum_length",
              "isArray": false,
              "type": "Int",
              "isRequired": false,
              "attributes": []
            },
            "minimum_length": {
              "name": "minimum_length",
              "isArray": false,
              "type": "Int",
              "isRequired": false,
              "attributes": []
            },
            "value": {
              "name": "value",
              "isArray": false,
              "type": "String",
              "isRequired": false,
              "attributes": []
            }
          }
        },
        "PaymentPagesCheckoutSessionCustomFieldsOption": {
          "name": "PaymentPagesCheckoutSessionCustomFieldsOption",
          "fields": {
            "label": {
              "name": "label",
              "isArray": false,
              "type": "String",
              "isRequired": true,
              "attributes": []
            },
            "value": {
              "name": "value",
              "isArray": false,
              "type": "String",
              "isRequired": true,
              "attributes": []
            }
          }
        },
        "PaymentPagesCheckoutSessionCustomFieldsText": {
          "name": "PaymentPagesCheckoutSessionCustomFieldsText",
          "fields": {
            "default_value": {
              "name": "default_value",
              "isArray": false,
              "type": "String",
              "isRequired": false,
              "attributes": []
            },
            "maximum_length": {
              "name": "maximum_length",
              "isArray": false,
              "type": "Int",
              "isRequired": false,
              "attributes": []
            },
            "minimum_length": {
              "name": "minimum_length",
              "isArray": false,
              "type": "Int",
              "isRequired": false,
              "attributes": []
            },
            "value": {
              "name": "value",
              "isArray": false,
              "type": "String",
              "isRequired": false,
              "attributes": []
            }
          }
        },
        "PaymentPagesCheckoutSessionCustomText": {
          "name": "PaymentPagesCheckoutSessionCustomText",
          "fields": {
            "after_submit": {
              "name": "after_submit",
              "isArray": false,
              "type": {
                "nonModel": "PaymentPagesCheckoutSessionCustomTextPosition"
              },
              "isRequired": false,
              "attributes": []
            },
            "shipping_address": {
              "name": "shipping_address",
              "isArray": false,
              "type": {
                "nonModel": "PaymentPagesCheckoutSessionCustomTextPosition"
              },
              "isRequired": false,
              "attributes": []
            },
            "submit": {
              "name": "submit",
              "isArray": false,
              "type": {
                "nonModel": "PaymentPagesCheckoutSessionCustomTextPosition"
              },
              "isRequired": false,
              "attributes": []
            },
            "terms_of_service_acceptance": {
              "name": "terms_of_service_acceptance",
              "isArray": false,
              "type": {
                "nonModel": "PaymentPagesCheckoutSessionCustomTextPosition"
              },
              "isRequired": false,
              "attributes": []
            }
          }
        },
        "PaymentPagesCheckoutSessionCustomTextPosition": {
          "name": "PaymentPagesCheckoutSessionCustomTextPosition",
          "fields": {
            "message": {
              "name": "message",
              "isArray": false,
              "type": "String",
              "isRequired": true,
              "attributes": []
            }
          }
        },
        "PaymentPagesCheckoutSessionCustomerDetails": {
          "name": "PaymentPagesCheckoutSessionCustomerDetails",
          "fields": {
            "address": {
              "name": "address",
              "isArray": false,
              "type": {
                "nonModel": "Address"
              },
              "isRequired": false,
              "attributes": []
            },
            "email": {
              "name": "email",
              "isArray": false,
              "type": "String",
              "isRequired": false,
              "attributes": []
            },
            "name": {
              "name": "name",
              "isArray": false,
              "type": "String",
              "isRequired": false,
              "attributes": []
            },
            "phone": {
              "name": "phone",
              "isArray": false,
              "type": "String",
              "isRequired": false,
              "attributes": []
            },
            "tax_exempt": {
              "name": "tax_exempt",
              "isArray": false,
              "type": {
                "enum": "PaymentPagesCheckoutSessionCustomerDetailsTaxExemptEnum"
              },
              "isRequired": false,
              "attributes": []
            },
            "tax_ids": {
              "name": "tax_ids",
              "isArray": true,
              "type": {
                "nonModel": "PaymentPagesCheckoutSessionTaxId"
              },
              "isRequired": false,
              "attributes": [],
              "isArrayNullable": true
            }
          }
        },
        "PaymentPagesCheckoutSessionInvoiceCreation": {
          "name": "PaymentPagesCheckoutSessionInvoiceCreation",
          "fields": {
            "enabled": {
              "name": "enabled",
              "isArray": false,
              "type": "Boolean",
              "isRequired": true,
              "attributes": []
            },
            "invoice_data": {
              "name": "invoice_data",
              "isArray": false,
              "type": {
                "nonModel": "PaymentPagesCheckoutSessionInvoiceSettings"
              },
              "isRequired": true,
              "attributes": []
            }
          }
        },
        "PaymentPagesCheckoutSessionInvoiceSettings": {
          "name": "PaymentPagesCheckoutSessionInvoiceSettings",
          "fields": {
            "account_tax_ids": {
              "name": "account_tax_ids",
              "isArray": true,
              "type": "AWSJSON",
              "isRequired": false,
              "attributes": [],
              "isArrayNullable": true
            },
            "custom_fields": {
              "name": "custom_fields",
              "isArray": true,
              "type": {
                "nonModel": "InvoiceSettingCustomField"
              },
              "isRequired": false,
              "attributes": [],
              "isArrayNullable": true
            },
            "description": {
              "name": "description",
              "isArray": false,
              "type": "String",
              "isRequired": false,
              "attributes": []
            },
            "footer": {
              "name": "footer",
              "isArray": false,
              "type": "String",
              "isRequired": false,
              "attributes": []
            },
            "issuer": {
              "name": "issuer",
              "isArray": false,
              "type": {
                "nonModel": "ConnectAccountReference"
              },
              "isRequired": false,
              "attributes": []
            },
            "metadata": {
              "name": "metadata",
              "isArray": false,
              "type": "AWSJSON",
              "isRequired": false,
              "attributes": []
            },
            "rendering_options": {
              "name": "rendering_options",
              "isArray": false,
              "type": {
                "nonModel": "InvoiceSettingRenderingOptions"
              },
              "isRequired": false,
              "attributes": []
            }
          }
        },
        "PaymentPagesCheckoutSessionPaymentMethodReuseAgreement": {
          "name": "PaymentPagesCheckoutSessionPaymentMethodReuseAgreement",
          "fields": {
            "position": {
              "name": "position",
              "isArray": false,
              "type": {
                "enum": "PaymentPagesCheckoutSessionPaymentMethodReuseAgreementPositionEnum"
              },
              "isRequired": true,
              "attributes": []
            }
          }
        },
        "PaymentPagesCheckoutSessionPhoneNumberCollection": {
          "name": "PaymentPagesCheckoutSessionPhoneNumberCollection",
          "fields": {
            "enabled": {
              "name": "enabled",
              "isArray": false,
              "type": "Boolean",
              "isRequired": true,
              "attributes": []
            }
          }
        },
        "PaymentPagesCheckoutSessionSavedPaymentMethodOptions": {
          "name": "PaymentPagesCheckoutSessionSavedPaymentMethodOptions",
          "fields": {
            "allow_redisplay_filters": {
              "name": "allow_redisplay_filters",
              "isArray": true,
              "type": {
                "enum": "PaymentPagesCheckoutSessionSavedPaymentMethodOptionsAllowRedisplayFiltersEnum"
              },
              "isRequired": false,
              "attributes": [],
              "isArrayNullable": true
            },
            "payment_method_remove": {
              "name": "payment_method_remove",
              "isArray": false,
              "type": {
                "enum": "PaymentPagesCheckoutSessionSavedPaymentMethodOptionsPaymentMethodRemoveEnum"
              },
              "isRequired": false,
              "attributes": []
            },
            "payment_method_save": {
              "name": "payment_method_save",
              "isArray": false,
              "type": {
                "enum": "PaymentPagesCheckoutSessionSavedPaymentMethodOptionsPaymentMethodSaveEnum"
              },
              "isRequired": false,
              "attributes": []
            }
          }
        },
        "PaymentPagesCheckoutSessionShippingAddressCollection": {
          "name": "PaymentPagesCheckoutSessionShippingAddressCollection",
          "fields": {
            "allowed_countries": {
              "name": "allowed_countries",
              "isArray": true,
              "type": {
                "enum": "PaymentPagesCheckoutSessionShippingAddressCollectionAllowedCountriesEnum"
              },
              "isRequired": false,
              "attributes": [],
              "isArrayNullable": false
            }
          }
        },
        "PaymentPagesCheckoutSessionShippingCost": {
          "name": "PaymentPagesCheckoutSessionShippingCost",
          "fields": {
            "amount_subtotal": {
              "name": "amount_subtotal",
              "isArray": false,
              "type": "Int",
              "isRequired": true,
              "attributes": []
            },
            "amount_tax": {
              "name": "amount_tax",
              "isArray": false,
              "type": "Int",
              "isRequired": true,
              "attributes": []
            },
            "amount_total": {
              "name": "amount_total",
              "isArray": false,
              "type": "Int",
              "isRequired": true,
              "attributes": []
            },
            "shipping_rate": {
              "name": "shipping_rate",
              "isArray": false,
              "type": "AWSJSON",
              "isRequired": false,
              "attributes": []
            },
            "taxes": {
              "name": "taxes",
              "isArray": true,
              "type": {
                "nonModel": "LineItemsTaxAmount"
              },
              "isRequired": false,
              "attributes": [],
              "isArrayNullable": true
            }
          }
        },
        "PaymentPagesCheckoutSessionShippingOption": {
          "name": "PaymentPagesCheckoutSessionShippingOption",
          "fields": {
            "shipping_amount": {
              "name": "shipping_amount",
              "isArray": false,
              "type": "Int",
              "isRequired": true,
              "attributes": []
            },
            "shipping_rate": {
              "name": "shipping_rate",
              "isArray": false,
              "type": "AWSJSON",
              "isRequired": true,
              "attributes": []
            }
          }
        },
        "PaymentPagesCheckoutSessionTaxId": {
          "name": "PaymentPagesCheckoutSessionTaxId",
          "fields": {
            "type": {
              "name": "type",
              "isArray": false,
              "type": {
                "enum": "PaymentPagesCheckoutSessionTaxIdTypeEnum"
              },
              "isRequired": true,
              "attributes": []
            },
            "value": {
              "name": "value",
              "isArray": false,
              "type": "String",
              "isRequired": false,
              "attributes": []
            }
          }
        },
        "PaymentPagesCheckoutSessionTaxIdCollection": {
          "name": "PaymentPagesCheckoutSessionTaxIdCollection",
          "fields": {
            "enabled": {
              "name": "enabled",
              "isArray": false,
              "type": "Boolean",
              "isRequired": true,
              "attributes": []
            }
          }
        },
        "PaymentPagesCheckoutSessionTotalDetails": {
          "name": "PaymentPagesCheckoutSessionTotalDetails",
          "fields": {
            "amount_discount": {
              "name": "amount_discount",
              "isArray": false,
              "type": "Int",
              "isRequired": true,
              "attributes": []
            },
            "amount_shipping": {
              "name": "amount_shipping",
              "isArray": false,
              "type": "Int",
              "isRequired": false,
              "attributes": []
            },
            "amount_tax": {
              "name": "amount_tax",
              "isArray": false,
              "type": "Int",
              "isRequired": true,
              "attributes": []
            },
            "breakdown": {
              "name": "breakdown",
              "isArray": false,
              "type": {
                "nonModel": "PaymentPagesCheckoutSessionTotalDetailsResourceBreakdown"
              },
              "isRequired": false,
              "attributes": []
            }
          }
        },
        "PaymentPagesCheckoutSessionTotalDetailsResourceBreakdown": {
          "name": "PaymentPagesCheckoutSessionTotalDetailsResourceBreakdown",
          "fields": {
            "discounts": {
              "name": "discounts",
              "isArray": true,
              "type": {
                "nonModel": "LineItemsDiscountAmount"
              },
              "isRequired": false,
              "attributes": [],
              "isArrayNullable": false
            },
            "taxes": {
              "name": "taxes",
              "isArray": true,
              "type": {
                "nonModel": "LineItemsTaxAmount"
              },
              "isRequired": false,
              "attributes": [],
              "isArrayNullable": false
            }
          }
        },
        "PlanTier": {
          "name": "PlanTier",
          "fields": {
            "flat_amount": {
              "name": "flat_amount",
              "isArray": false,
              "type": "Int",
              "isRequired": false,
              "attributes": []
            },
            "flat_amount_decimal": {
              "name": "flat_amount_decimal",
              "isArray": false,
              "type": "String",
              "isRequired": false,
              "attributes": []
            },
            "unit_amount": {
              "name": "unit_amount",
              "isArray": false,
              "type": "Int",
              "isRequired": false,
              "attributes": []
            },
            "unit_amount_decimal": {
              "name": "unit_amount_decimal",
              "isArray": false,
              "type": "String",
              "isRequired": false,
              "attributes": []
            },
            "up_to": {
              "name": "up_to",
              "isArray": false,
              "type": "Int",
              "isRequired": false,
              "attributes": []
            }
          }
        },
        "PortalBusinessProfile": {
          "name": "PortalBusinessProfile",
          "fields": {
            "headline": {
              "name": "headline",
              "isArray": false,
              "type": "String",
              "isRequired": false,
              "attributes": []
            },
            "privacy_policy_url": {
              "name": "privacy_policy_url",
              "isArray": false,
              "type": "String",
              "isRequired": false,
              "attributes": []
            },
            "terms_of_service_url": {
              "name": "terms_of_service_url",
              "isArray": false,
              "type": "String",
              "isRequired": false,
              "attributes": []
            }
          }
        },
        "PortalCustomerUpdate": {
          "name": "PortalCustomerUpdate",
          "fields": {
            "allowed_updates": {
              "name": "allowed_updates",
              "isArray": true,
              "type": {
                "enum": "PortalCustomerUpdateAllowedUpdatesEnum"
              },
              "isRequired": false,
              "attributes": [],
              "isArrayNullable": false
            },
            "enabled": {
              "name": "enabled",
              "isArray": false,
              "type": "Boolean",
              "isRequired": true,
              "attributes": []
            }
          }
        },
        "PortalFeatures": {
          "name": "PortalFeatures",
          "fields": {
            "customer_update": {
              "name": "customer_update",
              "isArray": false,
              "type": {
                "nonModel": "PortalCustomerUpdate"
              },
              "isRequired": true,
              "attributes": []
            },
            "invoice_history": {
              "name": "invoice_history",
              "isArray": false,
              "type": {
                "nonModel": "PortalInvoiceList"
              },
              "isRequired": true,
              "attributes": []
            },
            "payment_method_update": {
              "name": "payment_method_update",
              "isArray": false,
              "type": {
                "nonModel": "PortalPaymentMethodUpdate"
              },
              "isRequired": true,
              "attributes": []
            },
            "subscription_cancel": {
              "name": "subscription_cancel",
              "isArray": false,
              "type": {
                "nonModel": "PortalSubscriptionCancel"
              },
              "isRequired": true,
              "attributes": []
            },
            "subscription_update": {
              "name": "subscription_update",
              "isArray": false,
              "type": {
                "nonModel": "PortalSubscriptionUpdate"
              },
              "isRequired": true,
              "attributes": []
            }
          }
        },
        "PortalFlowsAfterCompletionHostedConfirmation": {
          "name": "PortalFlowsAfterCompletionHostedConfirmation",
          "fields": {
            "custom_message": {
              "name": "custom_message",
              "isArray": false,
              "type": "String",
              "isRequired": false,
              "attributes": []
            }
          }
        },
        "PortalFlowsAfterCompletionRedirect": {
          "name": "PortalFlowsAfterCompletionRedirect",
          "fields": {
            "return_url": {
              "name": "return_url",
              "isArray": false,
              "type": "String",
              "isRequired": true,
              "attributes": []
            }
          }
        },
        "PortalFlowsCouponOffer": {
          "name": "PortalFlowsCouponOffer",
          "fields": {
            "coupon": {
              "name": "coupon",
              "isArray": false,
              "type": "String",
              "isRequired": true,
              "attributes": []
            }
          }
        },
        "PortalFlowsFlow": {
          "name": "PortalFlowsFlow",
          "fields": {
            "after_completion": {
              "name": "after_completion",
              "isArray": false,
              "type": {
                "nonModel": "PortalFlowsFlowAfterCompletion"
              },
              "isRequired": true,
              "attributes": []
            },
            "subscription_cancel": {
              "name": "subscription_cancel",
              "isArray": false,
              "type": {
                "nonModel": "PortalFlowsFlowSubscriptionCancel"
              },
              "isRequired": false,
              "attributes": []
            },
            "subscription_update": {
              "name": "subscription_update",
              "isArray": false,
              "type": {
                "nonModel": "PortalFlowsFlowSubscriptionUpdate"
              },
              "isRequired": false,
              "attributes": []
            },
            "subscription_update_confirm": {
              "name": "subscription_update_confirm",
              "isArray": false,
              "type": {
                "nonModel": "PortalFlowsFlowSubscriptionUpdateConfirm"
              },
              "isRequired": false,
              "attributes": []
            },
            "type": {
              "name": "type",
              "isArray": false,
              "type": {
                "enum": "PortalFlowsFlowTypeEnum"
              },
              "isRequired": true,
              "attributes": []
            }
          }
        },
        "PortalFlowsFlowAfterCompletion": {
          "name": "PortalFlowsFlowAfterCompletion",
          "fields": {
            "hosted_confirmation": {
              "name": "hosted_confirmation",
              "isArray": false,
              "type": {
                "nonModel": "PortalFlowsAfterCompletionHostedConfirmation"
              },
              "isRequired": false,
              "attributes": []
            },
            "redirect": {
              "name": "redirect",
              "isArray": false,
              "type": {
                "nonModel": "PortalFlowsAfterCompletionRedirect"
              },
              "isRequired": false,
              "attributes": []
            },
            "type": {
              "name": "type",
              "isArray": false,
              "type": {
                "enum": "PortalFlowsFlowAfterCompletionTypeEnum"
              },
              "isRequired": true,
              "attributes": []
            }
          }
        },
        "PortalFlowsFlowSubscriptionCancel": {
          "name": "PortalFlowsFlowSubscriptionCancel",
          "fields": {
            "retention": {
              "name": "retention",
              "isArray": false,
              "type": {
                "nonModel": "PortalFlowsRetention"
              },
              "isRequired": false,
              "attributes": []
            },
            "subscription": {
              "name": "subscription",
              "isArray": false,
              "type": "String",
              "isRequired": true,
              "attributes": []
            }
          }
        },
        "PortalFlowsFlowSubscriptionUpdate": {
          "name": "PortalFlowsFlowSubscriptionUpdate",
          "fields": {
            "subscription": {
              "name": "subscription",
              "isArray": false,
              "type": "String",
              "isRequired": true,
              "attributes": []
            }
          }
        },
        "PortalFlowsFlowSubscriptionUpdateConfirm": {
          "name": "PortalFlowsFlowSubscriptionUpdateConfirm",
          "fields": {
            "discounts": {
              "name": "discounts",
              "isArray": true,
              "type": {
                "nonModel": "PortalFlowsSubscriptionUpdateConfirmDiscount"
              },
              "isRequired": false,
              "attributes": [],
              "isArrayNullable": true
            },
            "items": {
              "name": "items",
              "isArray": true,
              "type": {
                "nonModel": "PortalFlowsSubscriptionUpdateConfirmItem"
              },
              "isRequired": false,
              "attributes": [],
              "isArrayNullable": false
            },
            "subscription": {
              "name": "subscription",
              "isArray": false,
              "type": "String",
              "isRequired": true,
              "attributes": []
            }
          }
        },
        "PortalFlowsRetention": {
          "name": "PortalFlowsRetention",
          "fields": {
            "coupon_offer": {
              "name": "coupon_offer",
              "isArray": false,
              "type": {
                "nonModel": "PortalFlowsCouponOffer"
              },
              "isRequired": false,
              "attributes": []
            },
            "type": {
              "name": "type",
              "isArray": false,
              "type": {
                "enum": "PortalFlowsRetentionTypeEnum"
              },
              "isRequired": true,
              "attributes": []
            }
          }
        },
        "PortalFlowsSubscriptionUpdateConfirmDiscount": {
          "name": "PortalFlowsSubscriptionUpdateConfirmDiscount",
          "fields": {
            "coupon": {
              "name": "coupon",
              "isArray": false,
              "type": "String",
              "isRequired": false,
              "attributes": []
            },
            "promotion_code": {
              "name": "promotion_code",
              "isArray": false,
              "type": "String",
              "isRequired": false,
              "attributes": []
            }
          }
        },
        "PortalFlowsSubscriptionUpdateConfirmItem": {
          "name": "PortalFlowsSubscriptionUpdateConfirmItem",
          "fields": {
            "id": {
              "name": "id",
              "isArray": false,
              "type": "String",
              "isRequired": false,
              "attributes": []
            },
            "price": {
              "name": "price",
              "isArray": false,
              "type": "String",
              "isRequired": false,
              "attributes": []
            },
            "quantity": {
              "name": "quantity",
              "isArray": false,
              "type": "Int",
              "isRequired": false,
              "attributes": []
            }
          }
        },
        "PortalInvoiceList": {
          "name": "PortalInvoiceList",
          "fields": {
            "enabled": {
              "name": "enabled",
              "isArray": false,
              "type": "Boolean",
              "isRequired": true,
              "attributes": []
            }
          }
        },
        "PortalLoginPage": {
          "name": "PortalLoginPage",
          "fields": {
            "enabled": {
              "name": "enabled",
              "isArray": false,
              "type": "Boolean",
              "isRequired": true,
              "attributes": []
            },
            "url": {
              "name": "url",
              "isArray": false,
              "type": "String",
              "isRequired": false,
              "attributes": []
            }
          }
        },
        "PortalPaymentMethodUpdate": {
          "name": "PortalPaymentMethodUpdate",
          "fields": {
            "enabled": {
              "name": "enabled",
              "isArray": false,
              "type": "Boolean",
              "isRequired": true,
              "attributes": []
            }
          }
        },
        "PortalSubscriptionCancel": {
          "name": "PortalSubscriptionCancel",
          "fields": {
            "cancellation_reason": {
              "name": "cancellation_reason",
              "isArray": false,
              "type": {
                "nonModel": "PortalSubscriptionCancellationReason"
              },
              "isRequired": true,
              "attributes": []
            },
            "enabled": {
              "name": "enabled",
              "isArray": false,
              "type": "Boolean",
              "isRequired": true,
              "attributes": []
            },
            "mode": {
              "name": "mode",
              "isArray": false,
              "type": {
                "enum": "PortalSubscriptionCancelModeEnum"
              },
              "isRequired": true,
              "attributes": []
            },
            "proration_behavior": {
              "name": "proration_behavior",
              "isArray": false,
              "type": {
                "enum": "PortalSubscriptionCancelProrationBehaviorEnum"
              },
              "isRequired": true,
              "attributes": []
            }
          }
        },
        "PortalSubscriptionCancellationReason": {
          "name": "PortalSubscriptionCancellationReason",
          "fields": {
            "enabled": {
              "name": "enabled",
              "isArray": false,
              "type": "Boolean",
              "isRequired": true,
              "attributes": []
            },
            "options": {
              "name": "options",
              "isArray": true,
              "type": {
                "enum": "PortalSubscriptionCancellationReasonOptionsEnum"
              },
              "isRequired": false,
              "attributes": [],
              "isArrayNullable": false
            }
          }
        },
        "PortalSubscriptionUpdate": {
          "name": "PortalSubscriptionUpdate",
          "fields": {
            "default_allowed_updates": {
              "name": "default_allowed_updates",
              "isArray": true,
              "type": {
                "enum": "PortalSubscriptionUpdateDefaultAllowedUpdatesEnum"
              },
              "isRequired": false,
              "attributes": [],
              "isArrayNullable": false
            },
            "enabled": {
              "name": "enabled",
              "isArray": false,
              "type": "Boolean",
              "isRequired": true,
              "attributes": []
            },
            "products": {
              "name": "products",
              "isArray": true,
              "type": {
                "nonModel": "PortalSubscriptionUpdateProduct"
              },
              "isRequired": false,
              "attributes": [],
              "isArrayNullable": true
            },
            "proration_behavior": {
              "name": "proration_behavior",
              "isArray": false,
              "type": {
                "enum": "PortalSubscriptionUpdateProrationBehaviorEnum"
              },
              "isRequired": true,
              "attributes": []
            }
          }
        },
        "PortalSubscriptionUpdateProduct": {
          "name": "PortalSubscriptionUpdateProduct",
          "fields": {
            "prices": {
              "name": "prices",
              "isArray": true,
              "type": "String",
              "isRequired": false,
              "attributes": [],
              "isArrayNullable": false
            },
            "product": {
              "name": "product",
              "isArray": false,
              "type": "String",
              "isRequired": true,
              "attributes": []
            }
          }
        },
        "PriceTier": {
          "name": "PriceTier",
          "fields": {
            "flat_amount": {
              "name": "flat_amount",
              "isArray": false,
              "type": "Int",
              "isRequired": false,
              "attributes": []
            },
            "flat_amount_decimal": {
              "name": "flat_amount_decimal",
              "isArray": false,
              "type": "String",
              "isRequired": false,
              "attributes": []
            },
            "unit_amount": {
              "name": "unit_amount",
              "isArray": false,
              "type": "Int",
              "isRequired": false,
              "attributes": []
            },
            "unit_amount_decimal": {
              "name": "unit_amount_decimal",
              "isArray": false,
              "type": "String",
              "isRequired": false,
              "attributes": []
            },
            "up_to": {
              "name": "up_to",
              "isArray": false,
              "type": "Int",
              "isRequired": false,
              "attributes": []
            }
          }
        },
        "ProductMarketingFeature": {
          "name": "ProductMarketingFeature",
          "fields": {
            "name": {
              "name": "name",
              "isArray": false,
              "type": "String",
              "isRequired": false,
              "attributes": []
            }
          }
        },
        "RadarRadarOptions": {
          "name": "RadarRadarOptions",
          "fields": {
            "session": {
              "name": "session",
              "isArray": false,
              "type": "String",
              "isRequired": false,
              "attributes": []
            }
          }
        },
        "Recurring": {
          "name": "Recurring",
          "fields": {
            "aggregate_usage": {
              "name": "aggregate_usage",
              "isArray": false,
              "type": {
                "enum": "RecurringAggregateUsageEnum"
              },
              "isRequired": false,
              "attributes": []
            },
            "interval": {
              "name": "interval",
              "isArray": false,
              "type": {
                "enum": "RecurringIntervalEnum"
              },
              "isRequired": true,
              "attributes": []
            },
            "interval_count": {
              "name": "interval_count",
              "isArray": false,
              "type": "Int",
              "isRequired": true,
              "attributes": []
            },
            "meter": {
              "name": "meter",
              "isArray": false,
              "type": "String",
              "isRequired": false,
              "attributes": []
            },
            "usage_type": {
              "name": "usage_type",
              "isArray": false,
              "type": {
                "enum": "RecurringUsageTypeEnum"
              },
              "isRequired": true,
              "attributes": []
            }
          }
        },
        "SepaDebitGeneratedFrom": {
          "name": "SepaDebitGeneratedFrom",
          "fields": {
            "charge": {
              "name": "charge",
              "isArray": false,
              "type": "AWSJSON",
              "isRequired": false,
              "attributes": []
            },
            "setup_attempt": {
              "name": "setup_attempt",
              "isArray": false,
              "type": "AWSJSON",
              "isRequired": false,
              "attributes": []
            }
          }
        },
        "Shipping": {
          "name": "Shipping",
          "fields": {
            "address": {
              "name": "address",
              "isArray": false,
              "type": {
                "nonModel": "Address"
              },
              "isRequired": false,
              "attributes": []
            },
            "carrier": {
              "name": "carrier",
              "isArray": false,
              "type": "String",
              "isRequired": false,
              "attributes": []
            },
            "name": {
              "name": "name",
              "isArray": false,
              "type": "String",
              "isRequired": false,
              "attributes": []
            },
            "phone": {
              "name": "phone",
              "isArray": false,
              "type": "String",
              "isRequired": false,
              "attributes": []
            },
            "tracking_number": {
              "name": "tracking_number",
              "isArray": false,
              "type": "String",
              "isRequired": false,
              "attributes": []
            }
          }
        },
        "SubscriptionAutomaticTax": {
          "name": "SubscriptionAutomaticTax",
          "fields": {
            "enabled": {
              "name": "enabled",
              "isArray": false,
              "type": "Boolean",
              "isRequired": true,
              "attributes": []
            },
            "liability": {
              "name": "liability",
              "isArray": false,
              "type": {
                "nonModel": "ConnectAccountReference"
              },
              "isRequired": false,
              "attributes": []
            }
          }
        },
        "SubscriptionBillingThresholds": {
          "name": "SubscriptionBillingThresholds",
          "fields": {
            "amount_gte": {
              "name": "amount_gte",
              "isArray": false,
              "type": "Int",
              "isRequired": false,
              "attributes": []
            },
            "reset_billing_cycle_anchor": {
              "name": "reset_billing_cycle_anchor",
              "isArray": false,
              "type": "Boolean",
              "isRequired": false,
              "attributes": []
            }
          }
        },
        "SubscriptionItemBillingThresholds": {
          "name": "SubscriptionItemBillingThresholds",
          "fields": {
            "usage_gte": {
              "name": "usage_gte",
              "isArray": false,
              "type": "Int",
              "isRequired": false,
              "attributes": []
            }
          }
        },
        "SubscriptionPaymentMethodOptionsCard": {
          "name": "SubscriptionPaymentMethodOptionsCard",
          "fields": {
            "mandate_options": {
              "name": "mandate_options",
              "isArray": false,
              "type": {
                "nonModel": "InvoiceMandateOptionsCard"
              },
              "isRequired": false,
              "attributes": []
            },
            "network": {
              "name": "network",
              "isArray": false,
              "type": {
                "enum": "SubscriptionPaymentMethodOptionsCardNetworkEnum"
              },
              "isRequired": false,
              "attributes": []
            },
            "request_three_d_secure": {
              "name": "request_three_d_secure",
              "isArray": false,
              "type": {
                "enum": "SubscriptionPaymentMethodOptionsCardRequestThreeDSecureEnum"
              },
              "isRequired": false,
              "attributes": []
            }
          }
        },
        "SubscriptionPendingInvoiceItemInterval": {
          "name": "SubscriptionPendingInvoiceItemInterval",
          "fields": {
            "interval": {
              "name": "interval",
              "isArray": false,
              "type": {
                "enum": "SubscriptionPendingInvoiceItemIntervalIntervalEnum"
              },
              "isRequired": true,
              "attributes": []
            },
            "interval_count": {
              "name": "interval_count",
              "isArray": false,
              "type": "Int",
              "isRequired": true,
              "attributes": []
            }
          }
        },
        "SubscriptionTransferData": {
          "name": "SubscriptionTransferData",
          "fields": {
            "amount_percent": {
              "name": "amount_percent",
              "isArray": false,
              "type": "Float",
              "isRequired": false,
              "attributes": []
            },
            "destination": {
              "name": "destination",
              "isArray": false,
              "type": "AWSJSON",
              "isRequired": true,
              "attributes": []
            }
          }
        },
        "SubscriptionsResourceBillingCycleAnchorConfig": {
          "name": "SubscriptionsResourceBillingCycleAnchorConfig",
          "fields": {
            "day_of_month": {
              "name": "day_of_month",
              "isArray": false,
              "type": "Int",
              "isRequired": true,
              "attributes": []
            },
            "hour": {
              "name": "hour",
              "isArray": false,
              "type": "Int",
              "isRequired": false,
              "attributes": []
            },
            "minute": {
              "name": "minute",
              "isArray": false,
              "type": "Int",
              "isRequired": false,
              "attributes": []
            },
            "month": {
              "name": "month",
              "isArray": false,
              "type": "Int",
              "isRequired": false,
              "attributes": []
            },
            "second": {
              "name": "second",
              "isArray": false,
              "type": "Int",
              "isRequired": false,
              "attributes": []
            }
          }
        },
        "SubscriptionsResourcePauseCollection": {
          "name": "SubscriptionsResourcePauseCollection",
          "fields": {
            "behavior": {
              "name": "behavior",
              "isArray": false,
              "type": {
                "enum": "SubscriptionsResourcePauseCollectionBehaviorEnum"
              },
              "isRequired": true,
              "attributes": []
            },
            "resumes_at": {
              "name": "resumes_at",
              "isArray": false,
              "type": "AWSTimestamp",
              "isRequired": false,
              "attributes": []
            }
          }
        },
        "SubscriptionsResourcePaymentMethodOptions": {
          "name": "SubscriptionsResourcePaymentMethodOptions",
          "fields": {
            "acss_debit": {
              "name": "acss_debit",
              "isArray": false,
              "type": {
                "nonModel": "InvoicePaymentMethodOptionsAcssDebit"
              },
              "isRequired": false,
              "attributes": []
            },
            "bancontact": {
              "name": "bancontact",
              "isArray": false,
              "type": {
                "nonModel": "InvoicePaymentMethodOptionsBancontact"
              },
              "isRequired": false,
              "attributes": []
            },
            "card": {
              "name": "card",
              "isArray": false,
              "type": {
                "nonModel": "SubscriptionPaymentMethodOptionsCard"
              },
              "isRequired": false,
              "attributes": []
            },
            "customer_balance": {
              "name": "customer_balance",
              "isArray": false,
              "type": {
                "nonModel": "InvoicePaymentMethodOptionsCustomerBalance"
              },
              "isRequired": false,
              "attributes": []
            },
            "konbini": {
              "name": "konbini",
              "isArray": false,
              "type": "AWSJSON",
              "isRequired": false,
              "attributes": []
            },
            "sepa_debit": {
              "name": "sepa_debit",
              "isArray": false,
              "type": "AWSJSON",
              "isRequired": false,
              "attributes": []
            },
            "us_bank_account": {
              "name": "us_bank_account",
              "isArray": false,
              "type": {
                "nonModel": "InvoicePaymentMethodOptionsUsBankAccount"
              },
              "isRequired": false,
              "attributes": []
            }
          }
        },
        "SubscriptionsResourcePaymentSettings": {
          "name": "SubscriptionsResourcePaymentSettings",
          "fields": {
            "payment_method_options": {
              "name": "payment_method_options",
              "isArray": false,
              "type": {
                "nonModel": "SubscriptionsResourcePaymentMethodOptions"
              },
              "isRequired": false,
              "attributes": []
            },
            "payment_method_types": {
              "name": "payment_method_types",
              "isArray": true,
              "type": {
                "enum": "SubscriptionsResourcePaymentSettingsPaymentMethodTypesEnum"
              },
              "isRequired": false,
              "attributes": [],
              "isArrayNullable": true
            },
            "save_default_payment_method": {
              "name": "save_default_payment_method",
              "isArray": false,
              "type": {
                "enum": "SubscriptionsResourcePaymentSettingsSaveDefaultPaymentMethodEnum"
              },
              "isRequired": false,
              "attributes": []
            }
          }
        },
        "SubscriptionsResourcePendingUpdate": {
          "name": "SubscriptionsResourcePendingUpdate",
          "fields": {
            "billing_cycle_anchor": {
              "name": "billing_cycle_anchor",
              "isArray": false,
              "type": "AWSTimestamp",
              "isRequired": false,
              "attributes": []
            },
            "expires_at": {
              "name": "expires_at",
              "isArray": false,
              "type": "AWSTimestamp",
              "isRequired": true,
              "attributes": []
            },
            "subscription_items": {
              "name": "subscription_items",
              "isArray": true,
              "type": {
                "nonModel": "SubscriptionItem"
              },
              "isRequired": false,
              "attributes": [],
              "isArrayNullable": true
            },
            "trial_end": {
              "name": "trial_end",
              "isArray": false,
              "type": "AWSTimestamp",
              "isRequired": false,
              "attributes": []
            },
            "trial_from_plan": {
              "name": "trial_from_plan",
              "isArray": false,
              "type": "Boolean",
              "isRequired": false,
              "attributes": []
            }
          }
        },
        "SubscriptionsResourceSubscriptionInvoiceSettings": {
          "name": "SubscriptionsResourceSubscriptionInvoiceSettings",
          "fields": {
            "account_tax_ids": {
              "name": "account_tax_ids",
              "isArray": true,
              "type": "AWSJSON",
              "isRequired": false,
              "attributes": [],
              "isArrayNullable": true
            },
            "issuer": {
              "name": "issuer",
              "isArray": false,
              "type": {
                "nonModel": "ConnectAccountReference"
              },
              "isRequired": true,
              "attributes": []
            }
          }
        },
        "SubscriptionsTrialsResourceEndBehavior": {
          "name": "SubscriptionsTrialsResourceEndBehavior",
          "fields": {
            "missing_payment_method": {
              "name": "missing_payment_method",
              "isArray": false,
              "type": {
                "enum": "SubscriptionsTrialsResourceEndBehaviorMissingPaymentMethodEnum"
              },
              "isRequired": true,
              "attributes": []
            }
          }
        },
        "SubscriptionsTrialsResourceTrialSettings": {
          "name": "SubscriptionsTrialsResourceTrialSettings",
          "fields": {
            "end_behavior": {
              "name": "end_behavior",
              "isArray": false,
              "type": {
                "nonModel": "SubscriptionsTrialsResourceEndBehavior"
              },
              "isRequired": true,
              "attributes": []
            }
          }
        },
        "TaxIDsOwner": {
          "name": "TaxIDsOwner",
          "fields": {
            "account": {
              "name": "account",
              "isArray": false,
              "type": "AWSJSON",
              "isRequired": false,
              "attributes": []
            },
            "application": {
              "name": "application",
              "isArray": false,
              "type": "AWSJSON",
              "isRequired": false,
              "attributes": []
            },
            "customer": {
              "name": "customer",
              "isArray": false,
              "type": "AWSJSON",
              "isRequired": false,
              "attributes": []
            },
            "type": {
              "name": "type",
              "isArray": false,
              "type": {
                "enum": "TaxIDsOwnerTypeEnum"
              },
              "isRequired": true,
              "attributes": []
            }
          }
        },
        "TaxId": {
          "name": "TaxId",
          "fields": {
            "country": {
              "name": "country",
              "isArray": false,
              "type": "String",
              "isRequired": false,
              "attributes": []
            },
            "created": {
              "name": "created",
              "isArray": false,
              "type": "AWSTimestamp",
              "isRequired": true,
              "attributes": []
            },
            "customer": {
              "name": "customer",
              "isArray": false,
              "type": "AWSJSON",
              "isRequired": false,
              "attributes": []
            },
            "id": {
              "name": "id",
              "isArray": false,
              "type": "String",
              "isRequired": true,
              "attributes": []
            },
            "livemode": {
              "name": "livemode",
              "isArray": false,
              "type": "Boolean",
              "isRequired": true,
              "attributes": []
            },
            "object": {
              "name": "object",
              "isArray": false,
              "type": {
                "enum": "TaxIdObject"
              },
              "isRequired": false,
              "attributes": []
            },
            "owner": {
              "name": "owner",
              "isArray": false,
              "type": {
                "nonModel": "TaxIDsOwner"
              },
              "isRequired": false,
              "attributes": []
            },
            "type": {
              "name": "type",
              "isArray": false,
              "type": {
                "enum": "TaxIdTypeEnum"
              },
              "isRequired": true,
              "attributes": []
            },
            "value": {
              "name": "value",
              "isArray": false,
              "type": "String",
              "isRequired": true,
              "attributes": []
            },
            "verification": {
              "name": "verification",
              "isArray": false,
              "type": {
                "nonModel": "TaxIdVerification"
              },
              "isRequired": false,
              "attributes": []
            }
          }
        },
        "TaxIdVerification": {
          "name": "TaxIdVerification",
          "fields": {
            "status": {
              "name": "status",
              "isArray": false,
              "type": {
                "enum": "TaxIdVerificationStatusEnum"
              },
              "isRequired": true,
              "attributes": []
            },
            "verified_address": {
              "name": "verified_address",
              "isArray": false,
              "type": "String",
              "isRequired": false,
              "attributes": []
            },
            "verified_name": {
              "name": "verified_name",
              "isArray": false,
              "type": "String",
              "isRequired": false,
              "attributes": []
            }
          }
        },
        "TaxRate": {
          "name": "TaxRate",
          "fields": {
            "active": {
              "name": "active",
              "isArray": false,
              "type": "Boolean",
              "isRequired": true,
              "attributes": []
            },
            "country": {
              "name": "country",
              "isArray": false,
              "type": "String",
              "isRequired": false,
              "attributes": []
            },
            "created": {
              "name": "created",
              "isArray": false,
              "type": "AWSTimestamp",
              "isRequired": true,
              "attributes": []
            },
            "description": {
              "name": "description",
              "isArray": false,
              "type": "String",
              "isRequired": false,
              "attributes": []
            },
            "display_name": {
              "name": "display_name",
              "isArray": false,
              "type": "String",
              "isRequired": true,
              "attributes": []
            },
            "effective_percentage": {
              "name": "effective_percentage",
              "isArray": false,
              "type": "Float",
              "isRequired": false,
              "attributes": []
            },
            "id": {
              "name": "id",
              "isArray": false,
              "type": "String",
              "isRequired": true,
              "attributes": []
            },
            "inclusive": {
              "name": "inclusive",
              "isArray": false,
              "type": "Boolean",
              "isRequired": true,
              "attributes": []
            },
            "jurisdiction": {
              "name": "jurisdiction",
              "isArray": false,
              "type": "String",
              "isRequired": false,
              "attributes": []
            },
            "jurisdiction_level": {
              "name": "jurisdiction_level",
              "isArray": false,
              "type": {
                "enum": "TaxRateJurisdictionLevelEnum"
              },
              "isRequired": false,
              "attributes": []
            },
            "livemode": {
              "name": "livemode",
              "isArray": false,
              "type": "Boolean",
              "isRequired": true,
              "attributes": []
            },
            "metadata": {
              "name": "metadata",
              "isArray": false,
              "type": "AWSJSON",
              "isRequired": false,
              "attributes": []
            },
            "object": {
              "name": "object",
              "isArray": false,
              "type": {
                "enum": "TaxRateObject"
              },
              "isRequired": false,
              "attributes": []
            },
            "percentage": {
              "name": "percentage",
              "isArray": false,
              "type": "Float",
              "isRequired": true,
              "attributes": []
            },
            "state": {
              "name": "state",
              "isArray": false,
              "type": "String",
              "isRequired": false,
              "attributes": []
            },
            "tax_type": {
              "name": "tax_type",
              "isArray": false,
              "type": {
                "enum": "TaxRateTaxTypeEnum"
              },
              "isRequired": false,
              "attributes": []
            }
          }
        },
        "ThreeDSecureUsage": {
          "name": "ThreeDSecureUsage",
          "fields": {
            "supported": {
              "name": "supported",
              "isArray": false,
              "type": "Boolean",
              "isRequired": true,
              "attributes": []
            }
          }
        },
        "TransformQuantity": {
          "name": "TransformQuantity",
          "fields": {
            "divide_by": {
              "name": "divide_by",
              "isArray": false,
              "type": "Int",
              "isRequired": true,
              "attributes": []
            },
            "round": {
              "name": "round",
              "isArray": false,
              "type": {
                "enum": "TransformQuantityRoundEnum"
              },
              "isRequired": true,
              "attributes": []
            }
          }
        },
        "TransformUsage": {
          "name": "TransformUsage",
          "fields": {
            "divide_by": {
              "name": "divide_by",
              "isArray": false,
              "type": "Int",
              "isRequired": true,
              "attributes": []
            },
            "round": {
              "name": "round",
              "isArray": false,
              "type": {
                "enum": "TransformUsageRoundEnum"
              },
              "isRequired": true,
              "attributes": []
            }
          }
        },
        "UsBankAccountNetworks": {
          "name": "UsBankAccountNetworks",
          "fields": {
            "preferred": {
              "name": "preferred",
              "isArray": false,
              "type": "String",
              "isRequired": false,
              "attributes": []
            },
            "supported": {
              "name": "supported",
              "isArray": true,
              "type": {
                "enum": "UsBankAccountNetworksSupportedEnum"
              },
              "isRequired": false,
              "attributes": [],
              "isArrayNullable": false
            }
          }
        },
        "GetConfigurationsResponse": {
          "name": "GetConfigurationsResponse",
          "fields": {
            "data": {
              "name": "data",
              "isArray": true,
              "type": {
                "nonModel": "BillingPortalConfiguration"
              },
              "isRequired": false,
              "attributes": [],
              "isArrayNullable": false
            },
            "has_more": {
              "name": "has_more",
              "isArray": false,
              "type": "Boolean",
              "isRequired": true,
              "attributes": []
            },
            "object": {
              "name": "object",
              "isArray": false,
              "type": "String",
              "isRequired": true,
              "attributes": []
            },
            "url": {
              "name": "url",
              "isArray": false,
              "type": "String",
              "isRequired": true,
              "attributes": []
            }
          }
        },
        "GetCustomersResponse": {
          "name": "GetCustomersResponse",
          "fields": {
            "data": {
              "name": "data",
              "isArray": true,
              "type": {
                "nonModel": "Customer"
              },
              "isRequired": false,
              "attributes": [],
              "isArrayNullable": false
            },
            "has_more": {
              "name": "has_more",
              "isArray": false,
              "type": "Boolean",
              "isRequired": true,
              "attributes": []
            },
            "object": {
              "name": "object",
              "isArray": false,
              "type": "String",
              "isRequired": true,
              "attributes": []
            },
            "url": {
              "name": "url",
              "isArray": false,
              "type": "String",
              "isRequired": true,
              "attributes": []
            }
          }
        },
        "GetItemsResponse": {
          "name": "GetItemsResponse",
          "fields": {
            "data": {
              "name": "data",
              "isArray": true,
              "type": {
                "nonModel": "SubscriptionItem"
              },
              "isRequired": false,
              "attributes": [],
              "isArrayNullable": false
            },
            "has_more": {
              "name": "has_more",
              "isArray": false,
              "type": "Boolean",
              "isRequired": true,
              "attributes": []
            },
            "object": {
              "name": "object",
              "isArray": false,
              "type": "String",
              "isRequired": true,
              "attributes": []
            },
            "url": {
              "name": "url",
              "isArray": false,
              "type": "String",
              "isRequired": true,
              "attributes": []
            }
          }
        },
        "GetPaymentDetailsResponse": {
          "name": "GetPaymentDetailsResponse",
          "fields": {
            "data": {
              "name": "data",
              "isArray": true,
              "type": {
                "nonModel": "PaymentMethod"
              },
              "isRequired": false,
              "attributes": [],
              "isArrayNullable": false
            },
            "has_more": {
              "name": "has_more",
              "isArray": false,
              "type": "Boolean",
              "isRequired": true,
              "attributes": []
            },
            "object": {
              "name": "object",
              "isArray": false,
              "type": "String",
              "isRequired": true,
              "attributes": []
            },
            "url": {
              "name": "url",
              "isArray": false,
              "type": "String",
              "isRequired": true,
              "attributes": []
            }
          }
        },
        "GetPricesResponse": {
          "name": "GetPricesResponse",
          "fields": {
            "data": {
              "name": "data",
              "isArray": true,
              "type": {
                "nonModel": "Price"
              },
              "isRequired": false,
              "attributes": [],
              "isArrayNullable": false
            },
            "has_more": {
              "name": "has_more",
              "isArray": false,
              "type": "Boolean",
              "isRequired": true,
              "attributes": []
            },
            "object": {
              "name": "object",
              "isArray": false,
              "type": "String",
              "isRequired": true,
              "attributes": []
            },
            "url": {
              "name": "url",
              "isArray": false,
              "type": "String",
              "isRequired": true,
              "attributes": []
            }
          }
        },
        "GetProductsResponse": {
          "name": "GetProductsResponse",
          "fields": {
            "data": {
              "name": "data",
              "isArray": true,
              "type": {
                "nonModel": "Product"
              },
              "isRequired": false,
              "attributes": [],
              "isArrayNullable": false
            },
            "has_more": {
              "name": "has_more",
              "isArray": false,
              "type": "Boolean",
              "isRequired": true,
              "attributes": []
            },
            "object": {
              "name": "object",
              "isArray": false,
              "type": "String",
              "isRequired": true,
              "attributes": []
            },
            "url": {
              "name": "url",
              "isArray": false,
              "type": "String",
              "isRequired": true,
              "attributes": []
            }
          }
        },
        "GetSubscriptionsResponse": {
          "name": "GetSubscriptionsResponse",
          "fields": {
            "data": {
              "name": "data",
              "isArray": true,
              "type": {
                "nonModel": "SubscriptionType"
              },
              "isRequired": false,
              "attributes": [],
              "isArrayNullable": false
            },
            "has_more": {
              "name": "has_more",
              "isArray": false,
              "type": "Boolean",
              "isRequired": true,
              "attributes": []
            },
            "object": {
              "name": "object",
              "isArray": false,
              "type": "String",
              "isRequired": true,
              "attributes": []
            },
            "url": {
              "name": "url",
              "isArray": false,
              "type": "String",
              "isRequired": true,
              "attributes": []
            }
          }
        },
        "GetSubscriptionHistoryByEmailResponse": {
          "name": "GetSubscriptionHistoryByEmailResponse",
          "fields": {
            "data": {
              "name": "data",
              "isArray": true,
              "type": {
                "nonModel": "SubscriptionType"
              },
              "isRequired": false,
              "attributes": [],
              "isArrayNullable": false
            },
            "object": {
              "name": "object",
              "isArray": false,
              "type": "String",
              "isRequired": true,
              "attributes": []
            }
          }
        },
        "LicenseProduct": {
          "name": "LicenseProduct",
          "fields": {
            "id": {
              "name": "id",
              "isArray": false,
              "type": "ID",
              "isRequired": true,
              "attributes": []
            },
            "name": {
              "name": "name",
              "isArray": false,
              "type": "String",
              "isRequired": true,
              "attributes": []
            },
            "description": {
              "name": "description",
              "isArray": false,
              "type": "String",
              "isRequired": false,
              "attributes": []
            },
            "freeLimitInterval": {
              "name": "freeLimitInterval",
              "isArray": false,
              "type": "String",
              "isRequired": false,
              "attributes": []
            },
            "freeLimitQuotum": {
              "name": "freeLimitQuotum",
              "isArray": false,
              "type": "Int",
              "isRequired": false,
              "attributes": []
            }
          }
        },
        "CompanyAddress": {
          "name": "CompanyAddress",
          "fields": {
            "streetAddress": {
              "name": "streetAddress",
              "isArray": false,
              "type": "String",
              "isRequired": true,
              "attributes": []
            },
            "postalCode": {
              "name": "postalCode",
              "isArray": false,
              "type": "String",
              "isRequired": true,
              "attributes": []
            },
            "locality": {
              "name": "locality",
              "isArray": false,
              "type": "String",
              "isRequired": true,
              "attributes": []
            },
            "region": {
              "name": "region",
              "isArray": false,
              "type": "String",
              "isRequired": false,
              "attributes": []
            },
            "country": {
              "name": "country",
              "isArray": false,
              "type": "String",
              "isRequired": true,
              "attributes": []
            }
          }
        },
        "Media": {
          "name": "Media",
          "fields": {
            "title": {
              "name": "title",
              "isArray": false,
              "type": "String",
              "isRequired": true,
              "attributes": []
            },
            "url": {
              "name": "url",
              "isArray": false,
              "type": "String",
              "isRequired": true,
              "attributes": []
            },
            "duration": {
              "name": "duration",
              "isArray": false,
              "type": "String",
              "isRequired": false,
              "attributes": []
            },
            "size": {
              "name": "size",
              "isArray": false,
              "type": "Float",
              "isRequired": false,
              "attributes": []
            }
          }
        },
        "QuizOption": {
          "name": "QuizOption",
          "fields": {
            "id": {
              "name": "id",
              "isArray": false,
              "type": "String",
              "isRequired": true,
              "attributes": []
            },
            "option": {
              "name": "option",
              "isArray": false,
              "type": "String",
              "isRequired": true,
              "attributes": []
            },
            "isCorrect": {
              "name": "isCorrect",
              "isArray": false,
              "type": "Boolean",
              "isRequired": true,
              "attributes": []
            }
          }
        },
        "Quiz": {
          "name": "Quiz",
          "fields": {
            "question": {
              "name": "question",
              "isArray": false,
              "type": "String",
              "isRequired": false,
              "attributes": []
            },
            "options": {
              "name": "options",
              "isArray": true,
              "type": {
                "nonModel": "QuizOption"
              },
              "isRequired": false,
              "attributes": [],
              "isArrayNullable": true
            }
          }
        },
        "PollOption": {
          "name": "PollOption",
          "fields": {
            "id": {
              "name": "id",
              "isArray": false,
              "type": "String",
              "isRequired": true,
              "attributes": []
            },
            "option": {
              "name": "option",
              "isArray": false,
              "type": "String",
              "isRequired": true,
              "attributes": []
            },
            "count": {
              "name": "count",
              "isArray": false,
              "type": "Int",
              "isRequired": true,
              "attributes": []
            }
          }
        },
        "ReflectionPoll": {
          "name": "ReflectionPoll",
          "fields": {
            "prompt": {
              "name": "prompt",
              "isArray": false,
              "type": "String",
              "isRequired": true,
              "attributes": []
            },
            "totalParticipants": {
              "name": "totalParticipants",
              "isArray": false,
              "type": "Int",
              "isRequired": true,
              "attributes": []
            },
            "options": {
              "name": "options",
              "isArray": true,
              "type": {
                "nonModel": "PollOption"
              },
              "isRequired": false,
              "attributes": [],
              "isArrayNullable": true
            }
          }
        },
        "Article": {
          "name": "Article",
          "fields": {
            "title": {
              "name": "title",
              "isArray": false,
              "type": "String",
              "isRequired": true,
              "attributes": []
            },
            "body": {
              "name": "body",
              "isArray": false,
              "type": "String",
              "isRequired": true,
              "attributes": []
            },
            "description": {
              "name": "description",
              "isArray": false,
              "type": "String",
              "isRequired": false,
              "attributes": []
            }
          }
        },
        "StickerPack": {
          "name": "StickerPack",
          "fields": {
            "packName": {
              "name": "packName",
              "isArray": false,
              "type": "String",
              "isRequired": true,
              "attributes": []
            },
            "tray": {
              "name": "tray",
              "isArray": false,
              "type": {
                "nonModel": "Media"
              },
              "isRequired": true,
              "attributes": []
            },
            "stickers": {
              "name": "stickers",
              "isArray": true,
              "type": {
                "nonModel": "Sticker"
              },
              "isRequired": false,
              "attributes": [],
              "isArrayNullable": false
            }
          }
        },
        "Sticker": {
          "name": "Sticker",
          "fields": {
            "sticker": {
              "name": "sticker",
              "isArray": false,
              "type": {
                "nonModel": "Media"
              },
              "isRequired": true,
              "attributes": []
            },
            "shortcut": {
              "name": "shortcut",
              "isArray": false,
              "type": "String",
              "isRequired": true,
              "attributes": []
            }
          }
        },
        "Mission": {
          "name": "Mission",
          "fields": {
            "missionId": {
              "name": "missionId",
              "isArray": false,
              "type": "ID",
              "isRequired": false,
              "attributes": []
            },
            "name": {
              "name": "name",
              "isArray": false,
              "type": "String",
              "isRequired": true,
              "attributes": []
            },
            "isEnabled": {
              "name": "isEnabled",
              "isArray": false,
              "type": "Boolean",
              "isRequired": true,
              "attributes": []
            },
            "total": {
              "name": "total",
              "isArray": false,
              "type": "Int",
              "isRequired": true,
              "attributes": []
            }
          }
        },
        "ChallengeHackProgress": {
          "name": "ChallengeHackProgress",
          "fields": {
            "hackId": {
              "name": "hackId",
              "isArray": false,
              "type": "String",
              "isRequired": false,
              "attributes": []
            },
            "isWatched": {
              "name": "isWatched",
              "isArray": false,
              "type": "Boolean",
              "isRequired": false,
              "attributes": []
            }
          }
        },
        "ChallengeInsightProgress": {
          "name": "ChallengeInsightProgress",
          "fields": {
            "insightId": {
              "name": "insightId",
              "isArray": false,
              "type": "String",
              "isRequired": false,
              "attributes": []
            },
            "isWatched": {
              "name": "isWatched",
              "isArray": false,
              "type": "Boolean",
              "isRequired": false,
              "attributes": []
            }
          }
        },
        "WeeklyMissionAchievement": {
          "name": "WeeklyMissionAchievement",
          "fields": {
            "missionId": {
              "name": "missionId",
              "isArray": false,
              "type": "String",
              "isRequired": true,
              "attributes": []
            },
            "count": {
              "name": "count",
              "isArray": false,
              "type": "Int",
              "isRequired": true,
              "attributes": []
            },
            "target": {
              "name": "target",
              "isArray": false,
              "type": "Int",
              "isRequired": false,
              "attributes": []
            },
            "notes": {
              "name": "notes",
              "isArray": false,
              "type": "String",
              "isRequired": false,
              "attributes": []
            }
          }
        },
        "Points": {
          "name": "Points",
          "fields": {
            "current": {
              "name": "current",
              "isArray": false,
              "type": "Int",
              "isRequired": false,
              "attributes": []
            },
            "platinumTrophy": {
              "name": "platinumTrophy",
              "isArray": false,
              "type": "Int",
              "isRequired": false,
              "attributes": []
            },
            "goldTrophy": {
              "name": "goldTrophy",
              "isArray": false,
              "type": "Int",
              "isRequired": false,
              "attributes": []
            },
            "silverTrophy": {
              "name": "silverTrophy",
              "isArray": false,
              "type": "Int",
              "isRequired": false,
              "attributes": []
            },
            "bronzeTrophy": {
              "name": "bronzeTrophy",
              "isArray": false,
              "type": "Int",
              "isRequired": false,
              "attributes": []
            }
          }
        },
        "WeeklyPoints": {
          "name": "WeeklyPoints",
          "fields": {
            "week1": {
              "name": "week1",
              "isArray": false,
              "type": "Int",
              "isRequired": false,
              "attributes": []
            },
            "week2": {
              "name": "week2",
              "isArray": false,
              "type": "Int",
              "isRequired": false,
              "attributes": []
            },
            "week3": {
              "name": "week3",
              "isArray": false,
              "type": "Int",
              "isRequired": false,
              "attributes": []
            },
            "week4": {
              "name": "week4",
              "isArray": false,
              "type": "Int",
              "isRequired": false,
              "attributes": []
            }
          }
        },
        "RelatedContent": {
          "name": "RelatedContent",
          "fields": {
            "hacks": {
              "name": "hacks",
              "isArray": true,
              "type": {
                "nonModel": "ContentSummary"
              },
              "isRequired": false,
              "attributes": [],
              "isArrayNullable": true
            },
            "insights": {
              "name": "insights",
              "isArray": true,
              "type": {
                "nonModel": "ContentSummary"
              },
              "isRequired": false,
              "attributes": [],
              "isArrayNullable": true
            }
          }
        },
        "UserMilestoneHistory": {
          "name": "UserMilestoneHistory",
          "fields": {
            "milestoneHistory": {
              "name": "milestoneHistory",
              "isArray": true,
              "type": {
                "nonModel": "MilestoneHistory"
              },
              "isRequired": false,
              "attributes": [],
              "isArrayNullable": true
            }
          }
        },
        "ContentSummary": {
          "name": "ContentSummary",
          "fields": {
            "id": {
              "name": "id",
              "isArray": false,
              "type": "ID",
              "isRequired": true,
              "attributes": []
            },
            "title": {
              "name": "title",
              "isArray": false,
              "type": "String",
              "isRequired": false,
              "attributes": []
            },
            "locale": {
              "name": "locale",
              "isArray": false,
              "type": "String",
              "isRequired": false,
              "attributes": []
            },
            "tags": {
              "name": "tags",
              "isArray": true,
              "type": "String",
              "isRequired": false,
              "attributes": [],
              "isArrayNullable": true
            },
            "thumbnail": {
              "name": "thumbnail",
              "isArray": false,
              "type": {
                "nonModel": "Media"
              },
              "isRequired": false,
              "attributes": []
            },
            "video": {
              "name": "video",
              "isArray": false,
              "type": {
                "nonModel": "Media"
              },
              "isRequired": false,
              "attributes": []
            }
          }
        },
        "MilestoneHistory": {
          "name": "MilestoneHistory",
          "fields": {
            "milestoneId": {
              "name": "milestoneId",
              "isArray": false,
              "type": "String",
              "isRequired": true,
              "attributes": []
            },
            "title": {
              "name": "title",
              "isArray": false,
              "type": "String",
              "isRequired": true,
              "attributes": []
            },
            "timePeriods": {
              "name": "timePeriods",
              "isArray": false,
              "type": "String",
              "isRequired": true,
              "attributes": []
            },
            "weeklyPeriods": {
              "name": "weeklyPeriods",
              "isArray": true,
              "type": "String",
              "isRequired": false,
              "attributes": [],
              "isArrayNullable": false
            },
            "week1": {
              "name": "week1",
              "isArray": true,
              "type": {
                "nonModel": "WeeklyMissionAchievement"
              },
              "isRequired": false,
              "attributes": [],
              "isArrayNullable": true
            },
            "week2": {
              "name": "week2",
              "isArray": true,
              "type": {
                "nonModel": "WeeklyMissionAchievement"
              },
              "isRequired": false,
              "attributes": [],
              "isArrayNullable": true
            },
            "week3": {
              "name": "week3",
              "isArray": true,
              "type": {
                "nonModel": "WeeklyMissionAchievement"
              },
              "isRequired": false,
              "attributes": [],
              "isArrayNullable": true
            },
            "week4": {
              "name": "week4",
              "isArray": true,
              "type": {
                "nonModel": "WeeklyMissionAchievement"
              },
              "isRequired": false,
              "attributes": [],
              "isArrayNullable": true
            },
            "points": {
              "name": "points",
              "isArray": false,
              "type": {
                "nonModel": "Points"
              },
              "isRequired": false,
              "attributes": []
            },
            "weeklyPoints": {
              "name": "weeklyPoints",
              "isArray": false,
              "type": {
                "nonModel": "WeeklyPoints"
              },
              "isRequired": false,
              "attributes": []
            }
          }
        },
        "PositiveMessageBasedOnMood": {
          "name": "PositiveMessageBasedOnMood",
          "fields": {
            "positiveMessage": {
              "name": "positiveMessage",
              "isArray": false,
              "type": {
                "nonModel": "PositiveMessageByMoods"
              },
              "isRequired": false,
              "attributes": []
            }
          }
        },
        "BoostAppReport": {
          "name": "BoostAppReport",
          "fields": {
            "totalAppActivity": {
              "name": "totalAppActivity",
              "isArray": false,
              "type": "Int",
              "isRequired": true,
              "attributes": []
            },
            "totalOpenAppActivity": {
              "name": "totalOpenAppActivity",
              "isArray": false,
              "type": "Int",
              "isRequired": true,
              "attributes": []
            },
            "totalActiveUser": {
              "name": "totalActiveUser",
              "isArray": false,
              "type": "Int",
              "isRequired": true,
              "attributes": []
            },
            "topActiveUsers": {
              "name": "topActiveUsers",
              "isArray": true,
              "type": {
                "nonModel": "TopActivity"
              },
              "isRequired": false,
              "attributes": [],
              "isArrayNullable": true
            },
            "contents": {
              "name": "contents",
              "isArray": false,
              "type": {
                "nonModel": "BoostAppReportContents"
              },
              "isRequired": false,
              "attributes": []
            }
          }
        },
        "PositiveMessageByMoods": {
          "name": "PositiveMessageByMoods",
          "fields": {
            "feelingType": {
              "name": "feelingType",
              "isArray": false,
              "type": {
                "enum": "FeelingType"
              },
              "isRequired": true,
              "attributes": []
            },
            "message": {
              "name": "message",
              "isArray": false,
              "type": "String",
              "isRequired": true,
              "attributes": []
            },
            "locale": {
              "name": "locale",
              "isArray": false,
              "type": {
                "enum": "Locale"
              },
              "isRequired": false,
              "attributes": []
            }
          }
        },
        "ContentActivity": {
          "name": "ContentActivity",
          "fields": {
            "totalWatchedNumbers": {
              "name": "totalWatchedNumbers",
              "isArray": false,
              "type": "Int",
              "isRequired": false,
              "attributes": []
            },
            "mostWatched": {
              "name": "mostWatched",
              "isArray": true,
              "type": "ID",
              "isRequired": false,
              "attributes": [],
              "isArrayNullable": true
            },
            "totalActivity": {
              "name": "totalActivity",
              "isArray": false,
              "type": "Int",
              "isRequired": false,
              "attributes": []
            },
            "mostBookmarked": {
              "name": "mostBookmarked",
              "isArray": true,
              "type": {
                "nonModel": "TopActivity"
              },
              "isRequired": false,
              "attributes": [],
              "isArrayNullable": true
            }
          }
        },
        "TopActivity": {
          "name": "TopActivity",
          "fields": {
            "id": {
              "name": "id",
              "isArray": false,
              "type": "ID",
              "isRequired": true,
              "attributes": []
            },
            "count": {
              "name": "count",
              "isArray": false,
              "type": "Int",
              "isRequired": true,
              "attributes": []
            }
          }
        },
        "LastMonthPrize": {
          "name": "LastMonthPrize",
          "fields": {
            "lastMonthPrize": {
              "name": "lastMonthPrize",
              "isArray": false,
              "type": {
                "nonModel": "LastMonthPrizeLastMonthPrize"
              },
              "isRequired": false,
              "attributes": []
            }
          }
        },
        "CodeValidation": {
          "name": "CodeValidation",
          "fields": {
            "isCodeValid": {
              "name": "isCodeValid",
              "isArray": false,
              "type": "Boolean",
              "isRequired": true,
              "attributes": []
            },
            "isSeatsAvailable": {
              "name": "isSeatsAvailable",
              "isArray": false,
              "type": "Boolean",
              "isRequired": true,
              "attributes": []
            },
            "message": {
              "name": "message",
              "isArray": false,
              "type": "String",
              "isRequired": false,
              "attributes": []
            },
            "companyDetails": {
              "name": "companyDetails",
              "isArray": false,
              "type": {
                "nonModel": "CompanyDetails"
              },
              "isRequired": false,
              "attributes": []
            }
          }
        },
        "CreateNewBoostUser": {
          "name": "CreateNewBoostUser",
          "fields": {
            "id": {
              "name": "id",
              "isArray": false,
              "type": "ID",
              "isRequired": false,
              "attributes": []
            },
            "cognitoUserId": {
              "name": "cognitoUserId",
              "isArray": false,
              "type": "ID",
              "isRequired": false,
              "attributes": []
            },
            "name": {
              "name": "name",
              "isArray": false,
              "type": "String",
              "isRequired": false,
              "attributes": []
            },
            "profile_picture": {
              "name": "profile_picture",
              "isArray": false,
              "type": "String",
              "isRequired": false,
              "attributes": []
            },
            "appTypes": {
              "name": "appTypes",
              "isArray": true,
              "type": {
                "enum": "AppType"
              },
              "isRequired": false,
              "attributes": [],
              "isArrayNullable": true
            },
            "deviceToken": {
              "name": "deviceToken",
              "isArray": false,
              "type": "String",
              "isRequired": false,
              "attributes": []
            },
            "updatedAt": {
              "name": "updatedAt",
              "isArray": false,
              "type": "String",
              "isRequired": false,
              "attributes": []
            }
          }
        },
        "CompanyDetails": {
          "name": "CompanyDetails",
          "fields": {
            "id": {
              "name": "id",
              "isArray": false,
              "type": "ID",
              "isRequired": false,
              "attributes": []
            },
            "name": {
              "name": "name",
              "isArray": false,
              "type": "String",
              "isRequired": false,
              "attributes": []
            },
            "ownerIds": {
              "name": "ownerIds",
              "isArray": true,
              "type": "ID",
              "isRequired": false,
              "attributes": [],
              "isArrayNullable": true
            },
            "address": {
              "name": "address",
              "isArray": false,
              "type": {
                "nonModel": "CompanyAddress"
              },
              "isRequired": false,
              "attributes": []
            },
            "phoneNumber": {
              "name": "phoneNumber",
              "isArray": false,
              "type": "AWSPhone",
              "isRequired": false,
              "attributes": []
            },
            "users": {
              "name": "users",
              "isArray": true,
              "type": "ID",
              "isRequired": false,
              "attributes": [],
              "isArrayNullable": true
            },
            "licenseId": {
              "name": "licenseId",
              "isArray": false,
              "type": "ID",
              "isRequired": false,
              "attributes": []
            },
            "license": {
              "name": "license",
              "isArray": false,
              "type": {
                "nonModel": "CompanyDetailsLicense"
              },
              "isRequired": false,
              "attributes": []
            },
            "companyLogo": {
              "name": "companyLogo",
              "isArray": false,
              "type": {
                "nonModel": "Media"
              },
              "isRequired": false,
              "attributes": []
            }
          }
        },
        "AutoCancelSubscriptionReturnType": {
          "name": "AutoCancelSubscriptionReturnType",
          "fields": {
            "success": {
              "name": "success",
              "isArray": false,
              "type": "Boolean",
              "isRequired": true,
              "attributes": []
            },
            "message": {
              "name": "message",
              "isArray": false,
              "type": "String",
              "isRequired": true,
              "attributes": []
            },
            "canceledSubscriptions": {
              "name": "canceledSubscriptions",
              "isArray": true,
              "type": "String",
              "isRequired": false,
              "attributes": [],
              "isArrayNullable": true
            }
          }
        },
        "HacksRelatedContents": {
          "name": "HacksRelatedContents",
          "fields": {
            "hacks": {
              "name": "hacks",
              "isArray": true,
              "type": "ID",
              "isRequired": false,
              "attributes": [],
              "isArrayNullable": true
            },
            "insights": {
              "name": "insights",
              "isArray": true,
              "type": "ID",
              "isRequired": false,
              "attributes": [],
              "isArrayNullable": true
            }
          }
        },
        "UpdateContentTypeAndPublishedDateReturnType": {
          "name": "UpdateContentTypeAndPublishedDateReturnType",
          "fields": {
            "success": {
              "name": "success",
              "isArray": false,
              "type": "Boolean",
              "isRequired": false,
              "attributes": []
            }
          }
        },
        "CheckoutSessionLine_items": {
          "name": "CheckoutSessionLine_items",
          "fields": {
            "data": {
              "name": "data",
              "isArray": true,
              "type": {
                "nonModel": "Item"
              },
              "isRequired": false,
              "attributes": [],
              "isArrayNullable": false
            },
            "has_more": {
              "name": "has_more",
              "isArray": false,
              "type": "Boolean",
              "isRequired": true,
              "attributes": []
            },
            "object": {
              "name": "object",
              "isArray": false,
              "type": {
                "enum": "CheckoutSessionLineItemsObject"
              },
              "isRequired": false,
              "attributes": []
            },
            "url": {
              "name": "url",
              "isArray": false,
              "type": "String",
              "isRequired": true,
              "attributes": []
            }
          }
        },
        "CustomerSources": {
          "name": "CustomerSources",
          "fields": {
            "data": {
              "name": "data",
              "isArray": true,
              "type": "AWSJSON",
              "isRequired": false,
              "attributes": [],
              "isArrayNullable": false
            },
            "has_more": {
              "name": "has_more",
              "isArray": false,
              "type": "Boolean",
              "isRequired": true,
              "attributes": []
            },
            "object": {
              "name": "object",
              "isArray": false,
              "type": {
                "enum": "CustomerSourcesObject"
              },
              "isRequired": false,
              "attributes": []
            },
            "url": {
              "name": "url",
              "isArray": false,
              "type": "String",
              "isRequired": true,
              "attributes": []
            }
          }
        },
        "CustomerSubscriptions": {
          "name": "CustomerSubscriptions",
          "fields": {
            "data": {
              "name": "data",
              "isArray": true,
              "type": {
                "nonModel": "SubscriptionType"
              },
              "isRequired": false,
              "attributes": [],
              "isArrayNullable": false
            },
            "has_more": {
              "name": "has_more",
              "isArray": false,
              "type": "Boolean",
              "isRequired": true,
              "attributes": []
            },
            "object": {
              "name": "object",
              "isArray": false,
              "type": {
                "enum": "CustomerSubscriptionsObject"
              },
              "isRequired": false,
              "attributes": []
            },
            "url": {
              "name": "url",
              "isArray": false,
              "type": "String",
              "isRequired": true,
              "attributes": []
            }
          }
        },
        "CustomerTax_ids": {
          "name": "CustomerTax_ids",
          "fields": {
            "data": {
              "name": "data",
              "isArray": true,
              "type": {
                "nonModel": "TaxId"
              },
              "isRequired": false,
              "attributes": [],
              "isArrayNullable": false
            },
            "has_more": {
              "name": "has_more",
              "isArray": false,
              "type": "Boolean",
              "isRequired": true,
              "attributes": []
            },
            "object": {
              "name": "object",
              "isArray": false,
              "type": {
                "enum": "CustomerTaxIdsObject"
              },
              "isRequired": false,
              "attributes": []
            },
            "url": {
              "name": "url",
              "isArray": false,
              "type": "String",
              "isRequired": true,
              "attributes": []
            }
          }
        },
        "SubscriptionTypeItems": {
          "name": "SubscriptionTypeItems",
          "fields": {
            "data": {
              "name": "data",
              "isArray": true,
              "type": {
                "nonModel": "SubscriptionItem"
              },
              "isRequired": false,
              "attributes": [],
              "isArrayNullable": false
            },
            "has_more": {
              "name": "has_more",
              "isArray": false,
              "type": "Boolean",
              "isRequired": true,
              "attributes": []
            },
            "object": {
              "name": "object",
              "isArray": false,
              "type": {
                "enum": "SubscriptionTypeItemsObject"
              },
              "isRequired": false,
              "attributes": []
            },
            "url": {
              "name": "url",
              "isArray": false,
              "type": "String",
              "isRequired": true,
              "attributes": []
            }
          }
        },
        "BoostAppReportContents": {
          "name": "BoostAppReportContents",
          "fields": {
            "hack": {
              "name": "hack",
              "isArray": false,
              "type": {
                "nonModel": "ContentActivity"
              },
              "isRequired": false,
              "attributes": []
            },
            "insight": {
              "name": "insight",
              "isArray": false,
              "type": {
                "nonModel": "ContentActivity"
              },
              "isRequired": false,
              "attributes": []
            },
            "challenge": {
              "name": "challenge",
              "isArray": false,
              "type": {
                "nonModel": "ContentActivity"
              },
              "isRequired": false,
              "attributes": []
            }
          }
        },
        "LastMonthPrizeLastMonthPrize": {
          "name": "LastMonthPrizeLastMonthPrize",
          "fields": {
            "isHaveClaimablePrize": {
              "name": "isHaveClaimablePrize",
              "isArray": false,
              "type": "Boolean",
              "isRequired": true,
              "attributes": []
            },
            "userMilestone": {
              "name": "userMilestone",
              "isArray": false,
              "type": {
                "nonModel": "LastMonthPrizeLastMonthPrizeUserMilestone"
              },
              "isRequired": false,
              "attributes": []
            }
          }
        },
        "CompanyDetailsLicense": {
          "name": "CompanyDetailsLicense",
          "fields": {
            "endDate": {
              "name": "endDate",
              "isArray": false,
              "type": "AWSTimestamp",
              "isRequired": false,
              "attributes": []
            },
            "freeLimitUsed": {
              "name": "freeLimitUsed",
              "isArray": false,
              "type": "Int",
              "isRequired": false,
              "attributes": []
            },
            "id": {
              "name": "id",
              "isArray": false,
              "type": "ID",
              "isRequired": false,
              "attributes": []
            },
            "product": {
              "name": "product",
              "isArray": false,
              "type": {
                "nonModel": "LicenseProduct"
              },
              "isRequired": false,
              "attributes": []
            },
            "paymentTerm": {
              "name": "paymentTerm",
              "isArray": false,
              "type": {
                "enum": "IntervalEnum"
              },
              "isRequired": false,
              "attributes": []
            },
            "seats": {
              "name": "seats",
              "isArray": false,
              "type": "Int",
              "isRequired": false,
              "attributes": []
            },
            "startDate": {
              "name": "startDate",
              "isArray": false,
              "type": "AWSTimestamp",
              "isRequired": false,
              "attributes": []
            },
            "subscriptionId": {
              "name": "subscriptionId",
              "isArray": false,
              "type": "ID",
              "isRequired": false,
              "attributes": []
            },
            "type": {
              "name": "type",
              "isArray": false,
              "type": {
                "enum": "LicenseTypeEnum"
              },
              "isRequired": false,
              "attributes": []
            },
            "userId": {
              "name": "userId",
              "isArray": false,
              "type": "ID",
              "isRequired": false,
              "attributes": []
            },
            "users": {
              "name": "users",
              "isArray": true,
              "type": "ID",
              "isRequired": false,
              "attributes": [],
              "isArrayNullable": true
            }
          }
        },
        "LastMonthPrizeLastMonthPrizeUserMilestone": {
          "name": "LastMonthPrizeLastMonthPrizeUserMilestone",
          "fields": {
            "milestoneId": {
              "name": "milestoneId",
              "isArray": false,
              "type": "String",
              "isRequired": true,
              "attributes": []
            },
            "userId": {
              "name": "userId",
              "isArray": false,
              "type": "String",
              "isRequired": true,
              "attributes": []
            },
            "startDate": {
              "name": "startDate",
              "isArray": false,
              "type": "AWSDate",
              "isRequired": false,
              "attributes": []
            },
            "endDate": {
              "name": "endDate",
              "isArray": false,
              "type": "AWSDate",
              "isRequired": false,
              "attributes": []
            },
            "week1": {
              "name": "week1",
              "isArray": true,
              "type": {
                "nonModel": "WeeklyMissionAchievement"
              },
              "isRequired": false,
              "attributes": [],
              "isArrayNullable": true
            },
            "week2": {
              "name": "week2",
              "isArray": true,
              "type": {
                "nonModel": "WeeklyMissionAchievement"
              },
              "isRequired": false,
              "attributes": [],
              "isArrayNullable": true
            },
            "week3": {
              "name": "week3",
              "isArray": true,
              "type": {
                "nonModel": "WeeklyMissionAchievement"
              },
              "isRequired": false,
              "attributes": [],
              "isArrayNullable": true
            },
            "week4": {
              "name": "week4",
              "isArray": true,
              "type": {
                "nonModel": "WeeklyMissionAchievement"
              },
              "isRequired": false,
              "attributes": [],
              "isArrayNullable": true
            },
            "points": {
              "name": "points",
              "isArray": false,
              "type": {
                "nonModel": "Points"
              },
              "isRequired": false,
              "attributes": []
            },
            "weeklyPoints": {
              "name": "weeklyPoints",
              "isArray": false,
              "type": {
                "nonModel": "WeeklyPoints"
              },
              "isRequired": false,
              "attributes": []
            }
          }
        }
      },
      "queries": {
        "checkCompanyExists": {
          "name": "checkCompanyExists",
          "isArray": false,
          "type": "Boolean",
          "isRequired": false,
          "arguments": {
            "companyName": {
              "name": "companyName",
              "isArray": false,
              "type": "String",
              "isRequired": true
            }
          }
        },
        "getSeatCalculation": {
          "name": "getSeatCalculation",
          "isArray": false,
          "type": {
            "nonModel": "getSeatCalculationResult"
          },
          "isRequired": false,
          "arguments": {
            "licenseId": {
              "name": "licenseId",
              "isArray": false,
              "type": "ID",
              "isRequired": true
            }
          }
        },
        "listCompanyByAdmin": {
          "name": "listCompanyByAdmin",
          "isArray": true,
          "type": "String",
          "isRequired": false,
          "isArrayNullable": true
        },
        "checkUserExistInCompany": {
          "name": "checkUserExistInCompany",
          "isArray": false,
          "type": "Boolean",
          "isRequired": false,
          "arguments": {
            "email": {
              "name": "email",
              "isArray": false,
              "type": "AWSEmail",
              "isRequired": true
            },
            "companyName": {
              "name": "companyName",
              "isArray": false,
              "type": "String",
              "isRequired": true
            }
          }
        },
        "listCompaniesOverview": {
          "name": "listCompaniesOverview",
          "isArray": true,
          "type": {
            "nonModel": "ListCompaniesOverviewResponse"
          },
          "isRequired": false,
          "isArrayNullable": true
        },
        "getCompanyDetailsById": {
          "name": "getCompanyDetailsById",
          "isArray": false,
          "type": {
            "nonModel": "getCompanyDetailsByIdResult"
          },
          "isRequired": false,
          "arguments": {
            "licenseId": {
              "name": "licenseId",
              "isArray": false,
              "type": "ID",
              "isRequired": true
            }
          }
        },
        "getProductById": {
          "name": "getProductById",
          "isArray": false,
          "type": {
            "nonModel": "Product"
          },
          "isRequired": false,
          "arguments": {
            "id": {
              "name": "id",
              "isArray": false,
              "type": "ID",
              "isRequired": true
            }
          }
        },
        "getLicenseDetailById": {
          "name": "getLicenseDetailById",
          "isArray": false,
          "type": {
            "nonModel": "GetLicenseDetailByIdResponse"
          },
          "isRequired": false,
          "arguments": {
            "id": {
              "name": "id",
              "isArray": false,
              "type": "ID",
              "isRequired": true
            }
          }
        },
        "checkUserRoles": {
          "name": "checkUserRoles",
          "isArray": false,
          "type": "String",
          "isRequired": false,
          "arguments": {
            "companyName": {
              "name": "companyName",
              "isArray": false,
              "type": "String",
              "isRequired": true
            },
            "email": {
              "name": "email",
              "isArray": false,
              "type": "AWSEmail",
              "isRequired": true
            }
          }
        },
        "getUserGroup": {
          "name": "getUserGroup",
          "isArray": true,
          "type": "String",
          "isRequired": false,
          "isArrayNullable": true,
          "arguments": {
            "email": {
              "name": "email",
              "isArray": false,
              "type": "AWSEmail",
              "isRequired": true
            }
          }
        },
        "checkEmailExists": {
          "name": "checkEmailExists",
          "isArray": false,
          "type": "Boolean",
          "isRequired": false,
          "arguments": {
            "email": {
              "name": "email",
              "isArray": false,
              "type": "AWSEmail",
              "isRequired": true
            }
          }
        },
        "getConfigurations": {
          "name": "getConfigurations",
          "isArray": false,
          "type": {
            "nonModel": "GetConfigurationsResponse"
          },
          "isRequired": false
        },
        "getCustomer": {
          "name": "getCustomer",
          "isArray": false,
          "type": "String",
          "isRequired": false,
          "arguments": {
            "email": {
              "name": "email",
              "isArray": false,
              "type": "AWSEmail",
              "isRequired": true
            }
          }
        },
        "getCustomers": {
          "name": "getCustomers",
          "isArray": false,
          "type": {
            "nonModel": "GetCustomersResponse"
          },
          "isRequired": false
        },
        "getHasTrial": {
          "name": "getHasTrial",
          "isArray": false,
          "type": "Boolean",
          "isRequired": false,
          "arguments": {
            "email": {
              "name": "email",
              "isArray": false,
              "type": "AWSEmail",
              "isRequired": true
            }
          }
        },
        "getItems": {
          "name": "getItems",
          "isArray": false,
          "type": {
            "nonModel": "GetItemsResponse"
          },
          "isRequired": false,
          "arguments": {
            "id": {
              "name": "id",
              "isArray": false,
              "type": "ID",
              "isRequired": true
            }
          }
        },
        "getPaymentDetails": {
          "name": "getPaymentDetails",
          "isArray": false,
          "type": {
            "nonModel": "GetPaymentDetailsResponse"
          },
          "isRequired": false,
          "arguments": {
            "email": {
              "name": "email",
              "isArray": false,
              "type": "AWSEmail",
              "isRequired": true
            }
          }
        },
        "getPrices": {
          "name": "getPrices",
          "isArray": false,
          "type": {
            "nonModel": "GetPricesResponse"
          },
          "isRequired": false
        },
        "getProducts": {
          "name": "getProducts",
          "isArray": false,
          "type": {
            "nonModel": "GetProductsResponse"
          },
          "isRequired": false
        },
        "getSubscription": {
          "name": "getSubscription",
          "isArray": false,
          "type": {
            "nonModel": "SubscriptionType"
          },
          "isRequired": false,
          "arguments": {
            "id": {
              "name": "id",
              "isArray": false,
              "type": "ID",
              "isRequired": true
            }
          }
        },
        "getSubscriptionCustomer": {
          "name": "getSubscriptionCustomer",
          "isArray": false,
          "type": {
            "nonModel": "SubscriptionType"
          },
          "isRequired": false,
          "arguments": {
            "email": {
              "name": "email",
              "isArray": false,
              "type": "AWSEmail",
              "isRequired": true
            }
          }
        },
        "getSubscriptions": {
          "name": "getSubscriptions",
          "isArray": false,
          "type": {
            "nonModel": "GetSubscriptionsResponse"
          },
          "isRequired": false
        },
        "getPriceByIds": {
          "name": "getPriceByIds",
          "isArray": true,
          "type": {
            "nonModel": "Price"
          },
          "isRequired": false,
          "isArrayNullable": true,
          "arguments": {
            "id": {
              "name": "id",
              "isArray": true,
              "type": "ID",
              "isRequired": false,
              "isArrayNullable": false
            }
          }
        },
        "getUpcomingInvoice": {
          "name": "getUpcomingInvoice",
          "isArray": false,
          "type": {
            "nonModel": "Invoice"
          },
          "isRequired": false,
          "arguments": {
            "subId": {
              "name": "subId",
              "isArray": false,
              "type": "String",
              "isRequired": true
            }
          }
        },
        "getSubscriptionHistoryByEmail": {
          "name": "getSubscriptionHistoryByEmail",
          "isArray": true,
          "type": {
            "nonModel": "SubscriptionType"
          },
          "isRequired": false,
          "isArrayNullable": true,
          "arguments": {
            "email": {
              "name": "email",
              "isArray": false,
              "type": "AWSEmail",
              "isRequired": true
            }
          }
        },
        "getRelatedContentByTags": {
          "name": "getRelatedContentByTags",
          "isArray": false,
          "type": {
            "nonModel": "RelatedContent"
          },
          "isRequired": false,
          "arguments": {
            "tags": {
              "name": "tags",
              "isArray": true,
              "type": "String",
              "isRequired": false,
              "isArrayNullable": false
            }
          }
        },
        "listUserMilestoneHistories": {
          "name": "listUserMilestoneHistories",
          "isArray": false,
          "type": {
            "nonModel": "UserMilestoneHistory"
          },
          "isRequired": false,
          "arguments": {
            "userId": {
              "name": "userId",
              "isArray": false,
              "type": "String",
              "isRequired": true
            }
          }
        },
        "getRecommendedChallenges": {
          "name": "getRecommendedChallenges",
          "isArray": true,
          "type": {
            "model": "Challenges"
          },
          "isRequired": false,
          "isArrayNullable": true,
          "arguments": {
            "tags": {
              "name": "tags",
              "isArray": true,
              "type": "String",
              "isRequired": false,
              "isArrayNullable": false
            }
          }
        },
        "updateContentTypeAndPublishedDate": {
          "name": "updateContentTypeAndPublishedDate",
          "isArray": false,
          "type": {
            "nonModel": "UpdateContentTypeAndPublishedDateReturnType"
          },
          "isRequired": false
        },
        "getBoostAppReport": {
          "name": "getBoostAppReport",
          "isArray": false,
          "type": {
            "nonModel": "BoostAppReport"
          },
          "isRequired": false,
          "arguments": {
            "startDate": {
              "name": "startDate",
              "isArray": false,
              "type": "String",
              "isRequired": true
            },
            "endDate": {
              "name": "endDate",
              "isArray": false,
              "type": "String",
              "isRequired": true
            }
          }
        },
        "checkLastMonthPrize": {
          "name": "checkLastMonthPrize",
          "isArray": false,
          "type": {
            "nonModel": "LastMonthPrize"
          },
          "isRequired": false,
          "arguments": {
            "userId": {
              "name": "userId",
              "isArray": false,
              "type": "ID",
              "isRequired": true
            }
          }
        },
        "verifySchoolCode": {
          "name": "verifySchoolCode",
          "isArray": false,
          "type": {
            "nonModel": "CodeValidation"
          },
          "isRequired": false,
          "arguments": {
            "code": {
              "name": "code",
              "isArray": false,
              "type": "String",
              "isRequired": true
            }
          }
        }
      },
      "mutations": {
        "createCompanyByAdmin": {
          "name": "createCompanyByAdmin",
          "isArray": false,
          "type": {
            "nonModel": "CreateCompanyByAdminResponse"
          },
          "isRequired": false,
          "arguments": {
            "ownerIds": {
              "name": "ownerIds",
              "isArray": true,
              "type": "String",
              "isRequired": false,
              "isArrayNullable": true
            },
            "streetAddress": {
              "name": "streetAddress",
              "isArray": false,
              "type": "String",
              "isRequired": true
            },
            "postalCode": {
              "name": "postalCode",
              "isArray": false,
              "type": "String",
              "isRequired": true
            },
            "region": {
              "name": "region",
              "isArray": false,
              "type": "String",
              "isRequired": false
            },
            "locality": {
              "name": "locality",
              "isArray": false,
              "type": "String",
              "isRequired": true
            },
            "country": {
              "name": "country",
              "isArray": false,
              "type": "String",
              "isRequired": true
            },
            "users": {
              "name": "users",
              "isArray": true,
              "type": "String",
              "isRequired": false,
              "isArrayNullable": true
            },
            "companyPhoneNumber": {
              "name": "companyPhoneNumber",
              "isArray": false,
              "type": "AWSPhone",
              "isRequired": true
            },
            "companyEmail": {
              "name": "companyEmail",
              "isArray": false,
              "type": "String",
              "isRequired": true
            },
            "billingEmail": {
              "name": "billingEmail",
              "isArray": false,
              "type": "String",
              "isRequired": true
            },
            "licenseId": {
              "name": "licenseId",
              "isArray": false,
              "type": "String",
              "isRequired": false
            },
            "email": {
              "name": "email",
              "isArray": false,
              "type": "String",
              "isRequired": true
            },
            "phoneNumber": {
              "name": "phoneNumber",
              "isArray": false,
              "type": "AWSPhone",
              "isRequired": true
            },
            "firstName": {
              "name": "firstName",
              "isArray": false,
              "type": "String",
              "isRequired": true
            },
            "lastName": {
              "name": "lastName",
              "isArray": false,
              "type": "String",
              "isRequired": true
            },
            "gender": {
              "name": "gender",
              "isArray": false,
              "type": {
                "enum": "CreateCompanyByAdminGender"
              },
              "isRequired": false
            },
            "locale": {
              "name": "locale",
              "isArray": false,
              "type": "String",
              "isRequired": true
            },
            "companyName": {
              "name": "companyName",
              "isArray": false,
              "type": "String",
              "isRequired": true
            },
            "createdAt": {
              "name": "createdAt",
              "isArray": false,
              "type": "AWSDateTime",
              "isRequired": false
            },
            "roles": {
              "name": "roles",
              "isArray": false,
              "type": "String",
              "isRequired": true
            },
            "paymentTerm": {
              "name": "paymentTerm",
              "isArray": false,
              "type": {
                "enum": "CreateCompanyByAdminPaymentTerm"
              },
              "isRequired": false
            },
            "productId": {
              "name": "productId",
              "isArray": false,
              "type": "String",
              "isRequired": true
            },
            "productName": {
              "name": "productName",
              "isArray": false,
              "type": "String",
              "isRequired": true
            },
            "productDesc": {
              "name": "productDesc",
              "isArray": false,
              "type": "String",
              "isRequired": false
            },
            "freeLimitInterval": {
              "name": "freeLimitInterval",
              "isArray": false,
              "type": "String",
              "isRequired": false
            },
            "freeLimitQuotum": {
              "name": "freeLimitQuotum",
              "isArray": false,
              "type": "Int",
              "isRequired": false
            },
            "endDate": {
              "name": "endDate",
              "isArray": false,
              "type": "AWSDateTime",
              "isRequired": false
            },
            "startDate": {
              "name": "startDate",
              "isArray": false,
              "type": "AWSDateTime",
              "isRequired": false
            },
            "freeLimitUsed": {
              "name": "freeLimitUsed",
              "isArray": false,
              "type": "Int",
              "isRequired": false
            },
            "seats": {
              "name": "seats",
              "isArray": false,
              "type": "Int",
              "isRequired": false
            },
            "licenseType": {
              "name": "licenseType",
              "isArray": false,
              "type": {
                "enum": "CreateCompanyByAdminLicenseType"
              },
              "isRequired": false
            },
            "logoTitle": {
              "name": "logoTitle",
              "isArray": false,
              "type": "String",
              "isRequired": false
            },
            "logoUrl": {
              "name": "logoUrl",
              "isArray": false,
              "type": "String",
              "isRequired": false
            },
            "logoSize": {
              "name": "logoSize",
              "isArray": false,
              "type": "Float",
              "isRequired": false
            },
            "departments": {
              "name": "departments",
              "isArray": true,
              "type": "String",
              "isRequired": false,
              "isArrayNullable": true
            }
          }
        },
        "addUserToCompany": {
          "name": "addUserToCompany",
          "isArray": false,
          "type": {
            "nonModel": "AddUserToCompanyResponse"
          },
          "isRequired": false,
          "arguments": {
            "companyName": {
              "name": "companyName",
              "isArray": false,
              "type": "String",
              "isRequired": true
            },
            "email": {
              "name": "email",
              "isArray": false,
              "type": "AWSEmail",
              "isRequired": true
            },
            "phoneNumber": {
              "name": "phoneNumber",
              "isArray": false,
              "type": "AWSPhone",
              "isRequired": true
            },
            "firstName": {
              "name": "firstName",
              "isArray": false,
              "type": "String",
              "isRequired": true
            },
            "lastName": {
              "name": "lastName",
              "isArray": false,
              "type": "String",
              "isRequired": true
            },
            "roles": {
              "name": "roles",
              "isArray": false,
              "type": "String",
              "isRequired": true
            },
            "gender": {
              "name": "gender",
              "isArray": false,
              "type": "String",
              "isRequired": true
            },
            "locale": {
              "name": "locale",
              "isArray": false,
              "type": "String",
              "isRequired": true
            },
            "createdAt": {
              "name": "createdAt",
              "isArray": false,
              "type": "AWSDateTime",
              "isRequired": false
            }
          }
        },
        "deleteCompanyByAdmin": {
          "name": "deleteCompanyByAdmin",
          "isArray": false,
          "type": "String",
          "isRequired": false,
          "arguments": {
            "companyName": {
              "name": "companyName",
              "isArray": false,
              "type": "String",
              "isRequired": true
            }
          }
        },
        "deactivateUserSeat": {
          "name": "deactivateUserSeat",
          "isArray": false,
          "type": {
            "nonModel": "DeactivateUserSeatResponse"
          },
          "isRequired": false,
          "arguments": {
            "userLicenseId": {
              "name": "userLicenseId",
              "isArray": false,
              "type": "ID",
              "isRequired": true
            }
          }
        },
        "startAISession": {
          "name": "startAISession",
          "isArray": false,
          "type": {
            "nonModel": "AISessionResponse"
          },
          "isRequired": false,
          "arguments": {
            "userId": {
              "name": "userId",
              "isArray": false,
              "type": "String",
              "isRequired": true
            },
            "clientId": {
              "name": "clientId",
              "isArray": false,
              "type": "String",
              "isRequired": true
            }
          }
        },
        "cancelSubscription": {
          "name": "cancelSubscription",
          "isArray": false,
          "type": {
            "nonModel": "SubscriptionType"
          },
          "isRequired": false,
          "arguments": {
            "subId": {
              "name": "subId",
              "isArray": false,
              "type": "String",
              "isRequired": false
            },
            "email": {
              "name": "email",
              "isArray": false,
              "type": "AWSEmail",
              "isRequired": true
            }
          }
        },
        "createBillingSession": {
          "name": "createBillingSession",
          "isArray": false,
          "type": {
            "nonModel": "BillingPortalSession"
          },
          "isRequired": false,
          "arguments": {
            "email": {
              "name": "email",
              "isArray": false,
              "type": "AWSEmail",
              "isRequired": true
            }
          }
        },
        "createCheckoutSession": {
          "name": "createCheckoutSession",
          "isArray": false,
          "type": {
            "nonModel": "CheckoutSession"
          },
          "isRequired": false,
          "arguments": {
            "email": {
              "name": "email",
              "isArray": false,
              "type": "AWSEmail",
              "isRequired": true
            },
            "price": {
              "name": "price",
              "isArray": false,
              "type": "String",
              "isRequired": true
            },
            "trialDate": {
              "name": "trialDate",
              "isArray": false,
              "type": {
                "enum": "CreateCheckoutSessionTrialDate"
              },
              "isRequired": false
            }
          }
        },
        "createSubscription": {
          "name": "createSubscription",
          "isArray": false,
          "type": {
            "nonModel": "SubscriptionType"
          },
          "isRequired": false,
          "arguments": {
            "email": {
              "name": "email",
              "isArray": false,
              "type": "AWSEmail",
              "isRequired": true
            },
            "price": {
              "name": "price",
              "isArray": false,
              "type": "String",
              "isRequired": true
            }
          }
        },
        "removeItem": {
          "name": "removeItem",
          "isArray": false,
          "type": {
            "nonModel": "SubscriptionItem"
          },
          "isRequired": false,
          "arguments": {
            "id": {
              "name": "id",
              "isArray": false,
              "type": "ID",
              "isRequired": true
            }
          }
        },
        "resumeSubscription": {
          "name": "resumeSubscription",
          "isArray": false,
          "type": {
            "nonModel": "SubscriptionType"
          },
          "isRequired": false,
          "arguments": {
            "subId": {
              "name": "subId",
              "isArray": false,
              "type": "String",
              "isRequired": false
            },
            "email": {
              "name": "email",
              "isArray": false,
              "type": "AWSEmail",
              "isRequired": true
            }
          }
        },
        "updateSubscription": {
          "name": "updateSubscription",
          "isArray": false,
          "type": {
            "nonModel": "SubscriptionType"
          },
          "isRequired": false,
          "arguments": {
            "subId": {
              "name": "subId",
              "isArray": false,
              "type": "String",
              "isRequired": false
            },
            "email": {
              "name": "email",
              "isArray": false,
              "type": "AWSEmail",
              "isRequired": true
            },
            "price": {
              "name": "price",
              "isArray": false,
              "type": "String",
              "isRequired": true
            },
            "coupon": {
              "name": "coupon",
              "isArray": false,
              "type": "String",
              "isRequired": false
            }
          }
        },
        "autoCancelSubscription": {
          "name": "autoCancelSubscription",
          "isArray": false,
          "type": {
            "nonModel": "AutoCancelSubscriptionReturnType"
          },
          "isRequired": false,
          "arguments": {
            "reason": {
              "name": "reason",
              "isArray": false,
              "type": "String",
              "isRequired": false
            }
          }
        },
        "createTodaysMood": {
          "name": "createTodaysMood",
          "isArray": false,
          "type": {
            "nonModel": "PositiveMessageBasedOnMood"
          },
          "isRequired": false,
          "arguments": {
            "userId": {
              "name": "userId",
              "isArray": false,
              "type": "ID",
              "isRequired": true
            },
            "feeling": {
              "name": "feeling",
              "isArray": false,
              "type": "String",
              "isRequired": true
            },
            "indicator": {
              "name": "indicator",
              "isArray": false,
              "type": "Int",
              "isRequired": true
            },
            "thoughts": {
              "name": "thoughts",
              "isArray": false,
              "type": "String",
              "isRequired": false
            },
            "locale": {
              "name": "locale",
              "isArray": false,
              "type": "String",
              "isRequired": true
            }
          }
        },
        "createNewBoostUsers": {
          "name": "createNewBoostUsers",
          "isArray": false,
          "type": {
            "nonModel": "CreateNewBoostUser"
          },
          "isRequired": false,
          "arguments": {
            "cognitoUsername": {
              "name": "cognitoUsername",
              "isArray": false,
              "type": "String",
              "isRequired": true
            },
            "cognitoUserId": {
              "name": "cognitoUserId",
              "isArray": false,
              "type": "String",
              "isRequired": true
            },
            "name": {
              "name": "name",
              "isArray": false,
              "type": "String",
              "isRequired": true
            },
            "appTypes": {
              "name": "appTypes",
              "isArray": false,
              "type": {
                "enum": "CreateNewBoostUsersAppTypes"
              },
              "isRequired": false
            },
            "deviceToken": {
              "name": "deviceToken",
              "isArray": false,
              "type": "String",
              "isRequired": false
            },
            "companyId": {
              "name": "companyId",
              "isArray": false,
              "type": "ID",
              "isRequired": true
            },
            "classId": {
              "name": "classId",
              "isArray": false,
              "type": "ID",
              "isRequired": false
            }
          }
        }
      }
    }
  },
  "storage": {
    "aws_region": "us-east-1",
    "bucket_name": "amplify-d3s1nyjpucp6ee-de-amplifybackendbucket3e71-a8a9m6oqa2zo",
    "buckets": [
      {
        "name": "amplifyBackend",
        "bucket_name": "amplify-d3s1nyjpucp6ee-de-amplifybackendbucket3e71-a8a9m6oqa2zo",
        "aws_region": "us-east-1",
        "paths": {
          "hack/thumbnails/*": {
            "authenticated": [
              "get",
              "list",
              "write",
              "delete"
            ]
          },
          "hack/videos/*": {
            "authenticated": [
              "get",
              "list",
              "write",
              "delete"
            ]
          },
          "insight/thumbnails/*": {
            "authenticated": [
              "get",
              "list",
              "write",
              "delete"
            ]
          },
          "insight/videos/*": {
            "authenticated": [
              "get",
              "list",
              "write",
              "delete"
            ]
          },
          "challenge/thumbnails/*": {
            "authenticated": [
              "get",
              "list",
              "write",
              "delete"
            ]
          },
          "milestone/stickers/*": {
            "authenticated": [
              "get",
              "list",
              "write",
              "delete"
            ]
          },
          "company/logo/*": {
            "authenticated": [
              "get",
              "list",
              "write",
              "delete"
            ]
          }
        }
      }
    ]
  },
  "version": "1.2",
  "custom": {
    "API": {
      "webhook-api_development": {
        "endpoint": "https://4sl0n3qh0j.execute-api.us-east-1.amazonaws.com/",
        "region": "us-east-1",
        "apiName": "webhook-api_development"
      },
      "http-api_development": {
        "endpoint": "https://2qv8n760tb.execute-api.us-east-1.amazonaws.com/",
        "region": "us-east-1",
        "apiName": "http-api_development"
      }
    }
  }
}
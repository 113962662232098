import { EnvironmentType } from "@app/core/enums";
import outputs from 'amplify_outputs.json'

export const environment = {
    env: EnvironmentType.DEVELOPMENT,
    s3:`https:${outputs.storage.bucket_name}.s3.${outputs.storage.aws_region}.amazonaws.com/`,
    storage: {
      aws_region: outputs.storage.aws_region,
      bucket_name: 'https:'+outputs.storage.bucket_name+'/'
    }
}

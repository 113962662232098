@if (label) {
<label>
  {{ label }}
  @if (required) {
  <span>*</span>
  }
</label>
}

<ng-content></ng-content>

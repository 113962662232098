export enum TagType {
    SLEEP = 'sleep',
    FOCUS = 'focus',
    EMOTION = 'emotion',
    LIFESTYLES = 'lifestyle',
    SELF_ESTEEM = 'self_esteem',
    STIMULUS_PROCESSING = 'stimulus_processing',
    ADDICTION = 'addiction',
    RESILIENCE = 'resilience',
    SLAAP = 'slaap',
    EMOTIE = 'emotie',
    LEVENSSTIJL = 'levensstijl',
    EIGENWAARDE = 'eigenwaarde',
    PRIKKELVERWERKING = 'prikkelverwerking',
    VERSLAVING = 'verslaving',
    WEERBAARHEID = 'weerbaarheid'
}
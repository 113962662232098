import { Component, output } from '@angular/core';
import { NgIconComponent, provideIcons } from '@ng-icons/core';
import {
  saxEyeOutline,
  saxEdit2Outline,
  saxTrashOutline,
} from '@ng-icons/iconsax/outline';

@Component({
  selector: 'app-table-action-data-value',
  standalone: true,
  imports: [NgIconComponent],
  templateUrl: './table-action-data-value.component.html',
  styleUrl: './table-action-data-value.component.scss',
  viewProviders: [
    provideIcons({
      saxEyeOutline,
      saxEdit2Outline,
      saxTrashOutline,
    }),
  ],
})
export class TableActionDataValueComponent {
  public readonly viewDetail = output<void>();

  public readonly update = output<void>();

  public readonly delete = output<void>();

  public viewContentDetail () {
    this.viewDetail.emit();
  }

  public updateContent() {
    this.update.emit();
  }

  public deleteContent() {
    this.delete.emit();
  }
}
